import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "app/store";
import {
  getNotificationList,
  listNotificationActions
} from "components/AppBar/Notifications/listNotificationSlice";
import { notificationPermissionStruct } from "components/AppBar/Notifications/notificationPermissions";
import { Notification } from "components/AppBar/Notifications/types";
import { addApplicant, addApplicantActions } from "components/Applicants/addApplicantSlice";
import { applicantPermissionStruct } from "components/Applicants/applicantPermissions";
import {
  deleteApplicant,
  deleteApplicantActions
} from "components/Applicants/deleteApplicantSlice";
import { editApplicant, editApplicantActions } from "components/Applicants/editApplicantSlice";
import { getApplicantList, listApplicantActions } from "components/Applicants/listApplicantSlice";
import { applicantStruct } from "components/Applicants/model";
import { modifyApplicant } from "components/Applicants/modifyApplicant";
import {
  recoverApplicant,
  recoverApplicantActions
} from "components/Applicants/recoverApplicantSlice";
import { Applicant } from "components/Applicants/types";
import {
  addDeal as addAppOneDeal,
  addDealActions as addAppOneDealActions
} from "components/AppOne/Deal/addDealSlice";
import { appOnePermissionsStruct } from "components/AppOne/Deal/appOnePermissions";
import { dealStruct as appOneDealStruct } from "components/AppOne/Deal/model";
import { Deal as AppOneDeal } from "components/AppOne/Deal/types";
import { addContract, addContractActions } from "components/Contracts/addContractSlice";
import { contractPermissionsStruct } from "components/Contracts/contractPermissions";
import {
  addContractType,
  addContractTypeActions
} from "components/Contracts/ContractTypes/addContractTypeSlice";
import { contractTypePermissionsStruct } from "components/Contracts/ContractTypes/contractTypePermissions";
import {
  deleteContractType,
  deleteContractTypeActions
} from "components/Contracts/ContractTypes/deleteContractTypeSlice";
import {
  editContractType,
  editContractTypeActions
} from "components/Contracts/ContractTypes/editContractTypeSlice";
import {
  getContractTypeList,
  listContractTypeActions
} from "components/Contracts/ContractTypes/listContractTypeSlice";
import { contractTypeStruct } from "components/Contracts/ContractTypes/model";
import {
  recoverContractType,
  recoverContractTypeActions
} from "components/Contracts/ContractTypes/recoverContractTypeSlice";
import { ContractType } from "components/Contracts/ContractTypes/types";
import { deleteContract, deleteContractActions } from "components/Contracts/deleteContractSlice";
import { editContract, editContractActions } from "components/Contracts/editContractSlice";
import { getContractList, listContractActions } from "components/Contracts/listContractSlice";
import { contractStruct, editContractStruct } from "components/Contracts/model";
import { recoverContract, recoverContractActions } from "components/Contracts/recoverContractSlice";
import { Contract } from "components/Contracts/types";
import { addCreditScore, addCreditScoreActions } from "components/CreditScore/addCreditScoreSlice";
import { creditScorePermissionsStruct } from "components/CreditScore/creditScorePermissions";
import {
  editCreditScore,
  editCreditScoreActions
} from "components/CreditScore/editCreditScoreSlice";
import {
  getCreditScoreList,
  listCreditScoreActions
} from "components/CreditScore/listCreditScoreSlice";
import { creditScoreStruct } from "components/CreditScore/model";
import { CreditBureau } from "components/CreditScore/types";
import {
  addCustomReport,
  addCustomReportActions
} from "components/CustomReport/addCustomReportSlice";
import { customReportPermissionStruct } from "components/CustomReport/customReportPermissions";
import {
  deleteCustomReport,
  deleteCustomReportActions
} from "components/CustomReport/deleteCustomReportSlice";
import {
  editCustomReport,
  editCustomReportActions
} from "components/CustomReport/editCustomReportSlice";
import {
  getCustomReportList,
  listCustomReportActions
} from "components/CustomReport/listCustomReportSlice";
import { customReportStruct } from "components/CustomReport/model";
import {
  recoverCustomReport,
  recoverCustomReportActions
} from "components/CustomReport/recoverCustomReportSlice";
import { CustomReport } from "components/CustomReport/types";
import { addDealership, addDealershipActions } from "components/Dealerships/addDealershipSlice";
import { dealershipPermissionStruct } from "components/Dealerships/dealershipPermissions";
import {
  deleteDealership,
  deleteDealershipActions
} from "components/Dealerships/deleteDealershipSlice";
import { editDealership, editDealershipActions } from "components/Dealerships/editDealershipSlice";
import {
  getDealershipList,
  listDealershipActions
} from "components/Dealerships/listDealershipSlice";
import { dealershipStruct, editDealershipStruct } from "components/Dealerships/model";
import modifyDealership from "components/Dealerships/modifyDealership";
import {
  recoverDealership,
  recoverDealershipActions
} from "components/Dealerships/recoverDealershipSlice";
import { Dealership } from "components/Dealerships/types";
import {
  addDealerTrackDeal,
  addDealerTrackDealActions
} from "components/DealerTrack/addDealerTrackSlice";
import { dealertrackPermissionsStruct } from "components/DealerTrack/dealertrackPermissions";
import { dealerTypeStruct } from "components/DealerTrack/model";
import { Deal as DealerTrackDeal } from "components/DealerTrack/types";
import { addDeal, addDealActions } from "components/Deals/addDealSlice";
import { calculate } from "components/Deals/Calculations";
import { dealPermissionsStruct } from "components/Deals/dealsPermissions";
import { deleteDeal, deleteDealActions } from "components/Deals/deleteDealSlice";
import { editDeal, editDealActions } from "components/Deals/editDealSlice";
import { getDealList, listDealActions } from "components/Deals/listDealSlice";
import { addDealStruct, editDealStruct } from "components/Deals/model";
import {
  createInvoiceActions,
  createInvoiceAndVendorBill
} from "components/Deals/Netsuite/createInvoiceAndVendorBillSlice";
import { netsuitePermissionsStruct } from "components/Deals/Netsuite/netsuitePermissionsStruct";
import { recoverDeal, recoverDealActions } from "components/Deals/recoverDealSlice";
import { Deal } from "components/Deals/types";
import { deliveredDealPermissionStruct } from "components/DeliveredDeals/deliveredDealPermissions";
import { DeliveredDeal } from "components/DeliveredDeals/types";
import { addDmv, addDmvActions } from "components/Dmv/addDmvSlice";
import { deleteDmv, deleteDmvActions } from "components/Dmv/deleteDmvSlice";
import { dmvPermissionsStruct } from "components/Dmv/DmvPermissions";
import { editDmv, editDmvActions } from "components/Dmv/editDmvSlice";
import { getDmvList, listDmvActions } from "components/Dmv/listDmvSlice";
import { dmvStruct } from "components/Dmv/model";
import { recoverDmv, recoverDmvActions } from "components/Dmv/recoverDmvSlice";
import { Dmv } from "components/Dmv/types";
import {
  addDocumentTemplate,
  addDocumentTemplateActions
} from "components/DocumentTemplates/addDocumentTemplateSlice";
import {
  deleteDocumentTemplate,
  deleteDocumentTemplateActions
} from "components/DocumentTemplates/deleteDocumentTemplateSlice";
import { documentTemplatePermissionStruct } from "components/DocumentTemplates/documentTemplatePermissions";
import {
  editDocumentTemplate,
  editDocumentTemplateActions
} from "components/DocumentTemplates/editDocumentTemplateSlice";
import {
  getDocumentTemplateList,
  listDocumentTemplateActions
} from "components/DocumentTemplates/listDocumentTemplateSlice";
import {
  addDocumentTemplateStruct,
  editDocumentTemplateStruct
} from "components/DocumentTemplates/model";
import {
  recoverDocumentTemplate,
  recoverDocumentTemplateActions
} from "components/DocumentTemplates/recoverDocumentTemplateSlice";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import {
  addExternalCreditApplication,
  addExternalCreditApplicationActions
} from "components/ExternalCreditApplications/addExternalCreditApplicationSlice";
import {
  deleteExternalCreditApplication,
  deleteExternalCreditApplicationActions
} from "components/ExternalCreditApplications/deleteExternalCreditApplicationSlice";
import {
  editExternalCreditApplication,
  editExternalCreditApplicationActions
} from "components/ExternalCreditApplications/editExternalCreditApplicationSlice";
import { externalCreditApplicationPermissionStruct } from "components/ExternalCreditApplications/externalCreditApplicationPermissions";
import {
  getExternalCreditApplicationList,
  listExternalCreditApplicationActions
} from "components/ExternalCreditApplications/listExternalCreditApplicationSlice";
import { ExternalCreditApplicationStruct } from "components/ExternalCreditApplications/model";
import {
  recoverExternalCreditApplication,
  recoverExternalCreditApplicationActions
} from "components/ExternalCreditApplications/recoverExternalCreditApplicationSlice";
import { ExternalCreditApplication } from "components/ExternalCreditApplications/types";
import { addFile, addFileActions } from "components/Files/addFileSlice";
import { deleteFile, deleteFileActions } from "components/Files/deleteFileSlice";
import { editFile, editFileActions } from "components/Files/editFileSlice";
import { filePermissionStruct } from "components/Files/filePermissions";
import { getFileList, listFileActions } from "components/Files/listFileSlice";
import { fileStruct } from "components/Files/model";
import { modifyFile } from "components/Files/modifyFile";
import { recoverFile, recoverFileActions } from "components/Files/recoverFileSlice";
import { File } from "components/Files/types";
import { floorplanXpressPermissionStruct } from "components/FloorplanXpress/floorplanxpressPermissions";
import {
  getFloorplanXpressList,
  listFloorplanXpressActions
} from "components/FloorplanXpress/listFloorplanXpressSlice";
import { floorplanXpressStruct } from "components/FloorplanXpress/model";
import { FloorplanXpress } from "components/FloorplanXpress/types";
import {
  addFundingDocument,
  addFundingDocumentActions
} from "components/FundingDocuments/addFundingDocumentSlice";
import {
  deleteFundingDocument,
  deleteFundingDocumentActions
} from "components/FundingDocuments/deleteFundingDocumentSlice";
import {
  editFundingDocument,
  editFundingDocumentActions
} from "components/FundingDocuments/editFundingDocumentSlice";
import { fundingDocumentPermissionStruct } from "components/FundingDocuments/fundingDocumentsPermissions";
import {
  getFundingDocumentList,
  listFundingDocumentActions
} from "components/FundingDocuments/listFundingDocumentSlice";
import { FundingDocumentStruct } from "components/FundingDocuments/model";
import {
  recoverFundingDocument,
  recoverFundingDocumentActions
} from "components/FundingDocuments/recoverFundingDoucmentSlice";
import { FundingDocument } from "components/FundingDocuments/types";
import { historyPermissionStruct } from "components/Histories/historiesPermissions";
import { listHistoriesActions } from "components/Histories/listHistoriesSlice";
import {
  addLenderDecision,
  addLenderDecisionActions
} from "components/LenderDecisions/addLenderDecisionsSlice";
import {
  addManyLenderDecisions,
  addManyLenderDecisionsActions
} from "components/LenderDecisions/addManyLenderDecisionsSlice";
import {
  deleteLenderDecision,
  deleteLenderDecisionActions
} from "components/LenderDecisions/deleteLenderDecisionSlice";
import {
  editLenderDecision,
  editLenderDecisionActions
} from "components/LenderDecisions/editLenderDecisionSlice";
import { lenderDescisionPermissionStruct } from "components/LenderDecisions/lenderDecisionsPermissions";
import {
  getLenderDecision,
  listLenderDecisionActions
} from "components/LenderDecisions/listLenderDecisionsSlice";
import { lenderDecisionStruct } from "components/LenderDecisions/model";
import {
  recoverLenderDecision,
  recoverLenderDecisionActions
} from "components/LenderDecisions/recoverLenderDecisionSlice";
import { LenderDescision } from "components/LenderDecisions/types";
import { addLender, addLenderActions } from "components/Lenders/addLenderSlice";
import { deleteLender, deleteLenderActions } from "components/Lenders/deleteLenderSlice";
import { editLender, editLenderActions } from "components/Lenders/editLenderSlice";
import { lenderPermissionStruct } from "components/Lenders/lenderPermissions";
import { getLenderList, listLenderActions } from "components/Lenders/listLenderSlice";
import { lenderStruct } from "components/Lenders/model";
import { recoverLender, recoverLenderActions } from "components/Lenders/recoverLenderSlice";
import { Lender } from "components/Lenders/types";
import { addOtherVendor, addOtherVendorActions } from "components/OtherVendors/addOtherVendorSlice";
import {
  deleteOtherVendor,
  deleteOtherVendorActions
} from "components/OtherVendors/deleteOtherVendorsSlice";
import {
  editOtherVendor,
  editOtherVendorActions
} from "components/OtherVendors/editOtherVendorSlice";
import {
  getOtherVendorList,
  listOtherVendorActions
} from "components/OtherVendors/listOtheVendorsSlice";
import { otherVendorStruct } from "components/OtherVendors/model";
import { otherVendorsPermissionsStruct } from "components/OtherVendors/otherVendorPermissions";
import {
  recoverOtherVendor,
  recoverOtherVendorActions
} from "components/OtherVendors/recoverOtherVendoSlicer";
import { OtherVendor } from "components/OtherVendors/types";
import { addPayoffBank, addPayoffBankActions } from "components/PayoffBanks/addPayoffBankSlice";
import {
  deletePayoffBank,
  deletePayoffBankActions
} from "components/PayoffBanks/deletePayoffBankSlice";
import { editPayoffBank, editPayoffBankActions } from "components/PayoffBanks/editPayoffBankSlice";
import {
  getPayoffBankList,
  listPayoffBankActions
} from "components/PayoffBanks/listPayoffBankSlice";
import { payoffBankStruct } from "components/PayoffBanks/model";
import { payoffBankPermissionStruct } from "components/PayoffBanks/payoffbankPermissions";
import {
  recoverPayoffBank,
  recoverPayoffBankActions
} from "components/PayoffBanks/recoverPayoffBankSlice";
import { PayoffBank } from "components/PayoffBanks/types";
import {
  addBusinessReport,
  addBusinessReportActions
} from "components/Reports/BusinessReports/addBussinesReportSlice";
import { businessReportPermissionStruct } from "components/Reports/BusinessReports/businessReportPermissions";
import {
  deleteBussinesReport,
  deleteBussinesReportActions
} from "components/Reports/BusinessReports/deleteBusinessReportSlice";
import {
  getBusinessReportList,
  listBusinessReporActions
} from "components/Reports/BusinessReports/listBusinessReportsSlice";
import {
  recoverBussinesReport,
  recoverBussinesReportActions
} from "components/Reports/BusinessReports/recoverBusinessReportSlice";
import { BusinessReport } from "components/Reports/BusinessReports/types";
import { addRole, addRoleActions } from "components/Roles/addRoleSlice";
import { deleteRole, deleteRoleActions } from "components/Roles/deleteRoleSlice";
import { editRole, editRoleActions } from "components/Roles/editRoleSlice";
import { getRoleList, listRoleActions } from "components/Roles/listRoleSlice";
import { roleStruct } from "components/Roles/model";
import modifyRole from "components/Roles/modifyRole";
import { recoverRole, recoverRoleActions } from "components/Roles/recoverRoleSlice";
import { RolePermissionData, rolePermissionStruct } from "components/Roles/rolePermissions";
import { PermissionStruct, Role } from "components/Roles/types";
import {
  editSequenceNumber,
  editSequenceNumberActions
} from "components/Sequence/editSequenceNumberSlice";
import { sequencePermissionStruct } from "components/Sequence/sequencePermissions";
import { Sequence } from "components/Sequence/types";
import { editSettings, editSettingsActions } from "components/Settings/editSettingsSlice";
import { getSettingsList, listSettingsActions } from "components/Settings/listSettingsSlice";
import { settingsStruct } from "components/Settings/model";
import { settingsPermissionsStruct } from "components/Settings/settingsPermissions";
import { dealershipProgramPermissionStruct } from "components/DealershipPrograms/dealershipProgramPermissions";
import { managerProgramPermissionStruct } from "components/ManagerPrograms/managerProgramPermissions";
import { Settings } from "components/Settings/types";
import { addState, addStateActions } from "components/States/addStateSlice";
import { deleteState, deleteStateActions } from "components/States/deleteStateSlice";
import { editState, editStateActions } from "components/States/editStateSlice";
import { getStateList, listStateActions } from "components/States/listStateSlice";
import { newStateStruct } from "components/States/model";
import { recoverState, recoverStateActions } from "components/States/recoverStateSlice";
import { statePermissionStruct } from "components/States/statePermissions";
import { State } from "components/States/types";
import {
  addTableSettings,
  addTableSettingsActions
} from "components/Table/TableSettings/addTableSettingsSlice";
import {
  editTableSettings,
  editTableSettingsActions
} from "components/Table/TableSettings/editTableSettingsSlice";
import {
  getTableSettingsList,
  listTableSettingsActions
} from "components/Table/TableSettings/listTableSettingsSlice";
import {
  addDealershipProgram,
  addDealershipProgramActions
} from "components/DealershipPrograms/addDealershipProgramSlice";
import {
  editDealershipProgram,
  editDealershipProgramActions
} from "components/DealershipPrograms/editDealershipProgramSlice";
import {
  getDealershipProgramList,
  listDealershipProgramActions
} from "components/DealershipPrograms/listDealershipProgramSlice";
import {
  deleteDealershipProgram,
  deleteDealershipProgramActions
} from "components/DealershipPrograms/deleteDealershipProgramSlice";
import {
  recoverDealershipProgram,
  recoverDealershipProgramActions
} from "components/DealershipPrograms/recoverDealershipProgramSlice";

import {
  addManagerProgram,
  addManagerProgramActions
} from "components/ManagerPrograms/addManagerProgramSlice";
import {
  editManagerProgram,
  editManagerProgramActions
} from "components/ManagerPrograms/editManagerProgramSlice";
import {
  getManagerProgramList,
  listManagerProgramActions
} from "components/ManagerPrograms/listManagerProgramSlice";
import {
  deleteManagerProgram,
  deleteManagerProgramActions
} from "components/ManagerPrograms/deleteManagerProgramSlice";
import {
  recoverManagerProgram,
  recoverManagerProgramActions
} from "components/ManagerPrograms/recoverManagerProgramSlice";

import { tableSettingsPermissionStruct } from "components/Table/TableSettings/tableSettingsPermissions";
import { TableSettings } from "components/Table/TableSettings/types";
import {
  deleteTitleIssue,
  deleteTitleIssueActions
} from "components/TitleIssues/deleteTitleIssueSlice";
import { editTitleIssue, editTitleIssueActions } from "components/TitleIssues/editTitleIssueSlice";
import {
  getTitleIssueList,
  listTitleIssueActions
} from "components/TitleIssues/listTitleIssueSlice";
import { titleIssueStruct } from "components/TitleIssues/model";
import { dealershipProgramStruct } from "components/DealershipPrograms/model";
import { managerProgramStruct } from "components/ManagerPrograms/model";
import {
  recoverTitleIssue,
  recoverTitleIssueActions
} from "components/TitleIssues/recoverTitleIssueSlice";
import { titleIssuePermissionStruct } from "components/TitleIssues/titleIssuePermissions";
import { TitleIssue } from "components/TitleIssues/types";
import { addUser, addUserActions } from "components/Users/addUserSlice";
import {
  deleteUnapprovedUser,
  deleteUnapprovedUserActions
} from "components/Users/deleteUnapprovedUserSlice";
import { deleteUser, deleteUserActions } from "components/Users/deleteUserSlice";
import { editUser, editUserActions } from "components/Users/editUserSlice";
import {
  getUnapprovedUserList,
  listUnapprovedUserActions
} from "components/Users/listUnapprovedUserSlice";
import { getUserList, listUserActions } from "components/Users/listUserSlice";
import { addUserStruct, unapproveUserStruct, userStruct } from "components/Users/model";
import { recoverUser, recoverUserActions } from "components/Users/recoverUserSlice";
import { UnapprovedUser, User } from "components/Users/types";
import { unapprovedUserPermissionStruct } from "components/Users/upapprovedUserPermissions";
import { userPermissionStruct } from "components/Users/userPermissions";
import { addValidation, addValidationActions } from "components/Validations/addValidationSlice";
import {
  deleteValidation,
  deleteValidationActions
} from "components/Validations/deleteValidationSlice";
import { editValidation, editValidationActions } from "components/Validations/editValidationSlice";
import {
  getValidationsList,
  listValidationActions
} from "components/Validations/listValidationSlice";
import { validationStruct } from "components/Validations/model";
import { Validation } from "components/Validations/types";
import { validationPermissionStruct } from "components/Validations/validationPermissions";
import {
  addVehicleInsuranceCompany,
  addVehicleInsuranceCompanyActions
} from "components/VehicleInsuranceCompany/addVehicleInsuranceCompanySlice";
import {
  deleteVehicleInsuranceCompany,
  deleteVehicleInsuranceCompanyActions
} from "components/VehicleInsuranceCompany/deleteVehicleInsuranceCompanySlice";
import {
  editVehicleInsuranceCompany,
  editVehicleInsuranceCompanyActions
} from "components/VehicleInsuranceCompany/editVehicleInsuranceCompanySlice";
import {
  getVehicleInsuranceCompanyList,
  listVehicleInsuranceCompanyActions
} from "components/VehicleInsuranceCompany/listVehicleInsuranceCompanySlice";
import { vehicleInsuranceCompanyStruct } from "components/VehicleInsuranceCompany/model";
import {
  recoverVehicleInsuranceCompany,
  recoverVehicleInsuranceCompanyActions
} from "components/VehicleInsuranceCompany/recoverVehicleInsuranceCompanySlice";
import { VehicleInsuranceCompany } from "components/VehicleInsuranceCompany/types";
import { vehicleInsuranceCompanyPermissionStruct } from "components/VehicleInsuranceCompany/vehicleInsuranceCompanyPermissions";
import { listApiStatusActions } from "utils/Monitoring/listApiStatusSlice";
import { lockEntity, lockEntityActions } from "../utils/models/LockEntity/lockEntitySlice";
import { Lock } from "../utils/models/LockEntity/types";
import { assertNever } from "./functions";
import { Model, Path } from "./models/fields";
import { setByPath } from "./models/formGenerator";
import { listLockActions } from "./models/LockEntity/listLockSlice";
import { lockPermissionStruct } from "./models/LockEntity/lockPermissions";
import { apiStatusPermissionStruct } from "./Monitoring/apiStatusPermissions";
import { ApiStatus } from "./Monitoring/types";
import { CrudEntity, EntityTypes } from "./types";
import { DealershipProgram } from "components/DealershipPrograms/types";
import {
  addDealershipBills,
  addDealershipBillsActions
} from "components/Reports/DealershipBillsReport/addDealershipBillsSlice";
import {
  listDealershipBillsActions,
  getDealershipBillsList
} from "components/Reports/DealershipBillsReport/listDealershipBillsSlice";
import { validate } from "components/Dealerships/validation";
import {
  addAdditionalRequiredDocumentActions,
  addAdditionalRequiredDocument
} from "components/AdditionalRequiredDocuments/addAdditionalRequiredDocumentSlice";
import {
  deleteAdditionalRequiredDocumentActions,
  deleteAdditionalRequiredDocument
} from "components/AdditionalRequiredDocuments/deleteAdditionalRequiredDocumentSlice";
import {
  editAdditionalRequiredDocumentActions,
  editAdditionalRequiredDocument
} from "components/AdditionalRequiredDocuments/editAdditionalRequiredDocumentSlice";
import {
  listAdditionalRequiredDocumentActions,
  getAdditionalRequiredDocumentList
} from "components/AdditionalRequiredDocuments/listAdditionalRequiredDocumentSlice";
import { AdditionalRequiredDocumentStruct } from "components/AdditionalRequiredDocuments/model";
import {
  recoverAdditionalRequiredDocumentActions,
  recoverAdditionalRequiredDocument
} from "components/AdditionalRequiredDocuments/recoverAdditionalRequiredDocumentSlice";
import { additionalRequiredDocumentPermissionStruct } from "components/AdditionalRequiredDocuments/additionalRequiredDocumentPermissions";
import { AdditionalRequiredDocument } from "components/AdditionalRequiredDocuments/types";
import {
  addPrintedDocumentActions,
  addPrintedDocument
} from "components/PrintedDocuments/addPrintedDocumentSlice";
import {
  deletePrintedDocumentActions,
  deletePrintedDocument
} from "components/PrintedDocuments/deletePrintedDocumentSlice";
import {
  listPrintedDocumentActions,
  getPrintedDocumentList
} from "components/PrintedDocuments/listPrintedDocumentsSlice";
import {
  editPrintedDocument,
  editPrintedDocumentActions
} from "components/PrintedDocuments/editPrintedDocumentSlice";
import { PrintedDocument } from "components/PrintedDocuments/types";
import { printedDocumentsPermissionStruct } from "components/PrintedDocuments/printedDocumentsPermissionStruct";
import { unparsedRequiredDocumentsModel } from "components/UnparsedRequiredDocuments/model";
import { UnparsedRequiredDocument } from "components/UnparsedRequiredDocuments/types";
import {
  unparsedRequiredDocumentRequestActions,
  unparsedRequiredDocumentRequest
} from "components/UnparsedRequiredDocuments/unparsedRequiredDocumentSlice";
import { unparsedRequiredDocumentPermissionsStruct } from "components/UnparsedRequiredDocuments/unparsedRequiredDocumentPermissions";

import {
  editOneSpanSigning,
  editOneSpanSigningActions
} from "components/Deals/OneSpan/editOneSpanSigningSlice";
import { oneSpanPermissionsStruct } from "components/Deals/OneSpan/oneSpanPermissionsStruct";
import { OneSpanSigning } from "components/Deals/OneSpan/types";
import { checklistPermissionsStruct } from "components/Deals/Checklist/checklistPermissions";
import { Checklist } from "components/Deals/Checklist/types";
import {
  getChecklistList,
  listChecklistActions
} from "components/Deals/Checklist/listChecklistSlice";
import { addChecklist, addChecklistActions } from "components/Deals/Checklist/addChecklistSlice";
import { editChecklist, editChecklistActions } from "components/Deals/Checklist/editChecklistSlice";
import { Note } from "components/Notes/types";
import { notePermissionStruct } from "components/Notes/notesPermissions";
import { getNotesList, listNotesActions } from "components/Notes/listNoteSlice";
import { deleteNote, deleteNoteActions } from "components/Notes/deleteNoteSlice";
import { editNote, editNoteActions } from "components/Notes/editNoteSlice";
import { addNote, addNoteActions } from "components/Notes/addNoteSlice";
import { DealerNote } from "components/DealerNotes/types";
import { dealerNotePermissionStruct } from "components/DealerNotes/dealerNotesPermissions";
import {
  getDealerNotesList,
  listDealerNotesActions
} from "components/DealerNotes/listDealerNoteSlice";
import { addDealerNote, addDealerNoteActions } from "components/DealerNotes/addDealerNoteSlice";
import {
  deleteDealerNote,
  deleteDealerNoteActions
} from "components/DealerNotes/deleteDealerNoteSlice";
import { editDealerNote, editDealerNoteActions } from "components/DealerNotes/editDealerNoteSlice";
import {
  deleteStipulation,
  deleteStipulationActions
} from "components/Stipulations/deleteStipulationSlice";
import {
  editStipulation,
  editStipulationActions
} from "components/Stipulations/editStipulationSlice";
import { addStipulation, addStipulationActions } from "components/Stipulations/addStipulationSlice";
import {
  getStipulationsList,
  listStipulationsActions
} from "components/Stipulations/listStipulationsSlice";
import { Stipulation } from "components/Stipulations/types";
import { stipulationPermissionStruct } from "components/Stipulations/stipulationsPermissions";
import { ManagerProgram } from "components/ManagerPrograms/types";
import { LenderTicket } from "components/LenderTickets/types";
import { lenderTicketPermissionsStruct } from "components/LenderTickets/LenderTicketPermissions";

import {
  deleteLenderTicket,
  deleteLenderTicketActions
} from "components/LenderTickets/deleteLenderTicketSlice";
import {
  editLenderTicket,
  editLenderTicketActions
} from "components/LenderTickets/editLenderTicketSlice";
import {
  recoverLenderTicket,
  recoverLenderTicketActions
} from "components/LenderTickets/recoverLenderTicketSlice";
import {
  getLenderTicketList,
  listLenderTicketActions
} from "components/LenderTickets/listLenderTicketSlice";
import { addLenderTicketStruct, lenderTicketStruct } from "components/LenderTickets/model";
import { TitleIssueNote } from "components/TitleIssueNotes/types";
import { titleIssueNotePermissionStruct } from "components/TitleIssueNotes/titleIssueNotePermissions";
import {
  getTitleIssueNotesList,
  listTitleIssueNotesActions
} from "components/TitleIssueNotes/listTitleIssueNoteSlice";
import {
  addTitleIssueNote,
  addTitleIssueNoteActions
} from "components/TitleIssueNotes/addTitleIssueNoteSlice";
import {
  deleteTitleIssueNote,
  deleteTitleIssueNoteActions
} from "components/TitleIssueNotes/deleteTitleIssueNoteSlice";
import { editTitleIssueNoteActions } from "components/TitleIssueNotes/editTitleIssueNoteSlice";
import { LenderNote } from "components/LenderTickets/LenderNotes/types";
import { lenderNotePermissionStruct } from "components/LenderTickets/LenderNotes/lenderNotesPermissions";
import {
  addLenderNoteActions,
  addLenderNote
} from "components/LenderTickets/LenderNotes/addLenderNoteSlice";
import {
  deleteLenderNoteActions,
  deleteLenderNote
} from "components/LenderTickets/LenderNotes/deleteLenderNoteSlice";
import {
  editLenderNoteActions,
  editLenderNote
} from "components/LenderTickets/LenderNotes/editLenderNoteSlice";
import {
  listLenderNotesActions,
  getLenderNotesList
} from "components/LenderTickets/LenderNotes/listLenderNotesSlice";
import { Chargeback } from "components/Chargebacks/types";
import { chargebackPermissionsStruct } from "components/Chargebacks/ChargebackPermission";
import { addChargebackStruct, chargebackStruct } from "components/Chargebacks/model";
import { addChargebackActions, addChargeback } from "components/Chargebacks/addChargebackSlice";
import {
  deleteChargebackActions,
  deleteChargeback
} from "components/Chargebacks/deleteChargebackSlice";
import { editChargebackActions, editChargeback } from "components/Chargebacks/editChargebackSlice";
import {
  listChargebackActions,
  getChargebackList
} from "components/Chargebacks/listChargebackSlice";
import {
  recoverChargebackActions,
  recoverChargeback
} from "components/Chargebacks/recoverChargebackSlice";
import {
  addLenderTicket,
  addLenderTicketActions
} from "components/LenderTickets/addLenderTicketSlice";
import formatDate from "./formatDate";
import { ColumnGroup } from "components/GroupedTable/ColumnGroups/types";
import { columnGroupPermissionStruct } from "components/GroupedTable/ColumnGroups/columnGroupPermissions";
import {
  addColumnGroup,
  addColumnGroupActions
} from "components/GroupedTable/ColumnGroups/addColumnGroupSlice";
import {
  deleteColumnGroup,
  deleteColumnGroupActions
} from "components/GroupedTable/ColumnGroups/deleteColumnGroupSlice";
import {
  editColumnGroup,
  editColumnGroupActions
} from "components/GroupedTable/ColumnGroups/editColumnGroupSlice";
import {
  getColumnGroupList,
  listColumnGroupActions
} from "components/GroupedTable/ColumnGroups/listColumnGroupSlice";
import { CreditScoreRange } from "components/CreditScoreRanges/types";
import { creditScoreRangePermissionStruct } from "components/CreditScoreRanges/creditScoreRangePermissions";
import {
  getCreditScoreRangesList,
  listCreditScoreRangesActions
} from "components/CreditScoreRanges/listCreditScoreRangeSlice";
import {
  addCreditScoreRange,
  addCreditScoreRangeActions
} from "components/CreditScoreRanges/addCreditScoreRangeSlice";
import {
  deleteCreditScoreRange,
  deleteCreditScoreRangeActions
} from "components/CreditScoreRanges/deleteCreditScoreRangeSlice";
import {
  editCreditScoreRange,
  editCreditScoreRangeActions
} from "components/CreditScoreRanges/editCreditScoreRangeSlice";
import {
  recoverCreditScoreRange,
  recoverCreditScoreRangeActions
} from "components/CreditScoreRanges/recoverCreditScoreRangeSlice";
import { creditScoreRange } from "components/CreditScoreRanges/model";
import { DealershipTicket } from "components/DealershipTickets/types";
import { DealershipTicketNote } from "components/DealershipTicketNotes/types";
import { dealershipTicketPermissionsStruct } from "components/DealershipTickets/dealershipTicketsPermissions";
import { dealershipTicketNotePermissionsStruct } from "components/DealershipTicketNotes/dealershipTicketNotesPermissions";
import {
  getDealershipTicketNotesList,
  listDealershipTicketNotesActions
} from "components/DealershipTicketNotes/listDealershipTicketNoteSlice";
import {
  addDealershipTicketNote,
  addDealershipTicketNoteActions
} from "components/DealershipTicketNotes/addDealershipTicketNoteSlice";
import {
  deleteDealershipTicketNote,
  deleteDealershipTicketNoteActions
} from "components/DealershipTicketNotes/deleteDealershipTicketNoteSlice";
import {
  editDealershipTicketNote,
  editDealershipTicketNoteActions
} from "components/DealershipTicketNotes/editDealershipTicketNoteSlice";
import { dealershipTicketStruct } from "components/DealershipTickets/model";
import {
  deleteDealershipTicket,
  deleteDealershipTicketActions
} from "components/DealershipTickets/deleteDealershipTicketSlice";
import {
  editDealershipTicket,
  editDealershipTicketActions
} from "components/DealershipTickets/editDealershipTicketSlice";
import {
  recoverDealershipTicket,
  recoverDealershipTicketActions
} from "components/DealershipTickets/recoverDealershipTicketSlice";
import {
  getDealershipTicketList,
  listDealershipTicketActions
} from "components/DealershipTickets/listDealershipTicketSlice";
import { LenderTicketType } from "components/LenderTicketTypes/types";
import {
  addLenderTicketTypeActions,
  addLenderTicketType
} from "components/LenderTicketTypes/addLenderTicketTypeSlice";
import {
  deleteLenderTicketTypeActions,
  deleteLenderTicketType
} from "components/LenderTicketTypes/deleteLenderTicketTypeSlice";
import {
  editLenderTicketTypeActions,
  editLenderTicketType
} from "components/LenderTicketTypes/editLenderTicketTypeSlice";
import {
  listLenderTicketTypesActions,
  getLenderTicketTypesList
} from "components/LenderTicketTypes/listLenderTicketTypeSlice";
import {
  recoverLenderTicketTypeActions,
  recoverLenderTicketType
} from "components/LenderTicketTypes/recoverLenderTicketTypeSlice";
import { lenderTicketTypePermissionsStruct } from "components/LenderTicketTypes/lenderTicketTypePermissions";
import { lenderTicketTypeModel } from "components/LenderTicketTypes/model";

export type EntityType = keyof typeof EntityTypes;
export type EntityDataTable<T extends EntityType> = {
  dealership_ticket: "dealership_tickets";
  column_group: "column_groups";
  lender_ticket: "lender_tickets";
  chargeback: "chargeback";
  dealer_note: "dealer_notes";
  dealership_ticket_note: "dealership_ticket_notes";
  lender_note: "lender_notes";
  note: "notes";
  title_issue_note: "title_issue_notes";
  checklist: "checklists";
  stipulation: "stipulations";
  dealership_bills_report: "dealership_bills_report";
  table_settings: "table_settings";
  contract: "contracts";
  settings: "settings";
  contract_type: "contract_types";
  lender: "lenders";
  dealership: "dealerships";
  deal: "deals";
  applicant: "applicants";
  title_issue: "title_issues";
  title_dashboard: "title_dashboard";
  dealership_program: "dealership_programs";
  manager_program: "manager_programs";
  wfd_user: "wfd_users";
  dmv: "dmvs";
  custom_report: "custom_reports";
  business_report: "business_report";
  role: "roles";
  document_template: "document_templates";
  document_validation: "document_validations";
  gap_company: "gap_companies";
  insurance_company: "insurance_companies";
  vehicle_insurance_company: "vehicle_insurance_companies";
  file: "files";
  payoff_bank: "payoff_banks";
  other_vendor: "other_vendors";
  state: "states";
  funding_document: "funding_documents";
  appone_import_application: "deals";
  dealertrack_deal: "deals";
  unapproved_user: "users";
  sequence: "sequence";
  credit_application: "credit_applications";
  credit_score: "credit_scores";
  external_credit_application: "external_credit_applications";
  floorplanxpress: "floorplanxpress";
  lock: "lock";
  notification: "notification";
  lender_decision: "lender_decision";
  api_status: "api_status";
  delivered_deal: "delivered_deal";
  history: "histories";
  netsuite: "netsuite";
  additional_required_document: "additional_required_document";
  printed_document: "printed_document";
  unparsed_required_document: "unparsed_required_document";
  one_span_signing: "one_span_signging";
  credit_score_range: "credit_score_range";
  lender_ticket_type: "lender_ticket_type";
  never: never;
}[T];

export const collectionFromName = (name: string) => {
  switch (name) {
    case "dealership_ticket":
      return "dealership_tickets" as EntityDataTable<"dealership_ticket">;
    case "column_group":
      return "column_groups" as EntityDataTable<"column_group">;
    case "dealership_ticket_notes":
      return "dealership_ticket_notes" as EntityDataTable<"dealership_ticket_note">;
    case "title_issue_note":
      return "title_issue_notes" as EntityDataTable<"title_issue_note">;
    case "dealer_note":
      return "dealer_notes" as EntityDataTable<"dealer_note">;
    case "lender_note":
      return "lender_notes" as EntityDataTable<"lender_note">;
    case "note":
      return "notes" as EntityDataTable<"note">;
    case "stipulation":
      return "stipulations" as EntityDataTable<"stipulation">;
    case "checklist":
      return "checklists" as EntityDataTable<"checklist">;
    case "contract":
      return "contracts" as EntityDataTable<"contract">;
    case "contract_type":
      return "contract_types" as EntityDataTable<"contract_type">;
    case "lender":
      return "lenders" as EntityDataTable<"lender">;
    case "dealership":
      return "dealerships" as EntityDataTable<"dealership">;
    case "deal":
      return "deals" as EntityDataTable<"deal">;
    case "applicant":
      return "applicants" as EntityDataTable<"applicant">;
    case "title_issue":
      return "title_issues" as EntityDataTable<"title_issue">;
    case "wfd_user":
      return "wfd_users" as EntityDataTable<"wfd_user">;
    case "dmv":
      return "dmvs" as EntityDataTable<"dmv">;
    case "role":
      return "roles" as EntityDataTable<"role">;
    case "document_template":
      return "document_templates" as EntityDataTable<"document_template">;
    case "vehicle_insurance_company":
      return "vehicle_insurance_companies" as EntityDataTable<"vehicle_insurance_company">;
    case "file":
      return "files" as EntityDataTable<"file">;
    case "funding_document":
      return "funding_documents" as EntityDataTable<"funding_document">;
    case "payoff_bank":
      return "payoff_banks" as EntityDataTable<"payoff_bank">;
    case "other_vendor":
      return "other_vendor" as EntityDataTable<"other_vendor">;
    case "state":
      return "states" as EntityDataTable<"state">;
    case "appone_import_application":
      return "deals" as EntityDataTable<"appone_import_application">;
    case "unapproved_user":
      return "users" as EntityDataTable<"unapproved_user">;
    case "external_credit_application":
      return "external_credit_applications" as EntityDataTable<"external_credit_application">;
    case "notification":
      return "notification" as EntityDataTable<"notification">;
    case "lender_decision":
      return "lender_decision" as EntityDataTable<"lender_decision">;
    case "delivered_deal":
      return "delivered_deal" as EntityDataTable<"delivered_deal">;
    case "history":
      return "histories" as EntityDataTable<"history">;
    case "netsuite":
      return "netsuite" as EntityDataTable<"netsuite">;
    case "one_span_signing":
      return "one_span_signing" as EntityDataTable<"one_span_signing">;
    default:
      return "deals" as EntityDataTable<"deal">;
  }
};
export type PermissionEntityData<T extends EntityType> = T extends "role"
  ? RolePermissionData
  : EntityData<T>;

export type EntityData<T extends EntityType> = {
  dealership_ticket: DealershipTicket;
  dealership_ticket_note: DealershipTicketNote;
  column_group: ColumnGroup;
  lender_ticket: LenderTicket;
  dealer_note: DealerNote;
  lender_note: LenderNote;
  note: Note;
  title_issue_note: TitleIssueNote;
  stipulation: Stipulation;
  checklist: Checklist;
  credit_score_range: CreditScoreRange;
  lender_ticket_type: LenderTicketType;
  dealership_bills_report: Deal;
  table_settings: TableSettings;
  contract: Contract;
  settings: Settings;
  contract_type: ContractType;
  lender: Lender;
  dealership: Dealership;
  deal: Deal;
  representative_deal: Deal;
  applicant: Applicant;
  title_issue: TitleIssue;
  wfd_user: User;
  chargeback: Chargeback;
  dmv: Dmv;
  custom_report: CustomReport;
  business_report: BusinessReport;
  role: Role;
  document_template: DocumentTemplate;
  document_validation: Validation;
  vehicle_insurance_company: VehicleInsuranceCompany;
  file: File;
  funding_document: FundingDocument;
  payoff_bank: PayoffBank;
  other_vendor: OtherVendor;
  state: State;
  appone_import_application: AppOneDeal;
  dealertrack_deal: DealerTrackDeal;
  unapproved_user: UnapprovedUser;
  credit_score: CreditBureau;
  external_credit_application: ExternalCreditApplication;
  insurance_provider_deal: Deal;
  sequence: Sequence;
  floorplanxpress: FloorplanXpress;
  lock: Lock;
  notification: Notification;
  lender_decision: LenderDescision;
  api_status: ApiStatus;
  delivered_deal: DeliveredDeal;
  history: HistoryData;
  netsuite: Netsuite;
  dealership_program: DealershipProgram;
  manager_program: ManagerProgram;
  additional_required_document: AdditionalRequiredDocument;
  printed_document: PrintedDocument;
  unparsed_required_document: UnparsedRequiredDocument;
  one_span_signing: OneSpanSigning;
  never: never;
}[T];
export type EntityConfig<T extends EntityType> = {
  [K in "add" | "addMany" | "edit" | "delete" | "recover" | "list"]: {
    sliceActions: any;
    do: (args: any) => any;
    validate?: (state: any) => { valid: boolean; message?: string };
    model?: Model<EntityData<T>>;
  };
} & {
  showName: (data: EntityData<T>) => string;
  model: Model<EntityData<T>>;
};
type EntityConfigs<T extends EntityType> = EntityConfig<T>;

export const getModel = (type: EntityType) =>
  entityConfigs[type]?.add?.model ?? entityConfigs[type]?.model;

export const getModels = () => Object.keys(entityConfigs);
export type EntityConfigStruct = { [K in EntityType]: EntityConfigs<K> };
export type EntityPermissionStruct = {
  [K in EntityType]: PermissionStruct<PermissionEntityData<K>>;
};

export const permissionStructs: EntityPermissionStruct = {
  dealership_ticket: dealershipTicketPermissionsStruct,
  dealership_ticket_note: dealershipTicketNotePermissionsStruct,
  column_group: columnGroupPermissionStruct,
  lender_ticket: lenderTicketPermissionsStruct,
  dealer_note: dealerNotePermissionStruct,
  lender_note: lenderNotePermissionStruct,
  note: notePermissionStruct,
  title_issue_note: titleIssueNotePermissionStruct,
  stipulation: stipulationPermissionStruct,
  dealership_bills_report: dealPermissionsStruct,
  additional_required_document: additionalRequiredDocumentPermissionStruct,
  table_settings: tableSettingsPermissionStruct,
  dealership: dealershipPermissionStruct,
  notification: notificationPermissionStruct,
  lender_decision: lenderDescisionPermissionStruct,
  floorplanxpress: floorplanXpressPermissionStruct,
  lock: lockPermissionStruct,
  lender: lenderPermissionStruct,
  payoff_bank: payoffBankPermissionStruct,
  state: statePermissionStruct,
  applicant: applicantPermissionStruct,
  wfd_user: userPermissionStruct,
  unapproved_user: unapprovedUserPermissionStruct,
  role: rolePermissionStruct,
  deal: dealPermissionsStruct,
  checklist: checklistPermissionsStruct,
  contract: contractPermissionsStruct,
  appone_import_application: appOnePermissionsStruct,
  dealertrack_deal: dealertrackPermissionsStruct,
  credit_score: creditScorePermissionsStruct,
  credit_score_range: creditScoreRangePermissionStruct,
  lender_ticket_type: lenderTicketTypePermissionsStruct,
  external_credit_application: externalCreditApplicationPermissionStruct,
  dmv: dmvPermissionsStruct,
  custom_report: customReportPermissionStruct,
  business_report: businessReportPermissionStruct,
  title_issue: titleIssuePermissionStruct,
  document_template: documentTemplatePermissionStruct,
  document_validation: validationPermissionStruct,
  file: filePermissionStruct,
  funding_document: fundingDocumentPermissionStruct,
  vehicle_insurance_company: vehicleInsuranceCompanyPermissionStruct,
  sequence: sequencePermissionStruct,
  api_status: apiStatusPermissionStruct,
  delivered_deal: deliveredDealPermissionStruct,
  history: historyPermissionStruct,
  contract_type: contractTypePermissionsStruct,
  other_vendor: otherVendorsPermissionsStruct,
  settings: settingsPermissionsStruct,
  netsuite: netsuitePermissionsStruct,
  chargeback: chargebackPermissionsStruct,
  dealership_program: dealershipProgramPermissionStruct,
  manager_program: managerProgramPermissionStruct,
  printed_document: printedDocumentsPermissionStruct,
  unparsed_required_document: unparsedRequiredDocumentPermissionsStruct,
  one_span_signing: oneSpanPermissionsStruct
};

export const entityConfigs: EntityConfigStruct = {
  dealership: {
    showName(data) {
      return `Dealership - ${data?.data?.info?.displayName ?? data?.data?.info?.name}`;
    },
    model: editDealershipStruct,
    add: {
      model: dealershipStruct,
      sliceActions: addDealershipActions,
      do: addDealership,
      validate: validate
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDealershipActions,
      do: deleteDealership
    },
    edit: {
      sliceActions: editDealershipActions,
      do: editDealership,
      validate: validate
    },
    recover: {
      sliceActions: recoverDealershipActions,
      do: recoverDealership
    },
    list: {
      sliceActions: listDealershipActions,
      do: getDealershipList
    }
  },
  column_group: {
    showName(data) {
      return `N/A`;
    },
    model: {
      formComponent: "model",
      schema: "new_column_group",
      name: "column_group",
      entities: []
    },
    add: {
      sliceActions: addColumnGroupActions,
      do: addColumnGroup
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteColumnGroupActions,
      do: deleteColumnGroup
    },
    edit: {
      sliceActions: editColumnGroupActions,
      do: editColumnGroup
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: listColumnGroupActions,
      do: getColumnGroupList
    }
  },
  chargeback: {
    showName(data) {
      return `Chargeback - ${data?.data?.info?.refId ?? formatDate(data?.createdAt, "short")}`;
    },
    model: chargebackStruct,
    add: {
      model: addChargebackStruct,
      sliceActions: addChargebackActions,
      do: addChargeback
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteChargebackActions,
      do: deleteChargeback
    },
    edit: {
      sliceActions: editChargebackActions,
      do: editChargeback
    },
    recover: {
      sliceActions: recoverChargebackActions,
      do: recoverChargeback
    },
    list: {
      sliceActions: listChargebackActions,
      do: getChargebackList
    }
  },

  unparsed_required_document: {
    showName(data) {
      return `N/A`;
    },
    model: unparsedRequiredDocumentsModel,
    add: {
      model: unparsedRequiredDocumentsModel,
      sliceActions: unparsedRequiredDocumentRequestActions,
      do: unparsedRequiredDocumentRequest
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      model: unparsedRequiredDocumentsModel,
      sliceActions: unparsedRequiredDocumentRequestActions,
      do: unparsedRequiredDocumentRequest
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: null,
      do: () => {}
    }
  },
  dealership_program: {
    showName(data) {
      return `Dealership Program - ${data?.data?.info?.name}`;
    },
    model: dealershipProgramStruct,
    add: {
      sliceActions: addDealershipProgramActions,
      do: addDealershipProgram
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDealershipProgramActions,
      do: deleteDealershipProgram
    },
    edit: {
      sliceActions: editDealershipProgramActions,
      do: editDealershipProgram
    },
    recover: {
      sliceActions: recoverDealershipProgramActions,
      do: recoverDealershipProgram
    },
    list: {
      sliceActions: listDealershipProgramActions,
      do: getDealershipProgramList
    }
  },
  manager_program: {
    showName(data) {
      return `Manager Program - ${data?.data?.info?.name}`;
    },
    model: managerProgramStruct,
    add: {
      sliceActions: addManagerProgramActions,
      do: addManagerProgram
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteManagerProgramActions,
      do: deleteManagerProgram
    },
    edit: {
      sliceActions: editManagerProgramActions,
      do: editManagerProgram
    },
    recover: {
      sliceActions: recoverManagerProgramActions,
      do: recoverManagerProgram
    },
    list: {
      sliceActions: listManagerProgramActions,
      do: getManagerProgramList
    }
  },

  printed_document: {
    showName(data) {
      return `Printed document`;
    },
    model: settingsStruct,
    add: {
      sliceActions: addPrintedDocumentActions,
      do: addPrintedDocument
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deletePrintedDocumentActions,
      do: deletePrintedDocument
    },
    edit: {
      sliceActions: editPrintedDocumentActions,
      do: editPrintedDocument
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: listPrintedDocumentActions,
      do: getPrintedDocumentList
    }
  },
  settings: {
    showName(data) {
      return `Settings`;
    },
    model: settingsStruct,
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: editSettingsActions,
      do: editSettings
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: listSettingsActions,
      do: getSettingsList
    }
  },
  note: {
    model: {
      formComponent: "model",
      schema: "new_note",
      name: "note",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listNotesActions,
      do: getNotesList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: addNoteActions,
      do: addNote
    },
    delete: {
      sliceActions: deleteNoteActions,
      do: deleteNote
    },
    edit: {
      sliceActions: editNoteActions,
      do: editNote
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  dealer_note: {
    model: {
      formComponent: "model",
      schema: "new_dealer_note",
      name: "dealer_note",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listDealerNotesActions,
      do: getDealerNotesList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: addDealerNoteActions,
      do: addDealerNote
    },
    delete: {
      sliceActions: deleteDealerNoteActions,
      do: deleteDealerNote
    },
    edit: {
      sliceActions: editDealerNoteActions,
      do: editDealerNote
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  dealership_ticket_note: {
    model: {
      formComponent: "model",
      schema: "new_dealership_ticket_note",
      name: "dealership_ticket_note",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listDealershipTicketNotesActions,
      do: getDealershipTicketNotesList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: addDealershipTicketNoteActions,
      do: addDealershipTicketNote
    },
    delete: {
      sliceActions: deleteDealershipTicketNoteActions,
      do: deleteDealershipTicketNote
    },
    edit: {
      sliceActions: editDealershipTicketNoteActions,
      do: editDealershipTicketNote
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  title_issue: {
    showName(titleIssue) {
      return `Title Information - ${titleIssue?.data?.deal?.data?.info?.refNumber}`;
    },
    model: titleIssueStruct,
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteTitleIssueActions,
      do: deleteTitleIssue
    },
    edit: {
      sliceActions: editTitleIssueActions,
      do: editTitleIssue
    },
    recover: {
      sliceActions: recoverTitleIssueActions,
      do: recoverTitleIssue
    },
    list: {
      sliceActions: listTitleIssueActions,
      do: getTitleIssueList
    }
  },
  title_issue_note: {
    model: {
      formComponent: "model",
      schema: "new_title_issue_note",
      name: "title_issue_note",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listTitleIssueNotesActions,
      do: getTitleIssueNotesList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: addTitleIssueNoteActions,
      do: addTitleIssueNote
    },
    delete: {
      sliceActions: deleteTitleIssueNoteActions,
      do: deleteTitleIssueNote
    },
    edit: {
      sliceActions: editTitleIssueNoteActions,
      do: editTitleIssue
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  checklist: {
    model: {
      formComponent: "model",
      schema: "new_checklist",
      name: "checklist",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listChecklistActions,
      do: getChecklistList
    },
    add: {
      sliceActions: addChecklistActions,
      do: addChecklist
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: editChecklistActions,
      do: editChecklist
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  lender_note: {
    model: {
      formComponent: "model",
      schema: "new_lender_note",
      name: "lender_note",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listLenderNotesActions,
      do: getLenderNotesList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: addLenderNoteActions,
      do: addLenderNote
    },
    delete: {
      sliceActions: deleteLenderNoteActions,
      do: deleteLenderNote
    },
    edit: {
      sliceActions: editLenderNoteActions,
      do: editLenderNote
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  stipulation: {
    model: {
      formComponent: "model",
      schema: "new_stipulation",
      name: "stipulation",
      entities: []
    },
    showName(data) {
      return `N/A`;
    },
    list: {
      sliceActions: listStipulationsActions,
      do: getStipulationsList
    },
    add: {
      sliceActions: addStipulationActions,
      do: addStipulation
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteStipulationActions,
      do: deleteStipulation
    },
    edit: {
      sliceActions: editStipulationActions,
      do: editStipulation
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  notification: {
    model: {
      formComponent: "model",
      schema: false,
      name: "notification",
      entities: []
    },
    showName(data) {
      return `Notification`;
    },
    list: {
      sliceActions: listNotificationActions,
      do: getNotificationList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  credit_score_range: {
    model: creditScoreRange,
    showName(data) {
      return `Credit Score Range - ${data.data.info.name}`;
    },
    list: {
      sliceActions: listCreditScoreRangesActions,
      do: getCreditScoreRangesList
    },
    add: {
      sliceActions: addCreditScoreRangeActions,
      do: addCreditScoreRange
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteCreditScoreRangeActions,
      do: deleteCreditScoreRange
    },
    edit: {
      sliceActions: editCreditScoreRangeActions,
      do: editCreditScoreRange
    },
    recover: {
      sliceActions: recoverCreditScoreRangeActions,
      do: recoverCreditScoreRange
    }
  },
  lender_ticket_type: {
    model: lenderTicketTypeModel,
    showName(data) {
      return `Lender Ticket Type - ${data.data.info.type}`;
    },
    list: {
      sliceActions: listLenderTicketTypesActions,
      do: getLenderTicketTypesList
    },
    add: {
      sliceActions: addLenderTicketTypeActions,
      do: addLenderTicketType
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteLenderTicketTypeActions,
      do: deleteLenderTicketType
    },
    edit: {
      sliceActions: editLenderTicketTypeActions,
      do: editLenderTicketType
    },
    recover: {
      sliceActions: recoverLenderTicketTypeActions,
      do: recoverLenderTicketType
    }
  },
  table_settings: {
    model: {
      formComponent: "model",
      schema: false,
      name: "table_settings",
      entities: []
    },
    showName(data) {
      return `Table Settings`;
    },
    list: {
      sliceActions: listTableSettingsActions,
      do: getTableSettingsList
    },
    add: {
      sliceActions: addTableSettingsActions,
      do: addTableSettings
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: editTableSettingsActions,
      do: editTableSettings
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  history: {
    model: {
      formComponent: "model",
      schema: false,
      name: "history",
      entities: []
    },
    showName(data) {
      return `History`;
    },
    list: {
      sliceActions: listHistoriesActions,
      do: () => {}
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  netsuite: {
    model: {
      formComponent: "model",
      schema: false,
      name: "netsuite",
      entities: []
    },
    showName(data) {
      return `Netsuite`;
    },
    list: {
      sliceActions: null,
      do: () => {}
    },
    add: {
      sliceActions: createInvoiceActions,
      do: createInvoiceAndVendorBill
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  api_status: {
    model: {
      formComponent: "model",
      schema: false,
      name: "api_status",
      entities: []
    },
    showName(data) {
      return `API Status`;
    },
    list: {
      sliceActions: listApiStatusActions,
      do: () => {}
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  lender_decision: {
    showName(data) {
      return `Lender decision - ${data?.data?.info?.lender}`;
    },
    model: lenderDecisionStruct,
    list: {
      sliceActions: listLenderDecisionActions,
      do: getLenderDecision
    },
    add: {
      sliceActions: addLenderDecisionActions,
      do: addLenderDecision
    },
    addMany: {
      sliceActions: addManyLenderDecisionsActions,
      do: addManyLenderDecisions
    },
    delete: {
      sliceActions: deleteLenderDecisionActions,
      do: deleteLenderDecision
    },
    edit: {
      sliceActions: editLenderDecisionActions,
      do: editLenderDecision
    },
    recover: {
      sliceActions: recoverLenderDecisionActions,
      do: recoverLenderDecision
    }
  },
  floorplanxpress: {
    showName(data) {
      return `FloorplanXpress`;
    },
    model: floorplanXpressStruct,
    list: {
      sliceActions: listFloorplanXpressActions,
      do: getFloorplanXpressList
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  lock: {
    showName() {
      return `Lock`;
    },
    model: {
      formComponent: "model",
      schema: "update_lock",
      name: "lock",
      entities: []
    },
    list: {
      sliceActions: listLockActions,
      do: () => {}
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: lockEntityActions,
      do: lockEntity
    },
    recover: {
      sliceActions: null,
      do: () => {}
    }
  },
  lender: {
    showName(data) {
      return `Lender - ${data.data.info.name}`;
    },
    model: lenderStruct,
    add: {
      sliceActions: addLenderActions,
      do: addLender
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteLenderActions,
      do: deleteLender
    },
    edit: {
      sliceActions: editLenderActions,
      do: editLender
    },
    recover: {
      sliceActions: recoverLenderActions,
      do: recoverLender
    },
    list: {
      sliceActions: listLenderActions,
      do: getLenderList
    }
  },
  payoff_bank: {
    showName(data) {
      return `Payoff Bank - ${data.data.info.name}`;
    },
    model: payoffBankStruct,
    add: {
      sliceActions: addPayoffBankActions,
      do: addPayoffBank
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deletePayoffBankActions,
      do: deletePayoffBank
    },
    edit: {
      sliceActions: editPayoffBankActions,
      do: editPayoffBank
    },
    recover: {
      sliceActions: recoverPayoffBankActions,
      do: recoverPayoffBank
    },
    list: {
      sliceActions: listPayoffBankActions,
      do: getPayoffBankList
    }
  },
  other_vendor: {
    showName(data) {
      return `Other Vendor - ${data.data.info.name}`;
    },
    model: otherVendorStruct,
    add: {
      sliceActions: addOtherVendorActions,
      do: addOtherVendor
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteOtherVendorActions,
      do: deleteOtherVendor
    },
    edit: {
      sliceActions: editOtherVendorActions,
      do: editOtherVendor
    },
    recover: {
      sliceActions: recoverOtherVendorActions,
      do: recoverOtherVendor
    },
    list: {
      sliceActions: listOtherVendorActions,
      do: getOtherVendorList
    }
  },
  state: {
    showName(data) {
      return `State - ${data.data.info.name}`;
    },
    model: newStateStruct,
    add: {
      sliceActions: addStateActions,
      do: addState
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteStateActions,
      do: deleteState
    },
    edit: {
      sliceActions: editStateActions,
      do: editState
    },
    recover: {
      sliceActions: recoverStateActions,
      do: recoverState
    },
    list: {
      sliceActions: listStateActions,
      do: getStateList
    }
  },
  applicant: {
    showName(data) {
      return `Applicant - ${data.data?.info?.lastName}`;
    },
    model: applicantStruct,
    add: {
      sliceActions: addApplicantActions,
      do: addApplicant
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteApplicantActions,
      do: deleteApplicant
    },
    edit: {
      sliceActions: editApplicantActions,
      do: editApplicant
    },
    recover: {
      sliceActions: recoverApplicantActions,
      do: recoverApplicant
    },
    list: {
      sliceActions: listApplicantActions,
      do: getApplicantList
    }
  },
  wfd_user: {
    showName(data) {
      return `User - ${data.data.info.firstName || ""} ${data.data.info.lastName || ""}`;
    },
    model: userStruct,
    add: {
      model: addUserStruct,
      sliceActions: addUserActions,
      do: addUser
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteUserActions,
      do: deleteUser
    },
    edit: {
      sliceActions: editUserActions,
      do: editUser
    },
    recover: {
      sliceActions: recoverUserActions,
      do: recoverUser
    },
    list: {
      sliceActions: listUserActions,
      do: getUserList
    }
  },
  unapproved_user: {
    showName(data) {
      return `User - ${data.data.info.email}`;
    },
    model: unapproveUserStruct,
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteUnapprovedUserActions,
      do: deleteUnapprovedUser
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: listUnapprovedUserActions,
      do: getUnapprovedUserList
    }
  },
  role: {
    showName(data) {
      return `Role - ${data.data.info.name}`;
    },
    model: roleStruct(),
    add: {
      sliceActions: addRoleActions,
      do: addRole
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteRoleActions,
      do: deleteRole
    },
    edit: {
      sliceActions: editRoleActions,
      do: editRole
    },
    recover: {
      sliceActions: recoverRoleActions,
      do: recoverRole
    },
    list: {
      sliceActions: listRoleActions,
      do: getRoleList
    }
  },
  deal: {
    showName(data) {
      return `Deal - ${
        data?.data?.applicant
          ? [
              data?.data?.applicant?.data?.info?.lastName,
              data?.data?.applicant?.data?.info?.firstName
            ].join(" ")
          : "No applicant"
      }`;
    },
    model: editDealStruct,
    add: {
      model: addDealStruct,
      sliceActions: addDealActions,
      do: addDeal
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDealActions,
      do: deleteDeal
    },
    edit: {
      sliceActions: editDealActions,
      do: editDeal
    },
    recover: {
      sliceActions: recoverDealActions,
      do: recoverDeal
    },
    list: {
      sliceActions: listDealActions,
      do: getDealList
    }
  },
  dealership_bills_report: {
    showName(data) {
      return ``;
    },
    model: editDealStruct,
    add: {
      model: undefined,
      sliceActions: addDealershipBillsActions,
      do: addDealershipBills
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: listDealershipBillsActions,
      do: getDealershipBillsList
    }
  },
  contract: {
    showName(data) {
      return `Contract - ${data.data.info.name}`;
    },
    model: editContractStruct,
    add: {
      model: contractStruct,
      sliceActions: addContractActions,
      do: addContract
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteContractActions,
      do: deleteContract
    },
    edit: {
      sliceActions: editContractActions,
      do: editContract
    },
    recover: {
      sliceActions: recoverContractActions,
      do: recoverContract
    },
    list: {
      sliceActions: listContractActions,
      do: getContractList
    }
  },
  contract_type: {
    showName(data) {
      return `Contract Type - ${data.data.info.type}`;
    },
    model: contractTypeStruct,
    add: {
      sliceActions: addContractTypeActions,
      do: addContractType
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteContractTypeActions,
      do: deleteContractType
    },
    edit: {
      sliceActions: editContractTypeActions,
      do: editContractType
    },
    recover: {
      sliceActions: recoverContractTypeActions,
      do: recoverContractType
    },
    list: {
      sliceActions: listContractTypeActions,
      do: getContractTypeList
    }
  },
  appone_import_application: {
    showName(data) {
      return `AppOne Deal - ${data?.refNumber}`;
    },
    model: appOneDealStruct,
    add: {
      sliceActions: addAppOneDealActions,
      do: addAppOneDeal
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: editDealActions,
      do: editDeal
    },

    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: null,
      do: () => {}
    }
  },
  dealertrack_deal: {
    showName(data) {
      return `DealerTrack Deal - ${data?._id}`;
    },
    model: dealerTypeStruct,
    add: {
      sliceActions: addDealerTrackDealActions,
      do: addDealerTrackDeal
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: null,
      do: () => {}
    },
    edit: {
      sliceActions: addDealerTrackDealActions,
      do: addDealerTrackDeal
    },
    recover: {
      sliceActions: null,
      do: () => {}
    },
    list: {
      sliceActions: null,
      do: () => {}
    }
  },

  credit_score: {
    showName(entry) {
      return `Credit Bureau - ${entry?.data?.request?.NAME}  ${
        entry?.data?.request?.SPOUSE ? "/ " + entry?.data?.request?.SPOUSE : ""
      } `;
    },
    model: creditScoreStruct,
    add: {
      sliceActions: addCreditScoreActions,
      do: addCreditScore
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: () => {},
      do: () => {}
    },
    edit: {
      sliceActions: editCreditScoreActions,
      do: editCreditScore
    },
    recover: {
      sliceActions: undefined,
      do: () => {}
    },
    list: {
      sliceActions: listCreditScoreActions,
      do: getCreditScoreList
    }
  },
  one_span_signing: {
    showName(entry) {
      return `One span`;
    },
    model: {
      formComponent: "model",
      schema: false,
      name: "sequence",
      entities: []
    },
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: () => {},
      do: () => {}
    },
    edit: {
      sliceActions: editOneSpanSigningActions,
      do: editOneSpanSigning
    },
    recover: {
      sliceActions: undefined,
      do: () => {}
    },
    list: {
      sliceActions: null,
      do: () => {}
    }
  },
  delivered_deal: {
    showName() {
      return `NO name!`;
    },
    model: {
      formComponent: "model",
      schema: false,
      name: "sequence",
      entities: []
    },
    add: {
      sliceActions: undefined,
      do: () => {}
    },
    addMany: {
      sliceActions: undefined,
      do: () => {}
    },
    delete: {
      sliceActions: undefined,
      do: () => {}
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: undefined,
      do: () => {}
    },
    list: {
      sliceActions: undefined,
      do: () => {}
    }
  },
  external_credit_application: {
    showName(data) {
      return `Online credit app - ${data?.data?.info.creditType}`;
    },
    model: ExternalCreditApplicationStruct,
    add: {
      sliceActions: addExternalCreditApplicationActions,
      do: addExternalCreditApplication
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteExternalCreditApplicationActions,
      do: deleteExternalCreditApplication
    },
    edit: {
      sliceActions: editExternalCreditApplicationActions,
      do: editExternalCreditApplication
    },
    recover: {
      sliceActions: recoverExternalCreditApplicationActions,
      do: recoverExternalCreditApplication
    },
    list: {
      sliceActions: listExternalCreditApplicationActions,
      do: getExternalCreditApplicationList
    }
  },

  dmv: {
    showName(data) {
      return `Dmv - ${data.data.info.state}`;
    },
    model: dmvStruct,
    add: {
      sliceActions: addDmvActions,
      do: addDmv
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDmvActions,
      do: deleteDmv
    },
    edit: {
      sliceActions: editDmvActions,
      do: editDmv
    },
    recover: {
      sliceActions: recoverDmvActions,
      do: recoverDmv
    },
    list: {
      sliceActions: listDmvActions,
      do: getDmvList
    }
  },
  lender_ticket: {
    showName(data) {
      return `Lender Ticket - ${data.data.info.subject}`;
    },
    model: lenderTicketStruct,
    add: {
      model: addLenderTicketStruct,
      sliceActions: addLenderTicketActions,
      do: addLenderTicket
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteLenderTicketActions,
      do: deleteLenderTicket
    },
    edit: {
      sliceActions: editLenderTicketActions,
      do: editLenderTicket
    },
    recover: {
      sliceActions: recoverLenderTicketActions,
      do: recoverLenderTicket
    },
    list: {
      sliceActions: listLenderTicketActions,
      do: getLenderTicketList
    }
  },
  custom_report: {
    showName(data) {
      return `Report`;
    },
    model: customReportStruct,
    add: {
      sliceActions: addCustomReportActions,
      do: addCustomReport
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteCustomReportActions,
      do: deleteCustomReport
    },
    edit: {
      sliceActions: editCustomReportActions,
      do: editCustomReport
    },
    recover: {
      sliceActions: recoverCustomReportActions,
      do: recoverCustomReport
    },
    list: {
      sliceActions: listCustomReportActions,
      do: getCustomReportList
    }
  },
  business_report: {
    showName(data) {
      return `Report`;
    },
    model: customReportStruct,
    add: {
      sliceActions: addBusinessReportActions,
      do: addBusinessReport
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteBussinesReportActions,
      do: deleteBussinesReport
    },
    edit: {
      sliceActions: null,
      do: () => {}
    },
    recover: {
      sliceActions: recoverBussinesReportActions,
      do: () => recoverBussinesReport
    },
    list: {
      sliceActions: listBusinessReporActions,
      do: () => getBusinessReportList
    }
  },
  dealership_ticket: {
    showName(titleIssue) {
      return `Dealership ticket`;
    },
    model: dealershipTicketStruct,
    add: {
      sliceActions: null,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDealershipTicketActions,
      do: deleteDealershipTicket
    },
    edit: {
      sliceActions: editDealershipTicketActions,
      do: editDealershipTicket
    },
    recover: {
      sliceActions: recoverDealershipTicketActions,
      do: recoverDealershipTicket
    },
    list: {
      sliceActions: listDealershipTicketActions,
      do: getDealershipTicketList
    }
  },
  document_template: {
    showName(data) {
      return `Document template - ${data.data.info.name}`;
    },
    model: editDocumentTemplateStruct,
    add: {
      model: addDocumentTemplateStruct,
      sliceActions: addDocumentTemplateActions,
      do: addDocumentTemplate
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteDocumentTemplateActions,
      do: deleteDocumentTemplate
    },
    edit: {
      sliceActions: editDocumentTemplateActions,
      do: editDocumentTemplate
    },
    recover: {
      sliceActions: recoverDocumentTemplateActions,
      do: recoverDocumentTemplate
    },
    list: {
      sliceActions: listDocumentTemplateActions,
      do: getDocumentTemplateList
    }
  },
  document_validation: {
    model: validationStruct,
    showName(data) {
      return `Document validation`;
    },
    add: {
      model: validationStruct,
      sliceActions: addValidationActions,
      do: addValidation
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteValidationActions,
      do: deleteValidation
    },
    edit: {
      sliceActions: editValidationActions,
      do: editValidation
    },
    list: {
      sliceActions: listValidationActions,
      do: getValidationsList
    },
    recover: {
      sliceActions: undefined,
      do: () => {}
    }
  },
  file: {
    showName(data) {
      return `File  - ${data.data.info.url}`;
    },
    model: fileStruct,
    add: {
      sliceActions: addFileActions,
      do: addFile
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteFileActions,
      do: deleteFile
    },
    edit: {
      sliceActions: editFileActions,
      do: editFile
    },
    recover: {
      sliceActions: recoverFileActions,
      do: recoverFile
    },
    list: {
      sliceActions: listFileActions,
      do: getFileList
    }
  },
  funding_document: {
    showName(data) {
      return `Funding document  - ${data.data.info.url}`;
    },
    model: FundingDocumentStruct,
    add: {
      sliceActions: addFundingDocumentActions,
      do: addFundingDocument
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteFundingDocumentActions,
      do: deleteFundingDocument
    },
    edit: {
      sliceActions: editFundingDocumentActions,
      do: editFundingDocument
    },
    recover: {
      sliceActions: recoverFundingDocumentActions,
      do: recoverFundingDocument
    },
    list: {
      sliceActions: listFundingDocumentActions,
      do: getFundingDocumentList
    }
  },
  additional_required_document: {
    showName(data) {
      return `Additional required documnent - ${data.data.info.name}`;
    },
    model: AdditionalRequiredDocumentStruct,
    add: {
      sliceActions: addAdditionalRequiredDocumentActions,
      do: addAdditionalRequiredDocument
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteAdditionalRequiredDocumentActions,
      do: deleteAdditionalRequiredDocument
    },
    edit: {
      sliceActions: editAdditionalRequiredDocumentActions,
      do: editAdditionalRequiredDocument
    },
    recover: {
      sliceActions: recoverAdditionalRequiredDocumentActions,
      do: recoverAdditionalRequiredDocument
    },
    list: {
      sliceActions: listAdditionalRequiredDocumentActions,
      do: getAdditionalRequiredDocumentList
    }
  },
  vehicle_insurance_company: {
    showName(data) {
      return `Vehicle insurance company - ${data.data.info.name}`;
    },
    model: vehicleInsuranceCompanyStruct,
    add: {
      sliceActions: addVehicleInsuranceCompanyActions,
      do: addVehicleInsuranceCompany
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: deleteVehicleInsuranceCompanyActions,
      do: deleteVehicleInsuranceCompany
    },
    edit: {
      sliceActions: editVehicleInsuranceCompanyActions,
      do: editVehicleInsuranceCompany
    },
    recover: {
      sliceActions: recoverVehicleInsuranceCompanyActions,
      do: recoverVehicleInsuranceCompany
    },
    list: {
      sliceActions: listVehicleInsuranceCompanyActions,
      do: getVehicleInsuranceCompanyList
    }
  },
  sequence: {
    model: {
      formComponent: "model",
      schema: false,
      name: "sequence",
      entities: []
    },
    showName() {
      return ``;
    },

    add: {
      sliceActions: undefined,
      do: () => {}
    },
    addMany: {
      sliceActions: null,
      do: () => {}
    },
    delete: {
      sliceActions: undefined,
      do: () => {}
    },
    edit: {
      sliceActions: editSequenceNumberActions,
      do: editSequenceNumber
    },
    recover: {
      sliceActions: undefined,
      do: () => {}
    },
    list: {
      sliceActions: undefined,
      do: () => {}
    }
  }
};

export const getEntityConfigs = () => entityConfigs;

type PayloadData<T extends EntityType> = {
  _id: string;
  _insertedTS: string;
  createdAt: string;
  updatedAt: string;
  data: EntityData<T>;
};

interface Payload<T, P> {
  type: T;
  message: P;
}

type Payloads =
  | Payload<"history", HistoryData>
  | {
      [T in EntityType]: Payload<T, PayloadData<T>>;
    }[EntityType];

type UpdatePayloads =
  | { entity: "history"; _id: string; payload: HistoryData }
  | {
      [T in EntityType]: {
        entity: T;
        _id: string;
        payload: PayloadData<T>;
      };
    }[EntityType];

type PartialUpdatePayloads = {
  [T in EntityType]: {
    entity: T;
    _id: string;
    path: Path<T>;
    value: any;
  };
}[EntityType];

interface RemovePayload<T extends Contained> {
  type: T;
  _id: string;
}

export type HistoryData = CrudEntity & { data: any };
export type Netsuite = CrudEntity & {
  data: {
    dealId: string;
  };
};

type EntityBag<T extends Contained> = Record<
  string,
  T extends EntityType ? EntityData<T> : HistoryData
>;

type Contained = EntityType | "history";

type ReduxState = {
  [K in Contained]: EntityBag<K>;
};

const initialState: ReduxState = {
  dealership_ticket: {},
  dealership_ticket_note: {},
  column_group: {},
  lender_ticket: {},
  title_issue_note: {},
  dealer_note: {},
  lender_note: {},
  note: {},
  stipulation: {},
  checklist: {},
  dealership_bills_report: {},
  table_settings: {},
  contract: {},
  settings: {},
  contract_type: {},
  dealership: {},
  credit_score_range: {},
  lender_ticket_type: {},
  lender: {},
  applicant: {},
  wfd_user: {},
  dmv: {},
  custom_report: {},
  business_report: {},
  role: {},
  deal: {},
  title_issue: {},
  dealership_program: {},
  manager_program: {},
  document_template: {},
  document_validation: {},
  appone_import_application: {},
  credit_score: {},
  state: {},
  vehicle_insurance_company: {},
  file: {},
  funding_document: {},
  payoff_bank: {},
  other_vendor: {},
  external_credit_application: {},
  unapproved_user: {},
  dealertrack_deal: {},
  sequence: {},
  floorplanxpress: {},
  history: {},
  lock: {},
  chargeback: {},
  notification: {},
  api_status: {},
  lender_decision: {},
  delivered_deal: {},
  netsuite: {},
  additional_required_document: {},
  printed_document: {},
  unparsed_required_document: {},
  one_span_signing: {}
};

export const convertToInternalFormat = (type: EntityType, data: any) => {
  if (type === "appone_import_application") {
    const appOneData = data?.GetApplicationDataResult?.Application;
    return {
      ...appOneData,
      Borrowers: Array.isArray(appOneData?.Borrowers?.Borrower)
        ? {
            includeCoBorrower: appOneData?.Borrowers?.Borrower[1] ? true : false,
            Borrower: appOneData?.Borrowers?.Borrower[0],
            CoBorrower: appOneData?.Borrowers?.Borrower[1]
          }
        : {
            Borrower: appOneData?.Borrowers?.Borrower
          },
      Collateral: {
        ...appOneData?.Collateral,
        hasTrailer: appOneData?.Collateral.Trailer ? true : false,
        Motors: {
          Motor: Array.isArray(appOneData?.Collateral?.Motors?.Motor)
            ? appOneData?.Collateral?.Motors?.Motor
            : appOneData?.Collateral?.Motors?.Motor
            ? [appOneData?.Collateral?.Motors?.Motor]
            : []
        }
      }
    } as AppOneDeal;
  } else {
    return data;
  }
};

const convertToInternalIdFormat = (data: any): string => {
  return data.GetApplicationDataResult.Application.ID;
};

export const entitySlice = createSlice({
  name: "entrySlice",
  initialState: initialState,
  reducers: {
    add: (state: ReduxState, action: PayloadAction<Payloads>) => {
      switch (action.payload.type) {
        case "history":
          state[action.payload.type][action.payload.message._id] = {
            data: action.payload.message.data,
            createdAt: action.payload.message.createdAt,
            updatedAt: "",
            _id: action.payload.message._id
          };
          break;
        case "appone_import_application":
          state[action.payload.type][convertToInternalIdFormat(action.payload.message.data)] = {
            data: convertToInternalFormat(action.payload.type, action.payload.message.data),
            updatedAt: action.payload.message.updatedAt,
            createdAt: action.payload.message.createdAt,
            _id: convertToInternalIdFormat(action.payload.message.data)
          } as AppOneDeal;
          break;
        default:
          state[action.payload.type][action.payload.message._id] = {
            ...action.payload.message,
            data: convertToInternalFormat(action.payload.type, action.payload.message.data)
          };
          break;
      }
    },
    remove: <T extends Contained>(state: ReduxState, action: PayloadAction<RemovePayload<T>>) => {
      delete state[action.payload.type][action.payload._id];
    },
    partialUpdate: (state: ReduxState, action: PayloadAction<PartialUpdatePayloads>) => {
      const newState = setByPath(
        state[action.payload.entity][action.payload._id],
        action.payload.path as any,
        action.payload.value
      );
      const oldEntry = state[action.payload.entity][action.payload._id];
      switch (action.payload.entity) {
        case "deal":
          const deal = calculate((newState as unknown) as Deal, (oldEntry as unknown) as Deal);
          state[action.payload.entity][action.payload._id] = deal;
          break;
        case "file":
          const file = modifyFile((newState as unknown) as File);
          state[action.payload.entity][action.payload._id] = file;
          break;
        case "dealership":
          const oldDealership = state[action.payload.entity][action.payload._id];
          const dealership = modifyDealership(
            (newState as unknown) as Dealership,
            (oldDealership as unknown) as Dealership
          );
          // console.log("dealership", oldDealership, dealership);
          state[action.payload.entity][action.payload._id] = dealership;
          break;
        case "applicant":
          const applicant = modifyApplicant(
            (newState as unknown) as Applicant,
            (oldEntry as unknown) as Applicant
          );
          state[action.payload.entity][action.payload._id] = applicant;
          break;
        case "role":
          state[action.payload.entity][action.payload._id] = modifyRole(
            (newState as unknown) as Role,
            (oldEntry as unknown) as Role
          );
          break;

        default:
          state[action.payload.entity][action.payload._id] = newState;
      }
    },
    update: (state: ReduxState, action: PayloadAction<UpdatePayloads>) => {
      switch (action.payload.entity) {
        case "deal":
          const oldDeal = state[action.payload.entity][action.payload._id];
          const deal = calculate(
            (action.payload.payload as unknown) as Deal,
            (oldDeal as unknown) as Deal
          );
          state[action.payload.entity][action.payload._id] = deal;
          break;
        case "dealership":
          const oldDealership = state[action.payload.entity][action.payload._id];
          const dealership = modifyDealership(
            (action.payload.payload as unknown) as Dealership,
            (oldDealership as unknown) as Dealership
          );
          state[action.payload.entity][action.payload._id] = dealership;
          break;
        case "applicant":
          const oldApplicant = state[action.payload.entity][action.payload._id];
          const applicant = modifyApplicant(
            (action.payload.payload as unknown) as Applicant,
            (oldApplicant as unknown) as Applicant
          );
          state[action.payload.entity][action.payload._id] = applicant;
          break;
        case "file":
          const file = modifyFile((action.payload.payload as unknown) as File);
          state[action.payload.entity][action.payload._id] = file;
          break;
        case "role":
          const oldEntry = state[action.payload.entity][action.payload._id];
          state[action.payload.entity][action.payload._id] = modifyRole(
            (action.payload.payload as unknown) as Role,
            (oldEntry as unknown) as Role
          );
          break;

        default:
          state[action.payload.entity][action.payload._id] = action.payload.payload;
      }
    }
  }
});

const getTopic = (type: EntityType) => {
  switch (type) {
    case "column_group":
      return "get_column_group";
    case "lender_ticket":
      return "get_lender_ticket";
    case "stipulation":
      return "get_stipulation";
    case "table_settings":
      return "get_table_settings";
    case "contract":
      return "get_contract";
    case "contract_type":
      return "get_contract_type";
    case "dealership_ticket":
      return "get_dealership_ticket";
    case "lender":
      return "get_lender";
    case "dealership":
      return "get_dealership";
    case "applicant":
      return "get_applicant";
    case "wfd_user":
      return "get_wfd_user";
    case "chargeback":
      return "get_chargeback";
    case "credit_score_range":
      return "get_credit_score_range";
    case "lender_ticket_type":
      return "get_lender_ticket_type";
    case "dmv":
      return "get_dmv";
    case "custom_report":
      return "get_custom_report";
    case "business_report":
      return "get_business_report";
    case "role":
      return "get_role";
    case "deal":
      return "get_deal";
    case "title_issue":
      return "get_title_issue";
    case "dealership_program":
      return "get_dealership_program";
    case "manager_program":
      return "get_manager_program";
    case "dealership_bills_report":
      return "get_dealership_bills_report";
    case "floorplanxpress":
      return;
    case "document_template":
      return "get_document_template";
    case "document_validation":
      return "get_document_validation";
    case "appone_import_application":
      return "appone_get_application_data";
    case "dealertrack_deal":
      return "";
    case "vehicle_insurance_company":
      return "get_vehicle_insurance_company";
    case "lock":
      return "get_lock";
    case "file":
      return "get_file";
    case "funding_document":
      return "get_funding_document";
    case "state":
      return "get_state";
    case "payoff_bank":
      return "get_payoff_bank";
    case "other_vendor":
      return "get_other_vendor";
    case "unapproved_user":
      return;
    case "external_credit_application":
      return "get_external_credit_application";
    case "sequence":
      return;
    case "credit_score":
      return "get_credit_score";
    case "notification":
      return "get_notification";
    case "lender_decision":
      return "get_lender_decision";
    case "api_status":
      return;
    case "delivered_deal":
      return "get_delivery_deal";
    case "history":
      return "get_history";
    case "settings":
      return "get_settings";
    case "netsuite":
      return;
    case "additional_required_document":
      return "get_additional_required_document";
    case "printed_document":
    case "unparsed_required_document":
      return;
    case "one_span_signing":
      return;
    case "checklist":
      return;
    case "note":
      return;
    case "credit_score_range":
      return "get_credit_score_range";
    case "dealer_note":
      return;
    case "title_issue_note":
    case "dealership_ticket_note":
      return;
    case "lender_note":
      return;
    default:
      return assertNever(type);
  }
};

export const removeEntity = (type: EntityType, _id: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: entitySlice.actions.remove.type, payload: { type, _id } });
};

export const getEntity = (type: EntityType, _id: string) => async (dispatch: AppDispatch) => {
  type === "appone_import_application"
    ? dispatch({
        type: `getEntity/socketAction`,
        payload: { data: { params: { ApplicationID: _id } } },
        socket: {
          topic: getTopic(type),
          success: {
            type: entitySlice.actions.add.type,

            additionalOptions: { type }
          },
          error: {
            type: "fix"
          }
        },
        http: {
          path: getTopic(type),
          success: {
            type: entitySlice.actions.add.type,
            additionalOptions: { type }
          },
          error: {
            type: "tab/removeTabWithError"
          }
        }
      })
    : dispatch({
        type: `getEntity/socketAction`,
        payload: { _id },
        http: {
          path: getTopic(type),
          success: {
            type: entitySlice.actions.add.type,
            additionalOptions: { type }
          },
          error: {
            type: "tab/removeTabWithError"
          }
        },
        socket: {
          topic: getTopic(type),
          success: {
            type: entitySlice.actions.add.type,
            additionalOptions: { type }
          },
          error: {
            type: "tab/removeTabWithError"
          }
        }
      });
};

export default entitySlice.reducer;

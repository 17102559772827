import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import React from "react";
import History from "@material-ui/icons/History";
import { DmvHistory, DmvInfo, DmvStatus, TitleIssue } from "./types";
import { StateAccess } from "utils/models/formGenerator";
import formatDate, { addDaysToDate } from "utils/formatDate";
import CloseDialogButton from "components/common/CloseDialogButton";
import useForceTitleIssueCheck from "hooks/useForceCheckTitleIssue/useForceCheckTitleIssue";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import { daysBetweenDateAndNow } from "utils/functions";
import formEditContext from "components/Content/FormEditContext";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import States from "us-states";
import ImageIcon from "@material-ui/icons/Image";

type Props = {
  stateAccess: StateAccess;
};
type DetailedDmvInfoProps = {
  dmvInfo: DmvInfo;
  handleCloseDialog: () => void;
};
type DmvHistoryProps = {
  dmvHistory: DmvHistory[];
  handleCloseDialog: () => void;
};
type DmvCheckerProps = {
  titleIssue: TitleIssue;
  handleCloseDialog: () => void;
};
export const getDmvColorByStatus = (status: DmvStatus) => {
  switch (status) {
    case "titled":
      return "rgb(80, 165, 56)";
    case "untitled":
    case "old_title":
      return "#eed202";
    case "error":
      return "red";
    case "not_checked":
      return "#75b3f0";
  }
};
export const formatDmvStatus = (status: DmvStatus) => {
  switch (status) {
    case "titled":
      return "Titled";
    case "untitled":
      return "Untitled";
    case "old_title":
      return "Old Title";
    case "error":
      return "Error";
    default:
      return "N/A";
  }
};
const DmvHistoryDialog = ({ dmvHistory, handleCloseDialog }: DmvHistoryProps) => {
  return (
    <Dialog
      id="detailed-dmv-info-dialog"
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title">DMV History</DialogTitle>
      <CloseDialogButton closeFunction={handleCloseDialog} />
      <DialogContent dividers>
        <Grid container xs={12}>
          {dmvHistory.map((dmvHistoryRecord, index) => (
            <Paper
              elevation={3}
              key={index}
              style={{ padding: "10px", margin: "10px", width: "100%" }}
            >
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Checked Date: {formatDate(dmvHistoryRecord?.checkedDate, "full")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} style={{ padding: "10px", textAlign: "center" }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: "bold" }}>Status:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      color: getDmvColorByStatus(dmvHistoryRecord?.status),
                      fontWeight: "bold"
                    }}
                  >
                    {formatDmvStatus(dmvHistoryRecord?.status)}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: "bold" }}>DMV Result:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {typeof dmvHistoryRecord?.message === "string"
                      ? dmvHistoryRecord?.message
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: "bold" }}>Titled Date:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {formatDate(dmvHistoryRecord?.titledDate, "full") || "N/A"}
                  </Grid>
                </Grid>
                {dmvHistoryRecord?.titleImageUrl ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid container item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                      <Grid item xs={6}>
                        <Typography style={{ fontWeight: "bold" }}>Title image:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton
                          href={dmvHistoryRecord?.titleImageUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ImageIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Paper>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const DetailedDmvInfo = ({ dmvInfo, handleCloseDialog }: DetailedDmvInfoProps) => {
  return (
    <Dialog
      id="detailed-dmv-info-dialog"
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title">Detailed DMV Info</DialogTitle>
      <CloseDialogButton closeFunction={handleCloseDialog} />
      <DialogContent dividers>
        <Grid container xs={12}>
          <Grid container item xs={12} style={{ padding: "10px", textAlign: "center" }}>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>Status:</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ color: getDmvColorByStatus(dmvInfo?.status), fontWeight: "bold" }}
            >
              {formatDmvStatus(dmvInfo?.status)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} style={{ textAlign: "center", padding: "10px" }}>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>DMV Result:</Typography>
            </Grid>
            <Grid item xs={6}>
              {typeof dmvInfo?.message === "string" ? dmvInfo?.message : "N/A"}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} style={{ textAlign: "center", padding: "10px" }}>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>Titled Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              {formatDate(dmvInfo?.titledDate, "full") || "N/A"}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const DMVChecker = ({ titleIssue, handleCloseDialog }: DmvCheckerProps) => {
  const [state, setState] = React.useState<string | null>(
    titleIssue?.data?.deal?.data?.applicant?.data?.info?.currentState || null
  );
  const { enabled: editMode } = React.useContext(formEditContext);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { forceTitleIssueCheck, loading, success, error } = useForceTitleIssueCheck();

  const handleForceTitleIssueCheck = () => {
    if (state) {
      forceTitleIssueCheck({ ...titleIssue, state });
    } else {
      enqueueSnackbar("Please select a valid DMV.", {
        variant: "error"
      });
    }
  };
  React.useEffect(() => {
    if (loading) {
      dispatch(showLoader());
    } else {
      if (success) {
        enqueueSnackbar("Successfully performed a dmv check.", {
          variant: "success"
        });
        handleCloseDialog();
      }
      if (error) {
        enqueueSnackbar(error?.message || "There was an error performing a dmv check.", {
          variant: "error"
        });
      }
      dispatch(hideLoader());
    }
  }, [loading, success, error]);
  return (
    <Dialog
      id="dmv-checker-info-dialog"
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="customized-dialog-title">Select DMV</DialogTitle>
      <CloseDialogButton closeFunction={handleCloseDialog} />
      <DialogContent dividers>
        <Grid container xs={12}>
          <Grid item xs={12} style={{ padding: "30px" }}>
            <Autocomplete
              value={state}
              getOptionSelected={(x, y) => x === y}
              options={Object.keys(States)}
              onChange={(event, newValue) => {
                setState(newValue);
              }}
              openOnFocus
              id="dmv-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps
                  }}
                  label={"DMV"}
                  variant="filled"
                  required
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleForceTitleIssueCheck}
          disabled={!editMode}
        >
          Perform DMV Check
        </Button>
        <Button onClick={handleCloseDialog} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export const getNextCheckDate = (
  titleIssue: TitleIssue,
  format: "medium" | "short" | "full" | "long" = "short"
): undefined | number | string => {
  if (titleIssue?.data?.info?.dmvInfo?.status === "titled") return "";
  if (!titleIssue?.data?.info.lastCheckedDate) {
    return formatDate(new Date(), format);
  }
  return formatDate(addDaysToDate(new Date(titleIssue?.data?.info.lastCheckedDate), 15), format);
};
export const getDaysLeft = (titleIssue: TitleIssue): undefined | number | string => {
  if (titleIssue?.data?.info?.dmvInfo?.status === "titled") {
    return;
  }
  const submittedForFunding = titleIssue?.data?.deal?.data?.info?.dealDates?.submittedForFunding;
  if (submittedForFunding) {
    const daysLeft = 60 - daysBetweenDateAndNow(new Date(submittedForFunding));

    const isOverdue = daysLeft <= 0;

    if (isOverdue) {
      return "Overdue!";
    }
    return daysLeft;
  }
  return "N/A";
};
export default ({ stateAccess }: Props) => {
  const { enabled: editMode } = React.useContext(formEditContext);

  const titleIssue: TitleIssue = stateAccess.get([]);

  const [moreInfoOpened, setMoreInfoOpened] = React.useState(false);
  const [dmvCheckerOpened, setDmvCheckerOpened] = React.useState(false);
  const [dmvCheckHistoryOpened, setDmvCheckHistoryOpened] = React.useState(false);

  const isOverdue =
    titleIssue?.data?.deal?.data?.info?.dealDates?.submittedForFunding &&
    daysBetweenDateAndNow(
      new Date(titleIssue?.data?.deal?.data?.info?.dealDates?.submittedForFunding)
    ) > 60;

  return (
    <React.Fragment>
      {moreInfoOpened && (
        <DetailedDmvInfo
          dmvInfo={titleIssue?.data?.info?.dmvInfo}
          handleCloseDialog={() => setMoreInfoOpened(false)}
        />
      )}
      {dmvCheckHistoryOpened && (
        <DmvHistoryDialog
          dmvHistory={titleIssue?.data?.info?.dmvHistory}
          handleCloseDialog={() => setDmvCheckHistoryOpened(false)}
        />
      )}
      {dmvCheckerOpened && (
        <DMVChecker titleIssue={titleIssue} handleCloseDialog={() => setDmvCheckerOpened(false)} />
      )}
      <Paper elevation={3}>
        <Grid container xs={12} style={{ padding: "10px", justifyContent: "center" }}>
          <Grid item xs={12} style={{ textAlign: "left", marginBottom: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                style={{
                  color: "rgb(37, 78, 110)",
                  fontSize: "19px",
                  fontWeight: "bold"
                }}
              >
                DMV ({titleIssue?.data?.deal?.data?.applicant?.data?.info?.currentState})
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setDmvCheckerOpened(true)}
                disabled={!editMode}
              >
                Perform DMV Check
              </Button>
            </div>
          </Grid>
          <Grid item container xs={1} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                Status
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                placement="top"
                title={
                  typeof titleIssue?.data?.info?.dmvInfo?.message === "string"
                    ? titleIssue?.data?.info?.dmvInfo?.message
                    : "N/A"
                }
              >
                <Typography
                  style={{
                    color: getDmvColorByStatus(titleIssue?.data?.info?.dmvInfo?.status),
                    fontWeight: "bold"
                  }}
                >
                  {formatDmvStatus(titleIssue?.data?.info?.dmvInfo?.status)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={() => setMoreInfoOpened(true)}>
                More info
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                DMV Checks count
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{titleIssue?.data?.info?.dmvCheckedCount || 0}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                Last Action Date
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {formatDate(
                  titleIssue?.data?.info?.lastCheckedDate ?? titleIssue?.updatedAt,
                  "medium"
                ) || "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={1} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                Days Left
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={isOverdue ? { color: "red" } : {}}>
                {getDaysLeft(titleIssue)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                Next Check Date
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{getNextCheckDate(titleIssue, "medium")}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                DMV Check History
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                disabled={(titleIssue?.data?.info?.dmvHistory?.length || 0) < 1}
                onClick={() => setDmvCheckHistoryOpened(true)}
              >
                <History />
              </IconButton>
            </Grid>
          </Grid>
          {titleIssue?.data?.info?.dmvInfo?.titleImageUrl ? (
            <Grid item container xs={2} style={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  Latest DMV Check Image
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IconButton
                  href={titleIssue?.data?.info?.dmvInfo?.titleImageUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImageIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

import { DealStatus } from "state-machine/src/dealStateMachine";

export const defaultStatuses = [
  DealStatus.SentToDealer,
  DealStatus.SubmittedForFunding,
  DealStatus.Funded,
  DealStatus.FundingHeld
];
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

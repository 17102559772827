import { DateState } from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import DateFilter from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import useGetMixPanelReports from "hooks/getMixPanelReports/getMixPanelReports";
import { MixPanelQuery } from "hooks/getMixPanelReports/types";
import { TableRow, TableSortLabel, withStyles } from "@material-ui/core";
import { getTimeFrameMonthsBackToCurrentDate } from "utils/functions";
import "./styles.css";
import { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { eventsCountType } from "./types";
import Spinner from "components/Loader/Spinner";

export const MixPanelReportsTable = (): any => {
  const dates = getTimeFrameMonthsBackToCurrentDate(1);
  const [dateFilter, setDateFilter] = useState<DateState>(dates);

  const { data, isFetching, error } = useGetMixPanelReports({
    fromDate: dateFilter?.from
      ? `${dateFilter?.from.getFullYear()}-${
          dateFilter?.from.getMonth() + 1
        }-${dateFilter?.from.getDate()}`
      : undefined,
    toDate: dateFilter?.to
      ? `${dateFilter?.to.getFullYear()}-${
          dateFilter?.to.getMonth() + 1
        }-${dateFilter?.to.getDate()}`
      : undefined
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "25px",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex" }}>
          <h2>Mixpanel Reports</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <div style={{ width: "auto" }}>
            <DateFilter filter={dateFilter} setFilter={setDateFilter} />
          </div>
        </div>
      </div>

      <TableComponent data={data} isFetching={isFetching} error={error} />
    </div>
  );
};

const TableComponent = ({
  data,
  isFetching,
  error
}: {
  data: MixPanelQuery | undefined;
  isFetching: boolean;
  error: Error | null;
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState("dealer");

  const dealersArray = data
    ? Object.keys(data?.allApps).map((dealer) => ({
        dealer,
        ...data?.allApps[dealer]
      }))
    : [];

  const sortedArrayByDealers = dealersArray.sort((a, b) => {
    if (sortOrder === "asc") {
      return a["dealer"] > b["dealer"] ? 1 : -1;
    } else {
      return b["dealer"] > a["dealer"] ? 1 : -1;
    }
  });

  const mergeEvents = (events: eventsCountType, eventFilter: string[]) => {
    const regex = new RegExp(`^(${eventFilter.join("|")})`, "i");
    const dynamicEvents = Object?.keys(events ?? {})?.filter((x) => regex.test(x));
    const allMatchingEventsCount = dynamicEvents.reduce(
      (acc, curr) => acc + (events?.[curr] ?? 0),
      0
    );

    return allMatchingEventsCount;
  };

  const handleSort = (property: string) => {
    const isAscending = sortBy === property && sortOrder === "asc";
    setSortOrder(isAscending ? "desc" : "asc");
    setSortBy(property);
  };
  const returnDataRows = () => {
    const applicationsRegexWeb = [
      "Visited individual Applications page",
      "Visited page Applications",
      "Visited page Dashboard",
      "Deals checked",
      "Visited dashboard",
      "Visited application"
    ];
    const applicationsRegexMobile = [
      "Visited: /application",
      "Visited: /dashboard",
      "Visited: /applications",
      "Visited: /application/progress"
    ];

    return isFetching ? (
      <Spinner
        text={"Loading "}
        style={{
          maxWidth: "91%",
          maxHeight: "25px",
          display: "flex",
          alignItems: "center",
          marginTop: "25px",
          backgroundColor: "transparent",
          justifyContent: "space-around"
        }}
      />
    ) : !error ? (
      sortedArrayByDealers.map((arr) => {
        const eventOccurencesWeb = arr?.webApp?.eventOccurences;
        const eventOccurencesMobile = arr?.mobileApp?.eventOccurences;
        return (
          <StyledTableRow key={arr.dealer} className="cell-styles">
            <TableCell className="cell-styles">{arr?.dealer}</TableCell>
            <TableCell className="cell-styles">{arr?.webApp?.totalEvents ?? 0}</TableCell>
            <TableCell className="cell-styles">
              {mergeEvents(eventOccurencesWeb, applicationsRegexWeb)}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["Deal created"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["Document uploaded before signing"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">{arr?.mobileApp?.totalEvents ?? 0}</TableCell>
            <TableCell className="cell-styles">
              {mergeEvents(eventOccurencesMobile, applicationsRegexMobile)}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesMobile?.["Deal created"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesMobile?.["Document uploaded before signing"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.online_application ?? 0}</TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.web_finance_direct ?? 0}</TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.["AI-Parser"] ?? 0}</TableCell>
          </StyledTableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell
          className="cell-styles"
          colSpan={12}
          style={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}
        >
          No data found for this period
        </TableCell>
      </TableRow>
    );
  };
  return (
    <TableContainer
      component={Paper}
      style={{ overflowX: "visible", maxHeight: "auto", position: "relative" }}
    >
      <Table
        style={{
          tableLayout: "fixed",
          width: "100%",
          fontWeight: "bold"
        }}
        stickyHeader
      >
        <TableHead
          style={{
            position: "sticky",
            top: 0,
            padding: "10px",
            borderBottom: "1px solid #ddd",
            width: "100%",
            zIndex: 10
          }}
        >
          <TableRow>
            <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
            <TableCell colSpan={4} align="left" style={{ width: "auto" }}>
              Dealer Web App
            </TableCell>
            <TableCell colSpan={4} align="left" style={{ width: "auto" }}>
              Dealer Mobile App
            </TableCell>
            <TableCell colSpan={3} align="left" style={{ width: "auto" }}>
              Blackbird
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === "dealer"}
                direction={sortOrder}
                onClick={() => handleSort("dealer")}
              >
                Dealer Name
              </TableSortLabel>
            </TableCell>
            <TableCell>All Events Count</TableCell>
            <TableCell>Deals Checked</TableCell>
            <TableCell>Deals Created</TableCell>
            <TableCell>Documents Uploaded</TableCell>
            <TableCell>All Events Count</TableCell>
            <TableCell>Deal Checked</TableCell>
            <TableCell>Deal Created</TableCell>
            <TableCell>Documents Uploaded</TableCell>
            <TableCell>Deals From Widget</TableCell>
            <TableCell>Deals Created By Managers</TableCell>
            <TableCell>AI Parser</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{returnDataRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export default MixPanelReportsTable;

import { LenderTicket } from "./types";
import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";

export type LenderTicketPermissionsStruct = PermissionStruct<LenderTicket>;

export const lenderTicketPermissionsStruct: LenderTicketPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Deal Id"),
    lenderNotes: relationPermissionNode("Lender Notes", "lender_note"),
    notes: relationPermissionNode("Notes", "note"),
    deal: relationPermissionNode("Lender", "lender"),
    titleIssueId: permissionNode("Title Issue Id"),
    titleIssue: relationPermissionNode("Tittle Issue", "title_issue"),
    lenderId: permissionNode("Lender Id"),
    lender: relationPermissionNode("Lender", "lender"),
    userId: permissionNode("User Id"),
    replyTo: multyPermissionNode("Reply To"),
    assigneeIds: multyPermissionNode("Assigned To"),
    assignees: relationPermissionNode("Assignee", "wfd_user"),
    typeId: permissionNode("TypeID"),
    type: relationPermissionNode("Type", "lender_ticket_type"),
    info: {
      subject: permissionNode("Subject"),
      status: permissionNode("Subject"),
      description: permissionNode("Subject"),
      priority: permissionNode("Subject"),
      source: permissionNode("Source")
    }
  }
};

import DealershipPreview from "components/Dealerships/DealershipPreview";
import { dealershipStruct } from "components/Dealerships/model";
import { statusToLabel } from "components/Deals/Deals";
import LenderPreview from "components/Deals/LenderPreview";
import { editDealStruct } from "components/Deals/model";
import { DealStatus } from "components/Deals/types";
import { lenderStruct } from "components/Lenders/model";
import RolesPreview from "components/Roles/RolesPreview";
import UserPreview from "components/Users/UserPreview";
import React from "react";
import { capitalize } from "utils/functions";
import { createOptionsForSelect, FormComponent, Model } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { modelToData } from "./modelToRangeFields";
import { EntityApprovalType, Settings } from "./types";
import Calendar from "./Calendar";
import CreditScoreRanges from "components/CreditScoreRanges/CreditScoreRanges";
import {
  pivotPowerSportCostTableMenuFields,
  pivotAutomotiveCostTableMenuFields,
  pivotTableContentVehicleCost
} from "components/PivotTable/model";
import PivotTable from "components/PivotTable/PivotTable";

const parametersStyle: React.CSSProperties = {
  boxShadow:
    "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  margin: "10px",
  padding: "8px"
};

export const settingspowerSportCostPivotTableInfo: FormComponent<Settings> = {
  elevation: 1,
  formComponent: "segment",
  style: { padding: "10px" },
  separator: true,
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Power Sport Cost",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "powersportCost",
          label: "Power Sport Cost",
          path: ["data", "info", "insuranceCosts", "powersport"],
          width: "full",
          component: (stateAccess: StateAccess, mainStateAccess) => (
            <PivotTable
              path={["data", "info", "insuranceCosts", "powersport"]}
              stateAccess={stateAccess}
              mainStateAccess={mainStateAccess}
              menuFields={pivotPowerSportCostTableMenuFields}
              tableContent={pivotTableContentVehicleCost()}
            />
          ),
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Automotive Cost",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "automotiveCost",
          label: "Automotive Cost",
          path: ["data", "info", "insuranceCosts", "automotive"],
          width: "full",
          component: (stateAccess: StateAccess, mainStateAccess) => (
            <PivotTable
              path={["data", "info", "insuranceCosts", "automotive"]}
              stateAccess={stateAccess}
              mainStateAccess={mainStateAccess}
              menuFields={pivotAutomotiveCostTableMenuFields}
              tableContent={pivotTableContentVehicleCost()}
            />
          ),
          default: null
        }
      ]
    }
  ]
};

const approvalRolesStruct = (entity: EntityApprovalType): FormComponent<Settings> => ({
  formComponent: "segment",
  name: capitalize(entity),
  width: "1/3",
  entities: [
    {
      formComponent: "one-to-many-field",
      name: "roles",
      label: `${capitalize(entity)} Approval roles`,
      width: "3/4",
      path: ["data", "entityAprovalRoles", `${entity}ApprovalRoles`],
      component: (stateAccess, mainStateAccess, renderSet) => (
        <RolesPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "entityAprovalRoles", `${entity}ApprovalRoles`]}
          name="roles"
          label="Roles"
        />
      ),
      default: []
    }
  ]
});
export const settingsInfo: FormComponent<Settings> = {
  formComponent: "segment",
  name: "Settings",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Insurance extra commissions",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "segment",
          name: "Gold Standard Automotive Network",
          width: "1/4",
          entities: [
            {
              formComponent: "currency-field",
              name: "GoldStandardAutomotiveNetworkWaranty",
              label: "Gold Standard Automotive Network Warranty",
              path: ["data", "info", "goldStandartAutomotiveNetworkWarranty"],
              width: "full",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "ASC",
          width: "1/4",
          entities: [
            {
              formComponent: "currency-field",
              name: "ASCWarranty",
              label: "ASC Warranty",
              path: ["data", "info", "ascWarranty"],
              width: "full",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Protective",
          width: "1/4",
          entities: [
            {
              formComponent: "currency-field",
              name: "Protective",
              label: "Protective Gap",
              path: ["data", "info", "protectiveGap"],
              width: "full",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "FPC",
          width: "1/4",
          entities: [
            {
              formComponent: "currency-field",
              name: "FPC Warranty",
              label: "FPCWarranty",
              path: ["data", "info", "fpcWarranty"],
              width: "full",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "NSD",
          width: "1/4",
          entities: [
            {
              formComponent: "currency-field",
              name: "NSDGap",
              label: "NSD Gap",
              path: ["data", "info", "nsdGap"],
              width: "full",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "GWC",
          width: "1/2",
          entities: [
            {
              formComponent: "currency-field",
              name: "GWCWarranty",
              label: "GWC Warranty",
              path: ["data", "info", "gwcWarranty"],
              width: "1/2",
              default: null,
              min: 0
            },
            {
              formComponent: "currency-field",
              name: "GWCGap",
              label: "GWC Gap",
              path: ["data", "info", "gwcGap"],
              width: "1/2",
              default: null,
              min: 0
            }
          ]
        },

        {
          formComponent: "segment",
          name: "XtraRide",
          width: "1/2",
          entities: [
            {
              formComponent: "currency-field",
              name: "XtraRideGAP",
              label: "XtraRide GAP",
              path: ["data", "info", "xtraRideGap"],
              width: "1/2",
              default: null,
              min: 0
            },
            {
              formComponent: "currency-field",
              name: "XtraRideWarranty",
              label: "XtraRide Warranty",
              path: ["data", "info", "xtraRideWarranty"],
              width: "1/2",
              default: null,
              min: 0
            }
          ]
        },

        {
          formComponent: "segment",
          name: "Compass",
          width: "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "CompassGap",
              label: "Compass Gap",
              path: ["data", "info", "compassGap"],
              width: "1/3",
              default: null,
              min: 0
            },
            {
              formComponent: "currency-field",
              name: "Compass Motorhome",
              label: "Compass Motorhome",
              path: ["data", "info", "compasMotorhome"],
              width: "1/3",
              default: null,
              min: 0
            },
            {
              formComponent: "currency-field",
              name: "CompassMotorhomeCoach",
              label: "Compass Motorhome Coach",
              path: ["data", "info", "compasMotorhomeCoach"],
              width: "1/3",
              default: null,
              min: 0
            },

            {
              formComponent: "currency-field",
              name: "CompassTT&5th<$20k",
              label: "Compass TT & 5th <$20k ",
              path: ["data", "info", "compassTT5LessThan20k"],
              width: "1/2",
              default: null,
              min: 0
            },
            {
              formComponent: "currency-field",
              name: "CompassTT&5th>$20k",
              label: "Compass TT & 5th >$20k",
              path: ["data", "info", "compassTT5MoreThan20k"],
              width: "1/2",
              default: null,
              min: 0
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Campers",
          width: "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "Campers,PopUp,SlideIn",
              label: "Campers, Pop Up, Slide In <$20k",
              path: ["data", "info", "campersPopSlideLessThan20k"],
              width: "1/2",
              default: null,
              min: 0
            },

            {
              formComponent: "currency-field",
              name: "Campers,PopUp,SlideIn",
              label: "Campers, Pop Up, Slide In >$20k",
              path: ["data", "info", "campersPopSlideMoreThan20k"],
              width: "1/2",
              default: null,
              min: 0
            }
          ]
        }
      ]
    },

    {
      formComponent: "segment",
      name: "Profits",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "segment",
          name: "WFD",
          width: "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "maxWFDProfit",
              label: "Max WFD Profit",
              path: ["data", "info", "maxWFDProfit"],
              width: "1/3",
              default: null,
              min: 0
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Fees",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "currency-field",
          name: "wfdMinimumFee",
          label: "WFD Mininum Fee",
          path: ["data", "info", "wfdMinimumFee"],
          width: "1/3",
          default: null,
          min: 0
        },
        {
          formComponent: "currency-field",
          name: "acquisitionFee",
          label: "Acquisition Fee",
          path: ["data", "info", "acquisitionFee"],
          width: "1/3",
          default: null,
          min: 0
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      entities: [
        {
          formComponent: "tab-list-model",
          name: "Report types",
          getTabName: (entity) => entity.type ?? "New report type",
          entity: {
            formComponent: "segment",
            entities: [
              {
                formComponent: "text-field",
                name: "type",
                label: "Type",
                width: "1/2",
                path: ["type"],
                required: true
              },
              {
                formComponent: "text-field",
                name: "url",
                label: "Url",
                width: "1/2",
                path: ["url"],
                required: true
              },
              {
                formComponent: "text-field",
                name: "description",
                label: "Description",
                width: "full",
                path: ["description"]
              },
              {
                formComponent: "segment",
                width: "full",
                name: "Parameters",
                entities: [
                  {
                    formComponent: "multiselect-field",
                    name: "parameters",
                    label: "Parameters",
                    width: "full",
                    path: ["parameters"],
                    options: createOptionsForSelect({
                      possibleValues: () => [
                        "lender",
                        "dealership",
                        "representative",
                        "date range",
                        "time period"
                      ],
                      getOptionLabel: (x) => capitalize(x) ?? "",
                      getSelectedOption: (x, y) => x === y
                    })
                  },
                  {
                    formComponent: "segment",
                    width: "1/2",
                    style: parametersStyle,
                    name: "Dealership",
                    show: (stateAccess) => stateAccess.get(["parameters"])?.includes("dealership"),
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        struct: () => dealershipStruct,
                        component: (
                          stateAccess: StateAccess,
                          mainstateAccess: StateAccess,
                          renderSet: RenderSet
                        ) => (
                          <DealershipPreview
                            stateAccess={stateAccess}
                            renderSet={renderSet}
                            path={["defaultParameters", "defaultDealership"]}
                            name="dealership"
                            label="Default Dealership"
                            required={false}
                            type={"settings"}
                          />
                        ),
                        name: "dealership",
                        label: "Default Dealership",
                        required: true,
                        default: null,
                        path: ["defaultParameters", "defaultDealership"],
                        width: "full"
                      },
                      {
                        formComponent: "checkbox-field",
                        name: "Required",
                        label: "Required",
                        width: "1/4",
                        toggle: (stateAccess) =>
                          stateAccess.set(
                            ["defaultParameters", "defaultDealershipRequired"],
                            !stateAccess.get(["defaultParameters", "defaultDealershipRequired"])
                          ),
                        isDisabled: () => false,
                        isChecked: (stateAccess) =>
                          stateAccess.get(["defaultParameters", "defaultDealershipRequired"]),
                        path: ["defaultParameters", "defaultDealershipRequired"],
                        default: false
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "1/2",
                    name: "Lender",
                    style: parametersStyle,
                    show: (stateAccess) => stateAccess.get(["parameters"])?.includes("lender"),
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        struct: () => lenderStruct,

                        component: (
                          stateAccess: StateAccess,
                          mainstateAccess: StateAccess,
                          renderSet: RenderSet
                        ) => (
                          <LenderPreview
                            stateAccess={stateAccess}
                            renderSet={renderSet}
                            path={["defaultParameters", "defaultLender"]}
                            lenderIdPath={["defaultParameters", "defaultLenderId"]}
                            name="lender"
                            label="Default Lender"
                          />
                        ),
                        name: "lender",
                        label: "Lender",
                        required: true,
                        width: "full",
                        path: ["defaultParameters", "defaultLender"],
                        default: null
                      },
                      {
                        formComponent: "checkbox-field",
                        name: "Required",
                        label: "Required",
                        width: "1/4",
                        toggle: (stateAccess) =>
                          stateAccess.set(
                            ["defaultParameters", "defaultLenderRequired"],
                            !stateAccess.get(["defaultLenderRequired"])
                          ),
                        isDisabled: () => false,
                        isChecked: (stateAccess) =>
                          stateAccess.get(["defaultParameters", "defaultLenderRequired"]),
                        path: ["defaultParameters", "defaultLenderRequired"],
                        default: false
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "1/2",
                    style: parametersStyle,
                    name: "Representative",
                    show: (stateAccess) =>
                      stateAccess.get(["parameters"])?.includes("representative"),
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        name: "representative",
                        label: "Representative",

                        path: ["defaultParameters", "defaultRepresentative"],
                        width: "full",
                        component: (
                          stateAccess: StateAccess,
                          mainStateAccess,
                          renderSet: RenderSet
                        ) => (
                          <UserPreview
                            stateAccess={stateAccess}
                            renderSet={renderSet}
                            path={["defaultParameters", "defaultRepresentative"]}
                            name="representative"
                            label="Default Representative"
                            type="representative"
                          />
                        ),
                        required: true,

                        default: null
                      },
                      {
                        formComponent: "checkbox-field",
                        name: "Required",
                        label: "Required",
                        width: "1/4",
                        toggle: (stateAccess) =>
                          stateAccess.set(
                            ["defaultParameters", "defaultRepresentativeRequired"],
                            !stateAccess.get(["defaultParameters", "defaultRepresentativeRequired"])
                          ),
                        isDisabled: () => false,
                        isChecked: (stateAccess) =>
                          stateAccess.get(["defaultParameters", "defaultRepresentativeRequired"]),
                        path: ["defaultParameters", "defaultRepresentativeRequired"],
                        default: false
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "1/2",
                    style: parametersStyle,
                    name: "Time period",
                    show: (stateAccess) => stateAccess.get(["parameters"])?.includes("time period"),
                    entities: [
                      {
                        formComponent: "multiselect-field",
                        name: "Time period",
                        label: "Default Time period",
                        width: "full",
                        path: ["defaultParameters", "defaultTimePeriod"],
                        options: createOptionsForSelect({
                          possibleValues: () => ["D", "W", "M", "Q", "Y"],
                          getOptionLabel: (el) => {
                            switch (el) {
                              case "D":
                                return "Daily";
                              case "W":
                                return "Weekly";
                              case "M":
                                return "Monthly";
                              case "Q":
                                return "Quarterly";
                              case "Y":
                                return "Yearly";
                              default:
                                return "";
                            }
                          },
                          getSelectedOption: (x, y) => x === y
                        }),
                        default: ["D", "W", "M", "Q", "Y"]
                      },
                      {
                        formComponent: "checkbox-field",
                        name: "Required",
                        label: "Required",
                        width: "1/4",
                        toggle: (stateAccess) =>
                          stateAccess.set(
                            ["defaultParameters", "defaultTimePeriodRequired"],
                            !stateAccess.get(["defaultParameters", "defaultTimePeriodRequired"])
                          ),
                        isDisabled: () => false,
                        isChecked: (stateAccess) =>
                          stateAccess.get(["defaultParameters", "defaultTimePeriodRequired"]),
                        path: ["defaultParameters", "defaultTimePeriodRequired"],
                        default: false
                      }
                    ]
                  },
                  {
                    formComponent: "list-model",
                    width: "1/2",
                    style: parametersStyle,
                    name: "Date range",
                    show: (stateAccess) => stateAccess.get(["parameters"])?.includes("date range"),
                    path: ["defaultParameters", "defaultDateRanges"],
                    entity: {
                      formComponent: "segment",
                      width: "full",
                      entities: [
                        {
                          formComponent: "segment",
                          width: "full",
                          entities: [
                            {
                              formComponent: "select-field",
                              name: "Type",
                              label: "Date type",
                              width: "1/2",
                              path: ["defaultDateType"],
                              options: createOptionsForSelect({
                                possibleValues: () => ["data.createdAt", "data.dealDates.fundedAt"],
                                getOptionLabel: (x) => {
                                  switch (x) {
                                    case "data.createdAt":
                                      return "Created at";
                                    case "data.dealDates.fundedAt":
                                      return "Funded at";
                                    default:
                                      return "";
                                  }
                                },
                                getSelectedOption: (x, y) => x === y
                              }),
                              default: null
                            },
                            {
                              formComponent: "select-field",
                              name: "Status",
                              label: "Status",
                              width: "1/2",
                              path: ["defaultDealStatus"],
                              options: createOptionsForSelect({
                                possibleValues: () => Object.values(DealStatus),
                                getOptionLabel: (x) => statusToLabel(x),
                                getSelectedOption: (x, y) => x === y
                              }),
                              default: null
                            },
                            {
                              formComponent: "date-field",
                              name: "from",
                              label: "Default From",
                              width: "1/2",
                              path: ["defaultFrom"]
                            },
                            {
                              formComponent: "date-field",
                              name: "To",
                              label: "Default To",
                              width: "1/2",
                              path: ["defaultTo"]
                            }
                          ]
                        },
                        {
                          formComponent: "checkbox-field",
                          name: "Required",
                          label: "Required",
                          width: "1/4",
                          toggle: (stateAccess) =>
                            stateAccess.set(["required"], !stateAccess.get(["required"])),
                          isDisabled: () => false,
                          isChecked: (stateAccess) => stateAccess.get(["required"]),
                          path: ["required"],
                          default: false
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          },
          required: false,
          width: "full",
          path: ["data", "info", "businessReportTypes"]
        }
      ]
    },

    {
      formComponent: "segment",
      name: "Entity approval",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        approvalRolesStruct("dealership"),
        approvalRolesStruct("contract"),
        approvalRolesStruct("contractType"),
        approvalRolesStruct("lender"),
        approvalRolesStruct("deal"),
        approvalRolesStruct("applicant"),
        approvalRolesStruct("titleIssue"),
        approvalRolesStruct("wfd_user"),
        approvalRolesStruct("dmv"),
        approvalRolesStruct("customReport"),
        approvalRolesStruct("documentTemplate"),
        approvalRolesStruct("documentValidation"),
        approvalRolesStruct("file"),
        approvalRolesStruct("payoffBank"),
        approvalRolesStruct("otherVendor"),
        approvalRolesStruct("state"),
        approvalRolesStruct("fundingDocument"),
        approvalRolesStruct("lenderDecision")
      ]
    },

    {
      formComponent: "segment",
      name: "Number field ranges",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "tab-list-model",
          width: "full",
          name: "Deal ranges",
          getTabName: (entity) => entity?.field?.path.join(".") ?? "Select field",
          path: ["data", "info", "defaultNumberRanges"],
          entity: {
            formComponent: "segment",
            entities: [
              {
                formComponent: "select-field",
                name: "field",
                label: "Filed",
                width: "1/3",
                path: ["field"],
                required: true,
                options: createOptionsForSelect({
                  possibleValues: (stateAccess: StateAccess, path) => {
                    return modelToData(editDealStruct);
                  },
                  getOptionLabel: (x) => x.path.join(".") ?? " ",
                  getSelectedOption: (x, y) => x.name === y.name
                }),
                default: null
              },
              {
                formComponent: "number-field",
                name: "min",
                label: "Min",
                width: "1/3",
                path: ["min"],
                required: true
              },
              {
                formComponent: "number-field",
                name: "max",
                label: "Max",
                width: "1/3",
                path: ["max"],
                required: true
              }
            ]
          }
        }
      ]
    },
    {
      formComponent: "segment",
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "ranges",
          width: "full",
          path: [""],
          component: (stateAccess) => <CreditScoreRanges />,
          default: []
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Default stipulations for dealers",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "list-model",
          name: "Stipulations",
          entity: {
            formComponent: "segment",
            entities: [
              {
                formComponent: "text-field",
                name: "description",
                label: "Description",
                width: "full",
                path: ["description"],
                required: true
              }
            ]
          },
          required: false,
          width: "1/2",
          path: ["data", "info", "defaultStipulations"]
        }
      ]
    },
    settingspowerSportCostPivotTableInfo,
    {
      formComponent: "segment",
      name: "Business days calendar",
      elevation: 3,
      style: { padding: "10px" },
      separator: true,
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "calendar",
          width: "full",
          path: ["data", "info", "offDaysByYear"],
          component: (stateAccess) => <Calendar stateAccess={stateAccess} />,
          default: []
        }
      ]
    }
  ]
};

export const settingsStruct: Model<Settings> = {
  formComponent: "model",
  schema: false,
  name: "settings",

  entities: [{ formComponent: "segment", entities: [settingsInfo] }]
};

import { DealershipProgram } from "components/DealershipPrograms/types";
import { Dealership } from "components/Dealerships/types";
import { AllocationPeriod } from "components/Lenders/types";
import { ManagerProgram } from "components/ManagerPrograms/types";
import { Role } from "components/Roles/types";
import { Condition, CrudEntity } from "utils/types";

export interface Dashboard {
  type: string;
  order: string;
  width: number;
}

export interface FormState {
  info: Info;
  // dealView:string
  loginAsUser: User;
  loginAsUserId: string;
  roles: Role[];
  statistics?: UserStatistics[];
  rolesIds?: string[] | null;
  netsuiteId: string;
  dealershipId?: string | null;
  dealership?: Dealership;
  // vehicleInsuranceCompany: VehicleInsuranceCompany;
}
type CustomAllocation = { month: number; year: number; allocation: number };
type UserAllocation = Omit<AllocationPeriod, "allocation" | "allocationType"> & {
  customAllocations: CustomAllocation[];
};
export type UserAllocationPeriod = { [key: string]: UserAllocation[] };
export interface UserStatistics {
  _id: {
    year: number;
    month: number;
    userId: string;
  };
  year: number;
  month: number;
  userId: string;
  pvr: number;
  totalAmountFinanced: number;
  dealsCount: number;
}
type UserType =
  | "representative"
  | "f&i manager"
  | "dealership-admin"
  | "dealership-member"
  | "lender-admin"
  | "lender-user";

export const userTypes: UserType[] = [
  "representative",
  "f&i manager",
  "dealership-admin",
  "dealership-member",
  "lender-admin",
  "lender-user"
];
export interface Info {
  PIN: string;
  firstName: string;
  lastName: string;
  phoneNumber?: number;
  email: string;
  userType: UserType;
  enabled?: boolean;
  dashboard: Dashboard[];
  periodCommissions?: PeriodCommission[];
  onlineCreditApplicationNotification?: boolean;
  lenderAllocationNotification?: boolean;
  contractNotification?: boolean;
  dashboardTypes?: DashboardTypes;
  lenderSupport?: boolean;
  allocationPeriods?: UserAllocationPeriod;
}
export type DashboardType =
  | "accountant"
  | "lenderEstimation"
  | "f&i"
  | "salesRep"
  | "allocation"
  | "lender"
  | "titleIssues"
  | "coverageStatistics"
  | "custom";
export type DashboardTypes = {
  [key in DashboardType]?: boolean;
};
export type PeriodCommission = {
  from?: string;
  to?: string;
  commissions: Commission[];
};
export interface Commission {
  dealershipProgram?: DealershipProgram | null;
  managerProgram?: ManagerProgram | null;
  dealershipProgramId?: string | null;
  managerProgramId?: string | null;
  commissionableAmountType: "50% of gross profit" | "WFD total profit";
  type: string;
  amount: number;
  chargebackPercent: number;
  conditions: Condition[];
}

export interface UnapprovedUserFormState {
  info: {
    email: string;
  };
}

export type UnapprovedUser = CrudEntity & { data: UnapprovedUserFormState };
export type User = CrudEntity & { data: FormState };
export interface FirebaseUser {
  email: string;
  uid: string;
}

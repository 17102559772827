import { getListProps } from "components/Middlewares/listSliceCreator";
import React from "react";
import { capitalize, formatNumberAsCurrency, totalsDefault } from "../../../utils/functions";
import { DealSourceTypes, DealStatus, collateralTypes } from "../../Deals/types";
import DateFilter from "../../Filters/DateFilter";
import PriceFilter from "../../Filters/PriceFilter";

import { RootState } from "app/rootReducer";

import { useSelector } from "react-redux";
import { clearSalesRepDealList, getSalesRepDeals } from "./salesRepDealsSlice";

import { AppDispatch } from "app/store";

import { dealSourceToLabel, statusToChip, statusToLabel } from "components/Deals/Deals";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { HintTooltip } from "components/common/HintTooltip";
import GroupedTable, { CellValue, Column, Entry } from "../../GroupedTable";
import ChildDeals from "./ChildDeals";
const groupDealColumns: Column<any>[] = [
  {
    getData: (entry): CellValue =>
      // @ts-ignore
      entry?.applicantNames,
    label: "Customer",
    tableSettingsParent: "applicant",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "applicantNames",
      filters: [
        {
          preview: TextFilter,
          applyOnFunctions: ["first", "last"],
          partialSearch: true,
          caseInsensitive: true
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter,
        caseInsensitive: true,
        partialSearch: true,
        name: "customer",
        label: "Customer"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCity;
    },
    label: "Applicant city",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentCity",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentCity,
      key: "applicantCurrentCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentCity",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCity"]
    },
    name: "applicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCity?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentState;
    },
    label: "Applicant state",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentState",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentState,
      key: "applicantCurrentState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentState",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count", "list-count-unique"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentState"]
    },
    name: "applicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentState?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentZipCode;
    },
    label: "Applicant zip",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentZipCode",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentZipCode,
      key: "applicantCurrentZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count", "list-count-unique", "list"],
      path: "data.applicant.data.info.currentZipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentZipCode"]
    },
    name: "applicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentZipCode?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },

  {
    getData: (entry): CellValue => {
      return entry.data?.applicant?.data?.info?.currentCounty;
    },
    label: "Applicant county",
    tableSettingsParent: "applicant",
    grouping: {
      path: "data.applicant.data.info.currentCounty",
      enabled: true,
      options: {
        path: ["_id", "applicantCurrentCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.applicantCurrentCounty,
      key: "applicantCurrentCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.applicant.data.info.currentCounty",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "applicant", "data", "info", "currentCounty"]
    },
    name: "applicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCurrentCounty?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "applicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => dealSourceToLabel(entry.data?.source?.type),
    label: "Application source",
    tableSettingsParent: "deal",
    options: {
      sort: true,
      path: ["data", "source", "type"]
    },
    name: "source",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.source?.show;
    },
    grouping: {
      path: "data.source.type",
      enabled: true,
      options: {
        path: ["_id", "source"],
        sort: true
      },
      getData: (entry): CellValue => dealSourceToLabel(entry.data?.source),
      key: "source"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.source.type",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "source", "type"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealSourceTypes),
        optionLabelForSelect: (source) => dealSourceToLabel(source)
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCity;
    },
    label: "Co-Applicant city",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentCity",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentCity,
      key: "coApplicantCurrentCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentCity",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCity"]
    },
    name: "coApplicantCurrentCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCity?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCity"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentState;
    },
    label: "Co-Applicant state",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentState",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentState,
      key: "coApplicantCurrentState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentState",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentState"]
    },
    name: "coApplicantCurrentState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentState?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentState"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentZipCode;
    },
    label: "Co-Applicant zip",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentZipCode",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentZipCode,
      key: "coApplicantCurrentZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentZipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentZipCode"]
    },
    name: "coApplicantCurrentZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentZipCode?.show;
    },
    default: "N/A",
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentZipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.coApplicant?.data?.info?.currentCounty;
    },
    label: "Co-Applicant county",
    tableSettingsParent: "co-applicant",
    grouping: {
      path: "data.coApplicant.data.info.currentCounty",
      enabled: true,
      options: {
        path: ["_id", "coApplicantCurrentCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.coApplicantCurrentCounty,
      key: "coApplicantCurrentCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.coApplicant.data.info.currentCounty",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "coApplicant", "data", "info", "currentCounty"]
    },
    name: "coApplicantCurrentCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCurrentCounty?.show;
    },
    filters: [
      {
        path: ["data", "coApplicant", "data", "info", "currentCounty"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.city;
    },
    label: "Dealership city",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.city",
      enabled: true,
      options: {
        path: ["_id", "dealershipCity"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipCity,
      key: "dealershipCity"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.city",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "city"]
    },
    name: "dealershipCity",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCity?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "city"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.state;
    },
    label: "Dealership state",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.state",
      enabled: true,
      options: {
        path: ["_id", "dealershipState"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipState,
      key: "dealershipState"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.state",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "state"]
    },
    name: "dealershipState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipState?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "state"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.zipCode;
    },
    label: "Dealership zip",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.zipCode",
      enabled: true,
      options: {
        path: ["_id", "dealershipZipCode"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipZipCode,
      key: "dealershipZipCode"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.zipCode",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "zipCode"]
    },
    name: "dealershipZipCode",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipZipCode?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "zipCode"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.dealership?.data?.info?.county;
    },
    label: "Dealership county",
    tableSettingsParent: "dealership",
    grouping: {
      path: "data.dealership.data.info.county",
      enabled: true,
      options: {
        path: ["_id", "dealershipCounty"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.dealershipCounty,
      key: "dealershipCounty"
    },
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.dealership.data.info.county",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    default: "N/A",
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "county"]
    },
    name: "dealershipCounty",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipCounty?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "county"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry.data?.info?.refNumber;
    },
    label: "Ref #",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.refNumber",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    tableSettingsParent: "deal",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },

  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.make",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      path: "data.info.vehicle.make",
      enabled: true,
      options: {
        path: ["_id", "make"],
        sort: true
      },
      getData: (entry): CellValue => entry.data?.make,
      key: "make"
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ],
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    }
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.model",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.model,
      path: "data.info.vehicle.model",
      enabled: true,
      key: "model",
      options: {
        path: ["_id", "model"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.year,
    label: "Year",
    name: "year",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      path: "data.info.vehicle.year",
      possibleFunctions: ["first", "last", "list", "list-count-unique", "list-count", "avg"],
      filters: [
        { preview: PriceFilter, applyOnFunctions: ["avg", "list-count-unique", "list-count"] }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.year,
      path: "data.info.vehicle.year",
      enabled: true,
      key: "year",
      options: {
        path: ["_id", "year"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.year?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "year"],
        preview: PriceFilter,
        valuesForSelect: [1900, 2100],
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      possibleFunctions: ["first", "last", "list-count-unique", "list-count", "list"],
      path: "data.info.vehicle.VIN",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "VIN"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.vehicle?.odometer) ?? "N/A",
    label: "Mileage",
    name: "mileage",
    tableSettingsParent: "vehicle",
    columnAggregation: {
      path: "data.info.vehicle.odometer",
      possibleFunctions: ["avg", "first", "last", "list-count-unique", "list-count", "list"],
      filters: [
        { preview: PriceFilter, applyOnFunctions: ["avg", "list-count-unique", "list-count"] }
      ]
    },
    type: "number",
    default: "N/A",
    total: totalsDefault(),
    grouping: {
      getData: (entry): CellValue => entry.data?.odometer,
      path: "data.info.vehicle.odometer",
      enabled: true,
      key: "odometer",
      options: {
        path: ["_id", "odometer"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.mileage?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "odometer"],
        preview: TextFilter,
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    tableSettingsParent: "lender",
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.lender.data.info.name",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    grouping: {
      getData: (entry): CellValue => entry.data?.lender,
      path: "data.lender.data.info.name",
      enabled: true,
      key: "lender",
      options: {
        path: ["_id", "lender"],
        sort: true
      }
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ],
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    }
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    tableSettingsParent: "manager",
    name: "f&iManager",
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "userNames",
      filters: [
        {
          preview: TextFilter,
          applyOnFunctions: ["first", "last"],
          caseInsensitive: true,
          partialSearch: true
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return entry?.data?.info?.status;
    },
    label: "Status",
    tableSettingsParent: "status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChip(dealStatus, false);
      },
      sort: true,
      path: ["data", "info", "status"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.status",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ],
    grouping: {
      getData: (entry): CellValue => {
        return entry?.data?.status;
      },
      options: {
        path: ["_id", "status"],
        sort: true
      },
      path: "data.info.status",
      enabled: true,
      key: "status"
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    }
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.submittedForFunding
        ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
        : "",
    label: "Submitted at",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "submittedForFunding"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.submittedForFunding",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "submittedForFunding",
    dateFilterPath: ["data", "info", "dealDates", "submittedForFunding"],
    grouping: {
      options: {
        path: ["_id", "submittedForFunding"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.submittedForFunding,
      path: "data.info.dealDates.submittedForFunding",
      enabled: true,
      key: "submittedForFunding"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.submittedForFunding?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "submittedForFunding"],
        preview: DateFilter,
        label: "Submitted for funding"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.dealTotal ?? 0, "$"),
    label: "Amount Financed",
    name: "amountFinanced",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.amountFinanced?.show;
    },
    default: "$0.00",
    type: "number",
    total: totalsDefault("$"),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.dealTotal",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "dealTotal"],
        preview: PriceFilter,
        name: "amountFinanced",
        label: "Amount Financed"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.interestRate ?? 0),
    label: "Interest Rate",
    name: "interestRate",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.interestRate?.show;
    },
    default: "0.00",
    type: "number",
    total: totalsDefault(),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.interestRate"
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.interestRate;
      },
      enabled: true,
      key: "interestRate",
      options: {
        path: ["_id", "interestRate"],
        sort: true
      },
      path: "data.info.payment.interestRate"
    },
    filters: [
      {
        path: ["data", "info", "payment", "interestRate"],
        preview: PriceFilter,
        name: "interestRate",
        label: "Interest Rate"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.payment?.numberOfPayments ?? "N/A",
    label: "Term",
    name: "term",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.term?.show;
    },
    type: "number",
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.numberOfPayments",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "numberOfPayments"],
        preview: PriceFilter,
        name: "term",
        label: "Term"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.monthlyPayment ?? 0, "$"),
    label: "Monthly Payment",
    name: "monthlyPayment",
    tableSettingsParent: "financial",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.monthlyPayment?.show;
    },
    default: "$0.00",
    type: "number",
    sign: (funcName) => "$",
    total: totalsDefault("$"),
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.monthlyPayment",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "monthlyPayment"],
        preview: PriceFilter,
        name: "monthlyPayment",
        label: "Monthly payment"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      return formatNumberAsCurrency(entry.data?.info?.payment?.fundedAmount ?? 0, "$");
    },
    label: "Funded",
    tableSettingsParent: "financial",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "payment", "fundedAmount"]
    },
    type: "number",
    total: totalsDefault("$"),
    sign: (funcName) => "$",
    columnAggregation: {
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      path: "data.info.payment.fundedAmount",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter,
        name: "fundedAmount",
        label: "Funded Amount"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.fundedAt ? new Date(entry.data?.info?.dealDates?.fundedAt) : "",
    label: "Funded At",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "fundedAt"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.fundedAt",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "fundedAt",
    dateFilterPath: ["data", "info", "dealDates", "fundedAt"],
    grouping: {
      options: {
        path: ["_id", "fundedAt"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.fundedAt,
      path: "data.info.dealDates.fundedAt",
      enabled: true,
      key: "fundedAt"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAt?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "fundedAt"],
        preview: DateFilter,
        label: "Funded At"
      }
    ]
  },
  {
    getData: (entry): CellValue => {
      switch (entry?.data?.info?.type) {
        case "Recreational Vehicles":
          return "RV";
        case "Automotive":
          return "Auto";
        case "Marine":
          return "Marine";
        case "Power Sport":
          return "Power";
        default:
          return "";
      }
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.collateralType;
      },
      enabled: true,
      key: "collateralType",
      options: {
        path: ["_id", "collateralType"],
        sort: true
      },
      path: "data.info.type"
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.type",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    filters: [
      {
        path: ["data", "info", "type"],
        preview: MultiSelectFilter,
        valuesForSelect: Array.from(collateralTypes),
        optionLabelForSelect: (x) => x
      }
    ],
    label: "Collateral",
    truncate: 12,
    tableSettingsParent: "vehicle",
    name: "collateralType",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.collateralType?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "type"]
    }
  },
  {
    getData: (entry): CellValue => {
      return entry?.data?.dealership?.data?.info?.displayName;
    },
    label: "Dealership",
    tableSettingsParent: "dealership",
    truncate: 15,
    grouping: {
      getData: (entry): CellValue => {
        return entry?.data?.dealership;
      },
      options: {
        path: ["_id", "dealership"],
        sort: true
      },
      enabled: true,
      key: "dealership",
      path: "data.dealership.data.info.displayName"
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.dealership.data.info.displayName",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    options: {
      sort: true,
      path: ["data", "dealership", "data", "info", "displayName"]
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter,
        label: "Dealership"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.creditCheckDate ? new Date(entry.data?.info?.creditCheckDate) : "",
    label: "Credit check date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "creditCheckDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.creditCheckDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "creditCheckDate",
    dateFilterPath: ["data", "info", "creditCheckDate"],
    grouping: {
      options: {
        path: ["_id", "creditCheckDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.creditCheckDate,
      path: "data.info.creditCheckDate",
      enabled: true,
      key: "creditCheckDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.creditCheckDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "creditCheckDate"],
        preview: DateFilter,
        label: "Credit Check Date"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.collateralReceivedDate
        ? new Date(entry.data?.info?.collateralReceivedDate)
        : "",
    label: "Collateral received date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "collateralReceivedDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.collateralReceivedDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "collateralReceivedDate",
    dateFilterPath: ["data", "info", "collateralReceivedDate"],
    grouping: {
      options: {
        path: ["_id", "collateralReceivedDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.collateralReceivedDate,
      path: "data.info.collateralReceivedDate",
      enabled: true,
      key: "collateralReceivedDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.collateralReceivedDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "collateralReceivedDate"],
        preview: DateFilter,
        label: "Collateral Received Date"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract date",
    tableSettingsParent: "dates",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "contractDate"]
    },
    columnAggregation: {
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.dealDates.contractDate",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    type: "date",
    name: "contractDate",
    dateFilterPath: ["data", "info", "dealDates", "contractDate"],
    grouping: {
      options: {
        path: ["_id", "contractDate"],
        sort: true
      },
      getData: (entry): CellValue => entry?.data?.contractDate,
      path: "data.info.dealDates.contractDate",
      enabled: true,
      key: "contractDate"
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (entry, hiddenValues): CellValue => {
      return entry?.createdAt;
    },
    tableSettingsParent: "dates",
    label: "Created at",
    type: "date",
    truncate: 15,
    name: "createdAt",
    columnAggregation: {
      path: "createdAt",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last"],
      filters: [
        {
          preview: DateFilter,
          applyOnFunctions: ["first", "last"]
        },
        {
          preview: PriceFilter,
          applyOnFunctions: ["list-count-unique", "list-count"]
        }
      ]
    },
    dateFilterPath: ["createdAt"],
    grouping: {
      getData: (entry) => entry?.data?.createdAt,
      enabled: true,
      key: "createdAt",
      path: "createdAt",
      options: {
        path: ["_id", "createdAt"],
        sort: true
      }
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.createdAt?.show;
    },
    options: {
      sort: true,
      path: ["createdAt"]
    },
    filters: [
      {
        path: ["createdAt"],
        preview: DateFilter,
        label: "Created at"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.extractedCreditScores?.applicant?.score) ?? "N/A",
    label: "Applicant credit score",
    tableSettingsParent: "applicant",
    type: "number",
    name: "applicantCreditScore",
    total: totalsDefault(),
    columnAggregation: {
      path: "data.info.extractedCreditScores.applicant.score",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "extractedCreditScores", "applicant", "score"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.applicantCreditScore?.show;
    },
    filters: [
      {
        path: ["data", "info", "extractedCreditScores", "applicant", "score"],
        preview: PriceFilter,
        name: "applicantCreditScore",
        label: "Applicant credit score"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.extractedCreditScores?.coApplicant?.score) ?? "N/A",
    label: "Co-Applicant credit score",
    tableSettingsParent: "co-applicant",
    name: "coApplicantCreditScore",
    type: "number",
    total: totalsDefault(),
    columnAggregation: {
      path: "data.info.extractedCreditScores.coApplicant.score",
      possibleFunctions: ["list", "list-count-unique", "list-count", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "extractedCreditScores", "coApplicant", "score"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.coApplicantCreditScore?.show;
    },
    filters: [
      {
        path: ["data", "info", "extractedCreditScores", "coApplicant", "score"],
        preview: PriceFilter,
        name: "coApplicantCreditScore",
        label: "Co-Applicant credit score"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.profit?.dealershipProfit?.totalProfit ?? 0, "$"),

    label: "Dealership Profit",
    tableSettingsParent: "financial",
    type: "number",
    name: "dealershipProfit",
    total: totalsDefault("$"),
    sign: (funcName) => "$",
    default: "$0.00",
    columnAggregation: {
      possibleFunctions: ["avg", "sum", "list", "list-count-unique", "list-count", "first", "last"],
      path: "data.info.profit.dealershipProfit.totalProfit",
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["avg", "list-count-unique", "list-count", "sum"]
        }
      ]
    },
    options: {
      sort: true,
      path: ["data", "info", "dealershipProfit", "totalProfit"]
    },
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealershipProfit?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealershipProfit", "totalProfit"],
        preview: PriceFilter,
        name: "dealershipProfit",
        label: "Dealership Profit Total"
      }
    ]
  },
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.isInStatus?.[status] ? "Yes" : "No"),
    label: `Is in status ${status}`,
    tableSettingsParent: "is in status",
    total: totalsDefault(),
    options: {
      sort: true,
      path: ["data", "info", "isInStatus", status]
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.[`isInStatus${status}`] ? "Yes" : "No";
      },
      enabled: true,
      key: `isInStatus${status}`,
      options: {
        path: ["_id", "isInStatus"],
        sort: true
      },
      path: `data.info.isInStatus.${status}`
    },
    default: "N/A",
    columnAggregation: {
      path: `data.info.isInStatus.${status}`,
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    name: `isInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.[`isInStatus${capitalize(status)}`]?.show;
    },
    filters: [
      {
        path: ["data", "info", "isInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (isInStatusBinary) => (isInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<any>[]),
  ...(Object.values(DealStatus).map((status) => ({
    getData: (entry): CellValue => (entry.data?.info?.wasInStatus?.[status] ? "Yes" : "No"),
    label: `Was in status ${status}`,
    tableSettingsParent: "was in status",
    total: totalsDefault(),
    options: {
      sort: true,
      path: ["data", "info", "wasInStatus", status]
    },
    sign: (funcName) => {
      switch (funcName) {
        case "avg":
          return "%";
        default:
          return undefined;
      }
    },
    grouping: {
      getData: (entry) => {
        return entry?.data?.[`wasInStatus${status}`] ? "Yes" : "No";
      },
      enabled: true,
      key: `wasInStatus${status}`,
      options: {
        path: ["_id", "wasInStatus"],
        sort: true
      },
      path: `data.info.wasInStatus.${status}`
    },
    default: "N/A",
    columnAggregation: {
      path: `data.info.wasInStatus.${status}`,
      dataModifier: (cellValue, funcName) => {
        switch (funcName) {
          case "avg":
            return typeof cellValue === "number" ? cellValue * 100 : cellValue;
          default:
            return cellValue;
        }
      },
      possibleFunctions: ["list", "list-count", "list-count-unique", "first", "last", "sum", "avg"],
      filters: [
        {
          preview: PriceFilter,
          applyOnFunctions: ["sum", "list-count-unique", "list-count", "avg"]
        }
      ]
    },
    name: `wasInStatus${capitalize(status)}`,
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.[`wasInStatus${capitalize(status)}`]?.show;
    },
    filters: [
      {
        path: ["data", "info", "wasInStatus", status],
        preview: MultiSelectFilter,
        valuesForSelect: [1, 0],
        optionLabelForSelect: (wasInStatusBinary) => (wasInStatusBinary === 1 ? "Yes" : "No")
      }
    ]
  })) as Column<any>[])
];
export const renderListOnHint = (x: any, hintList: any[]) => {
  return (
    <HintTooltip
      title={
        <div>
          {hintList?.length > 0 && (
            <table>
              <tbody>
                {hintList.map((ref, index) => (
                  <tr key={index}>
                    <td>{ref}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      }
    >
      <div>{x}</div>
    </HintTooltip>
  );
};

export type ListFunction = (
  _id: string,
  props: getListProps
) => (dispatch: AppDispatch) => Promise<void>;

export default function RepDashboardDealTable({
  repIds,
  dealershipIds,
  userId,
  tableName,
  setGroupedTableResetStickyCallback
}: {
  setGroupedTableResetStickyCallback?: React.Dispatch<
    React.SetStateAction<(() => void) | undefined>
  >;
  userId: string | undefined;
  dealershipIds: string[] | undefined;
  repIds: string[] | undefined;
  tableName: string;
}): JSX.Element {
  const slice = `salesRepDeals-${userId}`;
  const dealList = useSelector((state: RootState) => state.listSalesRepDealsSlice[slice]);

  const projections = {
    "data.info.dealDates": 1,
    "data.info.creditCheckDate": 1,
    "data.info.collateralReceivedDate": 1,
    "data.info.type": 1,
    "data.source": 1,
    "data.info.status": 1,
    "data.notes": 1,
    "data.info.refNumber": 1,
    "data.creditBureaus": 1,
    "data.dealership._id": 1,
    "data.dealership.data.info.displayName": 1,
    "data.dealership.data.info.county": 1,
    "data.dealership.data.representativeId": 1,
    "data.dealership.data.info.state": 1,
    "data.dealership.data.info.city": 1,
    "data.dealership.data.info.zip": 1,
    "data.dealership.data.representative.data.info.firstName": 1,
    "data.dealership.data.representative.data.info.lastName": 1,
    "data.lender.data.info.name": 1,
    "data.info.payment": 1,
    "data.info.extractedCreditScores": 1,
    "data.applicant": 1,
    "data.info.wasInStatus": 1,
    "data.info.isInStatus": 1,
    "data.user._id": 1,
    "data.userId": 1,
    "data.user.data.info.firstName": 1,
    "data.user.data.info.lastName": 1,
    "data.coApplicant": 1,
    "data.info.vehicle": 1,
    "data.info.profit": 1,
    "data.info.taxesAndFees": 1,
    _id: 1,
    createdAt: 1,
    updatedAt: 1,
    deleted: 1,
    applicantNames: 1,
    userNames: 1,
    repNames: 1
  };

  const representativeQuery = {
    $and: [
      // {
      //   "data.info.status": { $nin: ["dead"] }
      // },
      ...(repIds
        ? [{ "data.dealership.data.representativeId": { $in: repIds } }]
        : [{ "data.dealership.data.representativeId": { $exists: true } }]),
      ...(dealershipIds ? [{ "data.dealershipId": { $in: dealershipIds } }] : [])
    ]
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <GroupedTable
        setGroupedTableResetStickyCallback={setGroupedTableResetStickyCallback}
        toolbarStyle={{ minHeight: "48px" }}
        tableName={tableName}
        customActionsStyle={{ height: 10, marginTop: "-10px", transform: "scale(0.7)" }}
        entityName="deal"
        groupingType={"salesrep"}
        filterByDateByDefault
        listFunction={getSalesRepDeals}
        clearListFunction={clearSalesRepDealList}
        renderChildTable={(ids, tableGroupBy) => {
          return <ChildDeals tableGroupBy={tableGroupBy} dealIds={ids} />;
        }}
        defaultColumnGroupQuery={{ "data.info.type": "salesrep" }}
        expandable={true}
        listEntity={{
          entities: dealList?.columnAggs?.length
            ? // @ts-ignore
              dealList?.entities?.map((dealAgg: any): any => {
                const { _id, ...restOfData } = dealAgg;
                return {
                  _id: Object.values(dealAgg._id).join("_"),
                  data: {
                    ...dealAgg._id,
                    ...restOfData
                  }
                };
              })
            : dealList?.entities,
          ...(dealList?.comparison
            ? {
                comparison: {
                  ...dealList?.comparison,
                  entities: dealList?.comparison?.columnAggs?.length
                    ? // @ts-ignore
                      dealList?.comparison?.entities?.map((dealAgg: any): any => {
                        const { _id, ...restOfData } = dealAgg;
                        return {
                          _id: Object.values(dealAgg._id).join("_"),
                          data: {
                            ...dealAgg._id,
                            ...restOfData
                          }
                        };
                      })
                    : dealList?.comparison?.entities
                }
              }
            : {}),
          columnAggs: dealList?.columnAggs || [],
          totals: dealList?.totals,
          totalsAvg: dealList?.totalsAvg,
          totalsListCount: dealList?.totalsListCount,
          totalsListCountAvg: dealList?.totalsListCountAvg,
          totalsListCountUnique: dealList?.totalsListCountUnique,
          totalsListCountUniqueAvg: dealList?.totalsListCountUniqueAvg,
          status: dealList?.status,
          total: dealList?.total || 0
        }}
        defaultDateFilterColumnName="createdAt"
        rowsPerPage={50}
        slice={slice}
        title={"Deals"}
        defaultColumns={groupDealColumns}
        customCellFontSize="12px"
        query={representativeQuery}
        //@ts-ignore
        projection={projections}
        showTotalFooter={true}
      />
    </div>
  );
}

import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { User } from "./types";

export type UserPermissionsStruct = PermissionStruct<User>;

export const userPermissionStruct: UserPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      PIN: permissionNode("PIN"),
      firstName: permissionNode("First Name"),
      lastName: permissionNode("Last Name"),
      phoneNumber: permissionNode("Phone number"),
      email: permissionNode("Email"),
      userType: permissionNode("UserType"),
      enabled: permissionNode("Enabled"),
      dashboard: multyPermissionNode("Dashboard"),
      periodCommissions: multyPermissionNode("Period commissions"),
      onlineCreditApplicationNotification: permissionNode("Online Credit Application Notification"),
      lenderAllocationNotification: permissionNode("Lender Allocation Notification"),
      contractNotification: permissionNode("ContractNotification"),
      lenderSupport: permissionNode("Lender support"),
      dashboardTypes: {
        coverageStatistics: permissionNode("Lender Coverage Statistics"),
        accountant: permissionNode("Accountant"),
        lenderEstimation: permissionNode("Lender Estimation"),
        salesRep: permissionNode("Representative"),
        "f&i": permissionNode("F&I"),
        lender: permissionNode("Lender"),
        allocation: permissionNode("Allocation"),
        titleIssues: permissionNode("Title Information"),
        custom: permissionNode("Custom")
      },
      allocationPeriods: { sads: multyPermissionNode("Allocation periods") }
    },
    statistics: multyPermissionNode("Statistics"),
    dealership: relationPermissionNode("Dealership", "dealership"),
    dealershipId: permissionNode("Dealership"),
    loginAsUser: relationPermissionNode("Login as User", "wfd_user"),
    loginAsUserId: permissionNode("Login as User Id"),
    roles: relationPermissionNode("Roles", "role"),
    rolesIds: multyPermissionNode("Roles Ids"),
    netsuiteId: permissionNode("Netsuite Id")
  }
};

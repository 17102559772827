import { Deal, DealStatus } from "./types";
import { machineGenerator, MachineTrigger } from "state-machine/src/stateMachine";
import {
  getDealState,
  setDealState,
  getDealMachineSchema
} from "state-machine/src/dealStateMachine";
const dealMachineTriggers: MachineTrigger<Deal, DealStatus[]> = {
  [DealStatus.Lead]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Dead]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Funded]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.WaitingForDealDetails]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.DealCreated]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.FundingHeld]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Cancelled]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Countered]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.CreditCheck]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Approved]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.SentToDealer]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.Denied]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.PendingLenderDecision]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.SubmittedForFunding]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  },
  [DealStatus.DidNotProcess]: {
    onLeave: async (data: Deal) => data,
    onEnter: async (data: Deal) => data
  }
};

export const dealStateMachine = machineGenerator<Deal, DealStatus[]>(
  getDealMachineSchema<Deal>(),
  getDealState,
  setDealState,
  dealMachineTriggers,
  (deal) => deal
);

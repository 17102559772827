import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Switch,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RootState } from "app/rootReducer";
import UnlockPINDialog from "components/Users/UnlockPINDialog";
import { useStickyState } from "index";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getFirstAndLastDateOfGivenYearAndMonth } from "utils/functions";
import { monthNames } from "../common";
import DashboardDeals from "../DashboardDeals";
import CountByProductsChart from "./CountByProductsChart ";
import CountChart from "./CountChart";
import ChargebackTable from "./F&IChargebackTable";
import PVRAndCommission from "./PVRAndCommission";
import SumChart from "./SumChart";

export const randomNumber = (): number => {
  return parseInt((Math.random() * 1000).toString());
};

export default function FIManagerDashboard({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) {
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();
  const tableName = `f&iDeals`;

  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const [locked, setLocked] = useState(true);
  const [openPINDialog, setOpenPINDialog] = useState(false);
  const [selectedMonth, setSelectedMonth] = useStickyState(
    new Date().getMonth(),
    "manager_dashboard_selectedMonth"
  );
  const [selectedYear, setSelectedYear] = useStickyState(
    new Date().getFullYear(),
    "manager_dashboard_selectedYear"
  );

  const { from, to } = getFirstAndLastDateOfGivenYearAndMonth(selectedYear, selectedMonth + 1);
  const handleResetStickyState = () => {
    setSelectedMonth(new Date().getMonth());
    setSelectedYear(new Date().getFullYear());
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <UnlockPINDialog
        open={openPINDialog}
        setOpen={setOpenPINDialog}
        userPIN={user?.data?.info?.PIN}
        setLocked={setLocked}
      />
      <Grid style={{ marginTop: "4px" }} container spacing={1}>
        <div style={{ position: "absolute", right: "0px" }}>
          <Switch
            checked={!locked}
            onChange={() => {
              if (!locked) {
                setLocked(true);
              } else {
                setOpenPINDialog(true);
              }
            }}
          />
        </div>
        <Grid item xs={4}>
          <CountChart from={from} to={to} userIds={[user?._id ?? ""]} />
        </Grid>
        <Grid item xs={4}>
          <SumChart showNumbers={!locked} from={from} to={to} userIds={[user?._id ?? ""]} />
        </Grid>
        <Grid item xs={4}>
          <PVRAndCommission showNumbers={!locked} from={from} to={to} user={user} />
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Additional statistics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid style={{ marginTop: "4px" }} container spacing={1}>
                <Grid item xs={4}>
                  <CountByProductsChart from={from} to={to} userIds={[user?._id ?? ""]} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={2} style={{ margin: "5px 0" }}>
          <Grid container spacing={1}>
            {[
              new Date().getFullYear() - 3,
              new Date().getFullYear() - 2,
              new Date().getFullYear() - 1,
              new Date().getFullYear()
            ].map((year, index) => (
              <Grid key={index} item xs={3}>
                <Button
                  onClick={() => setSelectedYear(year)}
                  variant={"contained"}
                  size={"small"}
                  style={{ width: "100%" }}
                  color={year === selectedYear ? "primary" : "default"}
                >
                  {year}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={10} style={{ margin: "5px 0" }}>
          <Grid container spacing={1}>
            {monthNames.map((month, index) => (
              <Grid key={index} item xs={1}>
                <Button
                  onClick={() => setSelectedMonth(index)}
                  disabled={
                    index > new Date().getMonth() && selectedYear === new Date().getFullYear()
                  }
                  variant={"contained"}
                  size={"small"}
                  style={{ width: "100%" }}
                  color={index === selectedMonth ? "primary" : "default"}
                >
                  {month}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DashboardDeals
            setResetStickyCallback={setResetStickyTableCallback}
            showNumbers={!locked}
            from={from}
            tableName={tableName}
            to={to}
            type="f&i"
            userIds={[user?._id ?? ""]}
          />
        </Grid>
        <Grid item xs={12}>
          <ChargebackTable from={from} to={to} userId={user?._id ?? ""} />
        </Grid>
      </Grid>
    </>
  );
}

import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import UserFilter from "components/Filters/UserFilter";
import React from "react";
import { useSelector } from "react-redux";
import Table, { CellValue, Column } from "../Table";

import { DmvStatus, FormState, Info, TitleIssueStatus } from "./types";
import { getTitleIssueList } from "./listTitleIssueSlice";
import { deleteTitleIssue } from "./deleteTitleIssueSlice";
import { recoverTitleIssue } from "./recoverTitleIssueSlice";
import AccessControl from "components/Access/AccessControl";
import TextFilter from "components/Filters/TextFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import States from "us-states";
import { dmvStatusToChip, titleStatusToChip, titleStatusToColor } from "./DocumentsSection";
import { formatDmvStatus, getNextCheckDate } from "./Overview";
import { capitalize } from "utils/functions";
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import PriceFilter from "components/Filters/PriceFilter";
import StyledBadge from "components/StyledBadge";

export const projections = {
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.deal.data.info.vehicle.VIN": 1,
  "data.deal.data.lender.data.info.name": 1,
  "data.deal.data.lender._id": 1,
  "data.deal.data.info.refNumber": 1,
  "data.deal.data.info.dealDates.contractDate": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.oldTitleRegistrationInfo.status": 1,
  "data.info.titleRegistrationInfo.status": 1,
  "data.info.receiptInfo.status": 1,
  "data.info.dmvCheckedCount": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice["table"]);
  const [overallQuery, setOverallQuery] = React.useState<
    | {
        active: TitleIssueStatus;
        query: Record<string, TitleIssueStatus | Record<string, TitleIssueStatus>[]>;
      }
    | undefined
  >(undefined);
  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.refNumber,
      label: "Deal ref.",
      name: "dealRef",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealRef?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "State",
      getData: (entry): CellValue =>
        entry?.data?.deal?.data?.applicant?.data?.info?.currentState ?? "",
      options: {
        sort: true,
        path: ["data", "deal", "data", "applicant", "data", "info", "currentState"]
      },
      name: "state",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.state?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "applicant", "data", "info", "currentState"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort()
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.info?.dmvCheckedCount,
      label: "DMV Checked count",
      name: "dmvCheckedCount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvCheckedCount?.show;
      },
      filters: [
        {
          path: ["data", "info", "dmvCheckedCount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => getNextCheckDate(entry),
      label: "Next check date",
      name: "nextCheckDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.nextCheckDate?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.info?.dmvInfo?.status,
      label: "DMV Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return dmvStatusToChip(x as DmvStatus);
        },
        sort: true,
        path: ["data", "info", "dmvInfo", "status"]
      },
      name: "dmvStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "dmvInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["titled", "untitled", "old_title", "error"],
          optionLabelForSelect: (status) => formatDmvStatus(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.titleRegistrationInfo?.status,
      label: "New Title Document Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "titleRegistrationInfo", "status"]
      },
      name: "titleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.titleRegistrationInfoStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "titleRegistrationInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.oldTitleRegistrationInfo?.status,
      label: "Old Title Document Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "oldTitleRegistrationInfo", "status"]
      },
      name: "oldTitleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.oldTitleRegistrationInfoStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "oldTitleRegistrationInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.receiptInfo?.status,
      label: "Receipt Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "receiptInfo", "status"]
      },
      name: "receiptStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.receiptStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "receiptInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.deal?.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.deal?.data?.info?.dealDates?.contractDate)
          : "",
      label: "Contract",
      options: {
        sort: true,
        path: ["data", "deal", "data", "info", "dealDates", "contractDate"]
      },
      name: "contractDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "dealDates", "contractDate"],
          preview: DateFilter,
          label: "Contract Date"
        }
      ]
    },
    {
      label: "Date updated",
      getData: (el): CellValue => new Date(el.updatedAt),
      options: {
        sort: true,
        path: ["updatedAt"]
      },
      name: "updatedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.updatedAt?.show;
      },
      filters: [
        {
          path: ["updatedAt"],
          preview: DateFilter,
          label: "Updated At",
          name: "updatedAt"
        }
      ]
    },
    {
      getData: (entry): CellValue => (entry.createdAt ? new Date(entry.createdAt) : ""),
      label: "Created At",
      options: {
        sort: true,
        path: ["createdAt"]
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const handleSetOverallQuery = (status: TitleIssueStatus) => {
    switch (status) {
      case "resolved":
      case "awaiting":
        setOverallQuery({
          active: status,
          query: {
            "data.info.oldTitleRegistrationInfo.status": status,
            "data.info.titleRegistrationInfo.status": status,
            "data.info.receiptInfo.status": status
          }
        });
        return;
      case "rejected":
      case "pending":
        setOverallQuery({
          active: status,
          query: {
            $or: [
              {
                "data.info.oldTitleRegistrationInfo.status": status
              },
              {
                "data.info.titleRegistrationInfo.status": status
              },
              {
                "data.info.receiptInfo.status": status
              }
            ]
          }
        });
    }
  };
  const titleStatusToBadgeColor = (status: TitleIssueStatus) => {
    switch (status) {
      case "resolved":
        return "green";
      case "rejected":
        return "red";
      case "awaiting":
        return "gray";
      case "pending":
        return "orange";
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12} style={{ margin: "5px 0" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography variant="h6">Overall Document Status</Typography>
          <Button
            style={{ margin: "5px" }}
            variant={"outlined"}
            onClick={() => setOverallQuery(undefined)}
          >
            Reset
          </Button>
          {(["resolved", "rejected", "pending", "awaiting"] as TitleIssueStatus[]).map(
            (status, index) => (
              <StyledBadge
                key={index}
                badgeContent={overallQuery?.active === status ? 1 : 0}
                color={titleStatusToBadgeColor(status)}
                variant="dot"
              >
                <Button
                  style={{ margin: "5px", background: titleStatusToColor(status) }}
                  variant={"outlined"}
                  onClick={() => handleSetOverallQuery(status)}
                >
                  {capitalize(status)}
                </Button>
              </StyledBadge>
            )
          )}
        </Paper>
      </Grid>
      <AccessControl requiredPermissions={{ entity: "title_issue", action: "read" }}>
        <Table
          tableName="title_issues"
          entityName="title_issue"
          listFunction={getTitleIssueList}
          deleteEntityFunction={deleteTitleIssue}
          recoverEntityFunction={recoverTitleIssue}
          sort={{ createdAt: "desc" }}
          listEntity={titleIssuesList}
          aggregateFirst={true}
          query={{ ...overallQuery?.query }}
          projection={projections}
          title={"Title Information"}
          columns={columns}
          hideAddButton
        />
      </AccessControl>
    </div>
  );
};

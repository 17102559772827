import { CustomRegexes } from "../../helpers";

export default {
  domain: { type: "string", nullable: true },
  name: { type: "string" },
  displayName: { type: "string", nullable: true },
  address: { type: "string", nullable: true },
  secondaryAddress: { type: "string", nullable: true },
  shippingAddress: { type: "string", nullable: true },
  abbreviation: { type: "string", nullable: true },
  eSign: { type: "boolean", nullable: true },
  collateralTypes: {
    type: "object",
    properties: {
      Automotive: {
        type: "boolean",
        nullable: true,
      },
      "Power Sport": {
        type: "boolean",
        nullable: true,
      },
      "Recreational Vehicles": {
        type: "boolean",
        nullable: true,
      },
      Marine: {
        type: "boolean",
        nullable: true,
      },
    },
    nullable: true,
  },
  city: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    nullable: true,
    errorMessage: { pattern: "should not contain numbers" },
  },
  state: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    nullable: true,
    errorMessage: { pattern: "should not contain numbers" },
  },
  county: {
    type: "string",
    pattern: CustomRegexes.LETTERS,
    nullable: true,
    errorMessage: { pattern: "should not contain numbers" },
  },
  zipCode: {
    type: "string",
    pattern: CustomRegexes.ZIP_CODE,
    nullable: true,
    errorMessage: {
      pattern:
        "should match either of the number formats: 'XXXXX' or 'XXXXX-XXXX'",
    },
  },
  relationType: { type: "string", nullable: true },
  type: { type: "string", nullable: true },
  phone: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1",
    },
  },
  faxNumber: {
    type: "string",
    pattern: CustomRegexes.PHONE,
    nullable: true,
    errorMessage: {
      pattern: "should not contain letters and should not start with 0 or 1",
    },
  },
  logo: {
    type: "object",
    nullable: true,
    properties: {
      name: { type: "string", nullable: true },
      url: { type: "string", nullable: true },
      metadata: {
        type: "object",
        nullable: true,
        properties: {
          type: { type: "string", nullable: true },
          bucket: { type: "string", nullable: true },
          generation: { type: "string", nullable: true },
          metageneration: { type: "string", nullable: true },
          fullPath: { type: "string", nullable: true },
          name: { type: "string", nullable: true },
          size: { type: "number", nullable: true },
          timeCreated: { type: "string", nullable: true },
          updated: { type: "string", nullable: true },
          md5Hash: { type: "string", nullable: true },
          contentDisposition: { type: "string", nullable: true },
          contentEncoding: { type: "string", nullable: true },
          contentType: { type: "string", nullable: true },
        },
      },
    },
  },
  agreement: {
    type: "object",
    nullable: true,
    properties: {
      dealershipSplit: { type: "number", nullable: true },
      wfdSplit: { type: "number", nullable: true },
    },
  },
};

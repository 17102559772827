import DealershipPreview from "components/Dealerships/DealershipPreview";
import { dealershipStruct } from "components/Dealerships/model";
import { statusToLabel } from "components/Deals/Deals";
import LenderPreview from "components/Deals/LenderPreview";
import { DealStatus } from "components/Deals/types";
import { lenderStruct } from "components/Lenders/model";
import UserPreview from "components/Users/UserPreview";
import React from "react";
import { EntityData } from "utils/entitySlice";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { RenderSet, StateAccess, generateForm } from "utils/models/formGenerator";
import store from "../../../app/store";
import { BusinessReportAdd } from "./types";

const defaultDateRangeTypeToLabel = (type: string) => {
  switch (type) {
    case "data.createdAt":
      return "created at";
    case "data.dealDates.fundedAt":
      return "funded at";
    default:
      return false;
  }
};

export const businessStruct = (
  settings: EntityData<"settings"> | undefined
): FormComponent<BusinessReportAdd> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "select-field",
      name: "Report Type",
      label: "Report Type",
      width: "full",
      path: ["data", "info", "request", "businessReportType"],
      options: createOptionsForSelect({
        possibleValues: () => {
          const user = store?.getState()?.authSlice?.user;
          if (user?.databaseData?.data?.rolesIds?.includes("62062c7f47619337fa510be9")) {
            // Representative role
            return (
              (settings?.data?.info.businessReportTypes ?? []).filter(
                (type) =>
                  type?.type === "Basic Representative Report" ||
                  type?.type === "Representative Dealer and Applicant Map" ||
                  type?.type === "Representative Dealer Productivity Report"
              ) ?? []
            );
          }
          if (user?.databaseData?.data?.rolesIds?.includes("627019929fef4ec5ca6e3404")) {
            // Representative role
            return (
              store
                ?.getState()
                ?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.businessReportTypes.filter(
                  (type) =>
                    type.type === "All Dealer report including Weekly Sales Data" ||
                    type.type === "Funding Notice Report"
                ) ?? []
            );
          }
          if (user?.databaseData?.data?.rolesIds?.includes("647ddfb0834f9fe532adcbc5")) {
            // VP of lenders role
            return (
              store
                ?.getState()
                ?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.businessReportTypes.filter(
                  (type) =>
                    type.type === "Lender Report" ||
                    type.type === "Top Funding Lenders Report" ||
                    type.type === "Lender Analysis Report" ||
                    type.type === "Lender Application Credit Analysis"
                ) ?? []
            );
          }
          const businessReportTypes =
            store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info
              .businessReportTypes ?? [];

          return businessReportTypes;
        },
        getOptionLabel: (x) => x?.type ?? "",
        getSelectedOption: (x, y) => x === y
      }),
      required: true,
      default: null
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          struct: () => lenderStruct,
          show: (stateAccess) => {
            const parameters = stateAccess.get<BusinessReportAdd>([
              "data",
              "info",
              "request",
              "businessReportType",
              "parameters"
            ]) as any;
            return parameters?.includes("lender");
          },
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <LenderPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              lenderIdPath={["data", "lenderId"]}
              path={["data", "lender"]}
              name="lender"
              label="Lender"
              required={(stateAccess) => {
                return stateAccess.get([
                  "data",
                  "info",
                  "request",
                  "businessReportType",
                  "defaultParameters",
                  "defaultLenderRequired"
                ]);
              }}
            />
          ),
          name: "lender",
          label: "Lender",
          required: true,
          width: "full",
          path: ["data", "lender"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          struct: () => dealershipStruct,
          show: (stateAccess) => {
            const parameters = stateAccess.get<BusinessReportAdd>([
              "data",
              "info",
              "request",
              "businessReportType",
              "parameters"
            ]) as any;
            return parameters?.includes("dealership");
          },
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealershipPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={false}
              type={"business_report"}
              requiredFunction={(stateAccess) => {
                return stateAccess.get([
                  "data",
                  "info",
                  "request",
                  "businessReportType",
                  "defaultParameters",
                  "defaultDealershipRequired"
                ]);
              }}
            />
          ),
          name: "dealership",
          label: "Dealership",
          required: true,
          default: null,
          path: ["data", "dealership"],
          width: "full"
        },
        {
          formComponent: "one-to-many-field",
          name: "representative",
          label: "Representative",
          show: (stateAccess) => {
            const parameters = stateAccess.get<BusinessReportAdd>([
              "data",
              "info",
              "request",
              "businessReportType",
              "parameters"
            ]) as any;
            return parameters?.includes("representative");
          },
          path: ["data", "representative"],
          width: "full",
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
            <UserPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "representative"]}
              name="representative"
              label="Representative"
              type="representative"
              required={(stateAccess) => {
                return stateAccess.get([
                  "data",
                  "info",
                  "request",
                  "businessReportType",
                  "defaultParameters",
                  "defaultRepresentativeRequired"
                ]);
              }}
            />
          ),
          required: true,

          default: null
        },
        {
          formComponent: "one-to-many-field",
          name: "Date ranges",
          width: "full",
          path: [],
          default: null,
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => {
            const state: BusinessReportAdd = stateAccess.get([]);
            const dateRanges =
              state.data?.info?.request?.businessReportType?.defaultParameters?.defaultDateRanges;

            const struct: FormComponent<BusinessReportAdd> = {
              formComponent: "segment",
              width: "full",
              show: (stateAccess) => {
                const parameters = stateAccess.get<BusinessReportAdd>([
                  "data",
                  "info",
                  "request",
                  "businessReportType",
                  "parameters"
                ]) as any;
                return parameters?.includes("date range");
              },
              entities:
                dateRanges?.map((range) => {
                  const path =
                    dateRanges?.length === 1 && !dateRanges?.[0].defaultDateType
                      ? ["data", "info", "request", "dateRange"]
                      : [
                          "data",
                          "info",
                          "request",
                          "dateRanges",
                          defaultDateRangeTypeToLabel(range.defaultDateType)
                        ];
                  return {
                    formComponent: "segment",
                    name: `Date range ${
                      defaultDateRangeTypeToLabel(range.defaultDateType)
                        ? "for" + " " + defaultDateRangeTypeToLabel(range.defaultDateType)
                        : ""
                    }`,
                    width: "full",
                    show: (stateAccess) => {
                      const parameters = stateAccess.get<BusinessReportAdd>([
                        "data",
                        "info",
                        "request",
                        "businessReportType",
                        "parameters"
                      ]) as any;
                      return parameters?.includes("date range");
                    },
                    entities: [
                      {
                        formComponent: "select-field",
                        name: "Status",
                        label: "Status",
                        width: "1/3",
                        path: [...path, "status"],
                        required: range.required,
                        options: createOptionsForSelect({
                          possibleValues: () => Object.values(DealStatus),
                          getOptionLabel: (x) => statusToLabel(x),
                          getSelectedOption: (x, y) => x === y
                        }),
                        default: null
                      },
                      {
                        formComponent: "date-field",
                        name: "from",
                        label: "From",
                        width: "1/3",
                        path: [...path, "from"],
                        required: range.required
                      },
                      {
                        formComponent: "date-field",
                        name: "To",
                        label: "To",
                        width: "1/3",
                        path: [...path, "to"],
                        required: range.required
                      }
                    ]
                  } as FormComponent<BusinessReportAdd>;
                }) ?? []
            } as FormComponent<BusinessReportAdd>;

            return generateForm(struct, stateAccess, [], stateAccess, renderSet) ?? <></>;
          }
        }
      ]
    },
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "multiselect-field",
          name: "Time period",
          label: "Time period",
          width: "full",
          show: (stateAccess) => {
            const parameters = stateAccess.get<BusinessReportAdd>([
              "data",
              "info",
              "request",
              "businessReportType",
              "parameters"
            ]) as any;
            return parameters?.includes("time period");
          },
          path: ["data", "info", "request", "timePeriod"],
          options: createOptionsForSelect({
            possibleValues: () => ["D", "W", "M", "Q", "Y"],
            getOptionLabel: (el) => {
              switch (el) {
                case "D":
                  return "Daily";
                case "W":
                  return "Weekly";
                case "M":
                  return "Monthly";
                case "Q":
                  return "Quarterly";
                case "Y":
                  return "Yearly";
                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          required: (stateAccess) => {
            return stateAccess.get([
              "data",
              "info",
              "request",
              "businessReportType",
              "defaultParameters",
              "defaultTimePeriodRequired"
            ]);
          }
        }
      ]
    }
  ]
});

import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { FormEditContext } from "components/Content/FormEditContext";
import { Permission } from "components/Roles/types";
import { EntityType } from "utils/entitySlice";
import { Paths } from "utils/types";
import { RequestType } from "validations/types";
import { RenderSet, StateAccess } from "./formGenerator";

export type Path<T> = Paths<T, 7>;
type Widths =
  | "full"
  | "hidden"
  | "1/2"
  | "1/3"
  | "2/3"
  | "1/4"
  | "3/4"
  | "1/6"
  | "auto"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
export type FieldWidth = Widths | ((stateAccess: StateAccess) => Widths);

export type TimelineItem = {
  name: string;
  data: {
    x: string;
    y: number[];
  }[];
};

interface GridItem {
  width?: FieldWidth;
  show?: (
    stateAccess: StateAccess,
    formEditContext?: FormEditContext,
    mainStateAccess?: StateAccess
  ) => boolean;
}

interface OptionalField<T> extends GridItem {
  name: string;
  label?: string;
  path: Path<T> | null;
  required?: boolean | ((stateAccess: StateAccess) => boolean);
  icon?: JSX.Element;
  default?:
    | string
    | object
    | number
    | null
    | boolean
    | (() => string | object | number | null | boolean);
  valueToString?: (value: any) => string;
  multiline?: boolean;
  rows?: number;
}

export interface Field<T> extends OptionalField<T> {
  path: Path<T>;
  minLength?: number;
  maxLength?: number;
  conditionalStyle?: (stateAccess: StateAccess) => React.CSSProperties;
}

export interface TextInputField<T> extends Field<T> {
  type: "text" | "email" | "phone" | "number" | "password";
  inputProps?: InputBaseComponentProps;
  minLength?: number;
  removeEmptySpaces?: boolean;
}

export interface NumberInputField<T> extends Field<T> {
  min?: number;
  max?: number;
  decimalPlaces?: number;
  currencySymbol?: string;
  helperText?: (stateAccess: StateAccess) => string | undefined;
  stringifyValue?: boolean;
  params?: AutocompleteRenderInputParams;
}
export interface NumberField<T> extends NumberInputField<T> {
  formComponent: "number-field";
}

export interface YearField<T> extends NumberInputField<T> {
  formComponent: "year-field";
}

export interface PercentageField<T> extends NumberInputField<T> {
  formComponent: "percentage-field";
}
export interface MonthsField<T> extends NumberInputField<T> {
  formComponent: "months-field";
}

export interface IncomeField<T> extends NumberInputField<T> {
  formComponent: "income-field";
}

export interface CurrencyField<T> extends NumberInputField<T> {
  formComponent: "currency-field";
}

export interface TextField<T> extends Field<T> {
  formComponent: "text-field";
}
export interface PasswordField<T> extends Field<T> {
  formComponent: "password-field";
}
export interface MultiTextField<T> extends Field<T> {
  formComponent: "multi-text-field";
}
export interface MultiEmailField<T> extends Field<T> {
  formComponent: "multi-email-field";
}
export interface MultiPhoneField<T> extends Field<T> {
  formComponent: "multi-phone-field";
}
export interface UpperCaseTextField<T> extends Field<T> {
  formComponent: "uppercase-text-field";
}

export interface HiddenField<T> extends Field<T> {
  formComponent: "hidden-field";
}
export interface VirtualField<T> extends Field<T> {
  formComponent: "virtual-field";
  description: string;
  value: (stateAccess: StateAccess) => string | number | boolean | null | JSX.Element;
  path: string[];
}

export interface NameField<T> extends Field<T> {
  formComponent: "name-field";
  icon?: never;
}
export interface CityField<T> extends Field<T> {
  formComponent: "city-field";
  icon?: never;
}

export interface PhoneField<T> extends Field<T> {
  formComponent: "phone-field";
  icon?: never;
}

export interface AddressField<T> extends Field<T> {
  formComponent: "address-field";
  icon?: never;
}
export interface Timeline<T> extends Field<T> {
  formComponent: "timeline";
  onTimelineDataClick?: (event: any, chartContext: any, config: any) => void;
  items: (stateAccess: StateAccess) => TimelineItem[];
}

export interface ZipCodeField<T> extends Field<T> {
  formComponent: "zip-code-field";
  zipCodeSearch?: (stateAccess: StateAccess) => void;
  hideZipCodeSearch?: boolean;
  icon?: never;
}

export interface EmailField<T> extends Field<T> {
  formComponent: "email-field";
  icon?: never;
}

export interface SsnField<T> extends Field<T> {
  formComponent: "ssn-field";
  icon?: never;
}
export interface RawField<T> extends Field<T> {
  formComponent: "raw-field";
  icon?: never;
}
export interface LicenseNumberField<T> extends Field<T> {
  formComponent: "license-number-field";
  icon?: never;
}

export interface ReadOnlyField<T> extends Field<T> {
  formComponent: "read-only-field";
  icon?: never;
}

export interface ReadOnlyNumberField<T> extends Field<T> {
  formComponent: "read-only-number-field";
  icon?: never;
}
export interface ReadOnlyPercentageField<T> extends Field<T> {
  formComponent: "read-only-percentage-field";
  icon?: never;
}

type DisableRegion = "future" | "past" | "none";

export interface DateField<T> extends Field<T> {
  formComponent: "date-field";
  disableRegion?: DisableRegion;
  icon?: never;
  error?: (stateAccess: StateAccess) => boolean;
  errorText?: string;
  minDate?: (stateAccess: StateAccess) => Date;
}

export interface ReadOnlyDateField<T> extends Field<T> {
  formComponent: "read-only-date-field";
  icon?: never;
}
export interface ReadOnlyMultiTextField<T> extends Field<T> {
  formComponent: "read-only-multi-text-field";
}

export interface RadioEntry {
  value: string;
  label: string;
  color?: string;
  labelElement?: (stateAccess: StateAccess) => JSX.Element;
  labelStyle?: React.CSSProperties;
}

export interface DurationField<T> extends Field<T> {
  formComponent: "duration-field";
  icon?: never;
  start: Path<T>;
  end: Path<T>;
}
export interface RadioField<T> extends Field<T> {
  formComponent: "radio-field";
  icon?: never;
  labelElement?: (stateAccess: StateAccess) => JSX.Element;
  possibleValues: RadioEntry[];
  radioTitle?: (stateAccess: StateAccess, radioValue: string) => string;
  onChange?: (stateAccess: StateAccess, radioValue: string) => void;
  disabledValues?: (
    stateAccess: StateAccess,
    formEditContext?: FormEditContext
  ) => { value: string; title: string }[];
}
export interface filePreviewField<T> extends Field<T> {
  formComponent: "file-preview-field";
}

export interface OptionsType<T> {
  possibleValues: (stateAccess: StateAccess, mainStateAccess: StateAccess) => Array<T>;
  getOptionLabel: (a: T) => string;
  getSelectedOption?: (a: T, b: T) => boolean;
}

export interface OptionsForSelect<T = any> {
  numeric?: boolean;
  freeSolo?: boolean;
  possibleValues: (
    stateAccess: StateAccess,
    path: string[],
    mainStateAccess: StateAccess
  ) => Array<T>;
  getOptionLabel: (a: T) => string;
  getSelectedOption?: (a: T, b: T) => boolean;
  onInputChange?: (value: string, stateAccess: StateAccess) => void;
}

export const createOptionsForSelect = <T>(x: OptionsForSelect<T>): OptionsForSelect<T> => x;

export interface SelectField<T> extends Field<T> {
  formComponent: "select-field";
  icon?: never;
  autoSelect?: boolean;
  options: OptionsForSelect;
  onOpen?: () => void;
  style?: object;
  error?: boolean;
  helperText?: string;
}
export interface MultiSelectField<T> extends Field<T> {
  formComponent: "multiselect-field";
  icon?: never;
  options: OptionsForSelect;
  valueToString?: (value: any) => string;
  onOpen?: () => void;
}
export interface MultiTextInputField<T> extends Field<T> {
  type: "text" | "email" | "phone" | "number";
  inputProps?: InputBaseComponentProps;
  minLength?: number;
  removeEmptySpaces?: boolean;
}
export type OneToManyStruct<U> = Model<U> | FormComponent<U> | (() => Model<U> | FormComponent<U>);
export interface OneToManyField<T, U = any> extends OptionalField<T> {
  formComponent: "one-to-many-field";
  icon?: never;
  struct?: OneToManyStruct<U>;
  component: (
    stateAccess: StateAccess,
    mainStateAccess: StateAccess,
    renderSet: RenderSet,
    parentPath?: string[],
    permissions?: Permission,
    formEditContext?: FormEditContext
  ) => JSX.Element | null;
}

export interface CheckboxField<T> extends Field<T> {
  formComponent: "checkbox-field";
  valueType?: string;
  icon?: never;
  style?: React.CSSProperties;
  isChecked: (stateAccess: StateAccess) => boolean;
  isDisabled?: (stateAccess: StateAccess) => boolean;
  isIndeterminate?: (stateAccess: StateAccess) => boolean;
  toggle: (stateAccess: StateAccess, checked: boolean) => void;
}

export interface DigitsOnlyField<T> extends NumberInputField<T> {
  formComponent: "digits-only-field";
}

export interface Segment<T> extends GridItem {
  formComponent: "segment";
  locked?: boolean;
  elevation?: number;
  separator?: boolean;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  name?: string | ((stateAccess: StateAccess) => string);
  path?: Path<T>;
  default?:
    | string
    | object
    | number
    | null
    | boolean
    | (() => string | object | number | null | boolean);
  entities: FormComponent<T>[];
}

export interface DateWithAge<T> extends Field<T> {
  formComponent: "date-with-age-field";
  disableRegion?: DisableRegion;
  icon?: never;
}
export interface ListModel<T, U = never> extends GridItem {
  formComponent: "list-model";
  name: string;
  entity: FormComponent<U>;
  path: Path<T>;
  required?: boolean;
  elevation?: number;
  style?: React.CSSProperties;
  renderOrder?: string;
  search?: (x: any, y: string) => boolean;
}
export interface ReadOnlyListModel<T, U = never> extends GridItem {
  formComponent: "read-only-list-model";
  name: string;
  entity: FormComponent<U>;
  path: Path<T>;
  required?: boolean;
  elevation?: number;
  style?: React.CSSProperties;
  renderOrder?: string;
  search?: (x: any, y: string) => boolean;
}

export interface TabListModel<T, U = never> extends GridItem {
  formComponent: "tab-list-model";
  name: string;
  entity: FormComponent<U>;
  path: Path<T>;
  required?: boolean;
  renderOrder?: string;
  getTabName: (entity: any) => string;
  search?: (x: any, y: string) => boolean;
  additionalOptions?: (stateAccess: StateAccess) => JSX.Element;
  addRestriction?: (stateAccess: StateAccess) => { canAdd: boolean; message?: string };
}

export interface Model<T> extends GridItem {
  formComponent: "model";
  entities: Array<FormComponent<T>>;
  name: EntityType;
  schema: RequestType | false;
}

export const createModel = <T>(x: Model<T>): Model<T> => x;

export type TextFieldTypes<T> =
  | MultiPhoneField<T>
  | MultiTextField<T>
  | MultiEmailField<T>
  | TextField<T>
  | PasswordField<T>
  | HiddenField<T>
  | VirtualField<T>
  | EmailField<T>
  | NameField<T>
  | AddressField<T>
  | CityField<T>
  | ZipCodeField<T>
  | SsnField<T>
  | RawField<T>
  | PhoneField<T>
  | LicenseNumberField<T>
  | DateWithAge<T>
  | ReadOnlyField<T>
  | ReadOnlyDateField<T>
  | DurationField<T>
  | ReadOnlyNumberField<T>
  | ReadOnlyPercentageField<T>
  | ReadOnlyMultiTextField<T>
  | UpperCaseTextField<T>;

export type NumberFieldTypes<T> =
  | NumberField<T>
  | MonthsField<T>
  | IncomeField<T>
  | CurrencyField<T>
  | PercentageField<T>
  | DigitsOnlyField<T>
  | YearField<T>;

export type Inputs<T, U> =
  | TextFieldTypes<T>
  | HiddenField<T>
  | DateField<T>
  | RadioField<T>
  | SelectField<T>
  | NumberFieldTypes<T>
  | OneToManyField<T, U>
  | CheckboxField<T>
  | MultiSelectField<T>
  | DateWithAge<T>
  | filePreviewField<T>
  | Timeline<T>;

export type FormComponent<T, U = any> =
  | Segment<T>
  | Model<T>
  | ReadOnlyListModel<T>
  | ListModel<T>
  | TabListModel<T>
  | Inputs<T, U>;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";
import { TitleIssueCountByDocumentStatus } from "./types";

const getTitleIssueCountByDocumentStatus = (): (() => Promise<TitleIssueCountByDocumentStatus>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/title_issues_count_by_document_status/`,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.result;
  };
};
const useGetTitleIssueCountByDocumentStatus = () => {
  const queryKey = ["title_issues_count_by_document_status"];

  const { data, isLoading, isSuccess, error, isFetching, isFetched } = useQuery({
    queryKey: queryKey,
    queryFn: getTitleIssueCountByDocumentStatus()
  });
  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetched,
    error
  };
};
export default useGetTitleIssueCountByDocumentStatus;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { auth } from "../../firebase/firebase";
import { TitleIssueCountByDmvStatus } from "./types";

const getTitleIssueCountByDmvStatus = (
  query?: object
): (() => Promise<TitleIssueCountByDmvStatus>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/title_issues_count_by_dmv_status/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          ...(query && Object.keys(query)?.length ? { query: JSON.stringify(query) } : {})
        }
      }
    );
    return response.data.result;
  };
};
const useGetTitleIssueCountByDmvStatus = (query?: object) => {
  const queryKey = ["title_issues_count_by_dmv_status", query];

  const { data, isLoading, isSuccess, error, isFetching, isFetched } = useQuery({
    queryKey: queryKey,
    queryFn: getTitleIssueCountByDmvStatus(query)
  });
  return {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetched,
    error
  };
};
export default useGetTitleIssueCountByDmvStatus;

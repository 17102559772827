import { Checkbox, FormControlLabel, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { RootState } from "app/rootReducer";
import { getLatestActiveContractFile } from "components/Contracts/ContractDatesPreview";
import { Deal } from "components/Deals/types";
import { Lender } from "components/Lenders/types";
import { HintTooltip } from "components/common/HintTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Path, createOptionsForSelect } from "utils/models/fields";
import { RenderSet, StateAccess, getByPath } from "utils/models/formGenerator";
import { showRenderSet } from "utils/models/formRenderers";
import { getDealershipList, removeDealershipList } from "./listDealershipSlice";
import { Dealership } from "./types";

interface Props {
  stateAccess: StateAccess;
  path: Path<Deal>;
  renderSet: RenderSet;
  lender?: Lender;
  name: string;
  label: string;
  required: boolean;
  type:
    | "deal"
    | "external_credit_application"
    | "contract"
    | "business_report"
    | "settings"
    | "wfd_user"
    | "chargeback";
  requiredFunction?: (stateAccess: StateAccess) => any;
}
const dealershipMissingRequiredContractTypes = (
  dealership: Dealership,
  lender: Lender | undefined
) => {
  return (lender?.data?.requiredContractTypes ?? [])?.filter((contractType) => {
    const filteredContracts = dealership?.data?.contracts ?? [];
    // dealership?.data?.contracts?.filter(
    //   (contract) => getLatestActiveContractFile(contract.data.files) !== undefined
    // ) ?? [];
    if (filteredContracts?.length === 0) {
      return true;
    }

    return !filteredContracts
      ?.map((contract) => contract.data.contractTypeIds)
      .flat()
      .includes(contractType._id);
  });
};
export default function DealershipPreview({
  stateAccess,
  path,
  renderSet,
  name,
  lender,
  label,
  required,
  requiredFunction,
  type
}: Props) {
  const dispatch = useDispatch();
  const listId = `list-autocomplete-${stateAccess.get(["_id"])}`;
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);
  const dealership: Dealership = stateAccess.get(path);
  const missingContractTypes = dealershipMissingRequiredContractTypes(dealership, lender);
  const dealershipStateAccess: StateAccess = {
    get: (path) =>
      dealership ? getByPath<Dealership>(dealership, path as Path<Dealership>) : undefined,
    set: (_, value): any => {
      stateAccess.set<Deal>(path, value);
    }
  };

  const handleOpen = () => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: { pagination: false, sort: { "data.info.displayName": "asc" } }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  };

  const assignedDealershipEmails: string[] =
    stateAccess.get(["data", "info", "assignedDealershipEmails"]) ?? [];
  if (type === "deal")
    return (
      <>
        <Grid
          container
          spacing={1}
          style={
            missingContractTypes?.length ? { border: "2px solid red", borderRadius: "5px" } : {}
          }
        >
          {missingContractTypes?.length > 0 && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{
                  padding: "5px",
                  background: "red",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px"
                }}
              >
                Dealership has to sign:{" "}
                {missingContractTypes
                  .map((contractType) => `'${contractType.data.info.type}'`)
                  .join(" / ")}
                , required by: {lender?.data?.info?.name}
              </Typography>
            </Grid>
          )}

          <Grid container item spacing={1} xs={6}>
            <Grid item xs={12}>
              {renderSet.selectInputRenderer(
                {
                  formComponent: "select-field",
                  name,
                  label,
                  required: requiredFunction ? requiredFunction(stateAccess) : required,
                  onOpen: handleOpen,
                  path: [] as [],
                  error: dealership?._id ? !dealership?.approved : false,
                  helperText: dealership?._id
                    ? !dealership?.approved
                      ? "Dealership is not approved"
                      : ""
                    : undefined,
                  options: createOptionsForSelect({
                    possibleValues: () => dealershipList?.entities ?? [],
                    getOptionLabel: (dealership) => dealership?.data?.info?.displayName,
                    getSelectedOption: (x, y) => x?._id === y?._id
                  }),
                  valueToString: (dealership) => dealership?.data?.info?.displayName
                },
                dealershipStateAccess,
                [],
                dealershipStateAccess,
                renderSet
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {showRenderSet(false).textInputRenderer(
                    {
                      name,
                      type: "text",
                      label: "Representative",
                      required,
                      path: [] as [],
                      valueToString: (dealership) =>
                        `${dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
                          dealership?.data?.representative?.data?.info?.lastName ?? ""
                        }`
                    },
                    dealershipStateAccess,
                    showRenderSet(false)
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={
                      dealership?.data?.info?.agreement?.wfdSplit !== undefined
                        ? `${dealership?.data?.info?.agreement?.wfdSplit?.toFixed(2)}%`
                        : " "
                    }
                    inputProps={{
                      readOnly: true
                    }}
                    name="wfdSplit"
                    fullWidth
                    label="WFD"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={
                      dealership?.data?.info?.agreement?.dealershipSplit !== undefined
                        ? `${dealership?.data?.info?.agreement?.dealershipSplit?.toFixed(2)}%`
                        : " "
                    }
                    inputProps={{
                      readOnly: true
                    }}
                    name="dealershipSplit"
                    fullWidth
                    label="Dealer"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={6} style={{ margin: "auto", position: "relative" }}>
            {dealership && (
              <div
                style={
                  assignedDealershipEmails.length === 0
                    ? {
                        width: "100%",
                        border: "1px solid #eed202",
                        borderRadius: "5px",
                        padding: "5px"
                      }
                    : {}
                }
              >
                {assignedDealershipEmails.length === 0 && (
                  <HintTooltip
                    title={
                      <div style={{ fontSize: "14px", lineHeight: "15px" }}>
                        Here you need to choose who should receive automated emails (notifications
                        about stipulations, etc...) generated by the system!
                      </div>
                    }
                  >
                    <div
                      style={{
                        width: "calc(100% + 10px)",
                        textAlign: "center",
                        background: "#eed202",
                        fontWeight: "bold",
                        borderRadius: "3px 0px",
                        margin: "-5px -5px 0px -5px",
                        padding: "5px 0px"
                      }}
                      className="blink_me"
                    >
                      Please select who to notify!
                    </div>
                  </HintTooltip>
                )}
                {dealership?.data?.contacts
                  ?.filter((c) => c.email)
                  ?.map((contact, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <FormControlLabel
                          onChange={(event, value) => {
                            if (value) {
                              stateAccess.set(
                                ["data", "info", "assignedDealershipEmails"],
                                [
                                  ...(assignedDealershipEmails ?? []).filter((dealEmail) =>
                                    dealership.data.contacts.map((x) => x.email).includes(dealEmail)
                                  ),
                                  contact.email
                                ]
                              );
                            } else {
                              stateAccess.set(
                                ["data", "info", "assignedDealershipEmails"],
                                (assignedDealershipEmails ?? [])
                                  .filter((dealEmail) =>
                                    dealership.data.contacts.map((x) => x.email).includes(dealEmail)
                                  )
                                  .filter((x) => x !== contact.email)
                              );
                            }
                          }}
                          control={
                            <Checkbox
                              id={contact.email}
                              checked={assignedDealershipEmails.includes(contact.email)}
                            />
                          }
                          label={`${contact.email}`}
                        />
                      </Grid>
                    );
                  })}
              </div>
            )}
          </Grid>
        </Grid>
      </>
    );
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {renderSet.selectInputRenderer(
            {
              formComponent: "select-field",
              name,
              label,
              required: requiredFunction ? requiredFunction(stateAccess) : required,
              onOpen: handleOpen,
              path: [] as [],
              error: dealership?._id ? !dealership?.approved : false,
              helperText: dealership?._id
                ? !dealership?.approved
                  ? "Dealership is not approved"
                  : ""
                : undefined,
              options: createOptionsForSelect({
                possibleValues: () => dealershipList?.entities ?? [],
                getOptionLabel: (dealership) => dealership?.data?.info?.displayName,
                getSelectedOption: (x, y) => x?._id === y?._id
              }),
              valueToString: (dealership) => dealership?.data?.info?.displayName
            },
            dealershipStateAccess,
            [],
            dealershipStateAccess,
            renderSet
          )}
        </Grid>
      </Grid>
    </>
  );
}

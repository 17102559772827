import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { FormState, AddLenderRequest, AddLenderRequestSend } from "./types";

interface AddLenderResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  AddLenderRequestSend,
  AddLenderResponse
>("AddLender", {
  request: "new_lender"
});

export const addLenderActions = socketMessageSlice.actions;
export const addLender = (request: AddLenderRequest & Identifiable) => {
  const { additionalRequiredDocuments, ...rest } = request.data;
  return socketAction({
    requestId: request.requestId,
    availableToRolesIds: request.availableToRolesIds ?? [],
    data: {
      ...rest,
      additionalRequiredDocumentsIds: additionalRequiredDocuments?.map((el) => el._id)
    }
  });
};
export default socketMessageSlice.reducer;

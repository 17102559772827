import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import AccessControl from "components/Access/AccessControl";
import { HintTooltip } from "components/common/HintTooltip";
import DealershipPreview from "components/Dealerships/DealershipPreview";
import { lenderStruct } from "components/Lenders/model";
import { isDefaultRoute } from "components/Lenders/Routes";
import UserPreview from "components/Users/UserPreview";
import { vehicleInsuranceCompanyStruct } from "components/VehicleInsuranceCompany/model";
import VehicleInsuranceCompanyPreview from "components/VehicleInsuranceCompany/VehicleInsuranceCompanyPreview";
import React from "react";
import { EntityData, EntityType } from "utils/entitySlice";
import formatDate, { getMonth } from "utils/formatDate";
import { v4 as uuidv4 } from "uuid";

import {
  createOptionsForSelect,
  FieldWidth,
  FormComponent,
  Model,
  Path
} from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import States from "../../us-states";
import { areDatesTheSameDay, capitalize, formatNumberAsCurrency } from "../../utils/functions";
import { applicantStruct } from "../Applicants/model";
import CheckCreditScore from "../CreditScore/CheckCreditScore";
import { dealershipStruct } from "../Dealerships/model";
import InsuranceProductsPreview from "../ExpressApi/Deal/InsuranceProductsPreview";
import FilesPreview from "../Files/FilesPreview";
import FundingDocumentsPreview from "../FundingDocuments/FundingDocumentPreview";
import { payoffBankStruct } from "../PayoffBanks/model";
import { userStruct } from "../Users/model";
import AddFundingFeeFile from "./AddFundingFeeFile";
import ApplicantPreview from "./ApplicantPreview";
import AppOneModal from "./AppOneModal";
import CalculateReservesButton from "./CalculateReservesButtons";
import {
  actualPrice,
  calcFinanceCharge,
  calcTaxableAmount,
  findMarkupTableRate,
  getDealershipProgramByDeal,
  getPivotTableData
} from "./Calculations";
import DealershipProfitPreview from "./DealershipProfitPreview";
import DealerTrackPreview from "./DealerTrackPreview";
import { moveDate } from "./DealsLog/DealsLog";
import DocumentsModalButton from "./DocumentsModalButton";
import FeesModalPreview from "./FeesModalPreview";
import LenderDecisionsPreview from "./LenderDecisionsPreview";
import LenderPreview from "./LenderPreview";
// import CreditApplication from "../CreditApplications/CreditApplications";
import LendingDisclosures from "./LendingDisclosures";
import MarkupAndReservePreview from "./MarkupAndReservePreview";
import MaxReservePreview from "./MaxReservePreview";
import AccountingProfitPreview from "./Netsuite/AccountingProfitPreview";
import NetsuiteIds from "./Netsuite/NetsuiteIdsModal";
import NetsuiteTotals from "./Netsuite/NetsuiteTotals";
import AccountingStepper from "./Netsuite/NetusuiteStepper";
import ProfitValuesButton from "./Netsuite/ProfitValuesButton";
import PayoffBankPreview from "./PayoffBankPreview";
import PrintRecapSheet from "./PrintRecapSheet";
import ProfitPreview from "./ProfitPreview";
import ReservePreview, { reserveStruct } from "./ReservePreview";
import TaxesPreview, { detailedTaxes } from "./TaxesPreview";
import TradeInPreview from "./TradeInPreview";
import {
  AllInsurancesTypes,
  CollateralTypes,
  collateralTypes,
  Deal,
  DealToEdit,
  defaultDaysToFirstPayment,
  FundingFee,
  Insurances,
  ReserveMethod
} from "./types";
// import VehicleCoveragePreview from "./VehicleCoveragePreview";
import { Button } from "@material-ui/core";
import Big from "big.js";
import { CreditBureau, CreditCheckTypes } from "components/CreditScore/types";
import DealerNotesPreview from "components/DealerNotes/DealerNotesPreview";
import Print from "components/ExternalCreditApplications/Print";
import { TabContext } from "components/Layout/LayoutWrapper";
import NotesPreview from "components/Notes/NotesPreview";
import StipulationsPreview from "components/Stipulations/StipulationsPreview";
import { HTML_VIRTUAL_FIELD_SPLITTER } from "utils/htmlVirtualFieldsSplitter";
import { auth } from "../../firebase/firebase";
import DealStatuses from "./DealStatuses";
import { ModalDocuments } from "./Documents/types";
import FraudValidationPreview from "./FraudValidation/FraudValidationPreview";
import DocumentsToBeReturnedButton from "./OneSpan/DocumentsToBeReturnedButton";
import PreviewChargebacks from "./PreviewChargebacks/PreviewChargebacks";
import SelectCollateralButton from "./SelectCollateralButton";
import VehiclePreview, { vehicleForm } from "./VehiclePreview";
import LenderSuggestions from "./LenderSuggestions/LenderSuggestions";

const methods = [
  "fixed",
  "percentage",
  "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %",
  "markup",
  "markup foursight"
] as const;
const fullName = (stateAccess: StateAccess, type: "applicant" | "coApplicant") => {
  return [
    stateAccess.get<Deal>(["data", type, "data", "info", "firstName"]),
    ...(stateAccess.get<Deal>(["data", type, "data", "info", "middleName"])
      ? [stateAccess.get<Deal>(["data", type, "data", "info", "middleName"])]
      : []),
    stateAccess.get<Deal>(["data", type, "data", "info", "lastName"]),
    stateAccess.get<Deal>(["data", type, "data", "info", "suffix"])
  ].join(" ");
};
const isGAPOrVSCSOLD = (stateAccess: StateAccess) =>
  (stateAccess.get<Deal>([
    "data",
    "info",
    "aftermarketOptions",
    "insurances"
  ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
    (insurance) =>
      insurance?.chosenRate?.insuranceType === "service warranty" ||
      insurance?.chosenRate?.insuranceType === "gap"
  )
    ? true
    : false;

const firstPaymentDate = (stateAccess: StateAccess): Date => {
  const contractDate = new Date(
    stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
  );

  const daysToFirstPayment =
    stateAccess.get<Deal>(["data", "info", "payment", "daysToFirstPayment"]) ??
    defaultDaysToFirstPayment;

  return (
    contractDate &&
    new Date(new Date(contractDate).setDate(contractDate?.getDate() + daysToFirstPayment))
  );
};

const subTotalDue = (stateAccess: StateAccess): number => {
  return (
    actualPrice(stateAccess.get<Deal>([] as any)) -
    (stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) || 0) +
    (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0) +
    (stateAccess.get<Deal>([
      "data",
      "info",
      "aftermarketOptions",
      "totalServiceWarrantySellPrice"
    ]) || 0) +
    (stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalGAPSellPrice"]) || 0) +
    (stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0)
  );
};
const firstPaymentDateUSSplit = (stateAccess: StateAccess) => {
  const fpd = firstPaymentDate(stateAccess);
  const fpdUS = fpd && fpd.toLocaleDateString("en-US");
  return fpdUS && fpdUS.split("/");
};

const totalGrossProfit = (stateAccess: StateAccess) => {
  const reserveCommission =
    stateAccess.get<Deal>(["data", "info", "profit", "dealershipProfit", "reserveCommission"]) || 0;
  const totalGapProfit = stateAccess.get<Deal>(["data", "info", "profit", "totalGAPProfit"]) || 0;
  const totalServiceWarrantyProfit =
    stateAccess.get<Deal>(["data", "info", "profit", "totalServiceWarrantyProfit"]) || 0;

  return reserveCommission + totalGapProfit + totalServiceWarrantyProfit;
};

// const dealStatusField: (width?: FieldWidth) => FormComponent<Deal> = (width = "1/2") => ({
//   formComponent: "radio-field",
//   possibleValues: [
//     { label: "Lead", value: DealStatus.Lead },
//     { label: "Credit check", value: DealStatus.CreditCheck, color: "#ff0080" },
//     { label: "Pending", value: DealStatus.PendingLenderD },
//     {
//       label: "Counter",
//       value: DealStatus.Counter
//     },
//     {
//       label: "Approved",
//       value: DealStatus.Approved
//     },
//     {
//       label: "Sent to Dealer",
//       value: DealStatus.Delivered,
//       color: "#4caf50"
//     },
//     {
//       label: "Submitted for Funding",
//       value: DealStatus.Signed,
//       color: "rgb(43 154 255)"
//     },
//     { label: "Denied", value: DealStatus.Denied, color: "#f44336" },
//     { label: "Canceled", value: DealStatus.Canceled, color: "#f44336" },
//     {
//       label: "Dead",
//       value: DealStatus.Dead,
//       color: "#1f2124"
//     }
//   ],
//   disabledValues: (stateAccess, formEditContext) => {
//     if (
//       stateAccess.get(["data", "dealership", "_id"]) &&
//       !stateAccess.get(["data", "dealership", "approved"])
//     ) {
//       return [
//         { value: DealStatus.Lead, title: "Dealership is not approved" },
//         { value: DealStatus.Signed, title: "Dealership is not approved" },
//         { value: DealStatus.CreditCheck, title: "Dealership is not approved" },
//         { value: DealStatus.Pending, title: "Dealership is not approved" },
//         { value: DealStatus.Approved, title: "Dealership is not approved" },
//         { value: DealStatus.Delivered, title: "Dealership is not approved" },
//         { value: DealStatus.Counter, title: "Dealership is not approved" },
//         { value: DealStatus.Lead, title: "Dealership is not approved" }
//       ];
//     }

//     const disabled = [
//       ...(formEditContext?.initialState?.data?.info?.status !== DealStatus.Lead
//         ? [{ value: DealStatus.Lead, title: "Deal should never go back to a lead." }]
//         : []),

//       ...(stateAccess.get(["data", "info", "status"]) !== DealStatus.Delivered &&
//       stateAccess.get(["data", "info", "status"]) !== DealStatus.Signed
//         ? [{ value: DealStatus.Signed, title: "Deal should be sent to dealer." }]
//         : []),
//       {
//         value: DealStatus.CreditCheck,
//         title: "Status is automatically applied after performing credit check."
//       },
//       ...(!(
//         ((stateAccess.get(["data", "creditBureaus"])?.length ?? 0) > 0 ||
//           (stateAccess.get(["data", "info", "creditScores"])?.length ?? 0) > 0) &&
//         stateAccess.get(["data", "info", "vehicle", "make"]) &&
//         stateAccess.get(["data", "info", "vehicle", "model"]) &&
//         stateAccess.get(["data", "info", "vehicle", "year"])
//       )
//         ? [
//             {
//               value: DealStatus.Pending,
//               title: "Please execute credit check and add collateral first."
//             }
//           ]
//         : []),
//       ...(!stateAccess.get(["data", "lender", "_id"])
//         ? [
//             { value: DealStatus.Approved, title: "Select a lender first." },
//             { value: DealStatus.Delivered, title: "Select a lender first." },
//             { value: DealStatus.Counter, title: "Select a lender first." }
//           ]
//         : []),
//       ...(!stateAccess.get(["data", "info", "dealDates", "contractDate"])
//         ? [{ value: DealStatus.Delivered, title: "Select a signed at date." }]
//         : [])
//     ];

//     return disabled;
//   },
//   radioTitle: (stateAccess, radioValue) => {
//     const statusHistory = (stateAccess.get(["data", "info", "statusHistory"]) ?? [])?.filter(
//       (el: any) => el.status === radioValue
//     );

//     return (
//       statusHistory
//         .map((status: any) => `${status.userEmail} ${formatDate(status.date, "short", true)}`)
//         .join("\n") ?? ""
//     );
//   },
//   name: "status",
//   label: "Status",
//   labelElement: (stateAccess) => <StatusHistoryButton stateAccess={stateAccess} />,
//   valueToString: (value) => capitalize(value) ?? "",
//   width: width,
//   path: ["data", "info", "status"],
//   default: DealStatus.Lead
// });

export const collateralStruct: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "read-only-field",
          name: "VIN",
          label: "VIN",
          width: "1/4",
          path: ["data", "collateral", "VIN"],
          default: null
        },
        {
          formComponent: "read-only-field",
          name: "year",
          label: "Year",
          width: "1/4",
          path: ["data", "collateral", "year"],
          default: null
        },
        {
          formComponent: "read-only-field",
          name: "Make",
          label: "Make",
          path: ["data", "collateral", "make"],
          default: null,
          width: "1/4"
        },
        {
          formComponent: "read-only-field",
          name: "Model",
          label: "Model",
          path: ["data", "collateral", "model"],
          default: null,
          width: "1/4"
        },
        {
          formComponent: "read-only-field",
          name: "Odometer",
          label: "Odometer",
          path: ["data", "collateral", "odometer"],
          default: null,
          width: "1/4"
        },
        {
          formComponent: "read-only-field",
          name: "type",
          label: "Type",
          path: ["data", "collateral", "unitType"],
          default: null,
          width: "1/4"
        },
        {
          formComponent: "read-only-field",
          name: "Unit status",
          label: "Unit status",
          path: ["data", "collateral", "unitStatus"],
          default: null,
          width: "1/4"
        },
        {
          width: "1/4",
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess, mainStateAccess, renderSet) => (
            <SelectCollateralButton
              stateAccess={mainStateAccess}
              renderSet={renderSet}
              collateral={stateAccess.get([])}
            />
          ),
          name: "SelectCollateralButton",
          path: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "Financials",
      entities: [
        {
          formComponent: "read-only-number-field",
          name: "NADA/MSRP",
          label: "NADA/MSRP",
          width: "1/4",
          path: ["data", "collateral", "MSRP"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "price",
          label: "Price",
          width: "1/4",
          path: ["data", "financialInfo", "price", "price"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "totalCash",
          label: "Total cash",
          width: "1/4",
          path: ["data", "financialInfo", "price", "totalCash"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "totalTrade",
          label: "Trade",
          width: "1/4",
          path: ["data", "financialInfo", "price", "totalTrade"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "payoff",
          label: "Payoff",
          width: "1/4",
          path: ["data", "financialInfo", "price", "payoff"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "Doc",
          label: "Doc",
          width: "1/4",
          path: ["data", "financialInfo", "taxesAndFees", "documentRegistrationFees"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "Title/Transfer",
          label: "Title/Transfer",
          width: "1/4",
          path: ["data", "financialInfo", "taxesAndFees", "titleTransfer"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "totalTaxes",
          label: "Total Tax",
          width: "1/4",
          path: ["data", "financialInfo", "payment", "totalTaxes"],
          default: null
        }
      ]
    }
  ]
};

export const collaterals: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "read-only-list-model",
      name: "Collateral Change Requests",
      width: "full",
      entity: collateralStruct,
      required: false,
      path: ["data", "collaterals"]
    }
  ]
};
const addDeal: FormComponent<Deal> = {
  formComponent: "segment",
  name: (stateAccess) => {
    return `Deal: ${stateAccess?.get?.(["data", "info", "refNumber"])}`;
  },
  width: "full",
  entities: [
    {
      width: "full",
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => <DealStatuses stateAccess={stateAccess} />,
      name: "dealStatuses",
      label: "Deal Status",
      path: null
    },
    // dealStatusField(),
    {
      formComponent: "radio-field",
      possibleValues: collateralTypes.map((type) => ({
        label: type === "Recreational Vehicles" ? "RV" : type,
        value: type,
        labelStyle: { whiteSpace: "nowrap", display: "inline-block" },
        labelElement: (stateAccess: StateAccess) =>
          !stateAccess.get<Deal>([
            "data",
            "dealership",
            "data",
            "info",
            "collateralTypes",
            type
          ]) ? (
            <HintTooltip title="The selected dealership does not support the selected collateral type!">
              <Warning style={{ transform: "translateY(5px)", color: "#eed202" }} />
            </HintTooltip>
          ) : (
            <></>
          )
      })),
      name: "type",
      label: "Type",
      width: "full",
      path: ["data", "info", "type"],
      default: "Automotive"
    },
    {
      formComponent: "virtual-field",
      name: "autoBoatRVPowerSport",
      label: "Auto/Boat/RV/Powersport",
      value: (stateAccess) => {
        const dealType: CollateralTypes = stateAccess.get<Deal>(["data", "info", "type"]);
        switch (dealType) {
          case "Automotive":
            return "Auto";
          case "Marine":
            return "Boat";
          case "Power Sport":
            return "Powersport";
          case "Recreational Vehicles":
            return "RV";
          default:
            return "N/A";
        }
      },
      description: "Displays collateral type as Auto/Boat/RV/Powersport",
      path: ["data", "info", "autoBoatRVPowerSport"],
      width: "hidden",
      default: null
    },
    // {
    //   formComponent: "radio-field",
    //   possibleValues: [
    //     { label: "Consumer loan", value: "consumer loan" },
    //     { label: "Commercial loan", value: "commercial loan" }
    //   ],
    //   name: "type",
    //   label: "Type",
    //   width: "1/2",
    //   path: ["data", "info", "type"],
    //   default: "consumer loan"
    // },
    {
      formComponent: "date-field",
      name: "CreditCheckDate",
      label: "Credit check date",
      width: "1/3",
      path: ["data", "info", "creditCheckDate"],
      default: null
    },
    {
      formComponent: "date-field",
      name: "CollateralReceivedDate",
      label: "Collateral received date",
      width: "1/3",
      path: ["data", "info", "collateralReceivedDate"],
      default: null
    },
    {
      formComponent: "date-field",
      name: "Contract date",
      label: "Contract date",
      width: "1/3",
      path: ["data", "info", "dealDates", "contractDate"],
      minDate: (stateAccess) =>
        new Date(
          stateAccess.get<Deal>(["data", "info", "creditCheckDate"])
        ),
      error: (stateAccess) => {
        const creditCheckDate = stateAccess.get<Deal>(["data", "info", "creditCheckDate"]);
        const contractDate = stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]);

        return creditCheckDate && contractDate
          ? new Date(contractDate).getTime() < new Date(creditCheckDate).getTime() &&
              !areDatesTheSameDay(new Date(creditCheckDate), new Date(contractDate))
          : false;
      },
      errorText: "Contract date shouldn't be before the credit check date!",
      default: null
    },
    {
      formComponent: "date-field",
      name: "Submitted for funding",
      label: "Submitted for funding",
      width: "1/3",
      path: ["data", "info", "dealDates", "submittedForFunding"],
      minDate: (stateAccess) =>
        moveDate(
          new Date(
            stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
          ),
          -7
        ),
      error: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]) &&
        stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
          ? new Date(
              stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
            ).getTime() <
            new Date(
              stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
            ).getTime()
          : false,
      errorText: "Submitted for funding date should be after the contract date!",
      default: null
    },
    {
      formComponent: "date-field",
      name: "FundedAt",
      label: "Funded At",
      width: "1/3",
      path: ["data", "info", "dealDates", "fundedAt"],
      minDate: (stateAccess) =>
        new Date(
          stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
        ),
      error: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]) &&
        stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
          ? new Date(
              stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
            ).getTime() <
            new Date(
              stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
            ).getTime()
          : false,
      errorText: "Funded at date should be after the contract date!",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "coApplicantContractDate",
      label: "Co Applicant Contract Date Or N/A",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "coApplicant"]) &&
        stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
          ? new Date(
              stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
            ).toLocaleDateString("en-US")
          : "N/A",
      description: "Displays contract Date date if there is a coApplicant otherwise N/A",
      path: ["data", "info", "dealDates", "coApplicantContractDateOrNA"],
      width: "hidden"
    },
    // {
    //   formComponent: "date-field",
    //   name: "SentToLender",
    //   label: "Sent to lender",
    //   width: "1/3",
    //   path: ["data", "info", "dealDates", "sentToLender"],
    //   minDate: (stateAccess) =>
    //     moveDate(
    //       new Date(
    //         stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
    //       ),
    //       -7
    //     ),
    //   error: (stateAccess) =>
    //     stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]) &&
    //     stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
    //       ? new Date(
    //           stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
    //         ).getTime() <
    //         new Date(
    //           stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
    //         ).getTime()
    //       : false,
    //   errorText: "Funded at date should be after the contract date!",
    //   default: null
    // },
    {
      formComponent: "date-field",
      name: "PayrollDate",
      label: "Payroll Date",
      width: "1/3",
      path: ["data", "info", "dealDates", "payrollDate"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isLoan",
      label: "X Is loan?",
      value: (stateAccess) => true,
      path: ["data", "info", "isLoan"],
      description: "Displays X sign",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "individual/join",
      label: "individual or join",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "coApplicant"]) === null ? "individial" : "joint",
      description: "Displays X if there is no coApplicant",
      path: ["data", "info", "individual/join"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isIndividual",
      label: "X Is Individual?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "coApplicant"]) === null,
      description: "Displays X if there is no coApplicant",
      path: ["data", "info", "isIndividual"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isJoint",
      label: "X Is Joint?",
      value: (stateAccess) => stateAccess.get<Deal>(["data", "coApplicant"]) !== null,
      description: "Displays X if there is a coApplicant",
      path: ["data", "info", "isJoint"],
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "DaysToFirstPayment",
      label: "Days to first payment",
      width: "hidden",
      path: ["data", "info", "payment", "daysToFirstPayment"],
      default: 30
    }
  ]
};

const utilsSegment: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Utils",
  show: () => false,
  entities: [
    {
      formComponent: "virtual-field",
      name: "additionalRequiredDocuments",
      label: "Additional Required Documents or N/A",
      value: (stateAccess) => {
        const documents =
          (stateAccess.get<Deal>(["data", "checklist", "documents"]) as ModalDocuments)?.filter(
            (x) => x.additionalProperties.required
          ) || [];
        if (Array.isArray(documents) && documents?.length) {
          return documents.map((doc) => "• " + doc.displayName).join(HTML_VIRTUAL_FIELD_SPLITTER);
        }
        return "N/A";
      },
      description: "Displays Additional Required Documents or N/A",
      path: ["data", "checklist", "documents"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "optionalText",
      label: "Optional text",
      value: (stateAccess) => {
        return stateAccess.get<Deal>(["data", "checklist", "optionalText"]) ?? "";
      },
      description: "Displays Optional Checklist text",
      path: ["data", "checklist", "optionalText"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "genericCheckField",
      label: "X Check",
      value: (stateAccess) => true,
      description: "Displays X",
      path: ["data", "info", "genericCheckField"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "N/A",
      label: "N/A",
      value: (stateAccess) => "N/A",
      description: "Displays N/A",
      path: ["data", "info", "N/A"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "0.00",
      label: "0.00",
      value: () => "0.00",
      description: "Displays 0.00",
      path: ["data", "info", "0.00"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "250",
      label: "250",
      value: () => "250",
      description: "Displays 250",
      path: ["data", "info", "250"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "900",
      label: "900",
      value: () => "900",
      description: "Displays 900",
      path: ["data", "info", "900"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "USA",
      label: "USA",
      value: () => "USA",
      description: "Displays USA",
      path: ["data", "info", "USA"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "WebFinanceDirect",
      label: "Web Finance Direct",
      value: () => "Web Finance Direct",
      description: "Displays Web Finance Direct",
      path: ["data", "info", "Web Finance Direct"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TransUnion FICO Auto Score 9",
      label: "TransUnion FICO Auto Score 9",
      value: () => "TransUnion FICO Auto Score 9",
      description: "Displays TransUnion FICO Auto Score 9",
      path: ["data", "info", "TransUnion FICO Auto Score 9"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "WebFinanceDirectAddressTwoLines",
      label: "Web Finance Direct Address two lines",
      value: () => `200 Mason St # 13${HTML_VIRTUAL_FIELD_SPLITTER}Onalaska, WI 54650`,
      description: "Displays Web Finance Direct address on 2 lines",
      path: ["data", "info", "Web Finance Direct address two lines"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "365/366",
      label: "365/366",
      value: () => "365/366",
      description: "Displays 365/366",
      path: ["data", "info", "365/366"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "365Actual",
      label: "365 Actual",
      value: () => "365 Actual",
      description: "Displays 365 Actual",
      path: ["data", "info", "365Actual"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "3.65",
      label: "3.65",
      value: () => "3.65",
      description: "Displays 3.65",
      // @ts-ignore
      path: ["data", "info", "3.65"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "$",
      label: "$",
      value: () => "$",
      description: "Displays $",
      path: ["data", "info", "$"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "driverlicense",
      label: "driverlicense",
      value: () => "driver license",
      description: "Displays 'driver license' text",
      path: ["data", "info", "driverlicense"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "individual",
      label: "individual",
      value: () => "Individual",
      description: "Displays 'Individual' text",
      path: ["data", "info", "individual"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "cell",
      label: "cell",
      value: () => "Cell",
      description: "Displays 'Cell' text",
      path: ["data", "info", "cell"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Collateral Purchased",
      label: "collateralPurchased",
      value: () => "Collateral Purchased:",
      description: "Displays 'Collateral Purchased:' text",
      path: ["data", "info", "collateralPurchased"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Monthly text",
      label: "MonthlyText",
      value: () => "Monthly",
      description: "Displays 'Monthly' text",
      path: ["data", "info", "MonthlyText"],
      default: null
    }
  ]
};

const lateChargeSegment: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Late Charge",
  show: () => false,
  entities: [
    {
      formComponent: "virtual-field",
      name: "hardcodedNumberTen",
      label: "10",
      value: (stateAccess) => "10",
      description: "Displays 10",
      path: ["data", "info", "hardcodedNumberTen"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lateChargeAmount",
      label: "Late Charge Amount 15$",
      value: (stateAccess) => "5% of the unpaid amount with a maximum of $15.00",
      description: "Displays: 5% of the unpaid amount with a maximum of $15.00",
      path: ["data", "info", "lateChargeAmount"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lateChargeAmount884",
      label: "Late Charge Amount 8.84$",
      value: (stateAccess) => "5% of the unpaid amount of the payment due or $8.84",
      description: "Displays: 5% of the unpaid amount of the payment due or $8.84",
      path: ["data", "info", "lateChargeAmount884"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lateChargeAmount10",
      label: "Late Charge Amount 10$",
      value: (stateAccess) => "5% of the unpaid amount with a maximum of $10.00",
      description: "Displays: 5% of the unpaid amount with a maximum of $10.00",
      path: ["data", "info", "lateChargeAmount10"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lateChargeAmount5",
      label: "Late Charge Amount 5$",
      value: (stateAccess) => "5% of the unpaid amount with a maximum of $5.00",
      description: "Displays: 5% of the unpaid amount with a maximum of $5.00",
      path: ["data", "info", "lateChargeAmount5"],
      default: null
    }
  ]
};

const editDeal: FormComponent<Deal> = {
  ...addDeal,
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, _, renderSet: RenderSet) => (
        <Print
          stateAccess={stateAccess}
          style={{ position: "relative", width: "100%" }}
          type="deal"
        />
      ),
      name: "printApplication",
      path: null,
      width: (stateAccess) => (stateAccess.get(["data", "titleIssueId"]) ? "1/2" : "full")
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, _, renderSet: RenderSet) => {
        const createOrFocusTab = React.useContext(TabContext);

        const handleClick = () => {
          return createOrFocusTab({
            label: "Show page",
            index: "showPage",
            isForSidebar: false,
            isForQuickAccess: false,
            isPersistent: false,
            props: {
              _id: stateAccess.get(["data", "titleIssueId"]),
              type: "title_issue"
            }
          });
        };
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{ width: "100%" }}
          >
            Title Information
          </Button>
        );
      },
      name: "printApplication",
      path: null,
      show: (stateAccess) => stateAccess.get(["data", "titleIssueId"]),
      width: "1/2"
    },

    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, _, renderSet: RenderSet) => (
        <DocumentsModalButton stateAccess={stateAccess} renderSet={renderSet} />
      ),
      name: "documentsModal",
      label: "Documents Modal",
      path: null
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => (
        <AppOneModal stateAccess={stateAccess} isDefault={isDefaultRoute(stateAccess, "appone")} />
      ),
      name: "appOneModal",
      label: "Upload to AppOne",
      show: (stateAccess) => {
        // TODO: disable the button instead of hidding
        // const lender = stateAccess.get<Deal>(["data", "lender", "data", "info"]);
        // if (!lender) return true;
        return true;
        // const collateralType = stateAccess.get<Deal>(["data", "info", "type"]);
        // return stateAccess.get<Deal>([
        //   "data",
        //   "lender",
        //   "data",
        //   "info",
        //   "routes",
        //   collateralType,
        //   "appone",
        //   "isAvailable"
        // ]);
      },

      path: null
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => (
        <DealerTrackPreview
          stateAccess={stateAccess}
          isDefault={isDefaultRoute(stateAccess, "dealertrack")}
        />
      ),
      name: "dealerTrack",
      label: "Upload to Dealertrack",
      show: (stateAccess) => {
        // TODO: disable the button instead of hidding
        // const lender = stateAccess.get<Deal>(["data", "lender", "data", "info"]);
        // if (!lender) return true;
        return true;
        // const collateralType = stateAccess.get<Deal>(["data", "info", "type"]);
        // return stateAccess.get<Deal>([
        //   "data",
        //   "lender",
        //   "data",
        //   "info",
        //   "routes",
        //   collateralType,
        //   "dealertrack",
        //   "isAvailable"
        // ]);
      },
      path: null
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => (
        <DocumentsToBeReturnedButton stateAccess={stateAccess} />
      ),
      name: "eSign",
      label: "eSign",
      path: null
    },
    {
      formComponent: "read-only-field",
      name: "Ref Number",
      label: "Reference Number",
      width: "full",
      show: () => false,
      path: ["data", "info", "refNumber"],
      default: null
    },
    ...addDeal.entities
  ]
};

export const tradeInStruct: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "year-field",
      name: "year",
      label: "Year",
      width: "1/6",
      path: ["year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "Make",
      label: "Make",
      path: ["make"],
      default: null,
      width: "1/4"
    },
    {
      formComponent: "text-field",
      name: "Model",
      label: "Model",
      path: ["model"],
      default: null,
      width: "1/4"
    },
    {
      formComponent: "number-field",
      name: "Odometer",
      label: "Odometer",
      path: ["odometer"],
      default: null,
      width: "1/3"
    },

    {
      formComponent: "uppercase-text-field",
      name: "VIN",
      label: "VIN",
      path: ["VIN"],
      default: null,
      width: "1/2"
    },
    {
      formComponent: "currency-field",
      name: "value",
      label: "Value",
      path: ["value"],
      default: null,
      width: "1/2"
    }
  ]
};

export const tradeInDocumentFileds: FormComponent<Deal> = {
  formComponent: "segment",
  name: "TradeIn Info",
  width: "full",
  entities: [
    {
      formComponent: "virtual-field",
      name: "TradeInValueOrNA",
      label: "Value or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "value"])
        ) ?? "N/A",
      description: "Displays trade in value or N/A",
      path: ["data", "info", "price", "trade", "valueOrNA"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TotalTradeInValueOrNA",
      label: "Total Value or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "totalTrade"])
        ) ?? "N/A",
      description: "Displays total trade in value or N/A",
      path: ["data", "info", "price", "trade", "totalTradeValueOrN/A"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TradeInMakeModelYearVIN",
      label: "Trade In Make Model Year VIN",
      value: (stateAccess) =>
        [
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "year"]),
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "make"]),
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "model"]),
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "VIN"])
        ].join(" "),
      description: "Displays trade in: year, make, model, VIN",
      path: ["data", "info", "price", "trade", "tradeInMakeModelYearVIN"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TradeInMakeModelYear",
      label: "Trade In Make Model Year",
      value: (stateAccess) =>
        [
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "year"]),
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "make"]),
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "model"])
        ].join(" "),
      description: "Displays trade in: year, make, model",
      path: ["data", "info", "price", "trade", "tradeInMakeModelYear"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Year",
      label: "Year",
      width: "hidden",
      description: "displays trade in year",
      value: (stateAccess) => {
        return stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "year"]);
      },
      path: ["data", "info", "price", "trade", "year"]
    },

    {
      formComponent: "virtual-field",
      name: "Make",
      label: "Make",
      width: "hidden",
      description: "displays trade in make",
      value: (stateAccess) => {
        return stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "make"]);
      },
      path: ["data", "info", "price", "trade", "make"]
    },
    {
      formComponent: "virtual-field",
      name: "Model",
      label: "Model",
      width: "hidden",
      description: "displays trade in make",
      value: (stateAccess) => {
        return stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "model"]);
      },
      path: ["data", "info", "price", "trade", "model"]
    },
    {
      formComponent: "virtual-field",
      name: "VIN",
      label: "VIN",
      width: "hidden",
      description: "displays trade in VIN",
      value: (stateAccess) => {
        return stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "VIN"]);
      },
      path: ["data", "info", "price", "trade", "VIN"]
    },
    {
      formComponent: "virtual-field",
      name: "odometerFormatted",
      label: "Odometer Formatted",
      width: "hidden",
      description: "displays formatted odometer",
      value: (stateAccess) => {
        return new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(
          stateAccess.get<Deal>(["data", "info", "price", "trade", 0, "odometer"]) || 0
        );
      },
      path: ["data", "info", "price", "trade", "odometerFormatted"]
    },
    {
      formComponent: "virtual-field",
      name: "AdditionalTradeInInfo",
      label: "Additional Trade In Info",
      value: (stateAccess) =>
        stateAccess
          .get<Deal>(["data", "info", "price", "trade"])
          .slice(1)
          .map((x: any) => `${x.year} ${x.make} ${x.model}${HTML_VIRTUAL_FIELD_SPLITTER}${x.VIN}`)
          .join(HTML_VIRTUAL_FIELD_SPLITTER),
      description: "Displays trade in: year, make, model",
      path: ["tradeInMakeModelYear"],
      default: null
    }
  ]
};
export const tradeIns: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "list-model",
      name: "Trade Ins",
      width: "full",
      entity: tradeInStruct,
      required: false,
      path: ["data", "info", "price", "trade"]
    }
  ]
};
const stipulations = (action: "create" | "update"): FormComponent<Deal> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      path: null,
      width: "full",
      name: "Stipulations",
      component: (stateAccess) => (
        <AccessControl requiredPermissions={{ entity: "stipulation", action }}>
          <StipulationsPreview
            stipulations={stateAccess.get(["data", "stipulations"]) ?? []}
            dealId={stateAccess.get(["_id"])}
          />
        </AccessControl>
      )
    }
  ]
});
const tradeIn: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: tradeInDocumentFileds,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <TradeInPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          name="Trade"
          label="Trade"
          required={false}
        />
      ),
      name: "TradeIn",
      width: "full",
      path: [] as any,
      default: null
    }
  ]
};

const commissions: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "1/3",
      name: "Representative",
      titleStyle: { color: "black" },
      show: (stateAccess, formEditContext) => {
        return (
          formEditContext?.initialState?.data?.user?.data?.info?.email === auth.currentUser?.email
        );
      },
      entities: [
        {
          formComponent: "read-only-number-field",
          name: "commission",
          label: "Commission",
          width: "full",
          path: ["data", "info", "profit", "representativeProfit", "commission"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "2/3",
      name: "F&I manager",
      locked: true,
      show: (stateAccess, formEditContext) => {
        return (
          formEditContext?.initialState?.data?.user?.data?.info?.email === auth.currentUser?.email
        );
      },
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "read-only-number-field",
          name: "commissionableAmount",
          label: "Commissionable amount",
          width: "1/2",
          path: ["data", "info", "profit", "managerProfit", "commissionableAmount"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "commission",
          label: "Commission",
          width: "1/2",
          path: ["data", "info", "profit", "managerProfit", "commission"],
          default: null
        }
      ]
    }
  ]
};

const fraudValidationApplicant: FormComponent<Deal> = {
  formComponent: "segment",
  show: (stateAccess) => stateAccess.get(["data", "applicant"]),
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
        const applicant = stateAccess.get(["data", "applicant"]);
        if (applicant?._id) {
          return (
            <AccessControl requiredPermissions={{ entity: "credit_score", action: "create" }}>
              <FraudValidationPreview stateAccess={stateAccess} applicantType={"applicant"} />
            </AccessControl>
          );
        } else {
          return <></>;
        }
      },
      required: true,
      name: "fraudValidationApplicant",
      label: "Fraud Validation Applicant",
      width: "full",
      path: [],
      default: null
    }
  ]
};
const fraudValidationCoApplicant: FormComponent<Deal> = {
  formComponent: "segment",
  show: (stateAccess) => stateAccess.get(["data", "coApplicant"]),
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => {
        const coApplicant = stateAccess.get(["data", "coApplicant"]);
        if (coApplicant?._id) {
          return (
            <AccessControl requiredPermissions={{ entity: "credit_score", action: "create" }}>
              <FraudValidationPreview stateAccess={stateAccess} applicantType={"coApplicant"} />
            </AccessControl>
          );
        } else {
          return <></>;
        }
      },
      required: true,
      name: "fraudValidationCoApplicant",
      label: "Fraud Validation CoApplicant",
      width: "full",
      path: [],
      default: null
    }
  ]
};
const applicant: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Applicant",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => applicantStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <ApplicantPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "applicant"]}
          name="applicant"
          label="Applicant"
          required={true}
        />
      ),
      required: true,
      name: "applicant",
      label: "Applicant",
      width: "full",
      path: ["data", "applicant"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "applicantAndCoApplicantNameAddress",
      label: "Applicant CoApplicant Names and Address",
      value: (stateAccess) => {
        const applicant: Deal["data"]["applicant"] = stateAccess.get<Deal>(["data", "applicant"]);
        if (!applicant) return "";
        const coApplicant: Deal["data"]["coApplicant"] = stateAccess.get<Deal>([
          "data",
          "coApplicant"
        ]);
        const firstLine = `${[
          fullName(stateAccess, "applicant"),
          coApplicant?.data?.info?.firstName && "and",
          fullName(stateAccess, "coApplicant")
        ].join(" ")}`;
        const secondLine = `${[
          applicant?.data?.info?.currentAddressNumber,
          applicant?.data?.info?.currentAddress
        ].join(" ")}`;
        const thirdLine = `${[
          applicant?.data?.info?.currentCity + ",",
          States[applicant?.data?.info?.currentState]?.prefix,
          applicant?.data?.info?.currentZipCode
        ].join(" ")}`;
        return (
          firstLine +
          HTML_VIRTUAL_FIELD_SPLITTER +
          secondLine +
          HTML_VIRTUAL_FIELD_SPLITTER +
          thirdLine
        );
      },

      path: ["data", "info", "applicantAndCoApplicantNameAddress"],
      description:
        "Displays applicant and coApplicant names and address with location of the applicant on 3 lines",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "applicantAndCoApplicantName",
      label: "Applicant CoApplicant Names",
      value: (stateAccess) => {
        const coApplicant: Deal["data"]["coApplicant"] = stateAccess.get<Deal>([
          "data",
          "coApplicant"
        ]);
        const firstLine = `${[
          fullName(stateAccess, "applicant"),
          coApplicant?.data?.info?.firstName && "and",
          fullName(stateAccess, "coApplicant")
        ].join(" ")}`;

        return firstLine;
      },

      path: ["data", "info", "applicantAndCoApplicantNames"],
      description: "Displays applicant and coApplicant full names on one line",
      default: null
    }
  ]
};

export const getCreditBureausScore = (deal: Deal, type: "applicant" | "co-applicant") => {
  const applicantSSN = deal?.data?.applicant?.data?.info?.socialSecurityNumber;
  const coApplicantSSN = deal?.data?.coApplicant?.data?.info?.socialSecurityNumber;
  let result:
    | {
        score: string | undefined;
        range: string | undefined;
        rank: string | undefined;
        date: string | undefined;
      }
    | undefined = undefined;
  if (type === "applicant") {
    const foundCreditBureau = [...deal.data?.creditBureaus]?.reverse().find((creditBureau) => {
      return creditBureau.data.type === "joint" || creditBureau.data.type === "applicant";
    });

    result = {
      score:
        foundCreditBureau?.data["700CreditResponse"]?.Results?.bureau_xml_data?.[0].TU_Report?.[0]
          ?.subject_segments?.[0]?.scoring_segments?.[0]?.scoring?.[0]?.score[0],
      range:
        foundCreditBureau?.data["700CreditResponse"]?.Results?.bureau_xml_data?.[0].TU_Report?.[0]
          ?.subject_segments?.[0]?.scoring_segments?.[0]?.scoring?.[0]?.ScoreRange[0],
      rank:
        foundCreditBureau?.data["700CreditResponse"]?.Results?.bureau_xml_data?.[0].TU_Report?.[0]
          ?.subject_segments?.[0]?.scoring_segments?.[0]?.scoring?.[0]?.ScoreRank[0],
      date: foundCreditBureau?.createdAt
    };
  }

  if (type === "co-applicant") {
    const foundCreditBureau = deal.data?.creditBureaus?.reverse().find((creditBureau) => {
      return creditBureau.data.type === "joint" || creditBureau.data.type === "coApplicant";
    });

    const scoring =
      foundCreditBureau?.data["700CreditResponse"]?.Results?.bureau_xml_data?.[0].TU_Report?.[0]
        ?.subject_segments?.[
        // @ts-ignore
        foundCreditBureau.data.request.SSN === coApplicantSSN ? 0 : 1
      ]?.scoring_segments?.[0]?.scoring?.[0];
    result = {
      score: scoring?.score[0],
      range: scoring?.ScoreRange[0],
      rank: scoring?.ScoreRank[0],
      date: foundCreditBureau?.createdAt
    };
  }

  return result;
};

const creditCheck: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Credit Check",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      width: "full",
      component: (stateAccess: StateAccess) => <CheckCreditScore stateAccess={stateAccess} />,
      name: "checkCreditScore",
      label: "Check Credit Score",
      path: null
    },
    {
      formComponent: "virtual-field",
      name: "applicant credit score",
      label: "Applucant credit score",
      path: ["data", "info", "creditScore", "applicant"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "applicant")?.score || "";
      },
      description: "show applicant credit score value"
    },
    {
      formComponent: "virtual-field",
      name: "co-applicant credit score",
      label: "co-applucant credit score",
      path: ["data", "info", "creditScore", "co-applicant"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "co-applicant")?.score || "";
      },
      description: "show co-applicant credit score value"
    },
    {
      formComponent: "virtual-field",
      name: "applicant credit range",
      label: "Applucant credit range",
      path: ["data", "info", "creditScore", "applicantRange"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "applicant")?.range || "";
      },
      description: "show applicant credit score range"
    },
    {
      formComponent: "virtual-field",
      name: "co-applicant credit range",
      label: "co-applucant credit range",
      path: ["data", "info", "creditScore", "co-applicantRange"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "co-applicant")?.range || "";
      },
      description: "show co-applicant credit score range"
    },
    {
      formComponent: "virtual-field",
      name: "applicant credit rank",
      label: "Applucant credit rank",
      path: ["data", "info", "creditScore", "applicantRank"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "applicant")?.rank || "";
      },
      description: "show applicant credit score rank"
    },
    {
      formComponent: "virtual-field",
      name: "co-applicant credit rank",
      label: "co-applucant credit rank",
      path: ["data", "info", "creditScore", "co-applicantRank"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);
        return getCreditBureausScore(deal, "co-applicant")?.rank || "";
      },
      description: "show co-applicant credit score rank"
    },
    {
      formComponent: "virtual-field",
      name: "applicant credit date",
      label: "Applucant credit date",
      path: ["data", "info", "creditScore", "applicantDate"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);

        const foundDate = getCreditBureausScore(deal, "applicant")?.date;
        return foundDate ? new Date(foundDate).toLocaleDateString("en-US") : "";
      },
      description: "show applicant credit score date"
    },
    {
      formComponent: "virtual-field",
      name: "co-applicant credit date",
      label: "co-applucant credit date",
      path: ["data", "info", "creditScore", "co-applicantDate"],
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);

        const foundDate = getCreditBureausScore(deal, "co-applicant")?.date;
        return foundDate ? new Date(foundDate).toLocaleDateString("en-US") : "";
      },
      description: "show co-applicant credit score Date"
    }
  ]
};
const coApplicant: FormComponent<Deal> = {
  formComponent: "segment",
  name: "CoApplicant",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => applicantStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <ApplicantPreview
          applicant={stateAccess.get(["data", "applicant"])}
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "coApplicant"]}
          name="coApplicant"
          label="coApplicant"
          required={false}
        />
      ),
      name: "coApplicant",
      label: "coApplicant",
      width: "full",
      required: false,
      path: ["data", "coApplicant"],
      default: null
    }
  ]
};
const payoffBank: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: payoffBankStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <PayoffBankPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          type="deal"
          path={["data", "payoffBank"]}
          name="payoffBank"
          label="Payoff Bank"
          required={false}
        />
      ),
      required: true,
      name: "payoffBank",
      width: "full",
      path: ["data", "payoffBank"],
      default: null
    }
  ]
};

const lender: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Lender",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => lenderStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <div style={{ position: "relative" }}>
          <LenderSuggestions stateAccess={stateAccess} renderSet={renderSet} />
          <LenderPreview
            showCheckbox={true}
            stateAccess={stateAccess}
            renderSet={renderSet}
            lenderIdPath={["data", "lenderId"]}
            path={["data", "lender"]}
            name="lender"
            label="Lender"
          />
        </div>
      ),
      name: "lender",
      label: "Lender",
      width: "full",
      path: ["data", "lender"],
      default: null
    }
  ]
};
const dealerNotes = (action: "create" | "update"): FormComponent<Deal> => ({
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess) => (
            <AccessControl requiredPermissions={{ entity: "dealer_note", action }}>
              <DealerNotesPreview
                dealerNotes={stateAccess.get(["data", "dealerNotes"]) ?? []}
                assignedDealershipEmails={
                  stateAccess.get(["data", "info", "assignedDealershipEmails"]) ?? []
                }
                dealId={stateAccess.get(["_id"])}
              />
            </AccessControl>
          ),
          path: null,
          name: "Notes",
          width: "full"
        }
      ]
    }
  ]
});
const vehicle: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Vehicle",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: vehicleForm,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <VehiclePreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "info", "vehicle"]}
          name="Vehicle"
          label="Vehicle"
        />
      ),
      name: "Vehicle",
      label: "Vehicle",
      width: "full",
      path: [] as any,
      default: { model: "" }
    }
  ]
};

const signature: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Signature Placements",
  width: "hidden",
  show: () => false,
  entities: [
    // START:  INPUTS FOR ONE SPAN
    {
      formComponent: "segment",
      name: "Inputs",
      width: "hidden",
      show: () => false,
      entities: [
        {
          formComponent: "virtual-field",
          name: "inputForApplicant",
          label: "Input For Applicant",
          value: (stateAccess) => "1",
          description: "Displays input in Esign for applicant",
          path: ["data", "inputForApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "inputForCoApplicant",
          label: "Input For Co-Applicant",
          value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
          description: "Displays input in Esign for co-applicant",
          path: ["data", "inputForCoApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "inputForDealership",
          label: "Input For Dealership",
          value: (stateAccess) => "1",
          description: "Displays input in Esign for dealership",
          path: ["data", "inputForDealership"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "textareaForApplicant",
          label: "Textarea For Applicant",
          value: (stateAccess) => "1",
          description: "Displays textarea in Esign for applicant",
          path: ["data", "textareaForApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "textareaForCoApplicant",
          label: "Textarea For Co-Applicant",
          value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
          description: "Displays textarea in Esign for co-applicant",
          path: ["data", "textareaForCoApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "textareaForDealership",
          label: "Textarea For Dealership",
          value: (stateAccess) => "1",
          description: "Displays textarea in Esign for dealership",
          path: ["data", "textareaForDealership"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "radioForDealership",
          label: "Radio For Dealership",
          value: (stateAccess) => "1",
          description: "Displays radio button in Esign for dealership",
          path: ["data", "radioForDealership"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "checkboxForApplicant",
          label: "Checkbox For Applicant",
          value: (stateAccess) => "1",
          description: "Displays checkbox in Esign for applicant",
          path: ["data", "checkboxForApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "radioForApplicant",
          label: "Radio For Applicant",
          value: (stateAccess) => "1",
          description: "Displays radio button in Esign for applicant",
          path: ["data", "radioForApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "radioForCoApplicant",
          label: "Radio For Co-Applicant",
          value: (stateAccess) => "1",
          description: "Displays radio button in Esign for co-applicant",
          path: ["data", "radioForCoApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "datePickerForApplicant",
          label: "Datepicker For Applicant",
          value: (stateAccess) => "1",
          description: "Displays datepicker in Esign for applicant",
          path: ["data", "datepickerForApplicant"],
          width: "hidden",
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Signatures for married applicants",
      width: "hidden",
      show: () => false,
      entities: [
        {
          formComponent: "virtual-field",
          name: "circleSignaturePlacement",
          label: "Circle Signature Placement For Married Applicant",
          value: (stateAccess) =>
            stateAccess.get(["data", "applicant", "data", "info", "maritalStatus"]) === "Married"
              ? "1"
              : "0",
          description: "Displays signature sign for married applicant",
          path: ["data", "circleSignaturePlacementMarriedApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "circleSignaturePlacement",
          label: "Circle Signature Placement For Married CoApplicant",
          value: (stateAccess) =>
            stateAccess.get(["data", "coApplicant", "data", "info", "maritalStatus"]) === "Married"
              ? "1"
              : "0",
          description: "Displays signature sign for married coApplicant",
          path: ["data", "circleSignaturePlacementMarriedCoApplicant"],
          width: "hidden",
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "inputs for married applicants",
      width: "hidden",
      show: () => false,
      entities: [
        // value: (stateAccess) =>
        // stateAccess.get<Applicant>(["data", "info", "maritalStatus"]) === "Married",
        {
          formComponent: "virtual-field",
          name: "inputForApplicant",
          label: "Input For Married Applicant",
          value: (stateAccess) =>
            stateAccess.get(["data", "applicant", "data", "info", "maritalStatus"]) === "Married"
              ? "1"
              : "0",
          description: "Displays input in Esign for applicant when is married",
          path: ["data", "inputForMarriedApplicant"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "inputForCoApplicant",
          label: "Input For Married Co-Applicant",
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "coApplicant"]) &&
            stateAccess.get(["data", "coApplicant", "data", "info", "maritalStatus"]) === "Married"
              ? "1"
              : "0",
          description: "Displays input in Esign for co-applicant  when is married",
          path: ["data", "inputForMarriedCoApplicant"],
          width: "hidden",
          default: null
        }
      ]
    },
    // END:  INPUTS FOR ONE SPAN
    {
      formComponent: "segment",
      name: "Applicant Exists",
      width: "hidden",
      show: () => false,
      entities: [
        {
          formComponent: "virtual-field",
          name: "circleSignaturePlacement",
          label: "Circle Signature Placement co Applicant Exists",
          value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
          description: "Displays signature sign for applicant",
          path: ["data", "circleSignaturePlacementCoApplicantExists"],
          width: "hidden",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "circleLineSignaturePlacement",
          label: "Circle Line Signature Placement co Applicant Exists",
          value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
          description: "Displays signature sign for applicant with line",
          path: ["data", "circleLineSignaturePlacementCoApplicantExists"],
          width: "hidden",
          default: null
        }
      ]
    },

    {
      formComponent: "virtual-field",
      name: "circleSignaturePlacement",
      label: "Circle Signature Placement",
      value: (stateAccess) => "1",
      description: "Displays signature sign for applicant",
      path: ["data", "circleSignaturePlacement"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "circleLineSignaturePlacement",
      label: "Circle Line Signature Placement",
      value: (stateAccess) => "1",
      description: "Displays signature sign for applicant with line",
      path: ["data", "circleLineSignaturePlacement"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "ySignaturePlacement",
      label: "Y Signature Placement",
      value: (stateAccess) => "1",
      path: ["data", "ySignaturePlacement"],
      description: "Displays signature sign for dealership",
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "yLineSignaturePlacement",
      label: "Y Line Signature Placement",
      value: (stateAccess) => "1",
      path: ["data", "yLineSignaturePlacement"],
      description: "Displays signature sign for dealership with line",
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "circleSignaturePlacementCoApplicant",
      label: "Circle Signature Placement Co Applicant",
      value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
      description: "Displays signature sign for coApplicant or nothing if there is no such",
      path: ["data", "circleSignaturePlacementCoApplicant"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "circleLineSignaturePlacementCoApplicant",
      label: "Circle Line Signature Placement Co Applicant",
      value: (stateAccess) => (stateAccess.get<Deal>(["data", "coApplicant"]) ? "1" : "0"),
      description: "Displays signature sign for coApplicant or nothing if there is no such",
      path: ["data", "circleLineSignaturePlacementCoApplicant"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "circleSignaturePlacementApplicantForInsurance",
      label: "Circle Signature Placement Applicant for Insurances",
      value: (stateAccess) => (isGAPOrVSCSOLD(stateAccess) ? "1" : "0"),

      description:
        "Displays signature sign for Applicant or nothing if there are no insurances bought",
      path: ["data", "circleSignaturePlacementInsurances"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "circleSignaturePlacementCoApplicantForInsurances",
      label: "Circle Signature Placement Co Applicant for Insurances",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "coApplicant"]) && isGAPOrVSCSOLD(stateAccess) ? "1" : "0",
      description:
        "Displays signature sign for coApplicant or nothing if there are no insurances bought",
      path: ["data", "circleSignaturePlacementCoApplicantInsurances"],
      width: "hidden",
      default: null
    }
  ]
};

// const vehicleCoverage: FormComponent<Deal> = {
//   formComponent: "segment",
//   name: "Vehicle coverage",
//   width: "full",
//   entities: [
//     {
//       formComponent: "one-to-many-field",
//       component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
//         <VehicleCoveragePreview stateAccess={stateAccess} renderSet={renderSet} />
//       ),
//       required: true,
//       name: "Vehicle Coverage",
//       width: "full",
//       path: null,
//       default: null
//     }
//   ]
// };

export const vehicleInsuranceCompany: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Vehicle Insurance Company",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: vehicleInsuranceCompanyStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <VehicleInsuranceCompanyPreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "vehicleInsuranceCompany"]}
          name="vehicleInsuranceCompany"
          label="Vehicle Insurance Company"
        />
      ),
      name: "vehicleInsuranceCompany",
      label: "Vehicle Insurance Company",
      width: "full",
      path: ["data", "vehicleInsuranceCompany"],
      default: null
    }
  ]
};

const assignee: FormComponent<Deal> = {
  formComponent: "segment",
  name: "F&I manager",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: userStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <UserPreview
          type="f&i manager"
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "user"]}
          name="user"
          label="Assignees"
        />
      ),
      name: "user",
      label: "Assignees",
      width: "full",
      path: ["data", "user"],
      default: null,
      valueToString: (el) => el.firstName
    }
  ]
};

const lenderDecisions: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => <LenderDecisionsPreview dealId={stateAccess.get<Deal>(["_id"])} />,
      name: "lenderDecisions",
      label: "Lender decisions",
      width: "full",
      path: null,
      valueToString: (el) => el.firstName
    }
  ]
};
const insuranceProfitStruct: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  titleStyle: { color: "black" },
  name: "Insurance",
  entities: [
    {
      formComponent: "currency-field",
      name: "Warranty Remit Amount",
      label: "Warranty Remit Amount",
      path: ["data", "info", "accounting", "warrantyRemit"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["warrantyRemit"],
          ["aftermarketOptions", "totalServiceWarrantyRemitPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Warranty Cost (dealer’s cost)",
      label: "Warranty Cost (dealer’s cost)",
      path: ["data", "info", "accounting", "warrantyCost"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["warrantyCost"],
          ["aftermarketOptions", "totalServiceWarrantyDealerCostPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Warranty Selling Price",
      label: "Warranty Selling Price",
      path: ["data", "info", "accounting", "warrantyPrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["warrantyPrice"],
          ["aftermarketOptions", "totalServiceWarrantySellPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "GAP Remit Amount",
      label: "GAP Remit Amount",
      path: ["data", "info", "accounting", "gapRemit"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["gapRemit"],
          ["aftermarketOptions", "totalGAPRemitPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "GAP Cost (dealer’s cost)",
      label: "GAP Cost (dealer’s cost)",
      path: ["data", "info", "accounting", "gapCost"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["gapCost"],
          ["aftermarketOptions", "totalGAPDealerCostPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "GAP Selling Price",
      label: "GAP Selling Price",
      path: ["data", "info", "accounting", "gapPrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["gapPrice"],
          ["aftermarketOptions", "totalGAPSellPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Eco Total Price",
      label: "Eco Total Price",
      path: ["data", "info", "accounting", "ecoPrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["ecoPrice"],
          ["aftermarketOptions", "totalEcoPrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Wheel and Tire Total Price",
      label: "Wheel and Tire Total Price",
      path: ["data", "info", "accounting", "wheelAndTirePrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["wheelAndTirePrice"],
          ["aftermarketOptions", "totalWheelAndTirePrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Pre-paid Maintenance Total Price",
      label: "Pre-paid Maintenance Total Price",
      path: ["data", "info", "accounting", "prePaidMaintenancePrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["prePaidMaintenancePrice"],
          ["aftermarketOptions", "totalPrePaidMaintenancePrice"]
        ),
      default: null,
      width: "1/3"
    },
    {
      formComponent: "currency-field",
      name: "Paint and Fabrication Total Price",
      label: "Paint and Fabrication Total Price",
      path: ["data", "info", "accounting", "paintAndFabricationPrice"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(
          stateAccess,
          true,
          ["paintAndFabricationPrice"],
          ["aftermarketOptions", "totalPaintAndFabricationPrice"]
        ),
      default: null,
      width: "1/3"
    }
  ]
};

const fundingFeesStruct: FormComponent<FundingFee> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "select-field",
          name: "type",
          label: "Type",
          width: "1/4",
          path: ["type"],
          options: createOptionsForSelect({
            possibleValues: () => ["Membership", "UCC", "Other"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          required: true,
          default: null
        },
        {
          formComponent: "currency-field",
          name: "amount",
          label: "Amount",
          width: "1/4",
          path: ["amount"],
          default: null,
          required: true
        },
        {
          formComponent: "text-field",
          name: "description",
          label: "Descritption",
          width: "1/2",
          path: ["description"],
          default: null
        }
      ]
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, mainStateAccess) => {
        return <AddFundingFeeFile _id={mainStateAccess.get(["_id"])} stateAccess={stateAccess} />;
      },
      width: "full",
      path: ["document"],
      name: "document",
      label: "Document"
    }
  ]
};

const fundingFees: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "list-model",
      name: "Funding fees",
      entity: fundingFeesStruct,
      required: false,
      width: "full",
      path: ["data", "info", "taxesAndFees", "fundingFees"]
    }
  ]
};

const files: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => {
        return (
          <AccessControl requiredPermissions={{ entity: "file", action: "read" }}>
            <FilesPreview
              tableName="requiredDocuments"
              type="deal"
              _id={stateAccess.get(["_id"])}
              types={stateAccess.get<Deal>(["data", "lender", "data", "requiredDocuments"])}
            />
          </AccessControl>
        );
      },
      name: "file",
      label: "Files",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

const fundingDocuments: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => {
        return (
          <AccessControl requiredPermissions={{ entity: "funding_document", action: "read" }}>
            <FundingDocumentsPreview
              type="deal"
              _id={stateAccess.get(["_id"])}
              types={stateAccess.get<Deal>(["data", "lender", "data", "requiredDocuments"])}
            />
          </AccessControl>
        );
      },
      name: "fundingDocument",
      label: "Funding Documents",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

// const creditApplication: FormComponent<Deal> = {
//   formComponent: "segment",
//   width: "full",
//   entities: [
//     {
//       formComponent: "one-to-many-field",
//       component: (stateAccess: StateAccess) => {
//         return <CreditApplication dealId={stateAccess.get(["_id"])} />;
//       },
//       name: "CreditApplication",
//       label: "Credit Application",
//       width: "full",
//       path: null,
//       valueToString: (el) => el.name
//     }
//   ]
// };
const lendingDisclosures: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainStateAccess, renderSet) => {
        return <LendingDisclosures stateAccess={stateAccess} renderSet={renderSet} />;
      },
      name: "LendingDisclosures",
      label: "Lending Disclosures",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

const dealership: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Dealership",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      struct: () => dealershipStruct,
      component: (stateAccess: StateAccess, mainstateAccess: StateAccess, renderSet: RenderSet) => (
        <DealershipPreview
          lender={stateAccess.get(["data", "lender"])}
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "dealership"]}
          name="dealership"
          label="Dealership"
          required={true}
          type="deal"
        />
      ),
      name: "dealership",
      label: "Dealership",
      required: true,
      default: null,
      path: ["data", "dealership"],
      width: "full"
    }
  ]
};
const fundsSegment: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Funds",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "FundedAmount",
      label: "Funded Amount",
      width: "full",
      path: ["data", "info", "payment", "fundedAmount"],
      default: null
    }
  ]
};
const paymentsSegment: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Payments",
  show: () => false,
  entities: [
    {
      formComponent: "currency-field",
      name: "amountFinanced",
      label: "Amount financed",
      width: "1/3",
      path: ["data", "info", "payment", "amountFinanced"],
      default: null,
      show: () => false
    },
    {
      formComponent: "virtual-field",
      name: "financeCharge",
      label: "Finance charge",
      value: (stateAccess) =>
        formatNumberAsCurrency(calcFinanceCharge(stateAccess.get<Deal>([] as any))) ?? "",
      description: "Displays amount: (totalOfPayments - dealTotal)",
      path: ["data", "info", "payment", "interestRate"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "dealTotalWithoutItemization",
      label: "Amount financed - TitleTransfer - Doc/Reg Fees - SW - GAP",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);
        const amountFinanced = new Big(deal?.data?.info?.payment?.dealTotal || 0);

        const titleTransfer = new Big(deal?.data?.info?.taxesAndFees?.titleTransfer || 0);
        const docRegFees = new Big(deal?.data?.info?.taxesAndFees?.documentRegistrationFees || 0);

        const serviceWarrantyPrice = new Big(
          deal?.data?.info?.aftermarketOptions?.totalServiceWarrantySellPrice || 0
        );
        const gapPrice = new Big(deal?.data?.info?.aftermarketOptions?.totalGAPSellPrice || 0);
        return (
          formatNumberAsCurrency(
            amountFinanced
              .sub(titleTransfer)
              .sub(docRegFees)
              .sub(serviceWarrantyPrice)
              .sub(gapPrice)
              .toNumber()
          ) || "0.00"
        );
      },
      description:
        "Displays amount: (dealTotal - titleTransfer - documentRegistrationFees - totalServiceWarrantySellPrice - totalGAPSellPrice)",
      path: ["data", "info", "payment", "dealTotalWithoutItemization"],
      width: "hidden",
      default: null
    },
    {
      formComponent: "currency-field",
      name: "totalOfPayments",
      label: "Total of payments",
      width: "1/3",
      path: ["data", "info", "payment", "totalOfPayments"],
      default: null,
      show: () => false
    },
    {
      formComponent: "currency-field",
      name: "totalSalePrice",
      label: "Total Sale Price",
      width: "1/3",
      path: ["data", "info", "payment", "totalSalePrice"],
      default: null,
      show: () => false
    },
    {
      formComponent: "read-only-number-field",
      name: "dealTotal",
      label: "Deal Total",
      width: "1/2",
      path: ["data", "info", "payment", "dealTotal"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "totalAftermarket",
      label: "Total aftermarket",
      width: "1/2",
      path: ["data", "info", "payment", "totalAftermarket"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "localTaxes",
      label: "Local Taxes",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "payment", "localTaxes"]) || 0
        ) ?? "0.00",
      description: "Local taxes",
      path: ["data", "info", "payment", "localTaxes"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "stateTaxes",
      label: "State taxes",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "payment", "stateTaxes"]) || 0
        ) ?? "0.00",
      description: "State taxes",
      path: ["data", "info", "payment", "stateTaxes"],
      width: "hidden"
    },
    {
      formComponent: "read-only-number-field",
      name: "totalTaxes",
      label: "Total Taxes",
      width: "1/2",
      path: ["data", "info", "payment", "totalTaxes"],
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "totalFees",
      label: "Total Fees",
      width: "1/2",
      path: ["data", "info", "payment", "totalFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalTaxesTotalFees",
      label: "Total taxes + Total fees",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0) +
            (stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0)
        ) ?? "0.00",
      description: "Total taxes + total fees",
      path: ["data", "info", "payment", "totalTaxesTotalFees"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "subTotalDue",
      label: "Sub Total due",
      value: (stateAccess) => {
        const registrationTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "registrationTax"]) ||
          0;
        const plateFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "plateFee"]) || 0;
        const wheelageTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "wheelageTax"]) || 0;
        const techSurchargeFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "techSurchargeFee"]) ||
          0;
        const psVehicleFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "psVehicleFee"]) || 0;
        const transferTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "transferTax"]) || 0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        const lienFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "lienFee"]) || 0;
        const contributionFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "contributionFee"]) ||
          0;
        const totalTaxes = stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0;
        return (
          formatNumberAsCurrency(
            registrationTax +
              plateFee +
              wheelageTax +
              techSurchargeFee +
              psVehicleFee +
              transferTax +
              titleTransferFee +
              lienFee +
              contributionFee +
              totalTaxes
          ) ?? "0.00"
        );
      },
      description: "Displays the sum of the shown fees without deputy filing fee",
      path: ["data", "info", "payment", "subTotalDue"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "totalDue",
      label: "Total due",
      value: (stateAccess) => {
        const registrationTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "registrationTax"]) ||
          0;
        const plateFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "plateFee"]) || 0;
        const wheelageTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "wheelageTax"]) || 0;
        const techSurchargeFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "techSurchargeFee"]) ||
          0;
        const psVehicleFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "psVehicleFee"]) || 0;
        const transferTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "transferTax"]) || 0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        const lienFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "lienFee"]) || 0;
        const contributionFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "contributionFee"]) ||
          0;
        const totalTaxes = stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0;
        const deputyFilingFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "deputyFilingFee"]) ||
          0;
        return (
          formatNumberAsCurrency(
            registrationTax +
              plateFee +
              wheelageTax +
              techSurchargeFee +
              psVehicleFee +
              transferTax +
              titleTransferFee +
              lienFee +
              contributionFee +
              totalTaxes +
              deputyFilingFee
          ) ?? "0.00"
        );
      },
      description: "Displays the sum of the shown fees",
      path: ["data", "info", "payment", "totalDue"],
      width: "hidden"
    }
  ]
};
const priceSegment: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Loan",
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "price",
      label: "Price",
      width: "full",
      path: ["data", "info", "price", "price"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "PriceOptions",
      label: "Price + Options",
      value: (stateAccess) =>
        formatNumberAsCurrency(actualPrice(stateAccess.get([] as any))) ?? "N/A",
      description: "Displays Payoff amount Or N/A",
      path: ["data", "info", "price", "priceOptions"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TaxableAmount",
      label: "Taxable amount",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([] as any);
        return formatNumberAsCurrency(deal?.data?.info?.payment?.taxableAmount || 0) ?? "0.00";
      },
      description: "Displays taxable amount",
      path: ["data", "info", "price", "taxableAmount"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TaxableAmountPlusTradeValue",
      label: "Taxable amount + Trade In Value",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([] as any);
        return (
          formatNumberAsCurrency(
            (calcTaxableAmount(deal)?.data?.info?.payment?.taxableAmount || 0) +
              (deal?.data?.info?.price?.totalTrade || 0)
          ) || 0
        );
      },
      description: "Displays taxable amount plus trade in value",
      path: ["data", "info", "price", "taxableAmountPlusTradeInValue"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "options",
      label: "Options/Accessories",
      width: "full",
      path: ["data", "info", "price", "options"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "optionsOrNA",
      label: "Options/Accessories or N/A",
      description: "Displays options or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "options"])
        ) ?? "N/A",
      path: ["data", "info", "price", "optionsOrN/A"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "rebates",
      label: "Rebates",
      width: "full",
      path: ["data", "info", "price", "rebates"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "rebates",
      label: "Rebates Or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "rebates"])
        ) ?? "N/A",
      description: "Displays Rebates amount Or N/A",
      path: ["data", "info", "price", "rebatesOrNA"],
      width: "hidden"
    },
    {
      formComponent: "currency-field",
      name: "totalCash",
      label: "Total cash",
      width: "full",
      path: ["data", "info", "price", "totalCash"],
      default: null
    },

    {
      formComponent: "segment",
      width: "full",
      entities: [tradeIn]
    },
    {
      formComponent: "virtual-field",
      name: "payoffOrNA",
      label: "Payoff Or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "payoff"])
        ) ?? "N/A",
      description: "Displays Payoff amount Or N/A",
      path: ["data", "info", "price", "payoffOrNA"],
      default: null
    },

    {
      formComponent: "segment",
      width: "full",
      entities: [payoffBank]
    },
    {
      formComponent: "read-only-number-field",
      name: "netTrade",
      label: "Net trade",
      width: "full",
      path: ["data", "info", "price", "netTrade"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "netTradeOrNA",
      label: "Net trade or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "netTrade"])
        ) ?? "N/A",
      description: "Displays netTrade amount Or N/A",
      path: ["data", "info", "price", "netTradeOrNA"],
      default: null
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        // @ts-ignore
        possibleValues: () => ["monthly", "yearly"],
        getOptionLabel: (x) =>
          ["monthly", "yearly"].includes(x) ? `${x[0].toUpperCase()}${x.slice(1)}` : "",
        getSelectedOption: (x, y) => x === y
      }),
      name: "paymentType",
      label: "Payment type",
      width: "1/2",
      path: ["data", "info", "payment", "type"],
      default: "monthly"
    },
    {
      formComponent: "currency-field",
      name: "monthlyPayment",
      label: "Monthly payment",
      width: "1/2",
      path: ["data", "info", "payment", "monthlyPayment"],
      default: null,
      show: () => false
    },
    {
      formComponent: "months-field",
      name: "numberOfPayments",
      label: "# of payments",
      width: "1/2",
      path: ["data", "info", "payment", "numberOfPayments"],
      default: null
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "percentage-field",
          name: "Buy rate",
          label: "Buy rate %",
          width: "auto",
          path: ["data", "info", "payment", "buyPercent"],
          show: (stateAccess: StateAccess) => {
            return typeof stateAccess.get(["data", "info", "payment", "markupData", "markup"]) ===
              "number"
              ? true
              : false;
          },
          default: null
        },
        {
          formComponent: "one-to-many-field",
          width: "auto",
          component: (
            stateAccess: StateAccess,
            mainStateAccess: StateAccess,
            renderSet: RenderSet
          ) => {
            const deal: Deal = stateAccess.get<Deal>([]);
            const lender = deal.data.lender;

            const pivotTableData = getPivotTableData(deal);
            const markupReserveTableData = findMarkupTableRate(
              deal,
              lender?.data?.info?.markupTables?.find(
                (mt) => pivotTableData && mt.id === pivotTableData?.markupTableId
              )?.table
            );
            return markupReserveTableData?.length > 0 ? (
              <MarkupAndReservePreview
                stateAccess={stateAccess}
                renderSet={renderSet}
                markupReserveTableData={markupReserveTableData}
              />
            ) : null;
          },
          path: null,
          name: "MarkupAndReservePreview",
          default: null
        },
        {
          formComponent: "percentage-field",
          name: "interestRate",
          label: "Contract rate %",
          width: "auto",
          path: ["data", "info", "payment", "interestRate"],
          show: (stateAccess: StateAccess) => {
            return typeof stateAccess.get(["data", "info", "payment", "markupData", "markup"]) !==
              "number"
              ? true
              : false;
          },
          default: null
        },
        {
          formComponent: "read-only-percentage-field",
          name: "interestRate",
          label: "Contract rate %",
          width: "auto",
          path: ["data", "info", "payment", "interestRate"],
          show: (stateAccess: StateAccess) => {
            return typeof stateAccess.get(["data", "info", "payment", "markupData", "markup"]) ===
              "number"
              ? true
              : false;
          },
          default: null
        }
      ]
    },

    {
      formComponent: "segment",
      width: "full",
      show: (stateAccess) => !stateAccess.get<Deal>(["_id"]),
      entities: [paymentsSegment]
    },
    {
      formComponent: "virtual-field",
      name: "publicOfficialsFees",
      label: "Public officials fees",
      value: (stateAccess) => {
        const titleTransfer =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"]) || 0;
        const filingFees =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "filingFees"]) || 0;

        return formatNumberAsCurrency(titleTransfer + filingFees) ?? "0.00";
      },
      description: "Displays title transfer + filing ",
      path: ["data", "info", "payment", "publicOfficialsFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "publicOfficialsFeesWithoutFLDocStampFee",
      label: "Public officials fees without FL Doc Stamp Fee",
      value: (stateAccess) => {
        const titleTransfer =
          (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"]) || 0) -
          (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "contributionFee"]) ||
            0);
        const filingFees =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "filingFees"]) || 0;
        return formatNumberAsCurrency(titleTransfer + filingFees) ?? "0.00";
      },
      description: "Displays title transfer + filing without FL Doc Stamp fee",
      path: ["data", "info", "payment", "publicOfficialsFeesWithoutFLDocStampFee"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "downPayment",
      label: "Down Payment",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "payment", "downPayment"])
        ) ?? "0.00",
      description: "Displays downPayment or 0.00",
      path: ["data", "info", "payment", "downPayment"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalOfPayments",
      label: "Total of Payments",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "payment", "totalOfPayments"])
        ) ?? "",
      description: "Displays total of payments or nothing",
      path: ["data", "info", "payment", "totalOfPayments"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TotalCashRebates",
      label: "Total Cash + Rebates",
      value: (stateAccess) => {
        return (
          formatNumberAsCurrency(
            (stateAccess.get<Deal>(["data", "info", "price", "totalCash"]) || 0) +
              (stateAccess.get<Deal>(["data", "info", "price", "rebates"]) || 0)
          ) ?? "N/A"
        );
      },
      description: "Displays amount:  (totalCash + rebates)",
      path: ["data", "info", "price", "totalCashRebates"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "netTradeTotalCashRebates",
      label: "Net Trade + Total Cash + Rebates",
      value: (stateAccess) => {
        const netTrade = stateAccess.get<Deal>(["data", "info", "price", "netTrade"]) || 0;
        return (
          formatNumberAsCurrency(
            (netTrade > 0 ? netTrade : 0) +
              (stateAccess.get<Deal>(["data", "info", "price", "totalCash"]) || 0) +
              (stateAccess.get<Deal>(["data", "info", "price", "rebates"]) || 0)
          ) ?? "N/A"
        );
      },
      description: "Displays amount:  (netTrade + totalCash + rebates) ",
      path: ["data", "info", "price", "netTradeTotalCashRebates"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "netTradeTotalCash",
      label: "Net Trade + Total Cash ",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (stateAccess.get<Deal>(["data", "info", "price", "netTrade"]) ?? 0) +
            (stateAccess.get<Deal>(["data", "info", "price", "totalCash"]) ?? 0)
        ) ?? "N/A",
      description: "Displays amount:  (netTrade + totalCash )",
      path: ["data", "info", "price", "netTradeTotalCash"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalCashOrN/A",
      label: "Total cash or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "price", "totalCash"])
        ) ?? "N/A",
      description: "Displays totalCash or N/A",
      path: ["data", "info", "price", "totalCashOrN/A"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "CashPrice",
      label: "Cash Price",
      value: (stateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);
        const payment = deal?.data?.info?.payment;

        return (
          formatNumberAsCurrency(
            actualPrice(deal) +
              (payment?.totalTaxes || 0) +
              (payment?.totalFees || 0) +
              (payment?.totalAftermarket || 0)
          ) ?? "N/A"
        );
      },
      description: "Displays amount: (price + totalTaxes + totalFees + totalAftermarket)",
      path: ["data", "info", "price", "cashPrice"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "priceMinusRebates",
      label: "Price - Rebates",
      value: (stateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);

        return (
          formatNumberAsCurrency(actualPrice(deal) + (deal?.data?.info?.price?.rebates || 0)) ??
          "0.00"
        );
      },
      description: "Displays amount: Price - Rebates",
      path: ["data", "info", "price", "priceMinusRebates"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "priceMinusTradeAndRebates",
      label: "Price - Trade - Rebates",
      value: (stateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);
        return (
          formatNumberAsCurrency(
            actualPrice(deal) -
              (deal?.data?.info?.price?.totalTrade || 0) -
              (deal?.data?.info?.price?.rebates || 0)
          ) ?? "0.00"
        );
      },
      description: "Displays amount: Price - Trade - Rebates",
      path: ["data", "info", "price", "priceMinusTradeAndRebates"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "unpaidBalance",
      label: "Unpaid Balance",
      value: (stateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);
        const netTrade = deal?.data?.info?.price?.netTrade || 0;
        return (
          formatNumberAsCurrency(
            actualPrice(deal) +
              (deal?.data?.info?.payment?.totalTaxes || 0) -
              ((netTrade > 0 ? netTrade : 0) +
                (deal?.data?.info?.price?.totalCash || 0) +
                (deal?.data?.info?.price?.rebates || 0))
          ) || "0.00"
        );
      },
      description: "Displays amount: (price + totalTaxes - (netTrade + totalCash + rebates))",
      path: ["data", "info", "price", "unpaidBalance"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "unpaidBalanceWithServiceContract",
      label: "Unpaid Balance with Service Contract",
      value: (stateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);
        const netTrade = deal?.data?.info?.price?.netTrade || 0;
        return (
          formatNumberAsCurrency(
            actualPrice(deal) +
              +(deal?.data?.info?.aftermarketOptions?.totalServiceWarrantySellPrice || 0) +
              (deal?.data?.info?.payment?.totalTaxes || 0) -
              ((netTrade > 0 ? netTrade : 0) +
                (deal?.data?.info?.price?.totalCash || 0) +
                (deal?.data?.info?.price?.rebates || 0))
          ) || "0.00"
        );
      },
      description:
        "Displays amount: (price + totalService + totalTaxes - (netTrade + totalCash + rebates))",
      path: ["data", "info", "price", "unpaidBalanceWithServiceContract"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalTaxesLienTitleTransferFees",
      label: "Taxes + Lien + TitleTransfer Fees",
      value: (stateAccess) => {
        const totalTaxes = stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0;
        const lienFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "lienFee"]) || 0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        return formatNumberAsCurrency(totalTaxes + lienFee + titleTransferFee) || "0.00";
      },
      description: "Displays amount: Total Taxes + Lien Fee + TitleTransfer",
      path: ["data", "info", "price", "totalTaxesLienTitleTransferFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lienTitleTransferFees",
      label: "Lien + TitleTransfer Fees",
      value: (stateAccess) => {
        const lienFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "lienFee"]) || 0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        return formatNumberAsCurrency(lienFee + titleTransferFee) || "0.00";
      },
      description: "Displays amount:  Lien Fee + TitleTransfer",
      path: ["data", "info", "price", "lienTitleTransferFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "titleTransferFeeLicenseAndRegFeePlateFee",
      label: "TitleTransferFee + LicenseAndRegFee + PlateFee",
      value: (stateAccess) => {
        const plateFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "plateFee"]) || 0;
        const licenseAndRegFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "licenseAndRegFee"]) ||
          0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;

        return formatNumberAsCurrency(plateFee + licenseAndRegFee + titleTransferFee) || "0.00";
      },
      description: "Displays amount: titleTransferFee + licenseAndRegFee + plateFee",
      path: ["data", "info", "price", "titleTransferFeeLicenseAndRegFeePlateFee"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalTaxesRegistrationTaxTransferFees",
      label: "Taxes + RegistrationTax + TitleTransfer Fees",
      value: (stateAccess) => {
        const totalTaxes = stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0;
        const registrationTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "registrationTax"]) ||
          0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        return formatNumberAsCurrency(totalTaxes + registrationTax + titleTransferFee) || "0.00";
      },
      description: "Displays amount: Total Taxes + Registration Tax + TitleTransfer",
      path: ["data", "info", "price", "totalTaxesRegistrationTaxTransferFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "registrationTaxTransferFees",
      label: "RegistrationTax + TitleTransfer Fees",
      value: (stateAccess) => {
        const registrationTax =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "registrationTax"]) ||
          0;
        const titleTransferFee =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "titleTransferFee"]) ||
          0;
        return formatNumberAsCurrency(registrationTax + titleTransferFee) || "0.00";
      },
      description: "Displays amount: Registration Tax + TitleTransfer",
      path: ["data", "info", "price", "registrationTaxTransferFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalBalanceDue",
      label: "Total Balance Due",
      value: (stateAccess) => {
        const cashPrice =
          actualPrice(stateAccess.get<Deal>([] as any)) +
          (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) || 0) +
          (stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0) +
          (stateAccess.get<Deal>(["data", "info", "payment", "totalAftermarket"]) || 0);

        const netTrade = stateAccess.get<Deal>(["data", "info", "price", "netTrade"]) || 0;
        const totalCash = stateAccess.get<Deal>(["data", "info", "price", "totalCash"]) || 0;
        const rebates = stateAccess.get<Deal>(["data", "info", "price", "rebates"]) || 0;
        return formatNumberAsCurrency(cashPrice - (netTrade + totalCash + rebates)) || "0.00";
      },
      description:
        "Displays amount: (price + totalTaxes + totalFees + totalAftermarket - (netTrade + totalCash + rebates))",
      path: ["data", "info", "price", "totalBalanceDue"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "netTradeInOr0",
      label: "Net Trade In positive or 0",
      value: (stateAccess) => {
        const netTradeIn =
          stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) -
          stateAccess.get<Deal>(["data", "info", "price", "payoff"]);
        return formatNumberAsCurrency(netTradeIn > 0 ? netTradeIn : 0) ?? "N/A";
      },
      description: "Displays: if positive (trade in - payoff) ; if negative N/A ",
      path: ["data", "info", "price", "netTradeInOr0"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "financedTradeIn",
      label: "Financed Trade In",
      value: (stateAccess) => {
        const netTradeIn =
          stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) -
          stateAccess.get<Deal>(["data", "info", "price", "payoff"]);
        return formatNumberAsCurrency(netTradeIn < 0 ? -netTradeIn : 0) ?? "N/A";
      },
      description: "Displays: if negative (trade in - payoff) ; if positive N/A ",
      path: ["data", "info", "price", "financedTradeIn"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "TotalFeesMinusDocReg",
      label: "Total Fees - Doc Reg",
      value: (stateAccess) => {
        const totalFees = stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0;
        const documentRegistrationFees =
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "documentRegistrationFees"]) || 0;

        return formatNumberAsCurrency(totalFees - documentRegistrationFees) ?? "0.00";
      },
      description: "Displays: Total fees - DocReg fees",
      path: ["data", "info", "price", "totalFeesMinusDocReg"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalOtherCharges",
      label: "Total Other Charges",
      value: (stateAccess) => {
        const netTradeIn =
          (stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) || 0) -
          (stateAccess.get<Deal>(["data", "info", "price", "payoff"]) || 0);
        const totalFees = stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0;

        const aftermarket =
          stateAccess.get<Deal>(["data", "info", "payment", "totalAftermarket"]) || 0;

        const netTradeInValue = netTradeIn < 0 ? -netTradeIn : 0;
        return formatNumberAsCurrency(netTradeInValue + totalFees + aftermarket) ?? "N/A";
      },
      description:
        "Displays: netTradeIn +  titleTransfer + documentRegistrationFees + totalGAPretailPrice + totalServiceWarrantySellPrice",
      path: ["data", "info", "price", "totalOtherCharges"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalOtherChargesWithoutServiceContract",
      label: "Total Other Charges Without Service Contract",
      value: (stateAccess) => {
        const netTradeIn =
          (stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) || 0) -
          (stateAccess.get<Deal>(["data", "info", "price", "payoff"]) || 0);
        const totalFees = stateAccess.get<Deal>(["data", "info", "payment", "totalFees"]) || 0;
        const aftermarket =
          stateAccess.get<Deal>(["data", "info", "payment", "totalAftermarket"]) || 0;
        const vsc =
          stateAccess.get<Deal>([
            "data",
            "info",
            "aftermarketOptions",
            "totalServiceWarrantySellPrice"
          ]) || 0;

        const netTradeInValue = netTradeIn < 0 ? -netTradeIn : 0;
        return formatNumberAsCurrency(netTradeInValue + totalFees + (aftermarket - vsc)) ?? "N/A";
      },
      description:
        "Displays: netTradeIn +  titleTransfer + documentRegistrationFees + totalGAPretailPrice ",
      path: ["data", "info", "price", "totalOtherChargesWithoutServiceContract"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "priceMinusTradeInValue",
      label: "Price - Trade-in Value",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          actualPrice(stateAccess.get<Deal>([] as any)) -
            (stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) ?? 0)
        ) ?? "N/A",
      description: "Displays: (price - trade in)",
      path: ["data", "info", "price", "priceMinusTradeInValue"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "balanceDueForTitleApplication",
      label: "Balance Due for title application",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          actualPrice(stateAccess.get<Deal>([] as any)) -
            (stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) ?? 0) +
            (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) ?? 0) +
            (stateAccess.get<Deal>([
              "data",
              "info",
              "taxesAndFees",
              "detailed",
              "titleTransferFee"
            ]) || 0) +
            (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "lienFee"]) || 0)
        ) ?? "N/A",
      description:
        "Displays: (price + options - trade in + totalTaxes + Title/TransferFee + Lien Fee)",
      path: ["data", "info", "price", "balanceDueForTitleApplication"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "pricePlusTaxes",
      label: "Price + Taxes",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          actualPrice(stateAccess.get<Deal>([] as any)) +
            (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) ?? 0)
        ) ?? "N/A",
      description: "Displays: (price + totalTaxes)",
      path: ["data", "info", "price", "pricePlusTaxes"],
      default: null
    },

    {
      formComponent: "virtual-field",
      name: "pricePlusTaxesAndServiceAndFinancedTradeIn",
      label: "Price + Taxes + Service + Financed Trade In",
      value: (stateAccess) => {
        let netTradeIn =
          stateAccess.get<Deal>(["data", "info", "price", "totalTrade"]) -
          stateAccess.get<Deal>(["data", "info", "price", "payoff"]);
        netTradeIn = netTradeIn < 0 ? -netTradeIn : 0;

        return (
          formatNumberAsCurrency(
            actualPrice(stateAccess.get<Deal>([] as any)) +
              (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) ?? 0) +
              (stateAccess.get<Deal>([
                "data",
                "info",
                "aftermarketOptions",
                "totalServiceWarrantySellPrice"
              ]) ?? 0) +
              netTradeIn
          ) ?? "N/A"
        );
      },
      description: "Displays: (price + totalTaxes + totalServiceWarrantyRetailPrice)",
      path: ["data", "info", "price", "pricePlusTaxesAndService"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "pricePlusTaxesAndService",
      label: "Price + Taxes + Service",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          actualPrice(stateAccess.get<Deal>([] as any)) +
            (stateAccess.get<Deal>(["data", "info", "payment", "totalTaxes"]) ?? 0) +
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "totalServiceWarrantySellPrice"
            ]) ?? 0)
        ) ?? "N/A",
      description: "Displays: (price + totalTaxes + totalServiceWarrantyRetailPrice)",
      path: ["data", "info", "price", "pricePlusTaxesAndService"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "[Price + options - tradeIn ](Difference) + total fees + total taxes + VSC + GAP",
      label: "Price + Taxes + Service + GAP (Sub total)",
      value: (stateAccess) => formatNumberAsCurrency(subTotalDue(stateAccess) || 0) || "0.00",
      description: "Displays: [Price + options - tradeIn ] + total fees + total taxes + VSC + GAP",
      path: ["data", "info", "price", "subTotal"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "totalDue",
      label: "Total amount due",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (subTotalDue(stateAccess) || 0) +
            (stateAccess.get<Deal>(["data", "info", "price", "payoff"]) || 0)
        ) || "0.00",
      description: "Displays: (Sub total + payoff)",
      path: ["data", "info", "price", "totalAmountDue"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "firstPaymentDate",
      label: "First Payment Date",
      value: (stateAccess) => {
        const fpd = firstPaymentDate(stateAccess);
        return fpd && `${fpd.toLocaleDateString("en-US")}`;
      },
      description: "Example: 1/22/2021",
      path: ["data", "info", "payment", "firstPaymentDate"],
      default: null
    },
    {
      formComponent: "segment",
      name: "firstPaymentDateSplit",
      show: () => false,
      entities: [
        {
          formComponent: "virtual-field",
          name: "firstPaymentDateMM",
          label: "MM",
          value: (stateAccess) => {
            const x = firstPaymentDateUSSplit(stateAccess);
            return x && x[0];
          },
          description: "Example: 1",
          path: ["data", "info", "payment", "firstPaymentDateMM"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "firstPaymentDateDD",
          label: "DD",
          value: (stateAccess) => {
            const x = firstPaymentDateUSSplit(stateAccess);
            return x && x[1];
          },
          description: "Example: 27",
          path: ["data", "info", "payment", "firstPaymentDateDD"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "firstPaymentDateYYYY",
          label: "YYYY",
          value: (stateAccess) => {
            const x = firstPaymentDateUSSplit(stateAccess);
            return x && x[2];
          },
          description: "Example: 2021",
          path: ["data", "info", "payment", "firstPaymentDateYYYY"],
          default: null
        }
      ]
    },
    {
      formComponent: "virtual-field",
      name: "monthToStartPayment",
      label: "Month to start payments",
      value: (stateAccess) => {
        const contractDate = new Date(
          stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
        );
        const daysToFirstPayment =
          stateAccess.get<Deal>(["data", "info", "payment", "daysToFirstPayment"]) ??
          defaultDaysToFirstPayment;
        return (
          contractDate &&
          `${getMonth(
            new Date(new Date(contractDate).setDate(contractDate?.getDate() + daysToFirstPayment))
          )}`
        );
      },
      description: "Example:November",
      path: ["data", "info", "payment", "monthToStartPayment"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "monthlyPaymentsStarting",
      label: "Monthly payments starting",
      value: (stateAccess) => {
        const contractDate = new Date(
          stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
        );
        const daysToFirstPayment =
          stateAccess.get<Deal>(["data", "info", "payment", "daysToFirstPayment"]) ??
          defaultDaysToFirstPayment;
        return (
          contractDate &&
          `Monthly payments starting - ${formatDate(
            new Date(new Date(contractDate).setDate(contractDate?.getDate() + daysToFirstPayment)),
            "long"
          )}`
        );
      },
      description: "Example: Monthly payments starting - 17 November 2020",
      path: ["data", "info", "payment", "monthlyPaymentsStarting"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "adminFeeToDealer",
      label: "Admin. Fee To Dealer",
      value: () => "Admin Fee To Dealer",
      description: "Displays text: Admin Fee To Dealer",
      path: ["data", "info", "price", "adminFeeToDealer"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "maxCollisionCoverageDeductible",
      label: "Max Collision Coverage Deductible",
      value: () => "1,000.00",
      description: "Displays amount: 1,000.00",
      path: ["data", "info", "price", "maxCollisionCoverageDeductible"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "6.5% of Net Price",
      label: "6.5% of Net Price",
      value: (stateAccess) =>
        parseFloat(
          (
            (actualPrice(stateAccess.get<Deal>([] as any)) -
              stateAccess.get<Deal>(["data", "info", "price", "totalTrade"])) *
            (6.5 / 100)
          ).toFixed(2)
        ),
      description: "Displays amount:  ((price - tradeIn) * (6.5 / 100))",
      path: ["data", "info", "payment", "6.5% of Net Price"],
      default: null
    }
  ]
};

const aftermarketOptions: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Insurances",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "virtual-field",
          name: "fullName",
          label: "Applicant full name or NA",
          value: (stateAccess) =>
            isGAPOrVSCSOLD(stateAccess) ? fullName(stateAccess, "applicant") : "N/A",
          path: ["data", "applicantfullNameOrNAForInsurances"],
          description:
            "Displays full name or N/A if there is no insurance bought. Example: John M. Doe",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "fullName",
          label: "CoApplicant full name or NA",
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "coApplicant"]) && isGAPOrVSCSOLD(stateAccess)
              ? fullName(stateAccess, "coApplicant")
              : "N/A",
          path: ["data", "coApplicantfullNameOrNAForInsurances"],
          description:
            "Displays full name or N/A if there is no insurance bought. Example: John M. Doe",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "ApplicantContractDateInsurances",
          label: "Applicant contract Date Or N/A",
          value: (stateAccess) =>
            isGAPOrVSCSOLD(stateAccess) &&
            stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
              ? new Date(
                  stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
                ).toLocaleDateString("en-US")
              : "N/A",
          description: "Displays contract Date date if GAP or VSC is sold otherwise N/A",
          path: ["data", "info", "dealDates", "applicantContractDateOrNAForInsurances"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "coApplicantContractDateInsurances",
          label: "Co Applicant contract Date Or N/A",
          value: (stateAccess) =>
            isGAPOrVSCSOLD(stateAccess) &&
            stateAccess.get<Deal>(["data", "coApplicant"]) &&
            stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
              ? new Date(
                  stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
                ).toLocaleDateString("en-US")
              : "N/A",
          description:
            "Displays contract Date date if there is a coApplicant and GAP or VSC is sold, otherwise N/A",
          path: ["data", "info", "dealDates", "coApplicantContractDateOrNAForInsurances"],
          width: "hidden"
        },
        {
          formComponent: "currency-field",
          name: "totalGAPSellPrice",
          label: "Total GAP Sell Price",
          width: "1/3",
          path: ["data", "info", "aftermarketOptions", "totalGAPSellPrice"],
          default: null,
          show: () => false
        },
        {
          formComponent: "currency-field",
          name: "totalServiceWarrantySellPrice",
          label: "Total Service Warranty Sell Price",
          width: "1/3",
          path: ["data", "info", "aftermarketOptions", "totalServiceWarrantySellPrice"],
          default: null,
          show: () => false
        },
        {
          formComponent: "virtual-field",
          name: "serviceWarrantyProvider",
          label: "Service Warranty Provider Or N/A",
          value: (stateAccess) => {
            const insurances: Insurances = stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]);
            const insurance: AllInsurancesTypes | undefined = insurances?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "service warranty"
            );
            if (!insurance || !insurance?.provider) return "N/A";
            return insurance?.type === "Custom" &&
              insurance?.provider === "Other" &&
              insurance?.customProvider
              ? insurance?.customProvider
              : insurance?.provider;
          },
          description: "VSC Insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "serviceWarrantyProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "paintAndFabricationText",
          label: "paintAndFabricationText",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "paint and fabrication"
            )
              ? "Paint and fabrication"
              : "N/A",
          description: "Paint And Fabrication text or N/A",
          path: ["data", "info", "aftermarketOptions", "paintAndFabricationText"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "prePaidMaintenanceText",
          label: "Pre-Paid Maintenance Text Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "pre-paid maintenance"
            )
              ? "Pre-paid maintenance"
              : "N/A",
          description: "Pre-Paid Maintenance Provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "prePaidMaintenanceText"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "Eco Text",
          label: "Eco Text Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "eco"
            )
              ? "Eco"
              : "N/A",
          description: "Eco insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "ecoText"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "WheelAndTire Text",
          label: "Wheel And Tire Text Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "wheel and tire"
            )
              ? "Wheel and tire"
              : "N/A",
          description: "Wheel And Tire insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "wheelAndTireText"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "paintAndFabricationProvider",
          label: "Paint And Fabrication Provider Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "paint and fabrication"
            )?.provider ?? "N/A",
          description: "Paint And Fabrication Provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "paintAndFabricationProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "prePaidMaintenanceProvider",
          label: "Pre-Paid Maintenance Provider Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "pre-paid maintenance"
            )?.provider ?? "N/A",
          description: "Pre-Paid Maintenance Provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "prePaidMaintenanceProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "Eco Provider",
          label: "Eco Provider Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "eco"
            )?.provider ?? "N/A",
          description: "Eco insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "ecoProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "WheelAndTire Provider",
          label: "Wheel And Tire Provider Or N/A",
          value: (stateAccess) =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "wheel and tire"
            )?.provider ?? "N/A",
          description: "Wheel And Tire insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "wheelAndTireProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "serviceWarrantyPrice",
          label: "Service Warranty Price Or N/A",
          value: (stateAccess) =>
            formatNumberAsCurrency(
              stateAccess.get<Deal>([
                "data",
                "info",
                "aftermarketOptions",
                "totalServiceWarrantySellPrice"
              ]) || undefined
            ) ?? "N/A",
          description: "VSC Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "serviceWarrantyPrice"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "xtraRideDeductible",
          label: "XtraRide Deductible",
          value: (stateAccess) => {
            const insurances: Insurances = stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]);
            const insurance = insurances?.find(
              (insurance) => insurance?.provider === "XtraRide" && insurance.type === "Custom"
            );

            if (insurance?.type === "Custom") return insurance?.chosenRate?.deductible ?? "N/A";
            else return "N/A";
          },
          description: "XtraRide Deductible or N/A",
          path: ["data", "info", "aftermarketOptions", "xtraRideDeductable"],
          width: "hidden"
        },
        {
          formComponent: "one-to-many-field",
          width: "full",
          component: (
            stateAccess: StateAccess,
            mainStateAccess: StateAccess,
            renderSet: RenderSet
          ) => <InsuranceProductsPreview stateAccess={stateAccess} renderSet={renderSet} />,
          path: null,
          name: "insuranceProductsPreview",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "GAPProvider",
          label: "GAP Provider Or N/A",
          value: (stateAccess) => {
            const insurances: Insurances = stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]);
            const insurance: AllInsurancesTypes | undefined = insurances?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "gap"
            );
            if (!insurance || !insurance?.provider) return "N/A";
            return insurance?.type === "Custom" &&
              insurance?.provider === "Other" &&
              insurance?.customProvider
              ? insurance?.customProvider
              : insurance?.provider;
          },
          description: "GAP Insurance provider name or N/A",
          path: ["data", "info", "aftermarketOptions", "GAPProvider"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "GAP SequenceNumber",
          label: "GAP Sequence Number",
          value: (stateAccess: StateAccess): string => {
            const insurance = (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance, index) =>
                insurance?.chosenRate?.insuranceType === "gap" && insurance?.type === "Custom"
            );
            return insurance?.type === "Custom" && insurance?.sequence?.seq
              ? `${insurance.sequence.seq}`
              : "N/A";
          },
          description: "displays gap insurance sequence number or  N/A",
          path: ["data", "info", "aftermarketOptions", "gapSequenceNumber"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "VSC SequenceNumber",
          label: "VSC Sequence Number",
          value: (stateAccess: StateAccess): string => {
            const insurance = (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance, index) =>
                insurance?.chosenRate?.insuranceType === "service warranty" &&
                insurance?.type === "Custom"
            );
            return insurance?.type === "Custom" && insurance?.sequence?.seq
              ? `${insurance.sequence.seq}`
              : "N/A";
          },
          description: "displays vsc insurance sequence number or  N/A",
          path: ["data", "info", "aftermarketOptions", "vscSequenceNumber"],
          default: null
        },

        {
          formComponent: "virtual-field",
          name: "GAPPriceOrNA",
          label: "GapPrice Or NA",
          value: (stateAccess) =>
            stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalGAPSellPrice"])
              ? formatNumberAsCurrency(
                  stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalGAPSellPrice"])
                ) ?? "N/A"
              : "N/A",
          description: "GAP Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "GAPPriceOrNA"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "PaintAndFabricationPriceOrNA",
          label: "PaintAndFabricationPrice Or NA",
          value: (stateAccess) =>
            stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "totalPaintAndFabricationPrice"
            ])
              ? formatNumberAsCurrency(
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "aftermarketOptions",
                    "totalPaintAndFabricationPrice"
                  ])
                ) ?? "N/A"
              : "N/A",
          description: "Paint And Fabrication Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "paintAndFabricationPriceOrNA"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "PrePaidMaintenancePriceOrNA",
          label: "PrePaidMaintenancePrice Or NA",
          value: (stateAccess: StateAccess): string =>
            stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "totalPrePaidMaintenancePrice"
            ])
              ? formatNumberAsCurrency(
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "aftermarketOptions",
                    "totalPrePaidMaintenancePrice"
                  ])
                ) ?? "N/A"
              : "N/A",
          description: "Pre-Paid Maintenance Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "prePaidMaintenancePriceOrNA"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "ecoPriceOrNA",
          label: "ecoPrice Or NA",
          value: (stateAccess: StateAccess): string =>
            stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalEcoPrice"])
              ? formatNumberAsCurrency(
                  stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalEcoPrice"])
                ) ?? "N/A"
              : "N/A",
          description: "Eco Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "ecoPriceOrNA"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "wheelAndTirePriceOrNA",
          label: "wheelAndTire Or NA",
          value: (stateAccess: StateAccess): string =>
            stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "totalWheelAndTirePrice"])
              ? formatNumberAsCurrency(
                  stateAccess.get<Deal>([
                    "data",
                    "info",
                    "aftermarketOptions",
                    "totalWheelAndTirePrice"
                  ])
                ) ?? "N/A"
              : "N/A",
          description: "wheelAndTire Insurance price or N/A",
          path: ["data", "info", "aftermarketOptions", "wheelAndTirePriceOrNA"],
          width: "hidden"
        },
        {
          formComponent: "virtual-field",
          name: "serviceWarrantyTotalCost",
          label: "Service warranty total cost",
          value: (stateAccess: StateAccess): string =>
            formatNumberAsCurrency(
              (stateAccess.get<Deal>([
                "data",
                "info",
                "aftermarketOptions",
                "insurances"
              ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.reduce(
                (sum, insurance) =>
                  insurance?.chosenRate?.insuranceType === "service warranty"
                    ? sum + (insurance?.chosenRate?.dealerCostPrice || 0)
                    : sum,
                0
              )
            ) ?? "0.00",
          description: "Sum of all the 'service warranty' dealerCostPrices",
          path: ["data", "info", "aftermarketOptions", "serviceWarrantyTotalCost"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "GAPTotalCost",
          label: "GAP total cost",
          value: (stateAccess: StateAccess): string =>
            formatNumberAsCurrency(
              (stateAccess.get<Deal>([
                "data",
                "info",
                "aftermarketOptions",
                "insurances"
              ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.reduce(
                (sum, insurance) =>
                  insurance?.chosenRate?.insuranceType === "gap"
                    ? sum + (insurance?.chosenRate?.dealerCostPrice || 0)
                    : sum,
                0
              )
            ) ?? "0.00",
          description: "Sum of all the 'GAP' dealerCostPrices",
          path: ["data", "info", "aftermarketOptions", "GAPTotalCost"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "asPerServiceContract",
          label: "as per Service Contract",
          value: (stateAccess: StateAccess): string =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "service warranty"
            )
              ? "as per Service Contract"
              : "N/A",
          description: "displays text: as per Service Contract if there is VSC insurance if no N/A",
          path: ["data", "info", "aftermarketOptions", "asPerServiceContract"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "asPerGapContract",
          label: "as per Gap Contract",
          value: (stateAccess: StateAccess): string =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "gap"
            )
              ? "as per Gap Contract"
              : "N/A",
          description: "displays text: as per Gap Contract if there is GAP insurance if no N/A",
          path: ["data", "info", "aftermarketOptions", "asPerGapContract"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "isGapSold?",
          label: "X is Gap Sold?",
          value: (stateAccess: StateAccess): boolean =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "gap"
            ) !== undefined,
          description: "displays X if there is a GAP",
          path: ["data", "info", "aftermarketOptions", "isGapSold"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "gapOrNA",
          label: "GAP or N/A",
          value: (stateAccess: StateAccess): string =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "gap"
            ) === undefined
              ? "N/A"
              : "GAP",
          description: "displays text GAP if there is a GAP or N/A",
          path: ["data", "info", "aftermarketOptions", "gapOrNA"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "serviceWarrantyOrNA",
          label: "serviceWarranty or N/A",
          value: (stateAccess: StateAccess): string =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "service warranty"
            ) === undefined
              ? "N/A"
              : "Service warranty",
          description: "displays text GAP if there is a Service Warranty or N/A",
          path: ["data", "info", "aftermarketOptions", "serviceWarrantyOrNA"],
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "isVSCSold?",
          label: "X is VSC Sold?",
          value: (stateAccess: StateAccess): boolean =>
            (stateAccess.get<Deal>([
              "data",
              "info",
              "aftermarketOptions",
              "insurances"
            ]) as Deal["data"]["info"]["aftermarketOptions"]["insurances"])?.find(
              (insurance) => insurance?.chosenRate?.insuranceType === "service warranty"
            ) !== undefined,
          description: "displays X if there is a VSC",
          path: ["data", "info", "aftermarketOptions", "isVSCSold"],
          default: null
        }
      ]
    }
  ]
};

export const detailedFees: FormComponent<Deal> = {
  formComponent: "segment",
  width: "full",
  name: "Detailed Fees",
  entities: [
    { name: "registrationTax", label: "Registration Tax" },
    { name: "plateFee", label: "Plate Fee" },
    { name: "tempPlateFee", label: "Temp Plate Fee" },
    { name: "wheelageTax", label: "Wheelage Tax" },
    { name: "psVehicleFee", label: "Public Safety Vehicle Fee" },
    { name: "transferTax", label: "Transfer Tax" },
    { name: "titleTransferFee", label: "Title/Transfer Fee" },
    { name: "techSurchargeFee", label: "Tech Surcharge Fee" },
    { name: "lienFee", label: "Lien Fee" },
    { name: "deputyFilingFee", label: "State Deputy Filing Fee" },
    { name: "exciseTax", label: "Excise Tax" },
    { name: "licenseAndRegFee", label: "License and Reg Fee" },
    { name: "processingFee", visualLabel: "Processing Fee", label: "Processing Fee (WI)" },
    { name: "contributionFee", visualLabel: "FL Doc Stamp Fee", label: "FL Doc Stamp Fee" }
  ].flatMap((x: { name: string; label: string; visualLabel?: string }) => [
    {
      formComponent: "currency-field",
      name: x.name,
      label: x.label,
      width: "1/2",
      path: ["data", "info", "taxesAndFees", "detailed", x.name],
      default: null
    },

    {
      formComponent: "virtual-field",
      name: `${x.name}Label`,
      label: `${x.label} Label`,
      value: () => x.visualLabel ?? x.label,
      description: `displays text: ${x.label}`,
      path: ["data", "info", "taxesAndFees", "detailed", x.name + "Label"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: `${x.name}NA`,
      label: `${x.label} or N/A`,
      value: (stateAccess: StateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", x.name] as Path<Deal>)
        ) ?? "N/A",
      description: `displays ${x.label} or N/A`,
      path: ["data", "info", "taxesAndFees", "detailed", x.name + "NA"],
      width: "hidden"
    }
  ]) as FormComponent<Deal>[]
};
const fees: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Fees",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "1/4",
      entities: [
        {
          formComponent: "currency-field",
          name: "titleTransfer",
          label: "Title/Transfer",
          width: "3/4",
          path: ["data", "info", "taxesAndFees", "titleTransfer"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          width: "1/4",
          component: (
            stateAccess: StateAccess,
            mainStateAccess: StateAccess,
            renderSet: RenderSet
          ) => <FeesModalPreview stateAccess={stateAccess} renderSet={renderSet} />,
          struct: detailedFees,
          path: [] as any,
          name: "detailedFees",
          default: null
        }
      ]
    },

    {
      formComponent: "virtual-field",
      name: "titleTransferOrNA",
      label: "TitleTransfer or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"])
        ) ?? "N/A",
      description: "displays titleTransfer amount  or N/A",
      path: ["data", "info", "taxesAndFees", "titleTransferOrNA"],
      width: "hidden"
    },

    {
      formComponent: "virtual-field",
      name: "titleTransferWithoutFLDocStampFeeOrNA",
      label: "TitleTransfer without FL DOC Stamp Fee (Contribution Fee) or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"]) || 0) -
            (stateAccess.get<Deal>([
              "data",
              "info",
              "taxesAndFees",
              "detailed",
              "contributionFee"
            ]) || 0)
        ) ?? "N/A",
      description:
        "displays titleTransfer amount without fl doc stamp fee (contribution fee)  or N/A",
      path: ["data", "info", "taxesAndFees", "titleTransferWithoutContributionFeeOrNA"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "flDocStampFeeOrNA",
      label: "FL DOC Stamp Fee (Contribution Fee) or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "detailed", "contributionFee"])
        ) ?? "N/A",
      description: "displays fl doc stamp fee (contribution fee)  or N/A",
      path: ["data", "info", "taxesAndFees", "contributionFeeOrNA"],
      width: "hidden"
    },

    {
      formComponent: "virtual-field",
      name: "titleTransferPlusDealerFeesOrNA",
      label: "TitleTransfer + Dealer Fees or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"]) || 0) +
            (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "otherFees"]) || 0)
        ) ?? "N/A",
      description: "displays titleTransfer amount plus Dealer Fees or N/A",
      path: ["data", "info", "taxesAndFees", "titleTransferPlusOtherFeesOrNA"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "TotalFeesForMV1WI",
      label: "TotalFeesForMV1WI",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);

        const titleTransferFee = new Big(
          deal?.data?.info?.taxesAndFees?.detailed?.titleTransferFee || 0
        );
        const lienFee = new Big(deal?.data?.info?.taxesAndFees?.detailed?.lienFee || 0);
        const plateFee = new Big(deal?.data?.info?.taxesAndFees?.detailed?.plateFee || 0);
        const techSurchargeFee = new Big(
          deal?.data?.info?.taxesAndFees?.detailed?.techSurchargeFee || 0
        );
        const wheelageTax = new Big(deal?.data?.info?.taxesAndFees?.detailed?.wheelageTax || 0);
        const tempPlateFee = new Big(deal?.data?.info?.taxesAndFees?.detailed?.tempPlateFee || 0);
        const processingFee = new Big(deal?.data?.info?.taxesAndFees?.detailed?.processingFee || 0);
        const totalTaxes = new Big(deal?.data?.info?.payment?.totalTaxes || 0);
        return (
          formatNumberAsCurrency(
            titleTransferFee
              .add(lienFee)
              .add(plateFee)
              .add(techSurchargeFee)
              .add(wheelageTax)
              .add(tempPlateFee)
              .add(processingFee)
              .add(totalTaxes)
              .toNumber()
          ) || "0.00"
        );
      },
      description:
        "displays the amount:  titleTransferFee + lienFee + plateFee + techSurchargeFee + wheelageTax + tempPlateFee + processingFee + totalTaxes",
      path: ["data", "info", "taxesAndFees", "TotalFeesForMV1WI"],
      width: "hidden"
    },

    {
      formComponent: "virtual-field",
      name: "TotalFeesForMOTitle",
      label: "TotalFeesForMOTitle",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get([]);

        const licenseAndRegFee = new Big(
          deal?.data?.info?.taxesAndFees?.detailed?.licenseAndRegFee || 0
        );

        const transferTax = new Big(deal?.data?.info?.taxesAndFees?.detailed?.transferTax || 0);
        const localTaxes = new Big(deal?.data?.info?.payment.localTaxes || 0);
        const stateTaxes = new Big(deal?.data?.info?.payment.stateTaxes || 0);

        return (
          formatNumberAsCurrency(
            licenseAndRegFee.add(transferTax).add(localTaxes).add(stateTaxes).toNumber()
          ) || "0.00"
        );
      },
      description:
        "displays the amount:  licenseAndRegFee + transferTax + localTaxes + stateTaxes ",
      path: ["data", "info", "taxesAndFees", "TotalFeesForMOTitle"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "titleTransferOrMinusDeputyFees",
      label: "TitleTransfer - Deputy fees",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          (stateAccess.get<Deal>(["data", "info", "taxesAndFees", "titleTransfer"]) || 0) -
            (stateAccess.get<Deal>([
              "data",
              "info",
              "taxesAndFees",
              "detailed",
              "deputyFilingFee"
            ]) || 0)
        ) || "0.00",
      description: "displays titleTransfer amount  or N/A",
      path: ["data", "info", "taxesAndFees", "titleTransferOrMinusDeputyFees"],
      width: "hidden"
    },
    {
      formComponent: "currency-field",
      name: "documentRegistrationFees",
      label: "Doc",
      width: "1/4",
      path: ["data", "info", "taxesAndFees", "documentRegistrationFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Doc/Reg Fees or N/a",
      label: "Doc/Reg Fees or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "documentRegistrationFees"])
        ) ?? "N/A",
      description: "displays documentRegistrationFees amount  or N/A",
      path: ["data", "info", "taxesAndFees", "documentRegistrationFeesOrNA"],
      width: "hidden"
    },
    {
      formComponent: "currency-field",
      name: "filingFees",
      label: "Filing Fees",
      width: "1/4",
      path: ["data", "info", "taxesAndFees", "filingFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "filingFeeOrNA",
      label: "Filing Fee or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "filingFees"])
        ) ?? "N/A",
      description: "displays Filing Fee amount  or N/A",
      path: ["data", "info", "taxesAndFees", "filingFeesOrNA"],
      width: "hidden"
    },
    {
      formComponent: "currency-field",
      name: "dealerFees",
      label: "Dealer Fees",
      width: "1/4",
      path: ["data", "info", "taxesAndFees", "otherFees"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "dealerFeesOrNA",
      label: "Dealer fees or N/A",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Deal>(["data", "info", "taxesAndFees", "otherFees"])
        ) ?? "N/A",
      description: "displays Other fees amount  or N/A",
      path: ["data", "info", "taxesAndFees", "otherFeesOrNA"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "titleTransfer",
      label: "Title/Transfer label",
      value: () => "Title/Transfer",
      description: "displays text: Title/Transfer",
      path: ["data", "info", "taxesAndFees", "titleTransferFeesName"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "flDocStampFeeLabel",
      label: "FL Doc Stamp Fee label",
      value: () => "FL Doc Stamp Fee",
      description: "displays text: FL Doc Stamp Fee",
      path: ["data", "info", "taxesAndFees", "flDocStampFeeName"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "License",
      label: "License label",
      value: () => "License Fees",
      description: "displays text: License Fees",
      path: ["data", "info", "taxesAndFees", "licenseFeesName"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "Doc/Reg Fees",
      label: "Document/RegistrationFees label",
      value: () => "Document/Registration Fees",
      description: "displays text: Document/Registration Fees",
      path: ["data", "info", "taxesAndFees", "documentRegistrationFeesName"],
      width: "hidden"
    },

    {
      formComponent: "virtual-field",
      name: "filingFees",
      label: "Filing Fees label",
      value: () => "Filing Fees",
      description: "displays text: Filing Fees",
      path: ["data", "info", "taxesAndFees", "filingFeesName"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "DealerFees",
      label: "Dealer Fees label",
      value: () => "Dealer Fees",
      description: "displays text: Dealer Fees",
      path: ["data", "info", "taxesAndFees", "otherFeesName"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "otherFeesNameOrNA",
      label: "Dealer fees text or N/A",
      value: (stateAccess) =>
        stateAccess.get<Deal>(["data", "info", "taxesAndFees", "otherFees"])
          ? "Dealer Fees"
          : "N/A",
      description: "displays Other fees text  or N/A depending on the value",
      path: ["data", "info", "taxesAndFees", "otherFeesNameOrNA"],
      width: "hidden"
    }
  ]
};

const stateTaxes: FormComponent<Deal> = {
  formComponent: "segment",
  width: "1/2",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
        <AccessControl requiredPermissions={{ entity: "state", action: "read" }}>
          <TaxesPreview stateAccess={stateAccess} renderSet={renderSet} />
        </AccessControl>
      ),
      required: true,
      name: "stateTaxes",
      width: "full",
      path: null,
      default: null,
      struct: detailedTaxes
    }
  ]
};
const taxes: FormComponent<Deal> = {
  formComponent: "segment",
  name: "Taxes",
  width: "full",
  entities: [
    {
      formComponent: "checkbox-field",
      path: ["data", "info", "taxesAndFees", "isFixedTax"],
      name: "isFixedTax",
      label: "Fixed tax?",
      width: "1/2",
      default: false,
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<Deal>(["data", "info", "taxesAndFees", "isFixedTax"]),
      toggle: (stateAccess: StateAccess) => {
        const deal = stateAccess.get<Deal>([] as any);
        stateAccess.set<Deal>(["data", "info", "taxesAndFees"], {
          ...deal?.data?.info?.taxesAndFees,
          isFixedTax: !deal?.data?.info?.taxesAndFees?.isFixedTax
        });
      }
    },
    stateTaxes
  ]
};

export const profitsSegment: (
  background?: string,
  withInsurances?: boolean
) => FormComponent<Deal> = (background, withInsurances) => ({
  formComponent: "segment",
  name: "Profits",
  elevation: 2,
  style: {
    // @ts-ignore
    background: background ?? "rgb(229 194 164)",
    filter: "invert(1)",
    padding: "0px 10px"
  },
  titleStyle: { color: "black" },
  width: "full",
  entities: [
    {
      formComponent: "currency-field",
      name: "buyPercent",
      label: "Buy rate",
      width: "1/2",
      path: ["data", "info", "payment", "buyPercent"],
      show: (stateAccess) =>
        [
          ReserveMethod[
            "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %"
          ],
          ReserveMethod["markup"],
          ReserveMethod["markup foursight"]
        ].includes(
          stateAccess.get<Deal>(["data", "info", "payment", "reserveMethod"])
        ),
      default: null
    },
    {
      formComponent: "read-only-number-field",
      name: "sellPercent",
      label: "Sell rate",
      width: "1/2",
      path: ["data", "info", "payment", "interestRate"],
      show: (stateAccess) =>
        [
          ReserveMethod[
            "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %"
          ],
          ReserveMethod["markup"],
          ReserveMethod["markup foursight"]
        ].includes(
          stateAccess.get<Deal>(["data", "info", "payment", "reserveMethod"])
        ),
      default: null
    },

    {
      formComponent: "virtual-field",
      name: "GrossProfit",
      label: "Gross profit",
      value: (stateAccess) => formatNumberAsCurrency(totalGrossProfit(stateAccess)) ?? "0.00",
      description: "Gross profit",
      path: ["data", "info", "profit", "grossProfit"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "25PercentOfTotalGrossProfit",
      label: "25% Of Total Gross profit",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          new Big(totalGrossProfit(stateAccess)).mul(25).div(100).toNumber()
        ) ?? "0.00",
      description: "25% Of Total Gross profit",
      path: ["data", "info", "profit", "grossProfit25Percent"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "35PercentOfTotalGrossProfit",
      label: "35% Of Total Gross profit",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          new Big(totalGrossProfit(stateAccess)).mul(35).div(100).toNumber()
        ) ?? "0.00",
      description: "35% Of Total Gross profit",
      path: ["data", "info", "profit", "grossProfit35Percent"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "45PercentOfTotalGrossProfit",
      label: "45% Of Total Gross profit",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          new Big(totalGrossProfit(stateAccess)).mul(45).div(100).toNumber()
        ) ?? "0.00",
      description: "45% Of Total Gross profit",
      path: ["data", "info", "profit", "grossProfit45Percent"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "dealerNet",
      label: "Dealer Net",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);
        const insurances = deal?.data?.info?.aftermarketOptions?.insurances ?? [];
        if (insurances.length === 0)
          return formatNumberAsCurrency(deal.data.info.payment.dealTotal ?? 0) ?? "0.00";
        const soldByWfdInsurancesSellPricesSum = insurances
          ?.reduce(
            (acc, insurance) =>
              acc.add(
                insurance?.chosenRate?.soldBy === "WFD"
                  ? insurance?.chosenRate?.retailPrice || 0
                  : 0
              ),
            new Big(0)
          )
          .toNumber();
        return (
          formatNumberAsCurrency(
            new Big(deal.data.info.payment.dealTotal ?? 0)
              .sub(soldByWfdInsurancesSellPricesSum)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "Dealer Net",
      path: ["data", "info", "profit", "dealerNet"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "dealerNetPlusAcquisitionFee",
      label: "Dealer Net + AcquisitionFee",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);
        const insurances = deal?.data?.info?.aftermarketOptions?.insurances ?? [];

        const soldByWfdInsurancesSellPricesSum = insurances
          ?.reduce(
            (acc, insurance) =>
              acc.add(
                insurance?.chosenRate?.soldBy === "WFD"
                  ? insurance?.chosenRate?.retailPrice || 0
                  : 0
              ),
            new Big(0)
          )
          .toNumber();
        return (
          formatNumberAsCurrency(
            new Big(deal.data.info.payment.dealTotal ?? 0)
              .sub(deal?.data?.info?.taxesAndFees?.lenderAcquisitionFee || 0)
              .sub(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
              .sub(deal?.data?.info?.taxesAndFees?.wfdMinimumFee || 0)
              .sub(soldByWfdInsurancesSellPricesSum)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "Dealer Net plus acquisition fee",
      path: ["data", "info", "profit", "dealerNetPlusAcquisitionFee"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "dealerNetPlusDealerProfitPlusAcquisitionFee",
      label: "Dealer Net + Dealer Profit + Lender AcquisitionFee",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);
        const insurances = deal?.data?.info?.aftermarketOptions?.insurances ?? [];

        const soldByWfdInsurancesSellPricesSum = insurances
          ?.reduce(
            (acc, insurance) =>
              acc.add(
                insurance?.chosenRate?.soldBy === "WFD"
                  ? insurance?.chosenRate?.retailPrice || 0
                  : 0
              ),
            new Big(0)
          )
          .toNumber();
        return (
          formatNumberAsCurrency(
            new Big(deal.data.info.payment.dealTotal ?? 0)
              .add(deal?.data?.info?.profit?.dealershipProfit?.totalProfit || 0)
              .sub(deal?.data?.info?.taxesAndFees?.lenderAcquisitionFee || 0)
              .sub(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
              .sub(soldByWfdInsurancesSellPricesSum)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "Dealer Net plus acquisition fee plus dealert total profit",
      path: ["data", "info", "profit", "dealerNetPlusDealerProfitPlusAcquisitionFee"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "LenderAcquisitionFeePlusWfdAcquisitionFee",
      label: "LenderAcquisitionFee + wfdAcquisitionFee",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);

        return (
          formatNumberAsCurrency(
            new Big(deal?.data?.info?.taxesAndFees?.lenderAcquisitionFee || 0)
              .add(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "lender acquisition fees plus WFD Acquisition Fee ",
      path: ["data", "info", "profit", "lenderAcquisitionFeePlusWfdAcquisitionFee"],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "LenderAcquisitionFeePlusWfdAcquisitionFeePlusMinumumWFDFee",
      label: "LenderAcquisitionFee + wfdAcquisitionFee + minumum wfd fee",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);

        return (
          formatNumberAsCurrency(
            new Big(deal?.data?.info?.taxesAndFees?.lenderAcquisitionFee || 0)
              .add(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
              .add(deal?.data?.info?.taxesAndFees?.wfdMinimumFee || 0)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "lender acquisition fees plus WFD Acquisition Fee plus wfd minimum fee ",
      path: [
        "data",
        "info",
        "profit",
        "LenderAcquisitionFeePlusWfdAcquisitionFeePlusMinumumWFDFee"
      ],
      width: "hidden"
    },
    {
      formComponent: "virtual-field",
      name: "dealerNetPlusDealerProfitPlusAcquisitionFeePlusWfdAcquisitionFee",
      label: "Dealer Net + Dealer Profit + LenderAcquisitionFee + wfdAcquisitionFee",
      value: (stateAccess) => {
        const deal: Deal = stateAccess.get<Deal>([]);
        const insurances = deal?.data?.info?.aftermarketOptions?.insurances ?? [];

        const soldByWfdInsurancesSellPricesSum = insurances
          ?.reduce(
            (acc, insurance) =>
              acc.add(
                insurance?.chosenRate?.soldBy === "WFD"
                  ? insurance?.chosenRate?.retailPrice || 0
                  : 0
              ),
            new Big(0)
          )
          .toNumber();
        return (
          formatNumberAsCurrency(
            new Big(deal.data.info.payment.dealTotal ?? 0)
              .add(deal?.data?.info?.profit?.dealershipProfit?.totalProfit || 0)
              .add(deal?.data?.info?.taxesAndFees?.lenderAcquisitionFee || 0)
              .add(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
              .sub(soldByWfdInsurancesSellPricesSum)
              .toNumber()
          ) ?? "0.00"
        );
      },
      description: "Dealer Net plus wfd/lender acquisition fees plus dealert total profit",
      path: [
        "data",
        "info",
        "profit",
        "dealerNetPlusDealerProfitPlusLenderAcquisitionFeeWfdAcquisitionFee"
      ],
      width: "hidden"
    },

    {
      formComponent: "segment",
      width: "full",
      name: "Dealership",
      titleStyle: { color: "black" },
      show: (stateAccess) =>
        getDealershipProgramByDeal(stateAccess.get<Deal>([]))?.data?.info?.name !==
        "Performance Program",
      entities: [
        {
          formComponent: "segment",
          width: "full",
          titleStyle: { color: "black" },

          entities: [
            {
              formComponent: "radio-field",
              name: "splitMethod",
              label: "Split method",
              width: "full",
              path: ["data", "info", "payment", "splitMethod"],
              valueToString: (value) => {
                switch (value) {
                  case "fulltimeF&I":
                    return "Full time F&I";
                  case "correspondent":
                    return "Correspondent";
                  default:
                    return "";
                }
              },
              possibleValues: [
                { label: "Full time F&I", value: "fulltimeF&I" },
                { label: "Correspondent", value: "correspondent" }
              ],
              default: "fulltimeF&I"
            }
          ]
        },

        {
          formComponent: "select-field",
          width: "2/3",
          options: createOptionsForSelect({
            possibleValues: (stateAccess) => {
              const deal: Deal = stateAccess.get([]);
              const foursightCapitalId = "6633c9970af34ee7410017e7";
              if (
                deal?.data?.lenderId === foursightCapitalId ||
                deal?.data?.lender?._id === foursightCapitalId
              ) {
                return [...methods];
              }
              return [...methods].filter((x) => x !== "markup foursight");
            },
            getOptionLabel: (x) => {
              switch (x) {
                case "fixed":
                  return "1 - Flat dollar amount per deal";
                case "percentage":
                  return `2 - Flat % of the total financed amount`;
                case "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %":
                  return "3 - Diffeence between buy / sell rate * total amount financed then / sell rate and then * reserve %";
                case "markup":
                  return "4 - Markup";
                case "markup foursight":
                  return "5 - Markup Foursight";
                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          name: "method",
          label: "Calc. method",
          path: ["data", "info", "payment", "reserveMethod"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => (
            <ReservePreview
              section={["payment"]}
              stateAccess={stateAccess}
              renderSet={renderSet}
              permissions={permissions}
            />
          ),
          path: null,
          width: "1/3",
          struct: reserveStruct(),
          name: "reservePreview"
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "payment", "wfdSplit"],
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "payment", "dealershipSplit"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) === "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "correspondentPercent",
              label: "Correspondent Percent",
              width: "full",
              path: ["data", "info", "payment", "correspondentPercent"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "2/3",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "read-only-number-field",
              name: "reserveCommission",
              label: "Total Reserve",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "reserveCommission"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "WFD Reserve Split",
              label: "WFD Reserve Split",
              width: "1/3",
              path: ["data", "info", "profit", "wfdProfit", "splitFromDeal"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "Dealer Reserve Split",
              label: "Dealer Reserve Split",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "splitFromDeal"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipGAPSplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "payment", "wfdGAPSplit"],
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipGAPSplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "payment", "dealershipGAPSplit"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent"
              ? "2/3"
              : "full",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "read-only-number-field",
              name: "GAP Profit",
              label: "GAP Profit",
              width: "1/3",
              path: ["data", "info", "profit", "totalGAPProfit"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "GAP Profit or N/A",
              label: "GAP Profit or N/A",
              width: "1/3",
              value: (stateAccess) => {
                return (
                  formatNumberAsCurrency(
                    stateAccess.get(["data", "info", "profit", "totalGAPProfit"]) || undefined
                  ) ?? "N/A"
                );
              },
              description: "",
              path: ["data", "info", "profit", "totalGapProfitOrNA"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "WFD GAP Split",
              label: "WFD GAP Split",
              width: "1/3",
              path: ["data", "info", "profit", "wfdProfit", "splitTotalFromGap"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "Dealer GAP Split",
              label: "Dealer GAP Split",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "splitTotalFromGap"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipWarrantySplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "payment", "wfdWarrantySplit"],
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipWarrantySplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "payment", "dealershipWarrantySplit"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent"
              ? "2/3"
              : "full",
          entities: [
            {
              formComponent: "read-only-number-field",
              name: "Warranty Profit",
              label: "Warranty Profit",
              width: "1/3",
              path: ["data", "info", "profit", "totalServiceWarrantyProfit"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "Warranty Profit or N/A",
              label: "Warranty Profit or N/A",
              width: "1/3",
              value: (stateAccess) => {
                return (
                  formatNumberAsCurrency(
                    stateAccess.get(["data", "info", "profit", "totalServiceWarrantyProfit"]) ||
                      undefined
                  ) ?? "N/A"
                );
              },
              description: "",
              path: ["data", "info", "profit", "totalServiceWarrantyProfitOrNA"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "WFD Warranty Split",
              label: "WFD Warranty Split",
              width: "1/3",
              path: ["data", "info", "profit", "wfdProfit", "splitTotalFromServiceWarranty"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "Dealer Warranty  Split",
              label: "Dealer Warranty  Split",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "splitTotalFromServiceWarranty"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "full",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "read-only-number-field",
              name: "Total Fees",
              label: "Total Fees",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "totalProfitFees"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "Total Deal Profit",
              label: "Total Deal Profit",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "totalDealProfit"],
              default: null
            },
            {
              formComponent: "read-only-number-field",
              name: "Total Profit",
              label: "Total Dealership Profit",
              width: "1/3",
              path: ["data", "info", "profit", "dealershipProfit", "totalProfit"],
              default: null
            },
            {
              formComponent: "virtual-field",
              name: "TotalProfitWFD",
              label: "Total Profit WFD",
              value: (stateAccess) => {
                return (
                  formatNumberAsCurrency(
                    totalGrossProfit(stateAccess) -
                      (stateAccess.get<Deal>([
                        "data",
                        "info",
                        "profit",
                        "dealershipProfit",
                        "totalProfit"
                      ]) || 0)
                  ) ?? "0.00"
                );
              },
              description: "WFD total profit as viewed by dealership",
              path: ["data", "info", "profit", "dealershipProfit", "totalProfitWFD"],
              width: "hidden"
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "Dealership (Performance program)",
      titleStyle: { color: "black" },
      show: (stateAccess) =>
        getDealershipProgramByDeal(stateAccess.get<Deal>([]))?.data?.info?.name ===
        "Performance Program",
      entities: [
        {
          formComponent: "segment",
          width: "full",
          titleStyle: { color: "black" },

          entities: [
            {
              formComponent: "radio-field",
              name: "splitMethod",
              label: "Split method",
              width: "full",
              path: ["data", "info", "payment", "splitMethod"],
              valueToString: (value) => {
                switch (value) {
                  case "fulltimeF&I":
                    return "Full time F&I";
                  case "correspondent":
                    return "Correspondent";
                  default:
                    return "";
                }
              },
              possibleValues: [
                { label: "Full time F&I", value: "fulltimeF&I" },
                { label: "Correspondent", value: "correspondent" }
              ],
              default: "fulltimeF&I"
            }
          ]
        },

        {
          formComponent: "select-field",
          width: "2/3",
          options: createOptionsForSelect({
            possibleValues: (stateAccess) => {
              const deal: Deal = stateAccess.get([]);
              const foursightCapitalId = "6633c9970af34ee7410017e7";
              if (
                deal?.data?.lenderId === foursightCapitalId ||
                deal?.data?.lender?._id === foursightCapitalId
              ) {
                return [...methods];
              }
              return [...methods].filter((x) => x !== "markup foursight");
            },
            getOptionLabel: (x) => {
              switch (x) {
                case "fixed":
                  return "1 - Flat dollar amount per deal";
                case "percentage":
                  return `2 - Flat % of the total financed amount`;
                case "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %":
                  return "3 - Diffeence between buy / sell rate * total amount financed then / sell rate and then * reserve %";
                case "markup":
                  return "4 - Markup";
                case "markup foursight":
                  return "5 - Markup Foursight";
                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          name: "method",
          label: "Calc. method",
          path: ["data", "info", "payment", "reserveMethod"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => (
            <ReservePreview
              section={["payment"]}
              stateAccess={stateAccess}
              renderSet={renderSet}
              permissions={permissions}
            />
          ),
          path: null,
          width: "1/3",
          struct: reserveStruct(),
          name: "reservePreview"
        },

        {
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => (
            // <AccessControl requiredPermissions={{ entity: "state", action: "read" }}>
            <DealershipProfitPreview stateAccess={stateAccess} />
            // </AccessControl>
          ),
          name: "dealershipProfitPreview",
          width: "full",
          path: null,
          show: (stateAccess) =>
            stateAccess.get<Deal>(["data", "dealership", "_id"]) &&
            getDealershipProgramByDeal(stateAccess.get<Deal>([]))?.data?.info?.name ===
              "Performance Program"
        }
      ]
    },

    {
      formComponent: "segment",
      width: "full",
      name: "WFD",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "select-field",
          width: "1/2",
          options: createOptionsForSelect({
            possibleValues: (stateAccess) => {
              const deal: Deal = stateAccess.get<Deal>([]);
              if (deal?.data?.info?.payment?.reserveMethod === ReserveMethod["markup foursight"]) {
                return ["fixed"];
              }
              return ["fixed", "percentage", "min(<interest>/2,2.25)"];
            },
            getOptionLabel: (x) => {
              switch (x) {
                case "fixed":
                  return "Flat dollar amount per deal";
                case "percentage":
                  return `% of the total financed amount`;
                case "min(<interest>/2,2.25)":
                  return "reserve is 1/2 the interest rate with max payout of 2.25%";

                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          name: "reserveMethod",
          label: "Extra reserve method",
          path: ["data", "info", "profit", "wfdProfit", "reserveMethodType"],
          default: "fixed"
        },
        {
          formComponent: "percentage-field",
          name: "Extra Reserve",
          label: "Extra Reserve %",
          width: "1/4",
          show: (stateAccess) => {
            const deal: Deal = stateAccess.get<Deal>([]);
            if (deal?.data?.info?.payment?.reserveMethod === ReserveMethod["markup foursight"]) {
              return false;
            }
            return ["percentage", "min(<interest>/2,2.25)"].includes(
              deal?.data?.info?.profit?.wfdProfit?.reserveMethodType as string
            );
          },
          path: ["data", "info", "profit", "wfdProfit", "reserverPercentage"],
          default: null
        },
        {
          formComponent: "read-only-field",
          name: "Extra Reserve",
          label: "Extra Reserve",
          width: "1/4",
          path: ["data", "info", "profit", "wfdProfit", "extraReserveProfit"],
          default: null,
          show: (stateAccess) =>
            ["percentage", "min(<interest>/2,2.25)"].includes(
              stateAccess.get<Deal>(["data", "info", "profit", "wfdProfit", "reserveMethodType"])
            )
        },
        {
          formComponent: "currency-field",
          name: "Extra Reserve",
          label: "Extra Reserve",
          width: "1/2",
          path: ["data", "info", "profit", "wfdProfit", "extraReserveProfit"],
          default: null,
          show: (stateAccess) =>
            !["percentage", "min(<interest>/2,2.25)"].includes(
              stateAccess.get<Deal>(["data", "info", "profit", "wfdProfit", "reserveMethodType"])
            )
        },

        {
          formComponent: "currency-field",
          name: "Extra Gap Commission",
          label: "Extra Gap Commission",
          width: "1/3",
          path: ["data", "info", "profit", "wfdProfit", "extraGAPProfit"],
          default: null
        },
        {
          formComponent: "currency-field",
          name: "Extra Warranty Commission",
          label: "Extra Warranty Commission",
          width: "1/3",
          path: ["data", "info", "profit", "wfdProfit", "extraServiceWarrantyProfit"],
          default: null
        },

        {
          formComponent: "read-only-number-field",
          name: "Total WFD Profit",
          label: "Total WFD Profit",
          width: "1/3",
          path: ["data", "info", "profit", "wfdProfit", "totalProfit"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "WFD Fees",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "currency-field",
          name: "acquisitionFee",
          label: "WFD Acquisition fee",
          width: "1/2",
          path: ["data", "info", "taxesAndFees", "acquisitionFee"],
          default: null
        },
        {
          formComponent: "currency-field",
          name: "mininumFee",
          label: "WFD mininum fee",
          width: "1/2",
          path: ["data", "info", "taxesAndFees", "wfdMinimumFee"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "Lender Fees",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "currency-field",
          name: "acquisitionFee",
          label: "Lender Acquisition fee",
          width: "1/3",
          path: ["data", "info", "taxesAndFees", "lenderAcquisitionFee"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "uccFee",
          label: "UCC fee",
          width: "1/3",
          path: ["data", "info", "taxesAndFees", "totalUCCFees"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "membershipFee",
          label: "Membership fee",
          width: "1/3",
          path: ["data", "info", "taxesAndFees", "totalMembershipFees"],
          default: null
        }
      ]
    },
    ...(withInsurances
      ? [
          {
            formComponent: "segment",
            width: "full",
            titleStyle: { color: "black" },
            name: "Insurance",
            entities: [
              {
                formComponent: "read-only-number-field",
                name: "Warranty Remit Amount",
                label: "Warranty Remit Amount",
                path: ["data", "info", "aftermarketOptions", "totalServiceWarrantyRemitPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Warranty Cost (dealer’s cost)",
                label: "Warranty Cost (dealer’s cost)",
                path: ["data", "info", "aftermarketOptions", "totalServiceWarrantyDealerCostPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Warranty Selling Price",
                label: "Warranty Selling Price",
                path: ["data", "info", "aftermarketOptions", "totalServiceWarrantySellPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "GAP Remit Amount",
                label: "GAP Remit Amount",
                path: ["data", "info", "aftermarketOptions", "totalGAPRemitPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "GAP Cost (dealer’s cost)",
                label: "GAP Cost (dealer’s cost)",
                path: ["data", "info", "aftermarketOptions", "totalGAPDealerCostPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "GAP Selling Price",
                label: "GAP Selling Price",
                path: ["data", "info", "aftermarketOptions", "totalGAPSellPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Eco Total Price",
                label: "Eco Total Price",
                path: ["data", "info", "aftermarketOptions", "totalEcoPrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Wheel and Tire Total Price",
                label: "Wheel and Tire Total Price",
                path: ["data", "info", "aftermarketOptions", "totalWheelAndTirePrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Pre-paid Maintenance Total Price",
                label: "Pre-paid Maintenance Total Price",
                path: ["data", "info", "aftermarketOptions", "totalPrePaidMaintenancePrice"],
                default: null,
                width: "1/3"
              },
              {
                formComponent: "read-only-number-field",
                name: "Paint and Fabrication Total Price",
                label: "Paint and Fabrication Total Price",
                path: ["data", "info", "aftermarketOptions", "totalPaintAndFabricationPrice"],
                default: null,
                width: "1/3"
              }
            ]
          } as FormComponent<Deal>
        ]
      : []),

    commissions,
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => (
        <CalculateReservesButton stateAccess={stateAccess} />
      ),
      path: null,
      name: "MaxReserve",
      label: "MaxReserve",
      width: "full"
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, _mainStateAccess, renderSet) => (
        <MaxReservePreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "info", "payment", "maxReserve"]}
        />
      ),
      path: null,
      name: "calculateReserves",
      label: "calculateReserves",
      width: "full"
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => <PrintRecapSheet stateAccess={stateAccess} />,
      path: null,
      name: "PrintRecapSheet",
      label: "PrintRecapSheet",
      width: "full"
    }
  ]
});

export const checkAccountingProfitsForDifferences = (
  stateAccess: StateAccess,
  isNumeric: boolean,
  accountingPath: string[],
  dealFieldPath?: string[]
) => {
  const dealData = stateAccess.get<Deal>(["data", "info", ...(dealFieldPath ?? accountingPath)]);
  const accountingData = stateAccess.get<Deal>(["data", "info", "accounting", ...accountingPath]);

  const hasDifference = isNumeric
    ? parseFloat(dealData ?? 0) === parseFloat(accountingData ?? 0)
    : dealData === accountingData;
  return hasDifference ? {} : { background: "#78c5ff" };
};
export const profitsSegmentAccounting: (background?: string) => FormComponent<Deal> = (
  background
) => ({
  formComponent: "segment",
  name: "Accounting",
  elevation: 2,
  style: {
    // @ts-ignore
    background: background ?? "rgb(229 194 164)",
    filter: "invert(1)",
    padding: "0px 10px"
  },
  titleStyle: { color: "black" },
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) =>
        !stateAccess.get(["data", "info", "accounting", "copied"]) ? (
          <div
            style={{
              position: "absolute",
              background: "#ff9e9eed",
              zIndex: "2",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              fontSize: "20px",
              textAlign: "center",
              paddingTop: "50%"
            }}
          >
            Use the &quot;Copy&quot; button to enable the section.
          </div>
        ) : (
          <></>
        ),
      path: ["data", "info", "accounting", "copied"],
      name: "notCopiedBanner",
      width: "full"
    },
    {
      formComponent: "currency-field",
      name: "buyPercent",
      label: "Buy rate",
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "buyPercent"]),
      width: "1/2",
      path: ["data", "info", "accounting", "payment", "buyPercent"],
      show: (stateAccess) =>
        [
          ReserveMethod[
            "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %"
          ],
          ReserveMethod["markup"],
          ReserveMethod["markup foursight"]
        ].includes(
          stateAccess.get<Deal>(["data", "info", "accounting", "payment", "reserveMethod"])
        ),
      default: null
    },
    {
      formComponent: "currency-field",
      name: "sellPercent",
      label: "Sell rate",
      width: "1/2",
      path: ["data", "info", "accounting", "payment", "interestRate"],
      conditionalStyle: (stateAccess) =>
        checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "interestRate"]),
      show: (stateAccess) =>
        [
          ReserveMethod[
            "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %"
          ],
          ReserveMethod["markup"],
          ReserveMethod["markup foursight"]
        ].includes(
          stateAccess.get<Deal>(["data", "info", "accounting", "payment", "reserveMethod"])
        ),
      default: null
    },

    {
      formComponent: "segment",
      width: "full",
      name: "Dealership",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "segment",
          width: "full",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "radio-field",
              name: "splitMethod",
              label: "Split method",
              width: "full",
              path: ["data", "info", "accounting", "payment", "splitMethod"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, false, [
                  "payment",
                  "splitMethod"
                ]),
              valueToString: (value) => {
                switch (value) {
                  case "fulltimeF&I":
                    return "Full time F&I";
                  case "correspondent":
                    return "Correspondent";
                  default:
                    return "";
                }
              },
              possibleValues: [
                { label: "Full time F&I", value: "fulltimeF&I" },
                { label: "Correspondent", value: "correspondent" }
              ],
              default: "fulltimeF&I"
            }
          ]
        },

        {
          formComponent: "select-field",
          width: "2/3",
          options: createOptionsForSelect({
            possibleValues: (stateAccess) => {
              const deal: Deal = stateAccess.get([]);
              const foursightCapitalId = "6633c9970af34ee7410017e7";
              if (
                deal?.data?.lenderId === foursightCapitalId ||
                deal?.data?.lender?._id === foursightCapitalId
              ) {
                return [...methods];
              }
              return [...methods].filter((x) => x !== "markup foursight");
            },
            getOptionLabel: (x) => {
              switch (x) {
                case "fixed":
                  return "1 - Flat dollar amount per deal";
                case "percentage":
                  return `2 - Flat % of the total financed amount`;
                case "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %":
                  return "3 - Diffeence between buy / sell rate * total amount financed then / sell rate and then * reserve %";
                case "markup":
                  return "4 - Markup";
                case "markup foursight":
                  return "5 - Markup Foursight";
                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          name: "method",
          label: "Calc. method",
          path: ["data", "info", "accounting", "payment", "reserveMethod"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, false, ["payment", "reserveMethod"]),
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => (
            <ReservePreview
              section={["accounting", "payment"]}
              stateAccess={stateAccess}
              renderSet={renderSet}
              permissions={permissions}
            />
          ),
          path: null,
          width: "1/3",
          struct: reserveStruct(),
          name: "reservePreview"
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "accounting", "payment", "splitMethod"]) !==
            "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "dealershipSplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "payment",
                  "dealershipSplit"
                ]),
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "wfdSplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "wfdSplit"]),
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "accounting", "payment", "splitMethod"]) ===
            "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "correspondentPercent",
              label: "Correspondent Percent",
              width: "full",
              path: ["data", "info", "accounting", "payment", "correspondentPercent"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "payment",
                  "correspondentPercent"
                ]),
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "2/3",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "currency-field",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "dealershipProfit",
                  "reserveCommission"
                ]),
              name: "reserveCommission",
              label: "Total Reserve",
              width: "1/3",
              path: [
                "data",
                "info",
                "accounting",
                "profit",
                "dealershipProfit",
                "reserveCommission"
              ],
              default: null
            },
            {
              formComponent: "currency-field",
              name: "WFD Reserve Split",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "wfdProfit",
                  "splitFromDeal"
                ]),
              label: "WFD Reserve Split",
              width: "1/3",
              path: ["data", "info", "accounting", "profit", "wfdProfit", "splitFromDeal"],
              default: null
            },
            {
              formComponent: "currency-field",
              name: "Dealer Reserve Split",
              label: "Dealer Reserve Split",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "dealershipProfit",
                  "splitFromDeal"
                ]),
              width: "1/3",
              path: ["data", "info", "accounting", "profit", "dealershipProfit", "splitFromDeal"],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "accounting", "payment", "splitMethod"]) !==
            "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "dealershipGAPSplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "payment",
                  "dealershipGAPSplit"
                ]),
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "wfdGAPSplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, ["payment", "wfdGAPSplit"]),
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent"
              ? "2/3"
              : "full",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "currency-field",
              name: "GAP Profit",
              label: "GAP Profit",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "totalGAPProfit"
                ]),
              width: "1/3",
              path: ["data", "info", "accounting", "profit", "totalGAPProfit"],
              default: null
            },
            {
              formComponent: "currency-field",
              name: "WFD GAP Split",
              label: "WFD GAP Split",
              width: "1/3",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "wfdProfit",
                  "splitTotalFromGap"
                ]),
              path: ["data", "info", "accounting", "profit", "wfdProfit", "splitTotalFromGap"],
              default: null
            },
            {
              formComponent: "currency-field",
              name: "Dealer GAP Split",
              label: "Dealer GAP Split",
              width: "1/3",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "dealershipProfit",
                  "splitTotalFromGap"
                ]),
              path: [
                "data",
                "info",
                "accounting",
                "profit",
                "dealershipProfit",
                "splitTotalFromGap"
              ],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          titleStyle: { color: "black" },
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "accounting", "payment", "splitMethod"]) !==
            "correspondent",
          entities: [
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "Dealer Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "dealershipWarrantySplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "payment",
                  "dealershipWarrantySplit"
                ]),
              default: null
            },
            {
              formComponent: "percentage-field",
              name: "dealershipSplit",
              label: "WFD Split",
              width: "1/2",
              path: ["data", "info", "accounting", "payment", "wfdWarrantySplit"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "payment",
                  "wfdWarrantySplit"
                ]),
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: (stateAccess) =>
            stateAccess.get(["data", "info", "payment", "splitMethod"]) !== "correspondent"
              ? "2/3"
              : "full",
          entities: [
            {
              formComponent: "currency-field",
              name: "Warranty Profit",
              label: "Warranty Profit",
              width: "1/3",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "totalServiceWarrantyProfit"
                ]),
              path: ["data", "info", "accounting", "profit", "totalServiceWarrantyProfit"],
              default: null
            },
            {
              formComponent: "currency-field",
              name: "WFD Warranty Split",
              label: "WFD Warranty Split",
              width: "1/3",
              path: [
                "data",
                "info",
                "accounting",
                "profit",
                "wfdProfit",
                "splitTotalFromServiceWarranty"
              ],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "wfdProfit",
                  "splitTotalFromServiceWarranty"
                ]),
              default: null
            },
            {
              formComponent: "currency-field",
              name: "Dealer Warranty  Split",
              label: "Dealer Warranty  Split",
              width: "1/3",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "dealershipProfit",
                  "splitTotalFromServiceWarranty"
                ]),
              path: [
                "data",
                "info",
                "accounting",
                "profit",
                "dealershipProfit",
                "splitTotalFromServiceWarranty"
              ],
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "full",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "currency-field",
              name: "Total Profit",
              label: "Total Profit",
              width: "1/3",
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "dealershipProfit",
                  "totalProfit"
                ]),
              path: ["data", "info", "accounting", "profit", "dealershipProfit", "totalProfit"],
              default: null
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "WFD",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "select-field",
          width: "1/2",
          options: createOptionsForSelect({
            possibleValues: (stateAccess) => {
              const deal: Deal = stateAccess.get<Deal>([]);
              if (
                deal?.data?.info?.accounting?.payment?.reserveMethod ===
                ReserveMethod["markup foursight"]
              ) {
                return ["fixed"];
              }
              return ["fixed", "percentage", "min(<interest>/2,2.25)"];
            },
            getOptionLabel: (x) => {
              switch (x) {
                case "fixed":
                  return "Flat dollar amount per deal";
                case "percentage":
                  return `% of the total financed amount`;
                case "min(<interest>/2,2.25)":
                  return "reserve is 1/2 the interest rate with max payout of 2.25%";

                default:
                  return "";
              }
            },
            getSelectedOption: (x, y) => x === y
          }),
          name: "reserveMethod",
          label: "Extra reserve method",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "reserveMethodType"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, false, [
              "profit",
              "wfdProfit",
              "reserveMethodType"
            ]),
          default: "fixed"
        },
        {
          formComponent: "percentage-field",
          name: "Extra Reserve",
          label: "Extra Reserve %",
          width: "1/4",
          show: (stateAccess) => {
            const deal: Deal = stateAccess.get<Deal>([]);
            if (
              deal?.data?.info?.accounting?.payment?.reserveMethod ===
              ReserveMethod["markup foursight"]
            ) {
              return false;
            }
            return ["percentage", "min(<interest>/2,2.25)"].includes(
              deal?.data?.info?.accounting?.profit?.wfdProfit?.reserveMethodType as string
            );
          },
          path: ["data", "info", "accounting", "profit", "wfdProfit", "reserverPercentage"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "reserverPercentage"
            ]),
          default: null
        },
        {
          formComponent: "read-only-field",
          name: "Extra Reserve",
          label: "Extra Reserve",
          width: "1/4",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "extraReserveProfit"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "extraReserveProfit"
            ]),
          default: null,
          show: (stateAccess) =>
            ["percentage", "min(<interest>/2,2.25)"].includes(
              stateAccess.get<Deal>([
                "data",
                "info",
                "accounting",
                "profit",
                "wfdProfit",
                "reserveMethodType"
              ])
            )
        },
        {
          formComponent: "currency-field",
          name: "Extra Reserve",
          label: "Extra Reserve",
          width: "1/2",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "extraReserveProfit"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "extraReserveProfit"
            ]),
          default: null,
          show: (stateAccess) =>
            !["percentage", "min(<interest>/2,2.25)"].includes(
              stateAccess.get<Deal>([
                "data",
                "info",
                "accounting",
                "profit",
                "wfdProfit",
                "reserveMethodType"
              ])
            )
        },
        {
          formComponent: "currency-field",
          name: "Extra Gap Commission",
          label: "Extra Gap Commission",
          width: "1/3",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "extraGAPProfit"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "extraGAPProfit"
            ]),
          default: null
        },
        {
          formComponent: "currency-field",
          name: "Extra Warranty Commission",
          label: "Extra Warranty Commission",
          width: "1/3",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "extraServiceWarrantyProfit"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "extraServiceWarrantyProfit"
            ]),
          default: null
        },

        {
          formComponent: "currency-field",
          name: "Total WFD Profit",
          label: "Total WFD Profit",
          width: "1/3",
          path: ["data", "info", "accounting", "profit", "wfdProfit", "totalProfit"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "profit",
              "wfdProfit",
              "totalProfit"
            ]),
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "WFD Fees",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "currency-field",
          name: "acquisitionFee",
          label: "WFD Acquisition fee",
          width: "1/2",
          path: ["data", "info", "accounting", "taxesAndFees", "acquisitionFee"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "taxesAndFees",
              "acquisitionFee"
            ]),
          default: null
        },
        {
          formComponent: "currency-field",
          name: "mininumFee",
          label: "WFD mininum fee",
          width: "1/2",
          path: ["data", "info", "accounting", "taxesAndFees", "wfdMinimumFee"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "taxesAndFees",
              "wfdMinimumFee"
            ]),
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      name: "Lender Fees",
      titleStyle: { color: "black" },
      entities: [
        {
          formComponent: "currency-field",
          name: "acquisitionFee",
          label: "Lender Acquisition fee",
          width: "1/3",
          path: ["data", "info", "accounting", "taxesAndFees", "lenderAcquisitionFee"],
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "taxesAndFees",
              "lenderAcquisitionFee"
            ]),
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "uccFee",
          label: "UCC fee",
          width: "1/3",
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "taxesAndFees",
              "totalUCCFees"
            ]),
          path: ["data", "info", "accounting", "taxesAndFees", "totalUCCFees"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "membershipFee",
          label: "Membership fee",
          width: "1/3",
          conditionalStyle: (stateAccess) =>
            checkAccountingProfitsForDifferences(stateAccess, true, [
              "taxesAndFees",
              "totalMembershipFees"
            ]),
          path: ["data", "info", "accounting", "taxesAndFees", "totalMembershipFees"],
          default: null
        }
      ]
    },
    insuranceProfitStruct,
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "segment",
          width: "1/3",
          name: "Representative",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "currency-field",
              name: "commission",
              label: "Commission",
              width: "full",
              path: ["data", "info", "accounting", "profit", "representativeProfit", "commission"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "representativeProfit",
                  "commission"
                ]),
              default: null
            }
          ]
        },
        {
          formComponent: "segment",
          width: "2/3",
          name: "F&I manager",
          titleStyle: { color: "black" },
          entities: [
            {
              formComponent: "read-only-number-field",
              name: "commissionableAmount",
              label: "Commissionable Amount",
              width: "1/2",
              path: [
                "data",
                "info",
                "accounting",
                "profit",
                "managerProfit",
                "commissionableAmount"
              ],
              default: null
            },

            {
              formComponent: "read-only-number-field",
              name: "commission",
              label: "Commission",
              width: "1/2",
              path: ["data", "info", "accounting", "profit", "managerProfit", "commission"],
              conditionalStyle: (stateAccess) =>
                checkAccountingProfitsForDifferences(stateAccess, true, [
                  "profit",
                  "managerProfit",
                  "commission"
                ]),
              default: null
            }
          ]
        }
      ]
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess) => {
        return <NetsuiteTotals deal={stateAccess.get<Deal>([])} />;
      },
      path: null,
      width: "full",
      name: "NetsuiteTotals"
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess) => <ProfitValuesButton stateAccess={stateAccess} />,
      path: null,
      name: "ProfitValuesButton",
      label: "ProfitValuesButton",
      width: "full"
    },
    {
      formComponent: "one-to-many-field",
      component: (stateAccess: StateAccess, _mainStateAccess, renderSet) => (
        <MaxReservePreview
          stateAccess={stateAccess}
          renderSet={renderSet}
          path={["data", "info", "accounting", "payment", "maxReserve"]}
        />
      ),
      path: null,
      name: "calculateReserves",
      label: "calculateReserves",
      width: "full"
    }
  ]
});

export const addDealStruct: Model<Deal> = {
  formComponent: "model",
  schema: "new_deal",
  name: "deal",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "segment",
          entities: [
            addDeal,
            applicant,
            fraudValidationApplicant,
            coApplicant,
            fraudValidationCoApplicant,
            creditCheck,
            vehicle,
            signature,
            utilsSegment,
            lateChargeSegment
          ],
          width: "1/3"
        },
        {
          formComponent: "segment",
          width: "2/3",
          entities: [
            {
              formComponent: "segment",
              entities: [
                {
                  formComponent: "segment",
                  entities: [priceSegment],
                  width: "1/4"
                },
                {
                  formComponent: "segment",
                  entities: [
                    fees,
                    taxes,
                    {
                      formComponent: "segment",
                      width: "full",
                      entities: [
                        { formComponent: "segment", width: "1/3", entities: [dealership] },
                        { formComponent: "segment", width: "1/3", entities: [lender] },
                        { formComponent: "segment", width: "1/3", entities: [assignee] }
                      ]
                    }
                  ],
                  width: "3/4"
                }
              ],
              width: "full"
            }
          ]
        }
      ]
    }
  ]
};

export const editDealStruct: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "segment",
          entities: [
            editDeal,
            {
              formComponent: "segment",
              width: "full",
              entities: [
                { formComponent: "segment", width: "full", entities: [dealership] },
                { formComponent: "segment", width: "1/2", entities: [lender] },
                { formComponent: "segment", width: "1/2", entities: [assignee] }
              ]
            },
            applicant,
            fraudValidationApplicant,
            coApplicant,
            fraudValidationCoApplicant,
            creditCheck,
            vehicle,
            utilsSegment,
            lateChargeSegment,
            signature
          ],
          width: "1/3"
        },

        {
          formComponent: "segment",
          width: "2/3",
          entities: [
            {
              formComponent: "segment",
              entities: [
                {
                  formComponent: "segment",
                  entities: [priceSegment],
                  width: "1/4"
                },
                {
                  formComponent: "segment",
                  entities: [
                    {
                      formComponent: "segment",
                      width: "full",
                      entities: [
                        { formComponent: "segment", width: "2/3", entities: [fees] },
                        { formComponent: "segment", width: "1/3", entities: [taxes] }
                      ]
                    },
                    aftermarketOptions,
                    lendingDisclosures
                  ],
                  width: "3/4"
                }
              ],
              width: "full"
            },
            {
              formComponent: "segment",
              width: "full",
              entities: [
                {
                  formComponent: "segment",
                  width: "1/2",
                  entities: [stipulations("update"), dealerNotes("update")]
                },

                {
                  formComponent: "segment",
                  width: "1/2",
                  entities: [
                    {
                      formComponent: "one-to-many-field",
                      component: (stateAccess) => (
                        <AccessControl requiredPermissions={{ entity: "note", action: "update" }}>
                          <NotesPreview
                            type="deal"
                            notes={stateAccess.get(["data", "notes"]) ?? []}
                            dealId={stateAccess.get(["_id"])}
                          />
                        </AccessControl>
                      ),
                      path: null,
                      name: "Notes",
                      width: "full"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (
                stateAccess,
                _mainstateAccess,
                renderSet,
                parentPath,
                permissions,
                formEditContext
              ) => (
                <ProfitPreview
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  permissions={permissions}
                  formEditContext={formEditContext}
                />
              ),
              path: [],
              width: "full",
              struct: profitsSegment(),
              name: "profitsSegment"
            }
          ]
        },
        {
          formComponent: "segment",
          width: "2/3",
          entities: [
            paymentsSegment,
            {
              formComponent: "segment",
              entities: [fundsSegment],
              width: "1/2"
            },
            fundingFees,
            {
              formComponent: "one-to-many-field",
              width: "1/2",
              component: (stateAccess: StateAccess) => (
                <PreviewChargebacks dealId={stateAccess.get(["_id"])} />
              ),
              name: "PreviewChargebacks",
              label: "PreviewChargebacks",
              path: null
            }
          ]
        }
      ]
    },
    {
      formComponent: "segment",
      entities: [files, fundingDocuments, lenderDecisions, collaterals]
    }
  ]
};
export const pivotTableStruct: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "segment",
          entities: [editDeal, vehicle, applicant],
          width: "1/3"
        },
        {
          formComponent: "segment",
          entities: [
            { formComponent: "segment", width: "full", entities: [dealership, assignee] },
            {
              formComponent: "segment",
              entities: [
                {
                  formComponent: "segment",
                  entities: [priceSegment],
                  width: "1/3"
                },
                {
                  formComponent: "segment",
                  entities: [fees, taxes, aftermarketOptions, fundingFees],
                  width: "2/3"
                }
              ],
              width: "full"
            },
            {
              formComponent: "segment",
              entities: [paymentsSegment],
              width: "full"
            }
          ],
          width: "2/3"
        }
      ]
    },
    {
      formComponent: "segment",

      entities: [files, fundingDocuments, lenderDecisions]
    }
  ]
};

export const accountingView: Model<Deal> = {
  formComponent: "model",
  schema: "update_deal",
  name: "deal",
  entities: [
    {
      formComponent: "segment",
      name: "Deal",
      entities: [
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "read-only-field",
              name: "Ref Number",
              label: "Reference Number",
              width: "1/2",
              path: ["data", "info", "refNumber"],
              default: null
            },
            {
              formComponent: "one-to-many-field",
              struct: userStruct,
              component: (
                stateAccess: StateAccess,
                mainstateAccess: StateAccess,
                renderSet: RenderSet
              ) => (
                <UserPreview
                  type="f&i manager"
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  path={["data", "user"]}
                  name="user"
                  label="Assignee"
                />
              ),
              name: "user",
              label: "Assignee",
              width: "1/2",
              path: ["data", "user"],
              default: null,
              valueToString: (el) => el.firstName
            },
            {
              formComponent: "segment",
              width: "full",
              entities: [
                {
                  formComponent: "one-to-many-field",
                  struct: () => dealershipStruct,
                  component: (
                    stateAccess: StateAccess,
                    mainstateAccess: StateAccess,
                    renderSet: RenderSet
                  ) => (
                    <DealershipPreview
                      stateAccess={stateAccess}
                      renderSet={renderSet}
                      path={["data", "dealership"]}
                      name="dealership"
                      label="Dealership"
                      required={true}
                      type={"contract"}
                    />
                  ),
                  name: "dealership",
                  label: "Dealership",
                  required: true,
                  default: null,
                  path: ["data", "dealership"],
                  width: "1/2"
                },
                {
                  formComponent: "one-to-many-field",
                  struct: () => lenderStruct,
                  component: (
                    stateAccess: StateAccess,
                    mainstateAccess: StateAccess,
                    renderSet: RenderSet
                  ) => (
                    <LenderPreview
                      stateAccess={stateAccess}
                      renderSet={renderSet}
                      lenderIdPath={["data", "lenderId"]}
                      path={["data", "lender"]}
                      name="lender"
                      label="Lender"
                    />
                  ),
                  name: "lender",
                  label: "Lender",
                  width: "1/2",
                  path: ["data", "lender"],
                  default: null
                }
              ]
            },
            // dealStatusField("full"),
            {
              formComponent: "segment",
              name: "Status",
              width: "full",
              entities: [
                {
                  formComponent: "one-to-many-field",
                  component: (stateAccess: StateAccess) => (
                    <DealStatuses stateAccess={stateAccess} />
                  ),
                  name: "dealStatuses",
                  label: "Deal Status",
                  path: null
                }
              ]
            },
            applicant,
            coApplicant,
            {
              formComponent: "segment",
              width: "full",
              name: "Vehicle",
              entities: [
                {
                  formComponent: "year-field",
                  name: "year",
                  label: "Year",
                  width: "1/3",
                  path: ["data", "info", "vehicle", "year"],
                  default: null
                },
                {
                  formComponent: "text-field",
                  name: "make",
                  label: "Make",
                  width: "1/3",
                  path: ["data", "info", "vehicle", "make"],
                  default: null
                },
                {
                  formComponent: "text-field",
                  name: "model",
                  label: "Model",
                  width: "1/3",
                  path: ["data", "info", "vehicle", "model"],
                  default: null
                }
              ]
            },
            {
              formComponent: "segment",
              name: "Funding",
              entities: [
                {
                  formComponent: "date-field",
                  name: "FundedAt",
                  label: "Funded At",
                  width: "1/2",
                  path: ["data", "info", "dealDates", "fundedAt"],
                  minDate: (stateAccess) =>
                    moveDate(
                      new Date(
                        stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
                      ),
                      1
                    ),
                  error: (stateAccess) =>
                    stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"]) &&
                    stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
                      ? new Date(
                          stateAccess.get<Deal>(["data", "info", "dealDates", "fundedAt"])
                        ).getTime() <
                        new Date(
                          stateAccess.get<Deal>(["data", "info", "dealDates", "contractDate"])
                        ).getTime()
                      : false,
                  errorText: "Funded at date should be after the contract date!",
                  default: null
                },
                {
                  formComponent: "currency-field",
                  name: "FundedAmount",
                  label: "Funded Amount",
                  width: "1/2",
                  path: ["data", "info", "payment", "fundedAmount"],
                  default: null
                },
                {
                  formComponent: "date-field",
                  name: "FundingNoticeDate",
                  label: "Funding Notice Date",
                  width: "1/3",
                  path: ["data", "info", "dealDates", "fundedNoticeDate"],
                  default: null
                },
                {
                  formComponent: "date-field",
                  name: "CollateralFundingDate",
                  label: "Collateral Funding Date",
                  width: "1/3",
                  path: ["data", "info", "dealDates", "collateralFundingDate"],
                  default: null
                },
                {
                  formComponent: "date-field",
                  name: "ReserveFundingDate",
                  label: "Reserve Funding Date",
                  width: "1/3",
                  path: ["data", "info", "dealDates", "reserveFundingDate"],
                  default: null
                },
                {
                  formComponent: "radio-field",
                  possibleValues: [
                    {
                      label: "ACH",
                      value: "ACH"
                    },
                    {
                      label: "Check",
                      value: "Check"
                    }
                  ],
                  name: "fundingPaymentMethod",
                  label: "Funding Payment Method",
                  width: "full",
                  path: ["data", "info", "fundingPaymentMethod"],
                  default: "ACH"
                }
              ],
              width: "full"
            },
            fundingFees
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (
                stateAccess,
                _mainstateAccess,
                renderSet,
                parentPath,
                permissions,
                formEditContext
              ) => (
                <ProfitPreview
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  permissions={permissions}
                  withInsurances={true}
                  formEditContext={formEditContext}
                />
              ),
              path: ["data", "info", "profit"],
              width: "full",
              struct: profitsSegment(),
              name: "profitsSegment"
            }
          ]
        },
        {
          formComponent: "segment",
          width: "1/3",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => (
                <AccountingProfitPreview
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  permissions={permissions}
                  background={`rgb(253 185 231)`}
                />
              ),
              path: ["data", "info", "accounting"],
              width: "full",
              struct: profitsSegmentAccounting(),
              name: "profitsSegment"
            }
          ]
        },
        {
          formComponent: "segment",
          name: "Netsuite",
          width: "full",
          entities: [
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => {
                return <NetsuiteIds stateAccess={stateAccess} renderSet={renderSet} />;
              },
              path: null,
              width: "full",
              name: "netsuiteIdsModal"
            },

            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainstateAccess, renderSet, parentPath, permissions) => {
                return <AccountingStepper stateAccess={stateAccess} renderSet={renderSet} />;
              },
              path: null,
              width: "full",
              name: "netsuiteStepper"
            }
          ]
        }
      ]
    }
  ]
};

const genLabels = <T extends EntityType>(
  x: FormComponent<EntityData<T>>,
  pathSoFar: Path<EntityData<T>>[]
): { [key: string]: string | undefined } => {
  switch (x.formComponent) {
    case "model":
    case "segment":
      return Object.assign({}, ...x.entities.map((y) => genLabels(y, pathSoFar)));
    case "list-model":
    case "tab-list-model":
    case "read-only-list-model":
      // @ts-ignore
      return genLabels(x.entity, [...pathSoFar, ...x.path]);
    default:
      return { [[...pathSoFar, ...(x.path ?? [])].join(".")]: x.label };
  }
};

export const dealLabels = genLabels<"deal">(editDealStruct, []);

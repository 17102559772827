import { Button, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { RootState } from "app/rootReducer";
import { LenderNote } from "components/LenderTickets/LenderNotes/types";
import LenderTickets from "components/LenderTickets/LenderTickets";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { Lender } from "components/Lenders/types";
import { TitleIssueNote } from "components/TitleIssueNotes/types";
import {
  getTitleIssueList,
  removeTitleIssueList
} from "components/TitleIssues/listTitleIssueSlice";
import { DmvStatus, TitleIssue, TitleIssueStatus } from "components/TitleIssues/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import States from "us-states";
import DashboardButtonInfo from "./DashboardButtonInfo";
import LatestTitleDocumentsTable from "./LatestTitleDocumentsTable";
import LatestTitlesTable from "./LatestTitlesTable";
import UnreadNotes from "./UnreadNotes";
import { useStickyState } from "index";

const allCollateralTypes = ["Automotive", "Power Sport", "Recreational Vehicles", "Marine"];
const projection = {
  "data.deal.data.lender._id": 1,
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.oldTitleRegistrationInfo.status": 1,
  "data.info.titleRegistrationInfo.status": 1,
  "data.info.receiptInfo.status": 1,
  "data.info.dmvCheckedCount": 1,
  "data.titleIssueNotes": 1,
  _id: 1,
  createdAt: 1,
  deleted: 1
};
export default ({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) => {
  const dispatch = useDispatch();
  const listId = "titleIssuesDashboard";
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice[listId]);
  const lenderTicketList = useSelector((state: RootState) => state.listLenderTicketSlice["table"]);

  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);

  const [lenderTicketsResetStickyCallback, setLenderTicketsResetStickyCallback] = React.useState<
    () => void
  >();
  const [
    latestTitleDocumentsTableResetStickyCallback,
    setLatestTitleDocumentsTableResetStickyCallback
  ] = React.useState<() => void>();
  const [
    latestTitlesTableResetStickyCallback,
    setLatestTitlesTableResetStickyCallback
  ] = React.useState<() => void>();
  const [lenders, setLenders] = useStickyState<Lender[]>(
    (["All lenders"] as unknown) as Lender[],
    "title_issues_dashboard_lenders"
  );
  const [dmvs, setDmvs] = useStickyState<string[]>(
    ["Minnesota", "Illinois", "North Dakota", "South Dakota"],
    "title_issues_dashboard_dmvs"
  );
  const [collateralTypesState, setCollateralTypes] = useStickyState<string[]>(
    ["All collateral types"],
    "title_issues_dashboard_collateralTypes"
  );
  const titleIssuesLeft = (titleIssuesList?.entities ?? []).filter(
    (x) => !x.data.info.dmvCheckedCount
  ).length;
  const ticketsLeft = (lenderTicketList?.entities ?? []).filter(
    (x) => x.data.info.status !== "solved"
  ).length;

  const documentsLeft = (titleIssuesList?.entities ?? []).reduce((acc, curr) => {
    if (curr.data.info.receiptInfo.status !== "resolved") {
      acc = acc + 1;
    }
    if (curr.data.info.oldTitleRegistrationInfo.status !== "resolved") {
      acc = acc + 1;
    }
    if (curr.data.info.titleRegistrationInfo.status !== "resolved") {
      acc = acc + 1;
    }
    return acc;
  }, 0);

  const [dmvLenderCollateralTypesQuery, setDmvLenderCollateralTypesQuery] = useStickyState<{
    lender: object | null;
    dmv: object | null;
    collateralTypes: object | null;
  }>(
    {
      lender: null,
      dmv: {
        $or: [
          {
            "data.deal.data.applicant.data.info.currentState": {
              $in: dmvs
            }
          },
          {
            "data.deal.data.coApplicant.data.info.currentState": {
              $in: dmvs
            }
          }
        ]
      },
      collateralTypes: null
    },
    "title_issues_dashboard_dmvLenderCollateralTypesQuery"
  );
  React.useEffect(() => {
    if (!titleIssuesList)
      dispatch(
        getTitleIssueList(listId, {
          options: { pagination: false, projection }
        })
      );
    return () => {
      dispatch(removeTitleIssueList(listId));
      return;
    };
  }, [dispatch, listId]);
  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  }, [dispatch, listId]);

  const filterTitleIssuesByLenderAndDmv = (titleIssues: TitleIssue[]) => {
    if (lenders?.every((lender) => lender === (("All lenders" as unknown) as Lender))) {
      if (dmvs?.every((dmvs) => dmvs === "All dmvs")) {
        return titleIssues;
      } else {
        return titleIssues?.filter((titleIssue) =>
          dmvs.includes(titleIssue?.data?.deal?.data?.applicant?.data?.info?.currentState || "")
        );
      }
    } else {
      if (dmvs?.every((dmvs) => dmvs === "All dmvs")) {
        return titleIssues?.filter((titleIssue) =>
          lenders?.map((x) => x._id).includes(titleIssue?.data?.deal?.data?.lender?._id || "")
        );
      } else {
        return titleIssues?.filter(
          (titleIssue) =>
            dmvs.includes(
              titleIssue?.data?.deal?.data?.applicant?.data?.info?.currentState || ""
            ) &&
            lenders?.map((x) => x._id).includes(titleIssue?.data?.deal?.data?.lender?._id || "")
        );
      }
    }
  };

  const unreadNotes = filterTitleIssuesByLenderAndDmv(titleIssuesList?.entities ?? []).reduce(
    (acc, titleIssue) => {
      const unreadNotes =
        titleIssue?.data?.titleIssueNotes?.filter(
          (note) => !note.data?.info?.seen?.wfd && note.data.info.creator === "dealer"
        ) || [];
      return [...acc, ...unreadNotes];
    },
    [] as TitleIssueNote[]
  );

  const unreadNotesTickets = (lenderTicketList?.entities ?? []).reduce((acc, ticket) => {
    const unreadNotes =
      ticket?.data?.lenderNotes?.filter(
        (note) => !note.data?.info?.seen?.wfd && note.data.info.creator === "lender"
      ) || [];
    return [...acc, ...unreadNotes];
  }, [] as LenderNote[]);

  const handleResetStickyState = () => {
    setLenders((["All lenders"] as unknown) as Lender[]);
    setDmvs(["Minnesota", "Illinois", "North Dakota", "South Dakota"]);
    setCollateralTypes(["All collateral types"]);
    setDmvLenderCollateralTypesQuery({
      lender: null,
      dmv: {
        $or: [
          {
            "data.deal.data.applicant.data.info.currentState": {
              $in: dmvs
            }
          },
          {
            "data.deal.data.coApplicant.data.info.currentState": {
              $in: dmvs
            }
          }
        ]
      },
      collateralTypes: null
    });
    lenderTicketsResetStickyCallback?.();
    latestTitleDocumentsTableResetStickyCallback?.();
    latestTitlesTableResetStickyCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [
    lenderTicketsResetStickyCallback,
    latestTitleDocumentsTableResetStickyCallback,
    latestTitlesTableResetStickyCallback
  ]);
  return (
    <React.Fragment>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid
        container
        xs={12}
        style={{ marginBottom: 10 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container xs={6} spacing={2}>
          <Grid container xs={12} style={{ margin: 10 }} spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                style={{ zIndex: 6 }}
                value={lenders}
                getOptionLabel={(lender) => {
                  if (((lender as unknown) as string) === "All lenders") {
                    return (lender as unknown) as string;
                  }
                  return lender.data.info.name ?? "";
                }}
                multiple
                getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                options={[("All lenders" as unknown) as Lender, ...(lenderList?.entities ?? [])]}
                onChange={(event, newValue) => {
                  if (newValue?.length === 0) {
                    setLenders((["All lenders"] as unknown) as Lender[]);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      lender: null
                    }));
                  } else {
                    const filteredValue = newValue.filter(
                      (x) => x !== (("All lenders" as unknown) as Lender)
                    );
                    setLenders(filteredValue);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      lender: {
                        "data.deal.data.lenderId": { $in: filteredValue.map((x) => x._id) }
                      }
                    }));
                  }
                }}
                loading={!Array.isArray(lenderList?.entities)}
                openOnFocus
                id="lender-statistics-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Lender"}
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                style={{ zIndex: 6 }}
                value={dmvs}
                getOptionLabel={(dmv) => dmv}
                multiple
                getOptionSelected={(x, y) => x === y}
                options={["All dmvs", ...(Object.keys(States) ?? [])]}
                onChange={(event, newValue) => {
                  if (newValue?.length === 0) {
                    setDmvs(["All dmvs"]);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      dmv: null
                    }));
                  } else {
                    const filteredValue = newValue.filter((x) => x !== "All dmvs");
                    setDmvs(filteredValue);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      dmv: {
                        $or: [
                          {
                            "data.deal.data.applicant.data.info.currentState": {
                              $in: filteredValue
                            }
                          },
                          {
                            "data.deal.data.coApplicant.data.info.currentState": {
                              $in: filteredValue
                            }
                          }
                        ]
                      }
                    }));
                  }
                }}
                openOnFocus
                id="dmvs-statistics-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Dmv"}
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                style={{ zIndex: 6 }}
                value={collateralTypesState}
                getOptionLabel={(collateralType) => collateralType}
                multiple
                getOptionSelected={(x, y) => x === y}
                options={["All collateral types", ...allCollateralTypes]}
                onChange={(event, newValue) => {
                  if (newValue?.length === 0) {
                    setCollateralTypes(["All collateral types"]);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      collateralTypes: null
                    }));
                  } else {
                    const filteredValue = newValue.filter((x) => x !== "All collateral types");
                    setCollateralTypes(filteredValue);
                    setDmvLenderCollateralTypesQuery((prev) => ({
                      ...prev,
                      collateralTypes: {
                        "data.deal.data.info.type": {
                          $in: filteredValue
                        }
                      }
                    }));
                  }
                }}
                openOnFocus
                id="collateral-types-statistics-select"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                    label={"Collateral type"}
                    variant="filled"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={6} spacing={2}>
          <Grid item xs={4}>
            <DashboardButtonInfo
              title="DMV Title Checks Left"
              number={`${titleIssuesLeft}`}
              scrollTo="dmvChecks"
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardButtonInfo
              title="Dealer Documents Required"
              number={`${documentsLeft}`}
              scrollTo="dealerDocuments"
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardButtonInfo
              title="Unsolved Lender Tickets"
              number={`${ticketsLeft}`}
              scrollTo="tickets"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2}>
        <Grid
          item
          id="dmvChecks"
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " flex-start"
          }}
        >
          <LatestTitlesTable
            setResetStickyCallback={setLatestTitlesTableResetStickyCallback}
            dmvLenderCollateralTypesQuery={dmvLenderCollateralTypesQuery}
          />
        </Grid>
        <Grid
          id="dealerDocuments"
          item
          xs={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <LatestTitleDocumentsTable
            setResetStickyCallback={setLatestTitleDocumentsTableResetStickyCallback}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <UnreadNotes notes={unreadNotes} type="dealer" />
        </Grid>
        <Grid
          id="tickets"
          item
          xs={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " flex-start"
          }}
        >
          <LenderTickets
            dashboardView
            setResetStickyCallback={setLenderTicketsResetStickyCallback}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " flex-start"
          }}
        >
          <UnreadNotes notes={unreadNotesTickets} type="lender" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

import {
  DealershipPerformanceReportRequest,
  DealershipPerformanceReportResponseData
} from "hooks/useDealershipPerformanceReport/types";
import { useStickyState } from "index";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import {
  capitalize,
  getCurrentQuarterDates,
  getFirstAndLastDateOfCurrentMonth,
  getFirstAndLastDateOfGivenYearAndMonth
} from "utils/functions";
import {
  Box,
  Grid,
  InputBase,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  Typography,
  Tab,
  Tabs,
  AppBar
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import States from "us-states";
import useDebounce from "Chat/hooks/useDebounce";
import useDealershipPerformanceReport from "hooks/useDealershipPerformanceReport/useDealershipPerformanceReport";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { Dealership } from "components/Dealerships/types";
import AddCustomerCareAssignee from "./AddCustomerCareAssignee";
import { getLockList } from "utils/models/LockEntity/listLockSlice";
import Loader from "components/Loader/Loader";
import Spinner from "components/Loader/Spinner";
type RequestState = DealershipPerformanceReportRequest & {
  fromYear: number | null;
  fromMonth: number | null;
  toYear: number | null;
  toMonth: number | null;
  allDealershipStates: boolean;
};
type ToggleState = "qtd" | "mtd" | "ytd";

const generateYearArray = () =>
  Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - 4 + i).reverse();

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const MonthYearToggle = ({
  requestState,
  setRequestState,
  toggleState,
  setToggle
}: {
  requestState: RequestState;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
  toggleState: ToggleState;
  setToggle: React.Dispatch<React.SetStateAction<ToggleState>>;
}) => {
  const [today] = React.useState(new Date());
  const handleSetToggle = (period: ToggleState) => {
    setToggle(period);
    setRequestState(() => {
      switch (period) {
        case "mtd": {
          const from = new Date(today.getFullYear(), today.getMonth() - 5, 1);
          return {
            ...requestState,
            from: from.toISOString(),
            to: today.toISOString(),
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
        case "qtd": {
          const { from } = getCurrentQuarterDates();
          return {
            ...requestState,
            from: from.toISOString(),
            to: today.toISOString(),
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
        case "ytd": {
          const from = new Date(today.getFullYear(), 0, 1);
          return {
            ...requestState,
            from: from.toISOString(),
            to: today.toISOString(),
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
      }
    });
  };
  const handlePeriodChange = (
    value: string | null,
    type: "fromYear" | "fromMonth" | "toYear" | "toMonth"
  ) => {
    const newRequestState = { ...requestState, [type]: value ? Number(value) : null };
    if (
      newRequestState.fromYear &&
      newRequestState.toYear &&
      newRequestState.fromMonth &&
      newRequestState.toMonth
    ) {
      const { from } = getFirstAndLastDateOfGivenYearAndMonth(
        newRequestState.fromYear,
        newRequestState.fromMonth
      );
      const { to } = getFirstAndLastDateOfGivenYearAndMonth(
        newRequestState.toYear,
        newRequestState.toMonth
      );

      setRequestState((prevRequestState) => ({
        ...prevRequestState,
        [type]: Number(value),
        from: from.toISOString(),
        to:
          to.getMonth() === today.getMonth() && to.getFullYear() === today.getFullYear()
            ? new Date(to.setDate(today.getDate())).toISOString()
            : to.toISOString()
      }));
    } else {
      setRequestState(newRequestState);
    }
  };
  const renderMonthAndYearSettings = (type: "from" | "to") => {
    return (
      <Paper elevation={2} style={{ width: "100%", padding: "10px" }}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
            {capitalize(type)}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={requestState?.[`${type}Month`]?.toString()}
              getOptionLabel={(month) => {
                return month;
              }}
              getOptionSelected={(x, y) => x === y}
              getOptionDisabled={(option) => {
                if (
                  type === "from" &&
                  requestState.toMonth &&
                  requestState.toYear === requestState.fromYear
                ) {
                  return Number(option) >= requestState.toMonth;
                }
                if (
                  type === "to" &&
                  requestState.fromMonth &&
                  requestState.toYear === requestState.fromYear
                ) {
                  return Number(option) <= requestState.fromMonth;
                }
                return false;
              }}
              options={Array.from({ length: 12 }, (_, i) => (i + 1).toString())}
              onChange={(event, newValue) => {
                handlePeriodChange(newValue, `${type}Month`);
              }}
              openOnFocus
              id={`${type}-month-select`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Month"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={requestState?.[`${type}Year`]?.toString()}
              getOptionLabel={(year) => {
                return year;
              }}
              getOptionSelected={(x, y) => x === y}
              options={generateYearArray().map((yr) => yr.toString())}
              getOptionDisabled={(option) => {
                if (type === "from" && requestState.toYear) {
                  return Number(option) > requestState.toYear;
                }
                if (type === "to" && requestState.fromYear) {
                  return Number(option) < requestState.fromYear;
                }
                return false;
              }}
              onChange={(event, newValue) => {
                handlePeriodChange(newValue, `${type}Year`);
              }}
              openOnFocus
              id={`${type}-year-select`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Year"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid container item xs={4}>
        {renderMonthAndYearSettings("from")}
      </Grid>
      <Grid container item xs={4}>
        {renderMonthAndYearSettings("to")}
      </Grid>

      <Grid container item xs={4}>
        <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                Predefined ranges
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box
                component={"div"}
                style={{
                  display: "flex",
                  maxHeight: "20px",
                  padding: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: toggleState === "mtd" ? "#fff" : "#000",
                  background: toggleState === "mtd" ? "#2B4E70" : "#E8E8E8"
                }}
                onClick={() => handleSetToggle("mtd")}
              >
                <Typography>6MTD</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                component={"div"}
                style={{
                  display: "flex",
                  maxHeight: "20px",
                  padding: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: toggleState === "qtd" ? "#fff" : "#000",
                  background: toggleState === "qtd" ? "#2B4E70" : "#E8E8E8"
                }}
                onClick={() => handleSetToggle("qtd")}
              >
                <Typography>QTD</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                component={"div"}
                style={{
                  display: "flex",
                  maxHeight: "20px",
                  padding: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: toggleState === "ytd" ? "#fff" : "#000",
                  background: toggleState === "ytd" ? "#2B4E70" : "#E8E8E8"
                }}
                onClick={() => handleSetToggle("ytd")}
              >
                <Typography>YTD</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
const generateTable = (
  data: DealershipPerformanceReportResponseData,
  formattedPeriods: string[],
  dealerships: Dealership[] | undefined
) => {
  const TABLE_CELL_STYLE: React.CSSProperties = {
    border: "1px solid black",
    textAlign: "center",
    padding: "8px",
    whiteSpace: "nowrap"
  };
  const headers = formattedPeriods.map((period, index) => (
    <TableCell
      key={`headers${index}`}
      colSpan={2}
      style={{ ...TABLE_CELL_STYLE, whiteSpace: "normal" }}
    >
      {period}
    </TableCell>
  ));
  if (Object.keys(data)?.length < 1) {
    return (
      <TableContainer>
        <Table size="small" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell style={TABLE_CELL_STYLE}></TableCell>
              <TableCell style={TABLE_CELL_STYLE}>Customer care assignee</TableCell>
              {headers}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={formattedPeriods.length * 2 + 2}
                style={{
                  ...TABLE_CELL_STYLE,
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "16px"
                }}
              >
                No data
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  const rows = Object.keys(data).map((dealershipName, i) => {
    const performanceRow = Object.keys(data?.[dealershipName]?.periods).map((period) => {
      const periodData = data?.[dealershipName]?.periods?.[period];
      const trendClass = periodData.trend;
      const trendColor =
        trendClass === "positive" ? "green" : trendClass === "negative" ? "red" : "black";

      return (
        <TableCell
          key={`performanceRow_${i}_${period}`}
          colSpan={2}
          style={{
            color: trendColor,
            ...TABLE_CELL_STYLE
          }}
        >
          {periodData.performance.toFixed(2) + "%"}
        </TableCell>
      );
    });

    const periodDataRow = Object.keys(data?.[dealershipName]?.periods).map((period, index) => {
      const periodData = data?.[dealershipName]?.periods?.[period];

      return (
        <React.Fragment key={`periodDataRow_${index}_${period}`}>
          <TableCell style={{ ...TABLE_CELL_STYLE, whiteSpace: "normal" }}>
            ({periodData.prevPeriodCount}) {periodData.prevPeriodAmount.toLocaleString()} $
          </TableCell>
          <TableCell style={{ ...TABLE_CELL_STYLE, whiteSpace: "normal" }}>
            ({periodData.currPeriodCount}) {periodData.currPeriodAmount.toLocaleString()} $
          </TableCell>
        </React.Fragment>
      );
    });

    return (
      <React.Fragment key={`row${i}`}>
        <TableRow style={{ background: i % 2 === 0 ? "#f3f3f3" : "#ffffff" }}>
          <TableCell rowSpan={2} style={TABLE_CELL_STYLE}>
            <div>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>{dealershipName}</div>
              {data[dealershipName].contactEmail ? (
                <div>{data[dealershipName].contactEmail}</div>
              ) : (
                ""
              )}
              {data[dealershipName].contactPhone ? (
                <div>{data[dealershipName].contactPhone}</div>
              ) : (
                ""
              )}
              {data[dealershipName].contactNames ? (
                <div>{data[dealershipName].contactNames}</div>
              ) : (
                ""
              )}
            </div>
          </TableCell>
          <TableCell rowSpan={2} style={TABLE_CELL_STYLE}>
            <AddCustomerCareAssignee
              dealership={dealerships?.find(
                (dealership) => dealership._id === data[dealershipName].dealershipId
              )}
            />
          </TableCell>
          {performanceRow}
        </TableRow>
        <TableRow style={{ background: i % 2 === 0 ? "#f3f3f3" : "#ffffff", textAlign: "center" }}>
          {periodDataRow}
        </TableRow>
      </React.Fragment>
    );
  });

  return (
    <TableContainer>
      <Table size="small" style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell style={TABLE_CELL_STYLE}></TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Customer care assignee</TableCell>
            {headers}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default () => {
  const [today] = React.useState(new Date());
  const [states, setStates] = useStickyState<string[]>([], "dealership_performance_report_states");
  const dispatch = useDispatch();
  const listId = "dealershipsPerformance";
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);
  const [tabValue, setTabValue] = React.useState(0);
  const [dealershipName, setDealershipName] = React.useState<string>("");
  const debouncedDealershipName = useDebounce<string>(dealershipName, 1000);
  const [periodToggle, setToggle] = useStickyState<ToggleState>(
    "mtd",
    "dealership_performance_report_state_periodToggle"
  );
  const fromDefault = new Date(today.getFullYear(), today.getMonth() - 5, 1);
  const [state, setState] = useStickyState<RequestState>(
    {
      ...getFirstAndLastDateOfCurrentMonth(),
      dealershipStates: ["All states"],
      allDealershipStates: true,
      from: fromDefault.toISOString(),
      to: today.toISOString(),
      fromMonth: fromDefault.getMonth() + 1,
      fromYear: fromDefault.getFullYear(),
      toMonth: today.getMonth() + 1,
      toYear: today.getFullYear()
    },
    "dealership_performance_report_state"
  );
  const { data, isFetching } = useDealershipPerformanceReport(state, debouncedDealershipName);

  React.useEffect(() => {
    if (allLocks?.entities === undefined) dispatch(getLockList("all"));
  }, [dispatch, allLocks]);
  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: {
            projection: {
              _id: 1,
              createdAt: 1,
              updatedAt: 1,
              "data.info.name": 1,
              "data.info.displayName": 1,
              "data.customerCareAssignee": 1
            },
            pagination: false
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  }, []);

  return (
    <Grid container xs={12} spacing={2}>
      <Grid container xs={12} item spacing={2} style={{ marginBottom: 10, marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", fontSize: "19px", color: "rgb(37, 78, 110)" }}>
            Dealership performance report
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={3} container>
            <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Dealership state
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  style={{ zIndex: 6 }}
                  value={state.allDealershipStates ? ["All states"] : states || null}
                  getOptionLabel={(state) => {
                    return state;
                  }}
                  multiple
                  getOptionSelected={(x, y) => x === y}
                  options={["All states", ...Object.keys(States)]}
                  onChange={(event, newValue) => {
                    if (newValue?.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        dealershipStates: ["All states"],
                        allDealershipStates: true
                      }));
                      setStates(["All states"]);
                    } else {
                      setStates(newValue.filter((x) => x !== "All states"));
                      setState((prevState) => ({
                        ...prevState,
                        dealershipStates: newValue.filter((x) => x !== "All states"),
                        allDealershipStates: false
                      }));
                    }
                  }}
                  openOnFocus
                  id="states-select"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                      label={"State"}
                      variant="filled"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3} container>
            <Paper style={{ width: "100%", padding: "10px" }}>
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Search by dealership name
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  component={"div"}
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #254e70",
                    gap: 10,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Box component="div">
                    <SearchIcon fontSize="small" />
                  </Box>
                  <InputBase
                    style={{
                      width: "100%",
                      fontSize: 14
                    }}
                    id="searchByDealerNameInput"
                    placeholder="Search..."
                    inputProps={{ "aria-label": "search" }}
                    value={dealershipName}
                    autoComplete="search"
                    onChange={(e) => setDealershipName(e.target.value)}
                  />
                </Box>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6} container>
            <MonthYearToggle
              requestState={state}
              setRequestState={setState}
              toggleState={periodToggle}
              setToggle={setToggle}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Paper style={{ width: "100%" }}>
          {!isFetching && dealershipList?.entities ? (
            <React.Fragment>
              <AppBar position="sticky" color="primary">
                <Tabs
                  value={tabValue}
                  onChange={(e, newValue) => setTabValue(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={"Positive trend"} {...a11yProps(0)} />
                  <Tab label={"Negative trend"} {...a11yProps(1)} />
                  <Tab label={"Flat trend"} {...a11yProps(2)} />
                  <Tab label={"Dealerships without deals trend"} {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                {data ? (
                  generateTable(data.positive, data.formattedPeriods, dealershipList?.entities)
                ) : (
                  <Typography style={{ textAlign: "center" }}>No data</Typography>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {data ? (
                  generateTable(data.negative, data.formattedPeriods, dealershipList?.entities)
                ) : (
                  <Typography style={{ textAlign: "center" }}>No data</Typography>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {data ? (
                  generateTable(data.flat, data.formattedPeriods, dealershipList?.entities)
                ) : (
                  <Typography style={{ textAlign: "center" }}>No data</Typography>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                {data ? (
                  generateTable(
                    data.dealershipsWithoutDeals,
                    data.formattedPeriods,
                    dealershipList?.entities
                  )
                ) : (
                  <Typography style={{ textAlign: "center" }}>No data</Typography>
                )}
              </TabPanel>
            </React.Fragment>
          ) : (
            <Spinner />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

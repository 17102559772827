import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from "@reduxjs/toolkit";
import { AppDispatch } from "app/store";
import { getValidationErrorMessage } from "utils/functions";
import { validateSchema } from "validations";

interface Bag<T> {
  [id: string]: T;
}

export type socketMessageState<T> =
  | {
      status: "success";
      data: T;
    }
  | { status: "error"; message: string }
  | { status: "waiting" | "none" };

export const httpRequestPaths = [
  "dealership_ticket",
  "dealership_ticket_note",
  "export_column_group",
  "deal_from_parsed_document",
  "lender_link_generator",
  "lender_email_verification_link",
  "lender_send_generated_link",
  "credit_score_range",
  "lender_ticket_type",
  "stipulation",
  "deals_by_ids",
  "title_issues_by_ids",
  "resend_esign_email",
  "note",
  "column_group",
  "dealer_note",
  "title_issue_note",
  "lender_note",
  "checklist",
  "dealership_link_generator",
  "dealership_email_verification_link",
  "dealership_send_generated_link",
  "generate_contract_for_print",
  "generate_contract",
  "additional_required_document",
  "deals_order_many",
  "current_user_permissions",
  "sequence",
  "business_report",
  "applicant",
  "settings",
  "contract_type",
  "contract",
  "credit_score",
  "credit_smarts_application",
  "custom_report",
  "dealership",
  "deal",
  "delete_all_notifications",
  "dmv",
  "lender_ticket",
  "document_template",
  "document_validation",
  "external_credit_application",
  "file",
  "floorplanxpress",
  "funding_document",
  "history",
  "lender_decision",
  "lender",
  "lock",
  "notification",
  "other_vendor",
  "payoff_bank",
  "wfd_user",
  "role",
  "state",
  "title_issue",
  "vehicle_insurance_company",
  "api_status",
  "appone_import_application",
  "asc_cancel_transaction",
  "asc_get_rate",
  "asc_process_transaction",
  "dealertrack_deal",
  "external_credit_application_deal",
  "deals_order",
  "firebase_user",
  "email_send",
  "income_report",
  "express_ex1_contract_void",
  "express_ex1_contract",
  "express_ex1_dealer_product",
  "express_ex1_pre_rate",
  "express_ex1_rate",
  "protective_delete_contract",
  "protective_get_dealer_details",
  "protective_get_rv_rates",
  "protective_submit_rv_contracts",
  "report_token",
  "unapproved_user",
  "vin_check",
  "sales_order_to_invoice_and_vendor_bill",
  "applicant",
  "contract_type",
  "contract",
  "credit_score",
  "credit_score",
  "custom_report",
  "deal",
  "dealership",
  "delivered_deal",
  "dmv",
  "document_template",
  "document_validation",
  "external_credit_application",
  "file",
  "funding_document",
  "lender_decision",
  "lender_decisions",
  "lender",
  "other_vendor",
  "payoff_bank",
  "table_settings",
  "role",
  "chargeback",
  "state",
  "title_issue",
  "wfd_user",
  "dealership_statistics",
  "dealership_program",
  "manager_program",
  "dealership_bills_report",
  "deals_count_by_status",
  "deals_count_by_products",
  "deals_commision_by_status",
  "deals_commision_by_days",
  "salesrep_deals",
  "rollback_status",
  "chargeback_bills",
  "send_generated_contract",
  "printed_document",
  "unparsed_required_document",
  "reject_printed_document",
  "approve_printed_document",
  "recognize_funding_document",
  "one_span_signing",
  "send_approved_documents",
  "change_user_pin"
] as const;
export type HttpRequestPaths = typeof httpRequestPaths[number];
export type RequestsTopics =
  | "new_dealership_ticket_note"
  | "update_dealership_ticket_note"
  | "all_dealership_ticket_notes"
  | "delete_dealership_ticket_note"
  | "update_dealership_ticket"
  | "delete_dealership_ticket"
  | "recover_dealership_ticket"
  | "all_dealership_tickets"
  | "new_chargeback"
  | "update_chargeback"
  | "get_chargeback"
  | "recover_chargeback"
  | "delete_chargeback"
  | "all_chargebacks"
  | "lender_link_generator"
  | "lender_email_verification_link"
  | "lender_send_generated_link"
  | "all_deals_by_ids"
  | "all_title_issues_by_ids"
  | "resend_esign_email"
  | "new_checklist"
  | "update_checklist"
  | "all_checklists"
  | "send_approved_documents"
  | "dealership_link_generator"
  | "dealership_email_verification_link"
  | "dealership_send_generated_link"
  | "new_generate_contract_for_print"
  | "new_generate_contract"
  | "deals_count_by_status"
  | "deals_count_by_products"
  | "all_table_settings"
  | "all_dealership_statistics"
  | "update_table_settings"
  | "new_table_settings"
  | "get_applicant"
  | "get_contract_type"
  | "get_contract"
  | "get_credit_score"
  | "get_credit_score_range"
  | "get_lender_ticket_type"
  | "get_custom_report"
  | "get_deal"
  | "get_dealership"
  | "get_delivered_deal"
  | "get_dmv"
  | "get_lender_ticket"
  | "get_document_template"
  | "get_document_validation"
  | "get_external_credit_application"
  | "get_file"
  | "get_funding_document"
  | "get_lender_decision"
  | "get_lender_decisions"
  | "get_lender"
  | "get_other_vendor"
  | "get_payoff_bank"
  | "get_role"
  | "get_state"
  | "get_title_issue"
  | "get_dealership_ticket"
  | "get_dealership_program"
  | "get_manager_program"
  | "get_wfd_user"
  | "get_history"
  | "get_vehicle_insurance_company"
  | "new_business_report"
  | "update_business_report"
  | "delete_business_report"
  | "all_business_reports"
  | "recover_business_report"
  | "all_applicants"
  | "all_column_groups"
  | "all_settings"
  | "all_contract_types"
  | "all_contracts"
  | "all_credit_scores"
  | "all_credit_smarts_application"
  | "all_custom_reports"
  | "all_dealerships"
  | "all_deals"
  | "all_dmvs"
  | "all_title_issue_notes"
  | "all_stipulations"
  | "all_credit_score_ranges"
  | "all_lender_ticket_types"
  | "all_document_templates"
  | "all_document_validations"
  | "all_external_credit_applications"
  | "all_files"
  | "all_floorplanxpress"
  | "all_funding_documents"
  | "all_histories"
  | "all_lender_decisions"
  | "all_lenders"
  | "all_locks"
  | "all_notes"
  | "all_dealer_notes"
  | "all_lender_notes"
  | "all_notifications"
  | "all_other_vendors"
  | "all_payoff_banks"
  | "all_roles"
  | "all_states"
  | "all_lender_tickets"
  | "all_title_issues"
  | "all_dealership_programs"
  | "all_manager_programs"
  | "all_wfd_users"
  | "all_vehicle_insurance_companies"
  | "api_status"
  | "appone_import_application"
  | "asc_cancel_transaction"
  | "asc_get_rate"
  | "asc_process_transaction"
  | "dealertrack_deal_add"
  | "deals_order"
  | "deals_order_many"
  | "delete_stipulation"
  | "delete_all_notifications"
  | "delete_applicant"
  | "delete_contract_type"
  | "delete_contract"
  | "delete_lender_ticket"
  | "delete_title_issue_note"
  | "delete_credit_smarts_application"
  | "delete_custom_report"
  | "delete_credit_score_range"
  | "delete_lender_ticket_type"
  | "delete_deal"
  | "delete_dealership"
  | "delete_dmv"
  | "delete_document_template"
  | "delete_document_validation"
  | "delete_external_credit_application"
  | "delete_file"
  | "delete_firebase_user"
  | "delete_funding_document"
  | "delete_lender_decision"
  | "delete_column_group"
  | "delete_lender"
  | "delete_notification"
  | "delete_other_vendor"
  | "delete_payoff_bank"
  | "delete_role"
  | "delete_state"
  | "delete_note"
  | "delete_dealer_note"
  | "delete_lender_note"
  | "delete_title_issue"
  | "delete_dealership_program"
  | "delete_manager_program"
  | "delete_wfd_user"
  | "delete_vehicle_insurance_company"
  | "edit_document_validation"
  | "email_send"
  | "express_ex1_contract_void"
  | "express_ex1_contract"
  | "express_ex1_dealer_product"
  | "express_ex1_pre_rate"
  | "express_ex1_rate"
  | "get_income_report"
  | "new_applicant"
  | "new_contract_type"
  | "new_contract"
  | "new_credit_score"
  | "new_credit_score"
  | "new_stipulation"
  | "new_custom_report"
  | "new_deal"
  | "new_external_credit_application_deal"
  | "new_dealership"
  | "new_delivered_deal"
  | "new_note"
  | "new_column_group"
  | "new_dealer_note"
  | "new_title_issue_note"
  | "new_lender_note"
  | "new_dmv"
  | "new_deal_from_parsed_document"
  | "new_document_template"
  | "new_document_validation"
  | "new_external_credit_application"
  | "new_file"
  | "new_credit_score_range"
  | "new_lender_ticket_type"
  | "new_funding_document"
  | "new_lender_decision"
  | "new_lender_decisions"
  | "new_lender"
  | "new_other_vendor"
  | "new_payoff_bank"
  | "new_role"
  | "new_state"
  | "new_lender_ticket"
  | "new_title_issue"
  | "new_dealership_program"
  | "new_manager_program"
  | "new_wfd_user"
  | "new_vehicle_insurance_company"
  | "protective_delete_contract"
  | "protective_get_dealer_details"
  | "protective_get_rv_rates"
  | "protective_submit_rv_contracts"
  | "recover_applicant"
  | "recover_contract_type"
  | "recover_contract"
  | "recover_credit_smarts_application"
  | "recover_custom_report"
  | "recover_deal"
  | "recover_lender_ticket"
  | "recover_dealership"
  | "recover_dmv"
  | "recover_document_template"
  | "recover_external_credit_application"
  | "recover_file"
  | "recover_funding_document"
  | "recover_lender_decision"
  | "recover_lender"
  | "recover_other_vendor"
  | "recover_payoff_bank"
  | "recover_role"
  | "recover_state"
  | "recover_title_issue"
  | "recover_dealership_program"
  | "recover_credit_score_range"
  | "recover_lender_ticket_type"
  | "recover_manager_program"
  | "recover_wfd_user"
  | "recover_vehicle_insurance_company"
  | "report_token"
  | "unapproved_users"
  | "update_applicant"
  | "update_contract_type"
  | "update_contract"
  | "update_credit_score"
  | "update_credit_smarts_application"
  | "update_custom_report"
  | "update_deal"
  | "update_dealership"
  | "update_dmv"
  | "update_column_group"
  | "update_document_template"
  | "update_external_credit_application"
  | "update_file"
  | "update_lender_ticket"
  | "update_funding_document"
  | "update_lender_decision"
  | "update_lender"
  | "update_printed_document"
  | "update_note"
  | "update_dealer_note"
  | "update_title_issue_note"
  | "update_lender_note"
  | "update_lock"
  | "update_stipulation"
  | "update_notification"
  | "update_other_vendor"
  | "update_payoff_bank"
  | "update_printed_document"
  | "update_role"
  | "update_credit_score_range"
  | "update_lender_ticket_type"
  | "update_sequence"
  | "update_state"
  | "update_settings"
  | "update_title_issue"
  | "update_dealership_program"
  | "update_manager_program"
  | "update_wfd_user"
  | "update_vehicle_insurance_company"
  | "vin_check"
  | "export_column_group"
  | "sales_order_to_invoice_and_vendor_bill"
  | "new_dealership_bills_report"
  | "all_dealership_bills_reports"
  | "add_chargeback_bills"
  | "all_chargeback_bills"
  | "deals_commision_by_status"
  | "deals_commision_by_days"
  | "salesrep_deals"
  | "new_rollback_status"
  | "new_additional_required_document"
  | "update_additional_required_document"
  | "delete_additional_required_document"
  | "recover_additional_required_document"
  | "all_additional_required_documents"
  | "get_additional_required_document"
  | "send_generated_contract"
  | "all_printed_documents"
  | "new_printed_document"
  | "delete_printed_document"
  | "unparsed_required_document"
  | "reject_printed_document"
  | "approve_printed_document"
  | "recognize_funding_document"
  | "update_one_span_signing"
  | "change_user_pin";
interface Topics {
  request: RequestsTopics;
}

interface SocketSliceOptions<U, Reducers extends SliceCaseReducers<Bag<socketMessageState<U>>>> {
  initialState?: Bag<socketMessageState<U>>;
  reducers?: ValidateSliceCaseReducers<Bag<socketMessageState<U>>, Reducers>;
}

export interface Identifiable {
  requestId: string;
}

export const createSocketMessageSlice = <
  Request,
  Response,
  Reducers extends SliceCaseReducers<Bag<socketMessageState<Response>>> = SliceCaseReducers<
    Bag<socketMessageState<Response>>
  >
>(
  name: string,
  topics: Topics,
  options: SocketSliceOptions<Response, Reducers> = {}
) => {
  const initialState: Bag<socketMessageState<Response>> = {};

  const socketMessageSlice = createSlice({
    name,
    initialState: options.initialState || initialState,
    reducers: {
      add(state: Bag<socketMessageState<Response>>, action: PayloadAction<Identifiable>) {
        state[action.payload.requestId] = {
          status: "none"
        };
      },
      remove(state: Bag<socketMessageState<Response>>, action: PayloadAction<Identifiable>) {
        delete state[action.payload.requestId];
      },
      waiting(state: Bag<socketMessageState<Response>>, action: PayloadAction<Identifiable>) {
        state[action.payload.requestId] = {
          status: "waiting"
        };
      },
      none(state: Bag<socketMessageState<Response>>, action: PayloadAction<Identifiable>) {
        state[action.payload.requestId] = {
          status: "none"
        };
      },
      success(
        state: Bag<socketMessageState<Response>>,
        action: PayloadAction<Response & Identifiable>
      ) {
        state[action.payload.requestId] = {
          status: "success",
          data: action.payload
        };
      },
      error(
        state: Bag<socketMessageState<Response>>,
        action: PayloadAction<
          { message: { message: string; status: string }; requestId: string } & Identifiable
        >
      ) {
        if (action.payload)
          state[action.payload.requestId] = {
            status: "error",
            message:
              typeof action.payload.message === "string"
                ? action.payload.message
                : action?.payload?.message?.message ?? "Unknown Error!"
          };
      },
      ...(options.reducers ||
        ({} as ValidateSliceCaseReducers<Bag<socketMessageState<Response>>, Reducers>))
    }
  });
  const createAction = (requestId: string, request: any) => ({
    type: `${name}/restAction`,
    payload: request,
    http: {
      path: topics.request,
      success: {
        type: (socketMessageSlice.actions.success as any).type,
        additionalOptions: { requestId }
      },
      error: {
        type: (socketMessageSlice.actions.error as any).type,
        additionalOptions: { requestId }
      }
    },
    socket: {
      topic: topics.request,
      success: {
        type: (socketMessageSlice.actions.success as any).type,
        additionalOptions: { requestId }
      },
      error: {
        type: (socketMessageSlice.actions.error as any).type,
        additionalOptions: { requestId }
      }
    }
  });
  const socketAction = ({ requestId, ...request }: Request & Identifiable) => async (
    dispatch: AppDispatch
  ) => {
    const { valid, errors } = validateSchema(topics.request as any, request);
    if (valid) {
      dispatch({ type: (socketMessageSlice.actions.waiting as any).type, payload: { requestId } });
      dispatch(createAction(requestId, request));
    } else {
      console.log("Invalid request", topics.request, request, valid, errors, "message: ", {
        requestId,
        message: getValidationErrorMessage(errors)
      });
      dispatch({
        type: (socketMessageSlice.actions.error as any).type,
        payload: { requestId, message: getValidationErrorMessage(errors) }
      });
    }
  };

  return { socketMessageSlice, socketAction, createAction };
};

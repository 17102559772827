import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { Lender } from "./types";

export type LenderPermissionsStruct = PermissionStruct<Lender>;

export const lenderPermissionStruct: LenderPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      coordinates: {
        lat: permissionNode("Latitute"),
        lon: permissionNode("Longitude")
      },
      county: permissionNode("County"),
      allocationType: permissionNode("allocationType"),
      allocationDashboardSettings: {
        dealDateToUse: permissionNode("dealDateToUse")
      },
      esignAuth: {
        sms: permissionNode("sms"),
        kba: permissionNode("kba")
      },
      eSign: permissionNode("E-Sign"),
      requestCreditScoreReport: permissionNode("RequestCreditScoreReport"),
      name: permissionNode("Name"),
      securedPartyNumber: permissionNode("securedPartyNumber"),
      active: permissionNode("active"),
      address: permissionNode("address"),
      additionalAddresses: multyPermissionNode("additionalAddresses"),
      state: permissionNode("state"),
      city: permissionNode("city"),
      zipCode: permissionNode("zipCode"),
      phone: permissionNode("phone"),
      faxNumber: permissionNode("faxNumber"),
      email: permissionNode("email"),
      otherInfo: multyPermissionNode("otherInfo"),
      idNumber: permissionNode("idNumber"),
      defaultReservePercent: permissionNode("defaultReservePercent"),
      defaultBuyRate: permissionNode("defaultBuyRate"),
      recapCalcMethod: permissionNode("recapCalcMethod"),
      statePrefix: permissionNode("statePrefix"),
      nameAndFullAddress: permissionNode("nameAndFullAddress"),
      coverage: permissionNode("coverage"),
      specificRouteMethod: permissionNode("specificRouteMethod"),
      routeDescription: permissionNode("routeDescription"),
      markupTables: multyPermissionNode("markupTables"),
      adminEmail: permissionNode("Admin email"),
      adminFirstName: permissionNode("Admin first name"),
      adminLastName: permissionNode("Admin last name"),
      adminRegistrationLink: permissionNode("Admin registration link"),
      aliases: multyPermissionNode("Alliases"),
      isPlatform: permissionNode("Is platform"),
      operatingStates: {
        status: permissionNode("Status"),
        states: multyPermissionNode("operatingStates")
      },
      reserves: {
        automotive: multyPermissionNode("automotive"),
        rv: multyPermissionNode("rv"),
        marine: multyPermissionNode("marine"),
        powerSport: multyPermissionNode("powerSport")
      },
      routes: {
        collateralType: {
          provider: {
            isAvailable: permissionNode("Is available"),
            partyId: permissionNode("Party Id")
          }
        }
      },
      collateralTypes: {
        Automotive: permissionNode("Automotive"),
        Marine: permissionNode("Marine"),
        "Recreational Vehicles": permissionNode("Recreational Vehicles"),
        "Power Sport": permissionNode("Power Sport")
      },
      allocation: permissionNode("Allocation"),
      monthlyAllocationPeriods: multyPermissionNode("Monthly Allocationperiods"),
      yearlyAllocationPeriods: multyPermissionNode("Yearly Allocationperiods"),
      lifetimeAllocationPeriods: multyPermissionNode("Lifetime Allocationperiods")
    },
    requiredContractTypes: relationPermissionNode("Required contract types", "contract_type"),
    requiredContractTypesIds: multyPermissionNode("Required contract types ids"),
    representatives: multyPermissionNode("representatives"),
    requirements: multyPermissionNode("requirements"),
    notes: multyPermissionNode("notes"),

    additionalRequiredDocuments: relationPermissionNode(
      "additionaRequiredDocuments",
      "additional_required_document"
    ),
    additionalRequiredDocumentsIds: multyPermissionNode("additionaRequiredDocuments Ids"),
    netsuiteId: permissionNode("Netsuite Id"),
    roundToDollar: permissionNode("Round to dollar")
  }
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { getDocumentTemplateList } from "components/DocumentTemplates/listDocumentTemplateSlice";
import { DealTypeProperties, DocumentTemplate } from "components/DocumentTemplates/types";

import { AdditionalRequiredDocument } from "components/AdditionalRequiredDocuments/types";
import formEditContext from "components/Content/FormEditContext";
import { CreditBureau } from "components/CreditScore/types";
import UploadContract from "components/ExpressApi/Deal/UploadContract";
import Print from "components/ExternalCreditApplications/Print";
import { Lender } from "components/Lenders/types";
import TextLoop from "components/Loader/TextLoop";
import getCategoryData from "components/Validations/dealTypeGetter";
import { Category } from "components/Validations/types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "utils/functions";
import { RenderSet, StateAccess, getByPath, setByPath } from "utils/models/formGenerator";
import CloseDialogButton from "../common/CloseDialogButton";
import { getChecklistList, removeChecklistList } from "./Checklist/listChecklistSlice";
import { Checklist as ChecklistType } from "./Checklist/types";
import Checklist from "./Documents/Checklist";
import DocumentsHistory from "./Documents/DocumentsHistory";
import DocumentsToGenerate from "./Documents/DocumentsToGenerate";
import GenerateDocumentsStepper from "./Documents/GenerateDocumentsStepper";
import NewOtherRequiredDocument from "./Documents/NewOtherRequiredDocument";
import {
  DocumentsTypesLiterals,
  ModalAdditionalRequiredDocumentType,
  ModalAllInsurancesDocumentType,
  ModalCreditScoreType,
  ModalDocumentTemplateType,
  ModalDocumentType,
  ModalDocuments,
  PackType,
  SortedDocumentTemplates
} from "./Documents/types";
import { AllInsurancesTypes, Deal, isAsc, isCustom, isFIExpress } from "./types";
import { CreditPrequalify } from "types/credit_prequalify";
import { extractUrlFromResponse } from "components/CreditScore/CreditPrequalifyView";

interface Props {
  stateAccess: StateAccess;
  checklistStateAccess: StateAccess;
  checklistListId: string;
  renderSet: RenderSet;
  open: boolean;
  handleClose: () => void;
}

export const isContractUploaded = (doc: AllInsurancesTypes) =>
  isAsc(doc) ? doc.PDF : isFIExpress(doc) ? doc.chosenRate.ContractForm : doc.url;

const addAdditionalPropertiesInsurance = (
  doc: AllInsurancesTypes,
  url?: string
): ModalAllInsurancesDocumentType => {
  return {
    _id: doc.id,
    type: DocumentsTypesLiterals.insurance_document,
    name: `Copy of ${formatProductName(doc)} contract`,
    displayName: `Copy of ${formatProductName(doc)} contract`,
    documentData: doc,
    provider: doc.provider,
    fileUrl: url || "",
    packType: PackType.email,
    additionalProperties: {
      required: true,
      dealerships: doc.chosenRate.soldBy === "Dealer",
      applicant: false,
      eSign: doc.type !== "Custom"
    }
  };
};
const addAdditionalPropertiesCreditScore = (
  doc: CreditBureau | CreditPrequalify,
  url?: string
): ModalCreditScoreType => {
  return {
    _id: doc._id,
    type: DocumentsTypesLiterals.credit_score,
    name: `Credit Report for ${capitalize(doc?.data?.request?.NAME)}`,
    displayName: `Credit Report for ${capitalize(doc?.data?.request?.NAME)}`,
    documentData: doc,
    fileUrl: url || "",
    packType: PackType.email,
    additionalProperties: {
      required: true,
      dealerships: true,
      applicant: false,
      eSign: false
    }
  };
};
const addAdditionalPropertiesAdditionalRequiredDocuments = (
  doc: AdditionalRequiredDocument
): ModalAdditionalRequiredDocumentType => {
  return {
    _id: doc._id,
    type: DocumentsTypesLiterals.additional_required_document,
    name: doc.data.info.name,
    displayName: doc.data.info.name,
    documentData: doc,
    packType: PackType.email,
    additionalProperties: {
      required: true,
      dealerships: doc.data?.info?.requestedBy?.dealership || false,
      applicant: doc.data?.info?.requestedBy?.applicant || false,
      eSign: doc.data?.info?.requestedBy?.eSign || false
    }
  };
};

export const addAdditionalPropertiesDocumentTemplate = (
  doc: DocumentTemplate
): ModalDocumentTemplateType => {
  return {
    _id: doc._id,
    type: DocumentsTypesLiterals.document_template,
    name: doc.data.info.name,
    packType: PackType.email,
    displayName: doc.data.info.displayName,
    forEsign: doc.data.info.requestedBy?.eSign,
    documentData: doc,
    additionalProperties: {
      required: doc?.data?.info?.requiredToBeReturned || true,
      dealerships: doc?.data?.info?.requestedBy?.dealership || false,
      applicant: doc?.data?.info?.requestedBy?.applicant || false,
      eSign: doc.data.info.requestedBy?.eSign || false
    }
  };
};

const dispatchGetDocumentTemplatesAndFiles = (
  dispatch: Dispatch<any>,
  requestId: string,
  dealCategory: Category
) => {
  dispatch(
    getDocumentTemplateList(requestId, {
      options: { limit: 100_000 }
    })
  );
};
const isStringArray = (
  property: string[] | Lender[] | boolean | undefined | string
): property is string[] => {
  return (
    Array.isArray(property) &&
    (property as any[]).every((x: Lender | "string") => typeof x === "string")
  );
};

const checkEquality = (
  documentField: keyof DealTypeProperties,
  categoryField: keyof Category,
  document: DocumentTemplate,
  dealCategory: Category
) => {
  const documentFieldData = document?.data?.info?.dealTypeProperties?.[documentField];
  if (typeof documentFieldData === "string")
    return documentFieldData === dealCategory[categoryField];
  if (!documentFieldData || (Array.isArray(documentFieldData) && documentFieldData?.length === 0))
    return true;

  if (typeof documentFieldData === "boolean")
    return documentFieldData === dealCategory[categoryField];
  if (
    Array.isArray(documentFieldData) &&
    !isStringArray(documentFieldData) &&
    categoryField === "lenderId"
  ) {
    return (
      documentFieldData?.some((el) => {
        return el._id === dealCategory[categoryField] ? true : false;
      }) || documentFieldData?.length === 0
    );
  } else {
    return (
      isStringArray(documentFieldData) &&
      // @ts-ignore
      documentFieldData?.includes(dealCategory[categoryField]?.toString())
    );
  }
};

const sortDocuments = (
  documents: DocumentTemplate[],
  dealCategory: Category,
  hasTrailer: boolean
) =>
  documents.reduce(
    (acc: SortedDocumentTemplates, document) => {
      if (
        checkEquality("hasCoApplicant", "hasCoApplicant", document, dealCategory) &&
        checkEquality("hasOneApplicant", "hasOneApplicant", document, dealCategory) &&
        checkEquality("hasVSC", "hasWarranty", document, dealCategory) &&
        checkEquality("hasGAP", "hasGap", document, dealCategory) &&
        checkEquality("lenders", "lenderId", document, dealCategory) &&
        checkEquality("programType", "programType", document, dealCategory) &&
        (checkEquality("states", "state", document, dealCategory) ||
          ((document?.data?.info?.dealTypeProperties?.applicantStates?.length || 0) > 0 &&
            checkEquality("applicantStates", "applicantState", document, dealCategory))) &&
        checkEquality("types", "collateralType", document, dealCategory) &&
        (hasTrailer ? true : !document?.data?.info?.dealTypeProperties?.hasTrailer)
      ) {
        return {
          ...acc,
          suggestedDocuments: [...(acc["suggestedDocuments"] ?? []), document]
        };
      } else {
        return {
          ...acc,
          otherDocuments: [...(acc["otherDocuments"] ?? []), document]
        };
      }
    },
    { otherDocuments: [], suggestedDocuments: [] }
  );

const displayDocuments = (
  sortedDocuments: DocumentTemplate[],
  selectedDocuments: ModalDocuments,
  handleChange: (ModalDocumentTemplate: ModalDocumentType) => void
) => {
  return (
    <>
      {(sortedDocuments ?? [])
        .sort((a, b) => a?.data?.info?.name?.localeCompare(b?.data?.info?.name))
        .map((document) => {
          return (
            <div key={document._id}>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                      <Grid item xs={10} key={`title-${document._id}`}>
                        {document.data.info.name}
                      </Grid>
                    </Grid>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={selectedDocuments?.some((x) => x._id === document._id)}
                    onChange={(e) =>
                      handleChange(addAdditionalPropertiesDocumentTemplate(document))
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </>
  );
};
const displayAdditionalDocuments = (
  additionalDocuments: AdditionalRequiredDocument[],
  selectedDocuments: ModalDocuments,
  handleChange: (ModalDocumentTemplate: ModalDocumentType) => void
) => {
  return (
    <>
      {(additionalDocuments ?? [])
        .slice()
        .sort((a, b) => a?.data?.info?.name?.localeCompare(b?.data?.info?.name))
        .map((document) => {
          return (
            <div key={document._id}>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                      <Grid item xs={10} key={`title-${document._id}`}>
                        {document.data.info.name}
                      </Grid>
                    </Grid>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={selectedDocuments?.some((x) => x._id === document._id)}
                    onChange={(e) =>
                      handleChange(addAdditionalPropertiesAdditionalRequiredDocuments(document))
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </>
  );
};
const formatProductName = (product: AllInsurancesTypes) => {
  return `${capitalize(product?.provider || "Insurance provider: N/A")} - ${capitalize(
    product?.chosenRate?.insuranceType === "service warranty"
      ? "warranty"
      : product?.chosenRate?.insuranceType || "Insurance type: N/A"
  )}`;
};
const displayInsuranceDocs = (
  insuranceDocs: AllInsurancesTypes[],
  selectedDocuments: ModalDocuments,
  handleChange: (ModalDocumentTemplate: ModalAllInsurancesDocumentType) => void,
  stateAccess: StateAccess,
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const _id = stateAccess.get(["_id"]);
  return (
    <>
      {(insuranceDocs ?? [])
        .slice()
        .filter(
          (document) =>
            !(document.provider === "Protective" && document?.chosenRate?.insuranceType === "gap")
        )
        .sort((a, b) => a?.provider?.localeCompare(b?.provider))
        .map((document, index) => {
          return (
            <div key={index}>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                      <Grid item xs={10} key={`title-${index}`}>
                        {formatProductName(document)}
                      </Grid>
                    </Grid>
                  }
                />
                <ListItemSecondaryAction>
                  <div style={{ display: "flex" }}>
                    {isCustom(document) && (
                      <UploadContract
                        name={"upload-contract-modal"}
                        stateAccess={stateAccess}
                        index={index}
                        insurance={document}
                        _id={_id}
                        containerRef={containerRef}
                      />
                    )}
                    <Checkbox
                      checked={selectedDocuments?.some((x) => x._id === document.id)}
                      onChange={() => {
                        const url = isContractUploaded(document);
                        handleChange(addAdditionalPropertiesInsurance(document, url || ""));
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </>
  );
};

const DocumentItem = ({
  title,
  url,
  isSelected,
  onChange,
  id
}: {
  title: string;
  url: string;
  isSelected: boolean;
  onChange: () => void;
  id: string;
}) => (
  <>
    <ListItem key={id}>
      <ListItemText
        primary={
          <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
            <Grid item xs={10}>
              {title}
            </Grid>
          </Grid>
        }
      />
      <ListItemSecondaryAction>
        <div style={{ display: "flex" }}>
          <Checkbox
            checked={isSelected}
            onChange={onChange}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
      </ListItemSecondaryAction>
    </ListItem>
    <Divider />
  </>
);

const displayCreditScoreDocs = (
  creditScores: CreditBureau[],
  creditPrequalify: CreditPrequalify[],
  selectedDocuments: ModalDocuments,
  handleChange: (ModalDocumentTemplate: ModalCreditScoreType) => void,
  stateAccess: StateAccess,
  containerRef: React.RefObject<HTMLDivElement>
) => {
  return (
    <>
      {(creditScores ?? []).map((creditScore, index) => {
        const url =
          creditScore?.data?.["700CreditResponse"]?.Results?.custom_report_url?.[0]?.iframe?.[0]
            ?.attributes?.src;

        return (
          <DocumentItem
            key={`credit-score-${index}`}
            title={`Credit Report for ${capitalize(creditScore?.data?.request?.NAME)}`}
            url={url || ""}
            isSelected={selectedDocuments?.some((x) => x._id === creditScore._id)}
            onChange={() => {
              handleChange(addAdditionalPropertiesCreditScore(creditScore, url || ""));
            }}
            id={creditScore._id}
          />
        );
      })}

      {(creditPrequalify ?? []).map((prequalify, index) => {
        const url = extractUrlFromResponse(prequalify);

        return (
          <DocumentItem
            key={`credit-prequalify-${index}`}
            title={`Prequalification Report for ${capitalize(prequalify?.data?.request?.NAME)}`}
            url={url || ""}
            isSelected={selectedDocuments?.some((x) => x._id === prequalify._id)}
            onChange={() => {
              handleChange(addAdditionalPropertiesCreditScore(prequalify, url || ""));
            }}
            id={prequalify._id}
          />
        );
      })}
    </>
  );
};

export default function DocumentsModal({
  stateAccess,
  checklistListId,
  checklistStateAccess,
  open,
  handleClose
}: Props) {
  const deal: Deal = stateAccess.get<Deal>([] as any);
  const checklistState: ChecklistType = checklistStateAccess.get([]);
  const dispatch = useDispatch();
  const requestId = `documents-in-deal-preload`;
  const checklist = useSelector(
    (rootState: RootState) => rootState.listChecklistSlice[checklistListId]
  )?.entities?.[0];
  const [checklistDealState, setChecklistDealState] = useState<Deal>(deal);

  const checklistDealStateAccess: StateAccess = {
    get: (path) => getByPath(checklistDealState, path),
    set: (path, value): any =>
      setChecklistDealState(setByPath((checklistDealState as any) ?? {}, path, value))
  };
  const [openedAccordion, setOpenedAccordion] = useState<"suggested" | "lib" | false>("suggested");
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const dealCategory = getCategoryData(deal);
  const [allDocumentsAreSelected, setAllDocumentsAreSelected] = useState(false);
  const documentTemplates = useSelector(
    (state: RootState) => state.listDocumentTemplateSlice[requestId]
  );
  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const hasTrailer = deal?.data?.info?.vehicle?.hasTrailer ?? false;
  const containerRef = useRef<HTMLDivElement>(null);
  const { enabled: editMode } = useContext(formEditContext);
  const additionalRequiredDocuments: AdditionalRequiredDocument[] =
    stateAccess.get<Deal>(["data", "lender", "data", "additionalRequiredDocuments"]) ?? [];
  const sortedDocuments = sortDocuments(
    [...(documentTemplates?.entities ?? [])],
    dealCategory,
    hasTrailer
  );
  const insuranceDocs: AllInsurancesTypes[] =
    stateAccess.get<Deal>(["data", "info", "aftermarketOptions", "insurances"]) ?? [];

  useEffect(() => {
    dispatch(
      getChecklistList(checklistListId, {
        options: { pagination: false },
        query: { "data.dealId": deal._id }
      })
    );
    return () => {
      dispatch(removeChecklistList(checklistListId));
      return;
    };
  }, [dispatch, checklistListId]);

  const setDocuments = (newDocuments: ModalDocuments) =>
    checklistStateAccess.set(["data", "info", "documents"], newDocuments);

  const handleDocumentsChange = (doc: ModalDocumentType) => {
    if ((checklistState?.data?.info?.documents ?? [])?.find((x) => x._id === doc?._id)) {
      checklistStateAccess.set(
        ["data", "info", "documents"],
        checklistState?.data?.info?.documents?.filter((el) => el._id !== doc._id)
      );
    } else {
      checklistStateAccess.set(
        ["data", "info", "documents"],
        [...(checklistState?.data?.info?.documents ?? []), doc]
      );
    }
  };
  const handleSelectDeselectAll = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation();
    if (!allDocumentsAreSelected) {
      //SELECT
      checklistStateAccess.set(
        ["data", "info", "documents"],
        [
          ...(checklistState?.data?.info?.documents ?? [])?.filter(
            (prevDocument) => prevDocument.type !== DocumentsTypesLiterals.document_template
          ),
          ...additionalRequiredDocuments.map((document) =>
            addAdditionalPropertiesAdditionalRequiredDocuments(document)
          ),
          ...sortedDocuments.suggestedDocuments.map((document) =>
            addAdditionalPropertiesDocumentTemplate(document)
          ),
          ...sortedDocuments.otherDocuments.map((document) =>
            addAdditionalPropertiesDocumentTemplate(document)
          ),
          ...insuranceDocs.map((document) => addAdditionalPropertiesInsurance(document))
        ]
      );

      setAllDocumentsAreSelected(true);
    } else {
      //DESELECT
      checklistStateAccess.set(
        ["data", "info", "documents"],
        (checklistState?.data?.info?.documents ?? [])?.filter(
          (prevDocument) => prevDocument.type === DocumentsTypesLiterals.other_required_document
        )
      );

      setAllDocumentsAreSelected(false);
    }
  };
  useEffect(() => {
    if (checklist) {
      const assignedDealershipEmails = deal.data.info.assignedDealershipEmails;
      checklistStateAccess.set([], {
        ...checklist,
        data: {
          ...checklist.data,
          info: {
            ...checklist.data.info,
            eSignEmailData: {
              ...(checklist.data.info?.eSignEmailData ?? {}),
              ...(assignedDealershipEmails?.length > 0
                ? { dealership: assignedDealershipEmails[0] }
                : {})
            }
          }
        }
      });
    }
  }, [checklist]);
  useEffect(() => {
    if (documentTemplates?.status !== "success")
      dispatchGetDocumentTemplatesAndFiles(dispatch, requestId, dealCategory);
  }, []);

  const handleAccordionChange = (panel: "lib" | "suggested") => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setOpenedAccordion(newExpanded ? panel : false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={open}>
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ paddingRight: "4px", width: "100%", height: "50px" }}
        >
          <Grid item xs={6} key="title">
            <DialogTitle>
              Generate documents
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>

        <DialogContent style={{ height: "900px" }}>
          {documentTemplates?.status === "success" ? (
            <Grid container spacing={1} style={{ height: "100%" }}>
              <Grid item xs style={{ height: "100%" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", height: "100%", gap: "12px" }}
                >
                  {documentTemplates ? (
                    <>
                      <div
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #cecece",
                          boxShadow:
                            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                          padding: "2px",
                          ...(openedAccordion === "suggested"
                            ? { flex: "1 1 auto", overflowY: "auto", minHeight: "0px" }
                            : {})
                        }}
                      >
                        <Accordion
                          elevation={0}
                          TransitionProps={{ timeout: 0 }}
                          expanded={openedAccordion === "suggested"}
                          onChange={handleAccordionChange("suggested")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%"
                              }}
                            >
                              <Typography variant="h6">Suggested documents</Typography>
                              {["qa2@wfd.com", "wfd@wfd.com"].includes(
                                user?.data?.info?.email || ""
                              ) && (
                                <Button onClick={(e) => handleSelectDeselectAll(e)}>
                                  {allDocumentsAreSelected ? "Deselect All" : "Select All"}
                                </Button>
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List style={{ width: "100%" }}>
                              {displayDocuments(
                                sortedDocuments.suggestedDocuments,
                                checklistState?.data?.info?.documents ?? [],
                                handleDocumentsChange
                              )}
                              {displayAdditionalDocuments(
                                additionalRequiredDocuments,
                                checklistState?.data?.info?.documents ?? [],
                                handleDocumentsChange
                              )}
                              {displayInsuranceDocs(
                                insuranceDocs,
                                checklistState?.data?.info?.documents ?? [],
                                handleDocumentsChange,
                                stateAccess,
                                containerRef
                              )}
                              {!deal?.data?.lender?.data?.info?.requestCreditScoreReport
                                ? displayCreditScoreDocs(
                                    deal.data.creditBureaus,
                                    deal.data.creditPrequlify ?? [],
                                    checklistState?.data?.info?.documents ?? [],
                                    handleDocumentsChange,
                                    stateAccess,
                                    containerRef
                                  )
                                : null}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <div
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #cecece",
                          boxShadow:
                            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                          padding: "2px",
                          ...(openedAccordion === "lib"
                            ? { flex: "1 1 auto", overflowY: "auto", minHeight: "0px" }
                            : {})
                        }}
                      >
                        <Accordion
                          elevation={0}
                          TransitionProps={{ timeout: 0 }}
                          expanded={openedAccordion === "lib"}
                          onChange={handleAccordionChange("lib")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h6">Library of documents</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List style={{ width: "100%" }}>
                              {displayDocuments(
                                sortedDocuments.otherDocuments,
                                checklistState?.data?.info?.documents ?? [],
                                handleDocumentsChange
                              )}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <div
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #cecece",
                          boxShadow:
                            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                          marginTop: "auto",
                          padding: "2px"
                        }}
                      >
                        <Paper
                          elevation={0}
                          style={{
                            padding: 10
                          }}
                        >
                          <Typography variant="h6">Other required documents</Typography>
                          <NewOtherRequiredDocument
                            documents={checklistState?.data?.info?.documents ?? []}
                            setDocuments={setDocuments}
                            dealId={deal._id}
                          />
                        </Paper>
                      </div>
                    </>
                  ) : (
                    <TextLoop text=""></TextLoop>
                  )}
                </div>
              </Grid>
              <Grid item xs style={{ height: "calc(100% - 20px)" }}>
                <Checklist
                  stateAccess={stateAccess}
                  checklistStateAccess={checklistStateAccess}
                  documents={checklistState?.data?.info?.documents ?? []}
                  setDocuments={setDocuments}
                />
              </Grid>
              {/* <Grid item xs={2} style={{ height: "calc(100% )" }}>
                <DocumentsToGenerate
                  documents={checklistState?.data?.info?.documents ?? []}
                  stateAccess={stateAccess}
                />
              </Grid> */}
              <Grid item xs={4} style={{ height: "calc(100% - 20px)" }}>
                <GenerateDocumentsStepper
                  checklistDealStateAccess={checklistDealStateAccess}
                  editMode={editMode}
                  stateAccess={stateAccess}
                  checklistStateAccess={checklistStateAccess}
                  documentTemplates={documentTemplates}
                />
              </Grid>
              {showHistory && (
                <Grid item xs style={{ height: "calc(100% )" }}>
                  <DocumentsHistory
                    stateAccess={stateAccess}
                    checklistStateAccess={checklistStateAccess}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "700px"
              }}
            >
              <TextLoop />
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ position: "relative" }}>
          <Button
            autoFocus
            onClick={() => setShowHistory(!showHistory)}
            id="close-validate-documents"
            color="primary"
            variant="contained"
            style={{ position: "absolute", left: "25px" }}
          >
            {showHistory ? "hide" : "show"} history
          </Button>

          <Button
            autoFocus
            onClick={handleClose}
            id="close-validate-documents"
            color="primary"
            variant="contained"
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

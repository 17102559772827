import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import React from "react";
import { useSelector } from "react-redux";

import { Button, capitalize, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import AccessControl from "components/Access/AccessControl";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import Table, { CellValue, Column } from "components/Table";
import { DmvStatus, FormState } from "components/TitleIssues/types";
import States from "us-states";

import { collateralTypes } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import StyledBadge from "components/StyledBadge";
import { deleteTitleIssue } from "components/TitleIssues/deleteTitleIssueSlice";
import { dmvStatusToChip } from "components/TitleIssues/DocumentsSection";
import { getTitleIssueList } from "components/TitleIssues/listTitleIssueSlice";
import { getDaysLeft, getNextCheckDate } from "components/TitleIssues/Overview";
import { recoverTitleIssue } from "components/TitleIssues/recoverTitleIssueSlice";
import useGetTitleIssueCountByDmvStatus from "hooks/useGetTitleIssueCount/useGetTitleIssueCountByDmvStatus";
import { useStickyState } from "index";

export const projections = {
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.deal.data.applicant.data.info.firstName": 1,
  "data.deal.data.applicant.data.info.lastName": 1,
  "data.deal.data.coApplicant.data.info.firstName": 1,
  "data.deal.data.coApplicant.data.info.lastName": 1,
  "data.deal.data.lender.data.info.name": 1,
  "data.deal.data.dealership.data.info.displayName": 1,
  "data.deal.data.lender._id": 1,
  "data.deal.data.dealership._id": 1,
  "data.deal.data.info.refNumber": 1,
  "data.deal.data.info.type": 1,
  "data.deal.data.info.vehicle.VIN": 1,
  "data.deal.data.info.dealDates.contractDate": 1,
  "data.deal.data.info.dealDates.submittedForFunding": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.lastCheckedDate": 1,
  "data.info.dmvCheckedCount": 1,
  _id: 1,
  createdAt: 1,
  deleted: 1
};
export type DmvQuery = {
  lender: object | null;
  dmv: object | null;
  collateralTypes: object | null;
};
type Props = {
  dmvLenderCollateralTypesQuery: DmvQuery;
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
};

const SupportedStates = ["South Dakota", "North Dakota", "Illinois", "Minnesota"];
const dmvStatusToColor = (status: DmvStatus) => {
  switch (status) {
    case "titled":
      return "rgba(0, 128, 0, 0.2)";
    case "error":
      return "rgba(250, 155, 155, 0.4)";
    case "old_title":
      return "#CECECE";
    case "untitled":
      return "rgba(238, 210, 2, 0.2)";
    case "not_checked":
      return "#75b3f0";
  }
};

const getDmvLenderCollateralTypesQuery = (
  dmvLenderCollateralTypesQuery: DmvQuery,
  statesQuery?: object | undefined
) => {
  if (Object.values(dmvLenderCollateralTypesQuery).filter((x) => x)?.length) {
    return {
      "data.deal.data.applicant.data.info.currentState": { $in: SupportedStates },
      "data.deal.data.info.dealDates.contractDate": { $ne: null },
      "data.deal.data.info.vehicle.VIN": { $ne: null },
      ...(statesQuery ? {} : dmvLenderCollateralTypesQuery?.dmv || {}),
      ...(dmvLenderCollateralTypesQuery?.lender || {}),
      ...(dmvLenderCollateralTypesQuery?.collateralTypes || {})
    };
  }
};

export default ({ dmvLenderCollateralTypesQuery, setResetStickyCallback }: Props): JSX.Element => {
  const sliceId = "latest-title-issues";
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice[sliceId]);
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();

  const [overallQuery, setOverallQuery] = useStickyState<
    | {
        active: DmvStatus;
        query: Record<string, DmvStatus | Record<string, DmvStatus>[]>;
      }
    | undefined
  >(
    {
      active: "untitled",
      query: {
        "data.info.dmvInfo.status": "untitled"
      }
    },
    "latest_titles_table_overallQuery"
  );
  const [statesQuery, setStatesQuery] = useStickyState<{
    supported: { query: object | undefined };
    unsupported: { query: object | undefined };
  }>(
    { supported: { query: undefined }, unsupported: { query: undefined } },
    "latest_titles_table_statesQuery"
  );

  const { data } = useGetTitleIssueCountByDmvStatus(
    getDmvLenderCollateralTypesQuery(dmvLenderCollateralTypesQuery)
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => getDaysLeft(entry),
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return (
            <Typography style={x === "Overdue!" ? { color: "red", fontWeight: "bold" } : {}}>
              {x}
            </Typography>
          );
        },
        sort: true,
        path: ["data", "deal", "data", "info", "dealDates", "submittedForFunding"]
      },
      label: "Days left",
      name: "daysLeft",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.daysLeft?.show;
      }
    },
    {
      getData: (entry): CellValue => getNextCheckDate(entry),
      label: "Next check date",
      name: "nextCheckDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.nextCheckDate?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.info?.dmvInfo?.status,
      label: "DMV Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return dmvStatusToChip(x as DmvStatus);
        },
        sort: true,
        path: ["data", "info", "dmvInfo", "status"]
      },
      name: "dmvStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvStatus?.show;
      }
    },
    {
      getData: (entry): CellValue => entry?.data?.info?.dmvCheckedCount,
      label: "DMV Checked count",
      name: "dmvCheckedCount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvCheckedCount?.show;
      },
      filters: [
        {
          path: ["data", "info", "dmvCheckedCount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        [
          entry.data?.deal?.data?.applicant
            ? `${entry.data?.deal?.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.deal?.data?.coApplicant
            ? `${entry.data?.deal?.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Applicant",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "deal", "data", "applicant", "data", "info", "firstName"],
            ["data", "deal", "data", "applicant", "data", "info", "lastName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "firstName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.deal?.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.dealership?.data?.info?.displayName,
      label: "Dealer",
      name: "dealer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealer?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.refNumber,
      label: "Deal ref.",
      name: "dealRef",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealRef?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "State",
      getData: (entry): CellValue =>
        entry?.data?.deal?.data?.applicant?.data?.info?.currentState ?? "",
      options: {
        sort: true,
        path: ["data", "deal", "data", "applicant", "data", "info", "currentState"]
      },
      name: "state",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.state?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "applicant", "data", "info", "currentState"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort()
        }
      ]
    },
    {
      label: "Type",
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.type,
      options: {
        sort: true,
        path: ["data", "deal", "data", "info", "type"]
      },
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: [...collateralTypes].sort()
        }
      ]
    },

    {
      getData: (entry): CellValue =>
        entry.data?.deal?.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.deal?.data?.info?.dealDates?.contractDate)
          : "",
      label: "Contract",
      options: {
        sort: true,
        path: ["data", "deal", "data", "info", "dealDates", "contractDate"]
      },
      name: "contractDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "dealDates", "contractDate"],
          preview: DateFilter,
          label: "Contract Date"
        }
      ]
    },

    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const handleSetOverallQuery = (status: DmvStatus) => {
    setOverallQuery({
      active: status,
      query: {
        "data.info.dmvInfo.status": status
      }
    });
  };
  const constructQuery = () => {
    const result: Record<string, any> = {
      "data.deal.data.applicant.data.info.currentState": { $in: SupportedStates },
      "data.deal.data.info.dealDates.contractDate": { $ne: null },
      "data.deal.data.info.vehicle.VIN": { $ne: null },
      $and: []
    };
    const dmvLenderCollateralTypesQueryResult = getDmvLenderCollateralTypesQuery(
      dmvLenderCollateralTypesQuery,
      statesQuery?.supported?.query || statesQuery?.unsupported?.query
    );
    console.log(dmvLenderCollateralTypesQuery);
    if (overallQuery?.query) {
      result["$and"] = [...result?.["$and"], overallQuery?.query];
    }
    if (Object.values(dmvLenderCollateralTypesQueryResult ?? {}).filter((x) => x)?.length) {
      result["$and"] = [...result?.["$and"], dmvLenderCollateralTypesQueryResult];
    }
    if (statesQuery?.supported?.query) {
      result["$and"] = [...result?.["$and"], statesQuery?.supported?.query];
    } else if (statesQuery?.unsupported?.query) {
      result["$and"] = [...result?.["$and"], statesQuery?.unsupported?.query];
    }
    return result;
  };
  const handleResetStickyState = () => {
    setOverallQuery({
      active: "untitled",
      query: {
        "data.info.dmvInfo.status": "untitled"
      }
    });
    setStatesQuery({ supported: { query: undefined }, unsupported: { query: undefined } });
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12} style={{ margin: "5px 0" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography variant="h6">DMV Status</Typography>
          <Button
            style={{ margin: "5px" }}
            variant={"outlined"}
            onClick={() => {
              setOverallQuery(undefined);
              setStatesQuery({
                supported: { query: undefined },
                unsupported: { query: undefined }
              });
            }}
          >
            Reset
          </Button>
          {(["untitled", "titled", "error", "old_title", "not_checked"] as DmvStatus[]).map(
            (status, index) => (
              <StyledBadge
                key={index}
                badgeContent={overallQuery?.active === status ? 1 : 0}
                color={dmvStatusToColor(status)}
                variant="dot"
              >
                <Button
                  style={{ margin: "5px", background: dmvStatusToColor(status) }}
                  variant={"outlined"}
                  onClick={() => handleSetOverallQuery(status)}
                >
                  {`${capitalize(status).replace("_", " ")} ${data?.[status] ?? ""}`}
                </Button>
              </StyledBadge>
            )
          )}
          {(["supported", "unsupported"] as const).map((stateQueryType, index) => (
            <StyledBadge
              key={index}
              badgeContent={statesQuery?.[stateQueryType]?.query ? 1 : 0}
              color={stateQueryType === "supported" ? "#24b347" : "#ed1a1a"}
              variant="dot"
            >
              <Tooltip
                title={
                  stateQueryType === "supported"
                    ? `Show only title information for the supported states: ${SupportedStates.join(
                        ", "
                      )}`
                    : `Show only title information for states other than: ${SupportedStates.join(
                        ", "
                      )}`
                }
              >
                <Button
                  style={{
                    margin: "5px",
                    background: stateQueryType === "supported" ? "green" : "red"
                  }}
                  variant={"outlined"}
                  onClick={() =>
                    setStatesQuery(
                      (prev) =>
                        (prev?.[stateQueryType]?.query
                          ? {
                              supported: {
                                query: undefined
                              },
                              unsupported: { query: undefined }
                            }
                          : {
                              [stateQueryType === "supported" ? "unsupported" : "supported"]: {
                                query: undefined
                              },
                              [stateQueryType]: {
                                query:
                                  stateQueryType === "supported"
                                    ? {
                                        $or: [
                                          {
                                            "data.deal.data.applicant.data.info.currentState": {
                                              $in: SupportedStates
                                            }
                                          },
                                          {
                                            "data.deal.data.coApplicant.data.info.currentState": {
                                              $in: SupportedStates
                                            }
                                          }
                                        ]
                                      }
                                    : {
                                        "data.deal.data.applicant.data.info.currentState": {
                                          $nin: SupportedStates
                                        },
                                        "data.deal.data.coApplicant.data.info.currentState": {
                                          $nin: SupportedStates
                                        }
                                      }
                              }
                            }) as {
                          supported: {
                            query: object | undefined;
                          };
                          unsupported: {
                            query: object | undefined;
                          };
                        }
                    )
                  }
                >
                  {`${capitalize(stateQueryType)} states`}
                </Button>
              </Tooltip>
            </StyledBadge>
          ))}

          <AccessControl requiredPermissions={{ entity: "title_issue", action: "read" }}>
            <Table
              setResetStickyCallback={setResetStickyTableCallback}
              hideColumns={overallQuery?.active === "titled" ? ["daysLeft"] : []}
              elevation={0}
              tableContainerOverflowX="scroll"
              rowsPerPage={10}
              tableName={sliceId}
              entityName="title_issue"
              listFunction={getTitleIssueList}
              deleteEntityFunction={deleteTitleIssue}
              recoverEntityFunction={recoverTitleIssue}
              sort={{ "data.deal.data.info.dealDates.contractDate": "asc" }}
              slice={sliceId}
              listEntity={titleIssuesList}
              aggregateFirst={true}
              query={constructQuery()}
              projection={projections}
              title={"Title Information"}
              columns={columns}
              hideAddButton
            />
          </AccessControl>
        </Paper>
      </Grid>
    </div>
  );
};

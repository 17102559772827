import React from "react";
import InfoIcon from "@material-ui/icons/MoreHoriz";
import { createStyles, Grid, Link, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      "&:hover": {
        backgroundColor: "#CECECE0f"
      },
      border: "1px solid #CECECE52",
      borderRadius: "8px",
      padding: "14px 12px",
      cursor: "pointer"
    },
    title: { fontSize: 15, fontWeight: 500 },
    numbers: {
      fontWeight: 700,
      fontSize: 18
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

type Props = {
  title: string;
  number: string;
  scrollTo: string;
};
export default function DashboardButtonInfo({ title, number, scrollTo }: Props) {
  const styles = useStyles();
  return (
    <Link href={`#${scrollTo}`} underline="none">
      <Paper className={styles.container}>
        <Grid container xs={12} alignItems="center">
          <Grid className={styles.title} xs={10}>
            {title}
          </Grid>
          <Grid xs={2} className={styles.icon}>
            <InfoIcon color="primary" fontSize="medium" />
          </Grid>
          <Grid className={styles.numbers} xs={6}>
            {number ?? " "}
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
}

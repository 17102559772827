import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { EditLenderRequestSend, EditLenderRequest } from "./types";

interface EditLenderResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditLenderRequestSend,
  EditLenderResponse
>("EditLender", {
  request: "update_lender"
});

export const editLenderActions = socketMessageSlice.actions;

export const editLender = (request: EditLenderRequest & Identifiable) => {
  const { additionalRequiredDocuments, requiredContractTypes, ...rest } = request.data;

  return socketAction({
    ...request,
    data: {
      ...rest,
      additionalRequiredDocumentsIds: additionalRequiredDocuments?.map((el) => el._id),
      requiredContractTypesIds: requiredContractTypes?.map((el) => el._id)
    }
  });
};

export default socketMessageSlice.reducer;

import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";

import { Deal } from "components/Deals/types";
import { ShowForm } from "utils/models/ShowForm";
import { entityConfigs } from "utils/entitySlice";
import { StateAccess } from "utils/models/formGenerator";
import { TitleIssue } from "components/TitleIssues/types";
import { TabContext } from "components/Layout/LayoutWrapper";

import { LenderTicket } from "./types";
import AddLenderTicket from "./AddLenderTicket";

export default ({ stateAccess, path }: { stateAccess: StateAccess; path: string[] }) => {
  const tittleIssueId = stateAccess.get(["_id"]);
  const ticket = stateAccess.get<LenderTicket>(path);
  const createOrFocusTab = useContext(TabContext);

  const handlePreview = () =>
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: ticket._id,
        type: "lender_ticket"
      }
    });

  const setNewTicket = (data: TitleIssue) => stateAccess.set(path, data);

  return (
    <Grid container xs={12}>
      {ticket && ticket._id ? (
        <>
          <ShowForm
            editable={false}
            tabId="preview-ticket"
            initialState={ticket}
            hideEdit={true}
            model={entityConfigs["lender_ticket"].model}
          />
          <Button
            color="default"
            variant="contained"
            onClick={handlePreview}
            style={{ margin: 5, marginLeft: "auto", background: "white" }}
          >
            Open ticket
          </Button>
        </>
      ) : (
        <AddLenderTicket titleIssueId={tittleIssueId} setSelected={setNewTicket} />
      )}
    </Grid>
  );
};

import { File } from "./types";
import {
  multyPermissionNode,
  permissionNode,
  relationPermissionNode,
  PermissionStruct
} from "components/Roles/types";

export type FilePermissionsStruct = PermissionStruct<File>;

export const filePermissionStruct: FilePermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      name: permissionNode("name"),
      url: permissionNode("Url"),
      type: permissionNode("Type"),
      coordinates: multyPermissionNode("Coordinates"),
      description: permissionNode("Description"),
      metadata: {
        contentType: permissionNode("Content type")
      },
      types: relationPermissionNode("Types", "document_template"),
      status: permissionNode("status"),
      additionalContractDocument: permissionNode("Additional Contract Document"),
      contractDates: {
        signedDate: permissionNode("signedDate"),
        startDate: permissionNode("startDate"),
        endDate: permissionNode("endDate")
      }
    },
    chargeback: relationPermissionNode("Chargeback", "chargeback"),
    chargebackId: permissionNode("Chargeback id"),
    lenderTicketId: permissionNode("Lender ticket id"),
    lender_ticket: relationPermissionNode("Lender ticket", "lender_ticket"),
    deal: relationPermissionNode("Deal", "deal"),
    dealId: permissionNode("Deal id"),
    contract: relationPermissionNode("Contract", "contract"),
    contractId: permissionNode("Contract id"),
    document_template: relationPermissionNode("Document template", "document_template"),
    documentTemplateId: permissionNode("Document template id")
  }
};

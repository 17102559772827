import { CustomRegexes } from "../../helpers";

export default {
  name: {
    type: "string",
    nullable: true,
    pattern: CustomRegexes.LETTERS,
    errorMessage: { pattern: "should not contain numbers" },
  },
  taxableItems: {
    type: "array",
    nullable: true,
    items: { type: "array", items: { type: "string" } },
  },
  dealTypes: {
    type: "array",
    items: {
      type: "object",
      properties: {
        type: {
          type: "array",
          items: {
            type: "string",
            enum: [
              "Power Sport",
              "Marine",
              "Recreational Vehicles",
              "Automotive",
            ],
            errorMessage: {
              enum: "must be one of the following: 'Automotive', Power Sport', 'Recreational Vehicles' or 'Marine'",
            },
          },
        },
        percents: {
          type: "array",
          items: {
            type: "object",
            properties: {
              percent: { type: "number", nullable: true },
              description: { type: "string", nullable: true },
            },
          },
        },
        counties: {
          type: "array",
          nullable: true,
          items: {
            type: "object",
            properties: {
              county: {
                type: "string",
                nullable: true,
                pattern: CustomRegexes.LETTERS,
                errorMessage: { pattern: "should not contain numbers" },
              },
              percents: {
                type: "array",
                nullable: true,
                items: {
                  type: "object",
                  properties: {
                    percent: { type: "number", nullable: true },
                    description: { type: "string", nullable: true },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

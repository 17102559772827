import { createSocketMessageSlice, Identifiable } from "../Middlewares/socketMessageSliceCreator";
import { File, EditFileRequestSend, EditFileRequest } from "./types";

interface EditFileResponse {
  message: File;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditFileRequestSend,
  EditFileResponse
>("EditFile", {
  request: "update_file"
});

export const editFileActions = socketMessageSlice.actions;
export const editFile = (request: EditFileRequest & Identifiable) => {
  const {
    deal,
    contract,
    document_template,
    chargeback,
    dealId,
    contractId,
    documentTemplateId,
    ...rest
  } = request.data;

  return socketAction({
    ...request,
    data: {
      ...rest,
      chargebackId: chargeback?._id ?? null,
      contractId: contractId ? contractId : contract?._id ?? null,
      documentTemplateId: documentTemplateId ? documentTemplateId : document_template?._id ?? null,
      dealId: dealId ? dealId : deal?._id ?? null
    }
  });
};
export default socketMessageSlice.reducer;

import { RootState } from "app/rootReducer";
import React from "react";
import { useSelector } from "react-redux";

import AccessControl from "components/Access/AccessControl";
import { capitalize } from "utils/functions";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import StyledBadge from "components/StyledBadge";
import { FormState, TitleIssueStatus } from "components/TitleIssues/types";
import Table, { CellValue, Column } from "components/Table";
import { titleStatusToChip, titleStatusToColor } from "components/TitleIssues/DocumentsSection";
import { getTitleIssueList } from "components/TitleIssues/listTitleIssueSlice";
import { deleteTitleIssue } from "components/TitleIssues/deleteTitleIssueSlice";
import { recoverTitleIssue } from "components/TitleIssues/recoverTitleIssueSlice";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import useGetTitleIssueCountByDocumentStatus from "hooks/useGetTitleIssueCount/useGetTitleIssueCountByDocumentStatus";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import TextFilter from "components/Filters/TextFilter";
import { useStickyState } from "index";

export const projections = {
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.deal.data.applicant.data.info.firstName": 1,
  "data.deal.data.applicant.data.info.lastName": 1,
  "data.deal.data.coApplicant.data.info.firstName": 1,
  "data.deal.data.coApplicant.data.info.lastName": 1,
  "data.deal.data.info.vehicle.VIN": 1,
  "data.deal.data.lender.data.info.name": 1,
  "data.deal.data.dealership.data.info.displayName": 1,
  "data.deal.data.lender._id": 1,
  "data.deal.data.dealership._id": 1,
  "data.deal.data.info.refNumber": 1,
  "data.deal.data.info.dealDates.contractDate": 1,
  "data.deal.data.info.dealDates.submittedForFunding": 1,
  "data.deal.data.dealership.data.info.titleDate": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.oldTitleRegistrationInfo.status": 1,
  "data.info.titleRegistrationInfo.status": 1,
  "data.info.receiptInfo.status": 1,
  _id: 1,
  createdAt: 1,
  deleted: 1
};

export default ({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}): JSX.Element => {
  const sliceId = "title_documents";
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice[sliceId]);
  const { data } = useGetTitleIssueCountByDocumentStatus();
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();
  const [overallQuery, setOverallQuery] = useStickyState<
    | {
        active: TitleIssueStatus;
        query: Record<string, TitleIssueStatus | Record<string, TitleIssueStatus>[]>;
      }
    | undefined
  >(
    {
      active: "awaiting",
      query: {
        $or: [
          {
            "data.info.oldTitleRegistrationInfo.status": "awaiting"
          },
          {
            "data.info.titleRegistrationInfo.status": "awaiting"
          },
          {
            "data.info.receiptInfo.status": "awaiting"
          }
        ]
      }
    },
    "latest_title_documents_table_overallQuery"
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data?.info?.titleRegistrationInfo?.status,
      label: "New Title Document Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "titleRegistrationInfo", "status"]
      },
      name: "titleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.titleRegistrationInfoStatus?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.info?.oldTitleRegistrationInfo?.status,
      label: "Old Title Document Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "oldTitleRegistrationInfo", "status"]
      },
      name: "oldTitleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.oldTitleRegistrationInfoStatus?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.info?.receiptInfo?.status,
      label: "Receipt Status",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "receiptInfo", "status"]
      },
      name: "receiptStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.receiptStatus?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.deal?.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.dealership?.data?.info?.displayName,
      label: "Dealer",
      name: "dealer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealer?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.deal?.data?.applicant
          ? `${entry.data?.deal.data.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.deal.data.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,

      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      }
    },

    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const handleSetOverallQuery = (status: TitleIssueStatus) => {
    switch (status) {
      case "resolved":
      case "awaiting":
        setOverallQuery({
          active: status,
          query: {
            "data.info.oldTitleRegistrationInfo.status": status,
            "data.info.titleRegistrationInfo.status": status,
            "data.info.receiptInfo.status": status
          }
        });
        return;
      case "rejected":
      case "pending":
        setOverallQuery({
          active: status,
          query: {
            $or: [
              {
                "data.info.oldTitleRegistrationInfo.status": status
              },
              {
                "data.info.titleRegistrationInfo.status": status
              },
              {
                "data.info.receiptInfo.status": status
              }
            ]
          }
        });
    }
  };
  const titleStatusToBadgeColor = (status: TitleIssueStatus) => {
    switch (status) {
      case "resolved":
        return "green";
      case "rejected":
        return "red";
      case "awaiting":
        return "gray";
      case "pending":
        return "orange";
    }
  };
  const constructQuery = () => {
    if (overallQuery?.query) {
      return overallQuery.query;
    }
  };
  const handleResetStickyState = () => {
    setOverallQuery({
      active: "awaiting",
      query: {
        $or: [
          {
            "data.info.oldTitleRegistrationInfo.status": "awaiting"
          },
          {
            "data.info.titleRegistrationInfo.status": "awaiting"
          },
          {
            "data.info.receiptInfo.status": "awaiting"
          }
        ]
      }
    });
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12} style={{ margin: "5px 0" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography variant="h6">Dealer Documents </Typography>
          <Button
            style={{ margin: "5px" }}
            variant={"outlined"}
            onClick={() => setOverallQuery(undefined)}
          >
            Reset
          </Button>
          {(["resolved", "rejected", "pending", "awaiting"] as TitleIssueStatus[]).map(
            (status, index) => (
              <StyledBadge
                key={index}
                badgeContent={overallQuery?.active === status ? 1 : 0}
                color={titleStatusToBadgeColor(status)}
                variant="dot"
              >
                <Button
                  style={{ margin: "5px", background: titleStatusToColor(status) }}
                  variant={"outlined"}
                  onClick={() => handleSetOverallQuery(status)}
                >
                  {`${capitalize(status)} ${data?.[status] ?? ""}`}
                </Button>
              </StyledBadge>
            )
          )}
          <AccessControl requiredPermissions={{ entity: "title_issue", action: "read" }}>
            <Table
              setResetStickyCallback={setResetStickyTableCallback}
              elevation={0}
              tableContainerOverflowX="scroll"
              rowsPerPage={10}
              tableName={sliceId}
              entityName="title_issue"
              listFunction={getTitleIssueList}
              deleteEntityFunction={deleteTitleIssue}
              recoverEntityFunction={recoverTitleIssue}
              sort={{ "data.deal.data.info.dealDates.contractDate": "asc" }}
              listEntity={titleIssuesList}
              slice={sliceId}
              aggregateFirst={true}
              query={{
                ...constructQuery(),
                $expr: {
                  $gt: [
                    "$data.deal.data.info.dealDates.contractDate",
                    "$data.deal.data.dealership.data.info.titleDate"
                  ]
                }
              }}
              projection={projections}
              title={"Title Information"}
              columns={columns}
              hideAddButton
            />
          </AccessControl>
        </Paper>
      </Grid>
    </div>
  );
};

import Big from "big.js";
import { DealershipProgram, Tier } from "components/DealershipPrograms/types";
import { DealershipStatistics, RelationTypes } from "components/Dealerships/types";
import { DealTypeToReserve, ReserveData, Reserves } from "components/Lenders/types";
import { Content } from "components/PivotTable/types";
import { Path } from "../../utils/models/fields";
import { Condition, HasConditions } from "../../utils/types";
import { Commission, PeriodCommission } from "../Users/types";
import {
  CustomInsurance,
  Deal,
  DealStatus,
  FundingFee,
  InsuranceType,
  Payment,
  ReserveMethod,
  WfdProfit
} from "./types";
const SYSTEM = "FRONTEND";
const getByPath = <T extends Object>(x: T, path: Path<T>) => {
  return (path as Array<any>).reduce((prev: any, curr: any) => prev?.[curr], x);
};

const actualPrice = (deal: Deal): number =>
  (deal?.data?.info?.price?.price || 0) + (deal?.data?.info?.price?.options || 0);

const resolveTier = (
  statisticForTheMonth: DealershipStatistics | undefined,
  tiers: Tier[],
  findFn: "find" | "findIndex" = "find"
) => {
  const statisticForTheMonthDefaulted = statisticForTheMonth ?? {
    dealsCount: 0,
    totalAmountFinanced: 0
  };

  const foundTier = tiers?.[findFn]((tier) =>
    tier.conditions.every((condition) => {
      const amount =
        condition.criteria === "deals count"
          ? statisticForTheMonthDefaulted?.dealsCount ?? 0
          : statisticForTheMonthDefaulted?.totalAmountFinanced ?? 0;
      switch (condition.type) {
        case ">=":
          return amount >= condition.value;
        case "<=":
          return amount <= condition.value;
        default:
          return false;
      }
    })
  );
  return foundTier;
};

const customProgram: DealershipProgram = {
  _id: "62c54def82bc529272695c21",
  data: {
    info: {
      name: "Custom Program",
      tiers: []
    }
  },
  deleted: false,
  creatorId: "5f17e21d46a2b90e45bb5a91",
  updatedAt: "",
  createdAt: "",
  availableToRolesIds: [],
  availableToRoles: []
};
const getDealershipProgramByDeal = (deal: Deal): DealershipProgram | undefined | null => {
  const foundProgram = deal?.data?.dealership?.data?.info?.dealershipPrograms?.find(
    (dealershipProgram) => {
      return (
        deal?.data?.info?.dealDates?.contractDate &&
        (!dealershipProgram.endDate ||
          new Date(dealershipProgram.endDate).getTime() >=
            new Date(deal?.data?.info?.dealDates?.contractDate).getTime()) &&
        (!dealershipProgram.startDate ||
          new Date(dealershipProgram.startDate).getTime() <=
            new Date(deal?.data?.info?.dealDates?.contractDate).getTime())
      );
    }
  )?.dealershipProgram;
  return foundProgram || customProgram;
};

const calcSplitPercentage = (deal: Deal, oldDeal: Deal): Deal => {
  const dealershipProgram = getDealershipProgramByDeal(deal);
  if (dealershipProgram?.data?.info?.name === "Performance Program") {
    const statistics = deal?.data?.dealership?.data?.statistics ?? [];

    const tiers = dealershipProgram?.data?.info?.tiers ?? [];
    const contractDate = deal.data?.info?.dealDates?.contractDate as string;
    const statisticForTheMonth = statistics.find(
      (statistic) =>
        statistic.month === new Date(contractDate).getMonth() + 1 &&
        statistic.year === new Date(contractDate).getFullYear()
    );
    const foundTier = resolveTier(statisticForTheMonth, tiers);
    if (typeof foundTier !== "number" && foundTier?.commissionType === "fixed") return deal;
    const dealershipSplitCommissionAmount =
      typeof foundTier !== "number" ? foundTier?.commissionAmount || 0 : 0;
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            wfdSplit: 100 - (dealershipSplitCommissionAmount || 0),
            dealershipSplit: dealershipSplitCommissionAmount || 0,
            wfdGAPSplit: 100 - (dealershipSplitCommissionAmount || 0),
            dealershipGAPSplit: dealershipSplitCommissionAmount || 0,
            wfdWarrantySplit: 100 - (dealershipSplitCommissionAmount || 0),
            dealershipWarrantySplit: dealershipSplitCommissionAmount || 0
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.dealershipSplit !== oldDeal?.data?.info?.payment?.dealershipSplit &&
    typeof deal?.data?.info?.payment?.dealershipSplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            wfdSplit: 100 - (deal?.data?.info?.payment?.dealershipSplit || 0)
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.wfdSplit !== oldDeal?.data?.info?.payment?.wfdSplit &&
    typeof deal?.data?.info?.payment?.wfdSplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            dealershipSplit: 100 - (deal?.data?.info?.payment?.wfdSplit || 0)
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.dealershipGAPSplit !==
      oldDeal?.data?.info?.payment?.dealershipGAPSplit &&
    typeof deal?.data?.info?.payment?.dealershipGAPSplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            wfdGAPSplit: 100 - (deal?.data?.info?.payment?.dealershipGAPSplit || 0)
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.wfdGAPSplit !== oldDeal?.data?.info?.payment?.wfdGAPSplit &&
    typeof deal?.data?.info?.payment?.wfdGAPSplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            dealershipGAPSplit: 100 - (deal?.data?.info?.payment?.wfdGAPSplit || 0)
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.dealershipWarrantySplit !==
      oldDeal?.data?.info?.payment?.dealershipWarrantySplit &&
    typeof deal?.data?.info?.payment?.dealershipWarrantySplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            wfdWarrantySplit: 100 - (deal?.data?.info?.payment?.dealershipWarrantySplit || 0)
          }
        }
      }
    };
  }
  if (
    deal?.data?.info?.payment?.wfdWarrantySplit !==
      oldDeal?.data?.info?.payment?.wfdWarrantySplit &&
    typeof deal?.data?.info?.payment?.wfdWarrantySplit === "number"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            dealershipWarrantySplit: 100 - (deal?.data?.info?.payment?.wfdWarrantySplit || 0)
          }
        }
      }
    };
  }
  return deal;
};

const calcFinanceCharge = (deal: Deal) =>
  (deal?.data?.info?.payment?.totalOfPayments || 0) - (deal?.data?.info?.payment?.dealTotal || 0);

const calcTotalAftermarket = (deal: Deal): Deal => {
  const totalAftermarket = new Big(deal?.data?.info?.aftermarketOptions?.totalGAPSellPrice || 0)
    .add(deal?.data?.info?.aftermarketOptions?.totalServiceWarrantySellPrice || 0)
    .add(deal?.data?.info?.aftermarketOptions?.totalPaintAndFabricationPrice || 0)
    .add(deal?.data?.info?.aftermarketOptions?.totalPrePaidMaintenancePrice || 0)
    .add(deal?.data?.info?.aftermarketOptions?.totalWheelAndTirePrice || 0)
    .add(deal?.data?.info?.aftermarketOptions?.totalEcoPrice || 0);
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        aftermarketOptions: deal?.data?.info?.aftermarketOptions ?? {
          totalGAPSellPrice: 0,
          totalServiceWarrantySellPrice: 0,
          totalPaintAndFabricationPrice: 0,
          totalPrePaidMaintenancePrice: 0,
          totalEcoPrice: 0,
          totalWheelAndTirePrice: 0,
          totalGAPRemitPrice: 0,
          totalGAPDealerCostPrice: 0,
          totalServiceWarrantyRemitPrice: 0,
          totalServiceWarrantyDealerCostPrice: 0
        },
        payment: {
          ...deal?.data?.info?.payment,
          totalAftermarket: totalAftermarket.toNumber()
        }
      }
    }
  };
};

const calcTotalTaxes = (deal: Deal): Deal => {
  if (deal?.data?.info?.taxesAndFees?.isFixedTax) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            totalTaxes: deal?.data?.info?.payment?.totalTaxes ?? 0,
            localTaxes: deal?.data?.info?.payment?.localTaxes ?? 0,
            stateTaxes: deal?.data?.info?.payment?.stateTaxes ?? 0,
            taxableAmount: deal?.data?.info?.payment?.taxableAmount ?? 0
          }
        }
      }
    };
  } else {
    const dealType = deal?.data?.info?.type;
    const county = deal?.data?.applicant?.data?.info?.currentCounty;

    const dealTypeTax = deal?.data?.stateTax?.data?.info?.dealTypes?.find((el: any) =>
      el?.type?.includes(dealType)
    );

    let stateTaxSum = new Big(0);

    const contractDate = new Date(
      deal?.data?.info?.dealDates?.contractDate || (deal.createdAt as string)
    );

    stateTaxSum = new Big(
      dealTypeTax?.percents?.reduce((acc: number, curr) => {
        if (curr.startDate && curr.endDate) {
          return new Date(curr.startDate).getTime() <= contractDate.getTime() &&
            new Date(curr.endDate).getTime() >= contractDate.getTime()
            ? curr?.percent ?? 0
            : acc;
        } else if (curr.startDate) {
          return new Date(curr.startDate).getTime() <= contractDate.getTime()
            ? curr?.percent ?? 0
            : acc;
        } else if (curr.endDate) {
          return new Date(curr.endDate).getTime() >= contractDate.getTime()
            ? curr?.percent ?? 0
            : acc;
        } else {
          return curr?.percent ?? 0;
        }
      }, 0) ?? 0
    );

    const defaultCounty = dealTypeTax?.counties?.find((el) =>
      ["", null, undefined].includes(el?.county)
    );
    const selectedCounty =
      dealTypeTax?.counties?.find((el) => el?.county === county) ?? defaultCounty;

    const countyTaxSum = new Big(
      selectedCounty?.percents?.reduce((acc: number, curr) => acc + curr?.percent, 0) ?? 0
    );
    const positiveTaxesBase =
      deal?.data?.info?.payment?.taxableAmount > 0 ? deal?.data?.info?.payment?.taxableAmount : 0;
    const localTaxes = new Big(positiveTaxesBase).mul(countyTaxSum.div(100));
    const stateTaxes = new Big(positiveTaxesBase).mul(stateTaxSum.div(100));

    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            totalTaxes: localTaxes.add(stateTaxes).round(2, 1).toNumber(),
            localTaxes: localTaxes.round(2, 1).toNumber(),
            stateTaxes: stateTaxes.round(2, 1).toNumber()
          }
        }
      }
    };
  }
};
const calcTaxableAmount = (deal: Deal): Deal => {
  if (deal?.data?.info?.taxesAndFees?.isFixedTax) {
    return deal;
  }
  const taxableItems = deal?.data?.stateTax?.data?.info?.taxableItems || [];

  const taxableItemsTotal = taxableItems.reduce(function (acc: number, item: any) {
    const taxableValue = parseFloat(getByPath<Deal>(deal, item)) || 0;
    return acc + taxableValue;
  }, 0);

  const taxableAmount = new Big(parseFloat(actualPrice(deal).toString()) || 0)
    .sub(parseFloat((deal?.data?.info?.price?.totalTrade ?? 0).toString()) || 0)
    .add(taxableItemsTotal);

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        payment: { ...deal?.data?.info?.payment, taxableAmount: taxableAmount.toNumber() }
      }
    }
  };
};

const calcTotalFees = (deal: Deal): Deal => {
  const totalFees = [
    new Big(deal?.data?.info?.taxesAndFees?.titleTransfer || 0),
    new Big(deal?.data?.info?.taxesAndFees?.documentRegistrationFees || 0),
    new Big(deal?.data?.info?.taxesAndFees?.filingFees || 0),
    new Big(deal?.data?.info?.taxesAndFees?.otherFees || 0)
  ].reduce((x, y) => x.add(y));

  const totalUCCFees =
    deal?.data?.info?.taxesAndFees?.fundingFees?.reduce(
      (acc: number, fee: FundingFee) => (fee.type === "UCC" ? acc + fee.amount : acc),
      0
    ) ?? 0;
  const totalMembershipFees =
    deal?.data?.info?.taxesAndFees?.fundingFees?.reduce(
      (acc: number, fee: FundingFee) => (fee.type === "Membership" ? acc + fee.amount : acc),
      0
    ) ?? 0;

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        payment: { ...deal?.data?.info?.payment, totalFees: totalFees.toNumber() },
        taxesAndFees: {
          ...deal?.data?.info?.taxesAndFees,
          totalUCCFees,
          totalMembershipFees
        }
      }
    }
  };
};

const calcDealTotal = (deal: Deal): Deal => {
  const dealTotal = deal?.data?.info?.price?.price
    ? [
        new Big(actualPrice(deal)),
        new Big(-deal?.data?.info?.price?.totalCash || 0),
        new Big(-(deal?.data?.info?.price?.rebates || 0)),
        new Big(-(deal?.data?.info?.price?.netTrade || 0)),
        new Big(deal?.data?.info?.vehicleCoverage?.amountOfPremium || 0),
        new Big(deal?.data?.info?.payment?.totalAftermarket || 0),
        new Big(deal?.data?.info?.payment?.totalTaxes || 0),
        new Big(deal?.data?.info?.payment?.totalFees || 0)
      ].reduce((x, y) => x.add(y))
    : new Big(0);

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        payment: {
          ...deal?.data?.info?.payment,
          dealTotal: dealTotal.toNumber()
        }
      }
    }
  };
};

const calcDealTotalWithAmortization = (deal: Deal): number => {
  const dealTotal = deal?.data?.info?.payment?.dealTotal;
  const daysToFirstPayment =
    parseInt(deal?.data?.info?.payment?.daysToFirstPayment?.toString() ?? "") || 30;
  if (daysToFirstPayment === 30) return dealTotal;

  const amort = interestParsed(deal)
    .div(365)
    .mul(daysToFirstPayment - 30)
    .mul(dealTotal)
    .round(2, 1);

  const dealTotalWithAmortization = new Big(dealTotal).add(amort);
  return dealTotalWithAmortization.toNumber();
};

const interestParsed = (deal: Deal) => {
  const interestRate = deal?.data?.info?.payment?.interestRate || 0;
  return interestRate === 0 ? new Big(interestRate) : new Big(interestRate).div(100);
};

const basePaymentInterestParsed = (deal: Deal) => {
  const interestRate = deal?.data?.info?.payment?.buyPercent || 0;
  return interestRate === 0 ? new Big(interestRate) : new Big(interestRate).div(100);
};

const calcMonthlyPayment = (deal: Deal): Deal => {
  const dealTotalWithAmortization = calcDealTotalWithAmortization(deal);

  const payments = deal?.data?.info?.payment?.numberOfPayments || 0;
  let monthlyPayment = 0;
  const interest = interestParsed(deal).div(deal?.data?.info?.payment?.type === "yearly" ? 1 : 12);
  if (payments === 0 || payments > 960) {
    monthlyPayment = 0;
  } else if (interest.toString() === "0") {
    monthlyPayment = new Big(dealTotalWithAmortization || 0).div(payments).toNumber();
  } else {
    // formula taken from https://www.thebalance.com/loan-payment-calculations-315564

    // const xOld = interest
    //   .add(1)
    //   .pow(payments)
    //   .sub(1)
    //   .div(interest.mul(new Big(1).add(interest).pow(payments)));
    const interestN = interest.toNumber();

    const x = ((interestN + 1) ** payments - 1) / (interestN * (1 + interestN) ** payments);

    // use calcTest to validate future amounts
    monthlyPayment = new Big(dealTotalWithAmortization || 0).div(x).round(2, 1).toNumber();
  }
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data.info,
        payment: {
          ...deal?.data?.info?.payment,
          monthlyPayment
        }
      }
    }
  };
};

const calcBasePayment = (deal: Deal): number => {
  const dealTotalWithAmortization = calcDealTotalWithAmortization(deal);

  const payments = deal?.data?.info?.payment?.numberOfPayments || 0;
  let monthlyPayment = 0;
  const interest = basePaymentInterestParsed(deal).div(
    deal?.data?.info?.payment?.type === "yearly" ? 1 : 12
  );
  if (payments === 0 || payments > 960) {
    monthlyPayment = 0;
  } else if (interest.toString() === "0") {
    monthlyPayment = new Big(dealTotalWithAmortization || 0).div(payments).toNumber();
  } else {
    // formula taken from https://www.thebalance.com/loan-payment-calculations-315564

    // const xOld = interest
    //   .add(1)
    //   .pow(payments)
    //   .sub(1)
    //   .div(interest.mul(new Big(1).add(interest).pow(payments)));
    const interestN = interest.toNumber();

    const x = ((interestN + 1) ** payments - 1) / (interestN * (1 + interestN) ** payments);

    // use calcTest to validate future amounts
    monthlyPayment = new Big(dealTotalWithAmortization || 0).div(x).round(2, 1).toNumber();
  }
  return monthlyPayment;
};

const calcTotalOfPayments = (deal: Deal): Deal => {
  const totalOfPayments = new Big(deal?.data?.info?.payment?.monthlyPayment)
    .mul(deal?.data?.info?.payment?.numberOfPayments ?? 0)
    .toNumber();

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data.info,
        payment: {
          ...deal?.data?.info?.payment,
          totalOfPayments
        }
      }
    }
  };
};

const calcTotalSalePrice = (deal: Deal): Deal => {
  const totalOfPayments = new Big(deal?.data?.info?.payment?.totalOfPayments || 0);
  const totalSalePrice = totalOfPayments
    .add(deal?.data?.info?.price?.totalCash || 0)
    .add(deal?.data?.info?.price?.rebates || 0)
    .add((deal?.data?.info?.price?.netTrade || 0) > 0 ? deal?.data?.info?.price?.netTrade : 0)
    .toNumber();

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        payment: {
          ...deal?.data?.info?.payment,
          totalSalePrice
        }
      }
    }
  };
};

const calcTotalTrade = (deal: Deal, oldDeal: Deal): Deal => {
  try {
    if (
      JSON.stringify(deal.data.info.price.trade) !== JSON.stringify(oldDeal.data.info.price.trade)
    ) {
      return {
        ...deal,
        data: {
          ...deal?.data,
          info: {
            ...deal?.data?.info,
            price: {
              ...deal?.data?.info?.price,
              totalTrade: deal?.data?.info?.price?.trade
                ?.reduce((acc, curr) => acc.add(curr?.value ?? 0), new Big(0))
                .toNumber()
            }
          }
        }
      };
    }
    return deal;
  } catch (error) {
    return deal;
  }
};
const calcNetTrade = (deal: Deal): Deal => {
  const trade = deal?.data?.info?.price?.totalTrade || 0;
  const payoff = deal?.data?.info?.price?.payoff || 0;
  const netTrade = new Big(trade).sub(payoff).round(2, 1).toNumber();
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        price: {
          ...deal?.data?.info?.price,
          netTrade
        }
      }
    }
  };
};

const matchConditions = <T extends HasConditions>(list: T[], deal: Deal): T | undefined => {
  return list?.find((agreement: T) => {
    if (agreement?.conditions?.length === 0) return true;
    return [...(agreement?.conditions ?? [])].every((condition: Condition) => {
      const criteria = getByPath<Deal>(deal, condition.field as Path<Deal>);
      if (condition.field !== undefined && criteria !== null && criteria !== undefined) {
        switch (condition.type) {
          case ">":
            return criteria >= condition.value;
          case "<":
            return criteria <= condition.value;
          case "=":
            return condition.value === criteria;
          case "in":
            if (Array.isArray(condition.value)) {
              return condition.value?.includes(criteria);
            }
            return false;
          default:
            return false;
        }
      } else {
        return false;
      }
    });
  });
};
export const findPeriodCommission = (
  periodCommissions: PeriodCommission[] | undefined,
  dealDate: Date
) => {
  return periodCommissions?.find((periodCommission) => {
    const from = periodCommission?.from
      ? new Date(periodCommission.from).getTime()
      : new Date(1970, 1, 1).getTime();
    const to = periodCommission?.to
      ? new Date(periodCommission.to).getTime()
      : new Date(3000, 1, 1).getTime();
    return from <= dealDate?.getTime() && to >= dealDate?.getTime();
  });
};

const findCommission = (deal: Deal) => {
  const user = deal?.data?.user?.data?.info;
  const dealershipProgram = getDealershipProgramByDeal(deal);
  const dealDate = new Date(
    deal?.data?.info?.dealDates?.submittedForFunding ||
      deal?.data?.info?.dealDates?.contractDate ||
      deal?.createdAt ||
      ""
  );
  const foundPeriodCommission = findPeriodCommission(user?.periodCommissions, dealDate);

  const commissions =
    foundPeriodCommission?.commissions?.filter((commission) => {
      return commission?.dealershipProgramId?.valueOf() === dealershipProgram?._id?.valueOf();
    }) ?? [];
  return user ? matchConditions<Commission>(commissions, deal) : undefined;
};
const calcCommissionableAmount = (deal: Deal, commission: Commission | undefined) => {
  let commissionableAmount = new Big(0);
  switch (commission?.commissionableAmountType) {
    case "50% of gross profit":
      commissionableAmount = new Big(deal?.data?.info?.profit?.grossProfit || 0)
        .div(2)
        .add(deal?.data?.info?.profit?.totalExtraProfit || 0);
      break;
    case "WFD total profit":
      commissionableAmount = new Big(deal?.data?.info?.profit?.wfdProfit?.totalProfit || 0);
      break;
  }

  return commissionableAmount;
};
const calcAccountingCommissionableAmount = (deal: Deal, commission: Commission | undefined) => {
  let commissionableAmount = new Big(0);
  switch (commission?.commissionableAmountType) {
    case "50% of gross profit":
      commissionableAmount = new Big(deal?.data?.info?.accounting?.profit?.grossProfit || 0)
        .div(2)
        .add(deal?.data?.info?.accounting?.profit?.totalExtraProfit || 0);
      break;
    case "WFD total profit":
      commissionableAmount = new Big(
        deal?.data?.info?.accounting?.profit?.wfdProfit?.totalProfit || 0
      );
      break;
  }

  return commissionableAmount;
};
const calcAccountingManagerCommission = (deal: Deal): Deal => {
  const commission = findCommission(deal);
  let newCommission = 0;
  if (commission !== undefined) {
    const commissionableAmount = calcAccountingCommissionableAmount(deal, commission);
    newCommission =
      commission.type === "fixed"
        ? commission.amount
        : commissionableAmount.mul(new Big(commission.amount || 0).div(100)).toNumber();
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          accounting: {
            ...(deal?.data?.info?.accounting ?? {}),
            profit: {
              ...(deal?.data?.info?.accounting?.profit ?? {}),
              managerProfit: {
                ...deal?.data?.info?.accounting?.profit?.managerProfit,
                commissionableAmount: commissionableAmount.toNumber(),
                commission: newCommission
              }
            }
          }
        }
      }
    };
  }
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        accounting: {
          ...(deal?.data?.info?.accounting ?? {}),
          profit: {
            ...(deal?.data?.info?.accounting?.profit ?? {}),
            managerProfit: {
              ...deal?.data?.info?.accounting?.profit?.managerProfit,
              commissionableAmount: 0,
              commission: 0
            }
          }
        }
      }
    }
  };
};
const calcManagerASCAndGapCommission = (commissionPercent: number, wfdProfit?: WfdProfit) => {
  const gapCommission = new Big(wfdProfit?.splitTotalFromGap || 0)
    .mul(new Big(commissionPercent))
    .div(100)
    .round(2, 1);
  const ascCommission = new Big(wfdProfit?.splitTotalFromServiceWarranty || 0)
    .mul(new Big(commissionPercent))
    .div(100)
    .round(2, 1);
  return {
    gapCommission: gapCommission.toNumber(),
    ascCommission: ascCommission.toNumber()
  };
};
const calcCommissionAmount = (deal: Deal, commission: Commission, commissionableAmount: number) => {
  switch (commission.type) {
    case "fixed":
      return {
        commission: commission.amount,
        chargebackPercentReserve: commission.chargebackPercent || commission.amount,
        chargebackPercentProduct: commission.chargebackPercent || commission.amount
      };
    case "percent":
      const reserveCommission = new Big(deal.data?.info?.profit?.wfdProfit?.totalReserveProfit || 0)
        .mul(commission.amount)
        .div(100)
        .round(2, 1)
        .toNumber();
      return {
        reserveCommission,
        ...calcManagerASCAndGapCommission(commission.amount, deal.data.info.profit?.wfdProfit),
        commission: new Big(commissionableAmount)
          .mul(new Big(commission.amount || 0).div(100))
          .round(2, 1)
          .toNumber(),
        chargebackPercentReserve: commission.chargebackPercent || commission.amount,
        chargebackPercentProduct: commission.chargebackPercent || commission.amount
      };
    case "program":
      const program = deal.data.managerPrograms?.find(
        (program) => program._id.valueOf() === commission.managerProgramId?.valueOf()
      );
      const dealDate = new Date(
        deal?.data?.info?.dealDates?.submittedForFunding ||
          deal?.data?.info?.dealDates?.contractDate ||
          deal?.createdAt ||
          ""
      );
      const pvr =
        deal?.data?.user?.data?.statistics?.find(
          (stat) => stat.year === dealDate.getFullYear() && stat.month === dealDate.getMonth() + 1
        )?.pvr || 0;
      const foundTier = program?.data?.info?.tiers?.filter((tier) => {
        return tier.conditions.every((cond) => {
          const criteria = cond.criteria === "pvr" ? pvr : 0;
          switch (cond?.type) {
            case ">=":
              return criteria >= cond?.value;
            case "<=":
              return criteria <= cond?.value;
            default:
              return [];
          }
        });
      })?.[0];
      if (foundTier) {
        const reserveCommission = new Big(
          deal.data?.info?.profit?.wfdProfit?.totalReserveProfit || 0
        )
          .mul(foundTier.reserveCommissionAmount)
          .div(100)
          .round(2, 1);
        const productCommission = new Big(
          deal.data.info.profit?.wfdProfit?.totalProductsProfit || 0
        )
          .mul(foundTier.productCommissionAmount)
          .div(100)
          .round(2, 1);
        const commission = reserveCommission.add(productCommission).round(2, 1).toNumber();

        return {
          commission,
          reserveCommission: reserveCommission.toNumber(),
          productCommission: productCommission.toNumber(),
          chargebackPercentReserve:
            foundTier.chargebackPercent || foundTier.reserveCommissionAmount,
          chargebackPercentProduct:
            foundTier.chargebackPercent || foundTier.productCommissionAmount,
          ...calcManagerASCAndGapCommission(
            foundTier.productCommissionAmount,
            deal.data.info.profit?.wfdProfit
          )
        };
      }
      return { commission: 0, reserveCommission: 0, productCommission: 0 };

    default:
      return { commission: 0 };
  }
};
const calcManagerCommission = (deal: Deal): Deal => {
  const commission = findCommission(deal);
  if (commission !== undefined) {
    const commissionableAmount = calcCommissionableAmount(deal, commission);
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          profit: {
            ...deal?.data?.info?.profit,
            managerProfit: {
              ...deal?.data?.info?.profit?.managerProfit,
              commissionableAmount: commissionableAmount.toNumber(),
              ...calcCommissionAmount(deal, commission, commissionableAmount.toNumber())
            }
          }
        }
      }
    };
  }
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          managerProfit: {
            ...deal?.data?.info?.profit?.managerProfit,
            commissionableAmount: 0,
            commission: 0
          }
        }
      }
    }
  };
};
const calcRepresentativeCommission = (deal: Deal): Deal => {
  const user = deal?.data?.dealership?.data?.representative?.data?.info;
  const wfdProfit = deal?.data?.info?.profit?.wfdProfit?.totalProfit ?? 0;
  const dealDate = deal?.data?.info?.dealDates?.contractDate
    ? new Date(deal?.data?.info?.dealDates?.contractDate)
    : new Date();
  const foundPeriodCommission = findPeriodCommission(user?.periodCommissions, dealDate);

  const commission = foundPeriodCommission
    ? matchConditions<Commission>(foundPeriodCommission?.commissions as Commission[], deal)
    : undefined;
  let newCommission = 0;
  if (commission !== undefined) {
    newCommission =
      commission.type === "fixed" ? commission.amount : wfdProfit * (commission.amount / 100);
  }
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          representativeProfit: {
            ...deal?.data?.info?.profit?.representativeProfit,
            commission: newCommission
          }
        }
      }
    }
  };
};

const calcVehicleCoverageSplit = (deal: Deal) => {
  const amountOfPremium = deal?.data?.info?.vehicleCoverage?.amountOfPremium ?? 0;

  const commissionPercent = deal?.data?.info?.vehicleCoverage?.commissionPercent ?? 0;

  return amountOfPremium * (commissionPercent / 100);
};

const calcReserveCommission = (deal: Deal): Deal => {
  const maxReserve = deal?.data?.info?.payment?.maxReserve;
  const reserveMethod = deal?.data?.info?.payment?.reserveMethod;
  const reserveValue = deal?.data?.info?.payment?.reserve;

  const payment = deal?.data?.info?.payment ?? {};
  const dealTotal = payment?.dealTotal ?? 0;
  const financeCharge = calcFinanceCharge(deal);
  const buyPercent = payment?.buyPercent ?? 0;
  const sellPercent = payment?.interestRate ?? 0;
  let reserve = 0;
  switch (reserveMethod) {
    case "markup foursight":
    case "percentage":
    case "markup":
      reserve = deal?.data?.lender?.data?.roundToDollar
        ? new Big(dealTotal)
            .mul(new Big(reserveValue || 0).div(100))
            .round(0, 1)
            .toNumber()
        : new Big(dealTotal).mul(new Big(reserveValue || 0).div(100)).toNumber();
      break;
    case "fixed":
      reserve = reserveValue ?? 0; // flat dollar amount per deal
      break;
    case "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %": // difference between buy / sell rate * total amount financed then / sell rate and then * reserve %
      if (sellPercent !== 0) {
        reserve =
          ((((sellPercent - buyPercent) / 100) * financeCharge) / (sellPercent / 100)) *
            ((reserveValue ?? 0) / 100) || 0;
      }
      break;

    default:
      reserve = 0;
  }
  if (maxReserve && maxReserve !== 0 && reserve > maxReserve) reserve = maxReserve;

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          wfdProfit: {
            ...deal?.data?.info?.profit?.wfdProfit,
            reserveCommission: parseFloat(reserve.toFixed(2))
          },
          dealershipProfit: {
            ...deal?.data?.info?.profit?.dealershipProfit,
            reserveCommission: parseFloat(reserve.toFixed(2))
          }
        }
      }
    }
  };
};

const calcDealershipSplit = (deal: Deal): number => {
  const reserve = deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0;
  const payment = deal?.data?.info?.payment;
  const splitMethod = payment.splitMethod;
  const correspondentPercent = payment.correspondentPercent || 0;
  const dealTotal = payment.dealTotal || 0;
  switch (splitMethod) {
    case "correspondent":
      return new Big(reserve)
        .sub(
          new Big(
            new Big(reserve)
              .sub(new Big(dealTotal).mul(new Big(correspondentPercent || 0).div(100)))
              .toNumber()
          ).round(2, 3)
        )
        .toNumber();

    default:
      return new Big(reserve)
        .mul(new Big(payment?.dealershipSplit || 0).div(100))
        .round(2, 0)
        .toNumber();
  }
};
const calcWFDSplit = (deal: Deal): number => {
  const reserve = deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0;
  const payment = deal?.data?.info?.payment;

  const splitMethod = payment?.splitMethod;
  const correspondentPercent = payment?.correspondentPercent || 0;

  const dealTotal = deal?.data?.info?.payment?.dealTotal || 0;

  switch (splitMethod) {
    case "correspondent":
      return new Big(reserve)
        .sub(new Big(dealTotal).mul(new Big(correspondentPercent || 0).div(100)))
        .round(2, 3)
        .toNumber();

    default:
      return new Big(reserve)
        .mul(new Big(payment?.wfdSplit || 0).div(100))
        .round(2, 3)
        .toNumber();
  }
};

const calcSplit = (
  payment: Payment,
  splitType:
    | "dealershipSplit"
    | "wfdSplit"
    | "dealershipGAPSplit"
    | "wfdGAPSplit"
    | "dealershipWarrantySplit"
    | "wfdWarrantySplit",
  amount: number
): number => {
  // if we calculate WFD profit we ceil otherwise we floor for the

  const round = ["wfdSplit", "wfdGAPSplit", "wfdWarrantySplit"].includes(splitType) ? 3 : 0;
  const split = amount * ((payment[splitType] || 0) / 100) || 0;

  return new Big(split).round(2, round).toNumber();
};
const calcGrossProfit = (deal: Deal): Deal => {
  const grossProfit = new Big(deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0)
    .add(deal?.data?.info?.profit?.totalGAPProfit || 0)
    .add(deal?.data?.info?.profit?.totalServiceWarrantyProfit || 0);

  const extraGAPProfit = new Big(deal?.data?.info?.profit?.wfdProfit?.extraGAPProfit ?? 0);
  const extraServiceWarrantyProfit = new Big(
    deal?.data?.info?.profit?.wfdProfit?.extraServiceWarrantyProfit ?? 0
  );
  const extraReserveProfit = new Big(deal?.data?.info?.profit?.wfdProfit?.extraReserveProfit ?? 0);

  const feesProfit = new Big(deal?.data?.info.taxesAndFees.acquisitionFee || 0).add(
    deal?.data?.info.taxesAndFees.wfdMinimumFee || 0
  );
  const totalExtraProfit = extraGAPProfit
    .add(extraServiceWarrantyProfit)
    .add(extraReserveProfit)
    .add(feesProfit);
  const grossProfitWithExtra = new Big(deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0)
    .add(deal?.data?.info?.profit?.totalGAPProfit || 0)
    .add(deal?.data?.info?.profit?.totalServiceWarrantyProfit || 0)
    .add(totalExtraProfit);
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          totalExtraProfit: totalExtraProfit.toNumber(),
          grossProfit: grossProfit.toNumber(),
          grossProfitWithExtra: grossProfitWithExtra.toNumber()
        }
      }
    }
  };
};
const calcGrossRevenues = (deal: Deal): Deal => {
  const extraGAPProfit = new Big(deal?.data?.info?.profit?.wfdProfit?.extraGAPProfit ?? 0);
  const extraServiceWarrantyProfit = new Big(
    deal?.data?.info?.profit?.wfdProfit?.extraServiceWarrantyProfit ?? 0
  );
  const gapProfit = new Big(deal?.data?.info?.profit?.totalGAPProfit ?? 0);
  const serviceWarrantyProfit = new Big(deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0);
  const feesProfit = new Big(deal?.data?.info.taxesAndFees.acquisitionFee || 0).add(
    deal?.data?.info.taxesAndFees.wfdMinimumFee || 0
  );
  const reserveProfit = new Big(deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0).add(
    new Big(deal?.data?.info?.profit?.wfdProfit?.extraReserveProfit || 0)
  );
  const productGrossRevenue = new Big(gapProfit)
    .add(serviceWarrantyProfit)
    .add(extraGAPProfit)
    .add(extraServiceWarrantyProfit)
    .toNumber();

  const grossRevenue = new Big(gapProfit)
    .add(serviceWarrantyProfit)
    .add(extraGAPProfit)
    .add(extraServiceWarrantyProfit)
    .add(reserveProfit)
    .add(feesProfit)
    .toNumber();
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          productGrossRevenue,
          grossRevenue
        }
      }
    }
  };
};
const calcAccountingGrossProfit = (deal: Deal): Deal => {
  const grossProfit = new Big(
    deal?.data?.info?.accounting?.profit?.wfdProfit?.reserveCommission || 0
  )
    .add(deal?.data?.info?.accounting?.profit?.totalGAPProfit || 0)
    .add(deal?.data?.info?.accounting?.profit?.totalServiceWarrantyProfit || 0);

  const extraGAPProfit = new Big(
    deal?.data?.info?.accounting?.profit?.wfdProfit?.extraGAPProfit ?? 0
  );
  const extraServiceWarrantyProfit = new Big(
    deal?.data?.info?.accounting?.profit?.wfdProfit?.extraServiceWarrantyProfit ?? 0
  );
  const extraReserveProfit = new Big(
    deal?.data?.info?.accounting?.profit?.wfdProfit?.extraReserveProfit ?? 0
  );
  const feesProfit = new Big(deal?.data?.info?.accounting?.taxesAndFees?.acquisitionFee || 0).add(
    deal?.data?.info?.accounting?.taxesAndFees?.wfdMinimumFee || 0
  );
  const totalExtraProfit = extraGAPProfit
    .add(extraServiceWarrantyProfit)
    .add(extraReserveProfit)
    .add(feesProfit);
  const grossProfitWithExtra = new Big(
    deal?.data?.info?.accounting?.profit?.wfdProfit?.reserveCommission || 0
  )
    .add(deal?.data?.info?.accounting?.profit?.totalGAPProfit || 0)
    .add(deal?.data?.info?.accounting?.profit?.totalServiceWarrantyProfit || 0)
    .add(totalExtraProfit);
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        accounting: {
          ...(deal?.data?.info?.accounting ?? {}),
          profit: {
            ...deal?.data?.info?.accounting?.profit,
            totalExtraProfit: totalExtraProfit.toNumber(),
            grossProfit: grossProfit.toNumber(),
            grossProfitWithExtra: grossProfitWithExtra.toNumber()
          }
        }
      }
    }
  };
};
const calcDealershipProfit = (deal: Deal): Deal => {
  const totalProfitFees =
    (deal?.data?.info.taxesAndFees.lenderAcquisitionFee || 0) +
    (deal?.data?.info.taxesAndFees.acquisitionFee || 0) +
    (deal?.data?.info.taxesAndFees.wfdMinimumFee || 0);
  const totalDealProfit =
    (deal?.data?.info?.profit?.dealershipProfit?.reserveCommission || 0) +
    (deal?.data?.info?.profit?.totalGAPProfit || 0) +
    (deal?.data?.info?.profit?.totalServiceWarrantyProfit || 0);

  const profit = {
    splitFromDeal: 0,
    splitTotalFromGap: 0,
    splitTotalFromServiceWarranty: 0,
    totalProfit: 0,
    totalProfitFees,
    totalDealProfit
  };
  const payment = deal?.data?.info?.payment;
  if (payment !== undefined) {
    profit.splitFromDeal = calcDealershipSplit(deal);

    if (deal?.data?.info?.payment?.splitMethod === "correspondent") {
      profit.splitTotalFromGap = deal?.data?.info?.profit?.totalGAPProfit ?? 0;
      profit.splitTotalFromServiceWarranty =
        deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0;
    } else {
      profit.splitTotalFromGap = calcSplit(
        payment,
        "dealershipGAPSplit",
        deal?.data?.info?.profit?.totalGAPProfit ?? 0
      );
      profit.splitTotalFromServiceWarranty = calcSplit(
        payment,
        "dealershipWarrantySplit",
        deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0
      );
    }

    profit.totalProfit =
      profit?.splitFromDeal +
      profit?.splitTotalFromGap +
      profit?.splitTotalFromServiceWarranty -
      profit.totalProfitFees;
  }

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          dealershipProfit: { ...deal?.data?.info?.profit?.dealershipProfit, ...profit }
        }
      }
    }
  };
};
const calcExtraServiceWarrantyProfit = (deal: Deal): Deal => {
  const hasServiceWarranty = deal.data.info.aftermarketOptions?.insurances?.some(
    (insurance) => insurance?.chosenRate?.insuranceType === "service warranty"
  );
  if (!hasServiceWarranty) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          profit: {
            ...deal?.data?.info?.profit,
            wfdProfit: {
              ...deal?.data?.info?.profit?.wfdProfit,
              extraServiceWarrantyProfit: 0
            }
          }
        }
      }
    };
  }
  return deal;
};
const calcExtraReserve = (
  reserveMethod: ReserveMethod | undefined,
  reserve: number | undefined,
  term: number,
  monthlyPayment: number,
  basePayment: number,
  extraReserveMethod: ReserveMethod | undefined,
  dealTotal: number,
  wfdProfit: WfdProfit | undefined,
  maxReserve: number | undefined,
  reserveCommission: number | undefined,
  roundToDollar: boolean | undefined
) => {
  if (reserveMethod === ReserveMethod["markup foursight"]) {
    let extraReserveProfit = 0;
    const differenceInPayments = new Big(monthlyPayment).sub(new Big(basePayment)).toNumber();
    if (differenceInPayments > 0) {
      extraReserveProfit = new Big(differenceInPayments)
        .mul(new Big(term))
        .mul(new Big(new Big(75).div(100)))
        .sub(new Big(new Big(reserve || 0).div(100)).mul(new Big(dealTotal)))
        .toNumber();
    }
    return extraReserveProfit;
  } else {
    const maxExtraCommission = Math.max(
      new Big(maxReserve ?? 0)
        .sub(new Big(reserveCommission ?? 0))
        .round(2)
        .toNumber(),
      0
    );

    switch (extraReserveMethod) {
      case "percentage":
      case "min(<interest>/2,2.25)":
        const potentialExtraCommission = roundToDollar
          ? new Big(dealTotal)
              .mul(new Big(wfdProfit?.reserverPercentage || 0).div(100))
              .round(0, 1)
              .toNumber()
          : new Big(dealTotal)
              .mul(new Big(wfdProfit?.reserverPercentage || 0).div(100))
              .round(2)
              .toNumber();

        return maxReserve
          ? Math.min(maxExtraCommission, potentialExtraCommission)
          : potentialExtraCommission;

      case "fixed":
        return maxReserve
          ? Math.min(maxExtraCommission, wfdProfit?.extraReserveProfit ?? 0)
          : wfdProfit?.extraReserveProfit ?? 0;
      default:
        return 0;
    }
  }
};

const setDefaultMarkup = (deal: Deal, oldDeal: Deal): Deal => {
  const lender = deal.data.lender;
  const oldLender = oldDeal.data.lender;
  if (
    lender?._id !== oldLender?._id ||
    typeof deal?.data?.info?.payment?.markupData?.markup !== "number"
  ) {
    const pivotTableData = getPivotTableData(deal);
    if (!pivotTableData) return deal;
    const markupReserveTableData = findMarkupTableRate(
      deal,
      lender?.data?.info?.markupTables?.find((mt) => mt.id === pivotTableData?.markupTableId)?.table
    );
    const defaultMarkup =
      markupReserveTableData && markupReserveTableData.length > 0
        ? markupReserveTableData.find((el) => el.isDefault)
        : undefined;

    return {
      ...deal,
      data: {
        ...deal.data,
        info: {
          ...deal.data.info,
          payment: {
            ...deal.data.info.payment,
            markupData: defaultMarkup
          }
        }
      }
    };
  }

  return deal;
};

const calcWfdProfit = (deal: Deal): Deal => {
  const payment = deal?.data?.info?.payment;
  const profit = {
    splitFromDeal: 0,
    splitTotalFromGap: 0,
    splitTotalFromServiceWarranty: 0,
    totalProfit: 0,
    extraReserveProfit: 0,
    totalReserveProfit: 0,
    totalProductsProfit: 0
  };
  if (payment !== undefined) {
    profit.splitFromDeal = calcWFDSplit(deal);

    if (deal?.data?.info?.payment?.splitMethod === "correspondent") {
      profit.splitTotalFromGap = 0;
      profit.splitTotalFromServiceWarranty = 0;
    } else {
      profit.splitTotalFromGap = calcSplit(
        payment,
        "wfdGAPSplit",
        deal?.data?.info?.profit?.totalGAPProfit ?? 0
      );
      profit.splitTotalFromServiceWarranty = calcSplit(
        payment,
        "wfdWarrantySplit",
        deal?.data?.info?.profit?.totalServiceWarrantyProfit ?? 0
      );
    }
    const splitFromVehicleCoverage = calcVehicleCoverageSplit(deal);

    const wfdProfit = deal?.data?.info?.profit?.wfdProfit;
    const extraGAPProfit = wfdProfit?.extraGAPProfit ?? 0;
    const extraServiceWarrantyProfit = wfdProfit?.extraServiceWarrantyProfit ?? 0;
    const dealTotal = deal?.data?.info?.payment?.dealTotal ?? 0;
    const reserveMethod = wfdProfit?.reserveMethodType;
    const term = deal?.data?.info?.payment?.numberOfPayments;
    const monthlyPayment = deal?.data?.info?.payment?.monthlyPayment;
    const basePayment = calcBasePayment(deal);

    profit.extraReserveProfit = calcExtraReserve(
      deal.data.info.payment.reserveMethod,
      deal.data.info?.payment?.reserve,
      term,
      monthlyPayment,
      basePayment,
      reserveMethod,
      dealTotal,
      wfdProfit,
      payment.maxReserve,
      wfdProfit?.reserveCommission,
      deal?.data.lender?.data.roundToDollar
    );

    const wfdExtraProfit = new Big(splitFromVehicleCoverage || 0)
      .add(extraGAPProfit || 0)
      .add(extraServiceWarrantyProfit || 0)
      .add(profit.extraReserveProfit || 0)
      .toNumber();

    profit.totalProfit = new Big(profit?.splitFromDeal || 0)
      .add(profit?.splitTotalFromGap || 0)
      .add(profit?.splitTotalFromServiceWarranty || 0)
      .add(wfdExtraProfit || 0)
      .add(deal?.data?.info.taxesAndFees.acquisitionFee || 0)
      .add(deal?.data?.info.taxesAndFees.wfdMinimumFee || 0)
      .toNumber();

    profit.totalReserveProfit = new Big(profit?.splitFromDeal || 0)
      .add(profit?.extraReserveProfit || 0)
      .add(deal?.data?.info?.taxesAndFees?.wfdMinimumFee || 0)
      .add(deal?.data?.info?.taxesAndFees?.acquisitionFee || 0)
      .toNumber();

    profit.totalProductsProfit = new Big(profit.splitTotalFromGap || 0)
      .add(extraGAPProfit || 0)
      .add(profit?.splitTotalFromServiceWarranty || 0)
      .add(extraServiceWarrantyProfit || 0)
      .toNumber();
  }

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          wfdProfit: {
            ...deal?.data?.info?.profit?.wfdProfit,
            ...profit
          }
        }
      }
    }
  };
};

const calcTotalInsuranceProfit = (deal: Deal, type: InsuranceType): Deal => {
  const insuranceProfit = (deal?.data?.info?.aftermarketOptions?.insurances || [])
    .filter((insurance) => insurance?.chosenRate?.insuranceType === type)
    .reduce(function (acc, insuranceData) {
      return acc + (insuranceData?.chosenRate?.profit ?? 0);
    }, 0);

  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        profit: {
          ...deal?.data?.info?.profit,
          ...(type === "gap"
            ? { totalGAPProfit: insuranceProfit }
            : { totalServiceWarrantyProfit: insuranceProfit })
        }
      }
    }
  };
};
const calcDownPayment = (deal: Deal): Deal => {
  const downPayment =
    (deal?.data?.info?.price?.totalCash || 0) +
    (deal?.data?.info?.price?.rebates || 0) +
    (deal?.data?.info?.price?.netTrade > 0 ? deal?.data?.info?.price?.netTrade : 0);
  return {
    ...deal,
    data: {
      ...deal?.data,
      info: {
        ...deal?.data?.info,
        payment: {
          ...deal?.data?.info?.payment,
          downPayment
        }
      }
    }
  };
};

const calcAmounts = (deal: Deal): Deal => {
  deal = calcDownPayment(deal);
  deal = calcTotalAftermarket(deal);
  deal = calcTaxableAmount(deal);
  deal = calcTotalTaxes(deal);
  deal = calcTotalFees(deal);
  deal = calcDealTotal(deal);

  deal = calcMonthlyPayment(deal);
  deal = calcTotalOfPayments(deal);
  deal = calcTotalSalePrice(deal);
  return deal;
};
const setSplitMethod = (deal: Deal, oldDeal: Deal): Deal => {
  if (deal?.data?.dealership?._id !== oldDeal?.data?.dealership?._id) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info.payment,
            splitMethod: deal?.data?.dealership?.data?.info?.relationType ?? "fulltimeF&I"
          }
        }
      }
    };
  }
  if (!deal?.data?.info?.payment?.splitMethod) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info.payment,
            splitMethod: "fulltimeF&I"
          }
        }
      }
    };
  }
  return deal;
};
const setReserveMethodType = (deal: Deal): Deal => {
  if (!deal?.data?.info?.profit?.wfdProfit?.reserveMethodType) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          profit: {
            ...deal?.data?.info?.profit,
            wfdProfit: {
              ...deal?.data?.info?.profit?.wfdProfit,
              reserveMethodType: ReserveMethod.fixed
            }
          }
        }
      }
    };
  }
  return deal;
};

const findMarkupTableRate = (
  deal: Deal,
  reserves: Reserves | undefined,
  content: any[] = []
): { reservePercentage: number; markup: number; isDefault?: boolean }[] => {
  try {
    if (!reserves) return [];

    if (reserves.name === "No condition") {
      for (const req of reserves.requirements) {
        if (req?.content) {
          content = [...content, req.content];
        } else content = findMarkupTableRate(deal, req.child || req?.vertical, content);
      }
    }
    const criteriaValue = getByPath(deal, reserves?.path)?.toString();
    const foundRequirements = reserves?.requirements?.filter((requirement) => {
      switch (requirement?.type) {
        case "==":
          return requirement?.criteria === criteriaValue;
        case ">":
          return parseFloat(criteriaValue) > parseFloat(requirement.criteria);
        case "<":
          return parseFloat(criteriaValue) < parseFloat(requirement?.criteria);
        case ">=":
          return parseFloat(criteriaValue) >= parseFloat(requirement?.criteria);
        case "<=":
          return parseFloat(criteriaValue) <= parseFloat(requirement?.criteria);
        default:
          return [];
      }
    });

    if (foundRequirements) {
      for (const req of foundRequirements) {
        if (req?.content) {
          content = [...content, req.content];
        } else content = findMarkupTableRate(deal, req.child || req?.vertical, content);
      }
    } else {
      return [];
    }
    return content;
  } catch (error) {
    return [];
  }
};

const findRate = (deal: Deal, reserves: Reserves | undefined): false | Content => {
  try {
    if (!reserves) return false;
    const criteriaValue = getByPath(deal, reserves?.path)?.toString();
    const foundRequirements = reserves?.requirements?.filter((requirement) => {
      if (requirement?.criteria === "") return true;
      else
        switch (requirement?.type) {
          case "==":
            return requirement?.criteria === criteriaValue;
          case ">":
            return parseFloat(criteriaValue) > parseFloat(requirement.criteria.replace(",", ""));
          case "<":
            return parseFloat(criteriaValue) < parseFloat(requirement?.criteria.replace(",", ""));
          case ">=":
            return parseFloat(criteriaValue) >= parseFloat(requirement?.criteria.replace(",", ""));
          case "<=":
            return parseFloat(criteriaValue) <= parseFloat(requirement?.criteria.replace(",", ""));
          case "in":
            if (Array.isArray(requirement.criteria)) {
              return requirement.criteria?.includes(criteriaValue);
            }
            return false;
          default:
            return false;
        }
    });

    if (foundRequirements) {
      let content: false | Content = false;
      for (const req of foundRequirements) {
        if (req?.content) {
          content = req.content;
        } else content = findRate(deal, req.child || req?.vertical);
        if (content) break;
      }

      return content;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const sortReserveByPriority = (
  reserveData: ReserveData[],
  relationType: RelationTypes
): ReserveData[] | undefined => {
  if (reserveData && relationType && reserveData.length > 0) {
    reserveData.sort((x, y) => y.priority - x.priority);
    return reserveData;
  }
  return;
};

const getPivotTableData = (deal: Deal): false | Content => {
  const relationType: RelationTypes = deal?.data?.info?.payment?.splitMethod || "fulltimeF&I";
  const reserve =
    deal?.data?.lender?.data?.info?.reserves?.[DealTypeToReserve[deal.data.info.type]];
  const contractDate = deal?.data?.info?.dealDates?.contractDate;
  if (contractDate && reserve) {
    const filteredByDate = reserve?.filter((res) => {
      return (
        new Date(res.startDate).getTime() <= new Date(contractDate).getTime() &&
        (!res.endDate || new Date(res.endDate).getTime() >= new Date(contractDate).getTime())
      );
    });
    const sortedReserves = sortReserveByPriority(filteredByDate, relationType);

    let rate: Content | false = false;
    if (sortedReserves)
      for (const res of sortedReserves) {
        rate = findRate(deal, res?.reserves?.[relationType]);
        if (rate) break;
      }

    return rate ?? false;
  }
  return false;
};

const setSplit = (deal: Deal): Deal | string => {
  if (
    typeof deal?.data?.dealership?.data?.info?.agreement?.wfdSplit === "number" &&
    typeof deal?.data?.dealership?.data?.info?.agreement?.dealershipSplit === "number" &&
    deal?.data?.dealership?.data?.info?.relationType === "fulltimeF&I"
  ) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            dealershipSplit: deal?.data?.dealership?.data?.info?.agreement?.dealershipSplit,
            wfdSplit: deal?.data?.dealership?.data?.info?.agreement?.wfdSplit,
            dealershipGAPSplit: deal?.data?.dealership?.data?.info?.agreement?.dealershipGAPSplit,
            wfdGAPSplit: deal?.data?.dealership?.data?.info?.agreement?.wfdGAPSplit,
            dealershipWarrantySplit:
              deal?.data?.dealership?.data?.info?.agreement?.dealershipWarrantySplit,
            wfdWarrantySplit: deal?.data?.dealership?.data?.info?.agreement?.wfdWarrantySplit
          }
        }
      }
    };
  }
  return deal?.data?.dealership?.data?.info?.relationType === "correspondent"
    ? deal
    : "Reserve split not found!";
};

const setReserve = (deal: Deal): Deal | string => {
  const relationType = deal.data.info.payment.splitMethod;
  if (!relationType) return deal;

  const rate = getPivotTableData(deal);
  if (rate) {
    switch (rate.reserveMethod) {
      case "markup foursight": {
        return {
          ...deal,
          data: {
            ...deal?.data,
            info: {
              ...deal?.data?.info,
              payment: {
                ...deal?.data?.info?.payment,
                reserveMethod: ReserveMethod[rate.reserveMethod ?? "markup foursight"],
                reserve: rate.reservePercentage,
                maxReserve: rate?.maxReserve || 0
              },
              profit: {
                ...deal?.data?.info?.profit,
                wfdProfit: {
                  ...deal?.data?.info?.profit?.wfdProfit,
                  reserveMethodType: ReserveMethod["fixed"]
                }
              }
            }
          }
        };
      }
      case "fixed":
      case "percentage":
        const reserve =
          (relationType === "correspondent"
            ? rate.reserveMethod === "fixed"
              ? rate?.reserveFixed
              : rate?.reservePercentage
            : rate.reserveMethod === "fixed"
            ? rate?.dealerSplitFixed
            : rate?.dealerSplitPercentage) || 0;

        return {
          ...deal,
          data: {
            ...deal?.data,
            info: {
              ...deal?.data?.info,
              payment: {
                ...deal?.data?.info?.payment,
                reserveMethod: ReserveMethod[rate.reserveMethod ?? "percentage"],
                reserve: reserve,
                maxReserve: rate?.maxReserve || 0,
                correspondentPercent: rate?.correspondentReservePercentage || 0
              },
              profit: {
                ...deal?.data?.info?.profit,
                wfdProfit:
                  relationType === "fulltimeF&I"
                    ? {
                        ...deal?.data?.info?.profit?.wfdProfit,
                        reserverPercentage: rate?.extraReservePercentage || 0,
                        extraReserveProfit: rate?.extraReserveFixed || 0,
                        reserveMethodType: ReserveMethod[rate?.reserveMethod ?? "percentage"]
                      }
                    : {
                        ...deal?.data?.info?.profit?.wfdProfit,
                        reserverPercentage: 0,
                        extraReserveProfit: 0,
                        reserveMethodType: ReserveMethod[rate?.reserveMethod ?? "percentage"]
                      }
              }
            }
          }
        };
      case "markup":
        const defaultMarkup = deal?.data?.info?.payment?.markupData;
        return {
          ...deal,
          data: {
            ...deal.data,
            info: {
              ...deal.data.info,
              payment: {
                ...deal.data.info.payment,
                reserveMethod: ReserveMethod.markup,
                reserve: defaultMarkup?.dealerSplitPercentage,
                ...(relationType === "correspondent"
                  ? { correspondentPercent: defaultMarkup?.reservePercentage }
                  : {}),
                maxReserve: rate?.maxReserve,
                markupData: defaultMarkup
              },
              profit: {
                ...deal.data.info.profit,
                wfdProfit: {
                  ...deal.data.info.profit?.wfdProfit,
                  reserveMethodType: ReserveMethod.percentage,
                  reserverPercentage:
                    relationType === "fulltimeF&I" ? defaultMarkup?.extraReservePercentage || 0 : 0,
                  extraReserveProfit: defaultMarkup?.extraReserveFixed || 0
                }
              }
            }
          }
        };
      case "formula":
        const interestRate = deal?.data?.info?.payment?.interestRate ?? 0;
        if (rate.formula === "min(<interest>/2,2.25)" && interestRate !== 0) {
          const RESERVE = rate.dealerSplitPercentage || 0;
          const MAXRESERVE = 2.25;

          const extraReserve = new Big(
            Math.min(new Big(interestRate).div(new Big(2)).toNumber(), MAXRESERVE)
          )
            .sub(new Big(RESERVE))
            .toNumber();

          return {
            ...deal,
            data: {
              ...deal?.data,
              info: {
                ...deal?.data?.info,
                payment: {
                  ...deal?.data?.info?.payment,
                  reserveMethod: ReserveMethod.percentage,
                  reserve: RESERVE || 0,
                  maxReserve: rate?.maxReserve || 0
                },
                profit: {
                  ...deal?.data?.info?.profit,
                  wfdProfit: {
                    ...deal?.data?.info?.profit?.wfdProfit,
                    reserverPercentage: extraReserve || 0,
                    reserveMethodType: ReserveMethod["min(<interest>/2,2.25)"]
                  }
                }
              }
            }
          };
        }
        break;

      default:
        return deal;
    }
  }
  return "Reserve rate not found!";
};

const setDefaultAccounting = (deal: Deal): Deal => {
  if (deal.data.info.accounting) return deal;
  else
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          accounting: {
            copied: false,
            payment: {
              fundedAmount: 0,
              wfdSplit: 0,
              dealershipSplit: 0,
              wfdGAPSplit: 0,
              dealershipGAPSplit: 0,
              wfdWarrantySplit: 0,
              dealershipWarrantySplit: 0,
              buyPercent: 0,
              correspondentPercent: 0,
              reserve: 0,
              daysToFirstPayment: 30,
              type: "monthly",
              monthlyPayment: 0,
              numberOfPayments: 0,
              interestRate: 0,
              amountFinanced: 0,
              totalOfPayments: 0,
              totalSalePrice: 0,
              dealTotal: 0,
              totalAftermarket: 0,
              totalTaxes: 0,
              totalFees: 0,
              splitMethod: "fulltimeF&I",
              downPayment: 0,
              taxableAmount: 0,
              localTaxes: 0,
              stateTaxes: 0
            },
            profit: {
              wfdProfit: {
                reserveMethodType: ReserveMethod.fixed,
                reserveCommission: 0,
                splitFromDeal: 0,
                splitTotalFromGap: 0,
                splitTotalFromServiceWarranty: 0,
                totalProfit: 0,
                extraReserveProfit: 0
              },
              totalGAPProfit: 0,
              totalServiceWarrantyProfit: 0,
              dealershipProfit: {
                reserveCommission: 0,
                splitFromDeal: 0,
                splitTotalFromGap: 0,
                splitTotalFromServiceWarranty: 0,
                totalProfit: 0
              },
              representativeProfit: {
                commission: 0
              },
              managerProfit: {
                commissionableAmount: 0,
                commission: 0
              }
            },
            taxesAndFees: {
              isFixedTax: false,
              titleTransfer: 0,
              license: 0,
              documentRegistrationFees: 0,
              filingFees: 0,
              otherFees: 0,
              fundingFees: undefined,
              totalMembershipFees: 0,
              totalUCCFees: 0,
              wfdMinimumFee: 0,
              acquisitionFee: 0,
              lenderAcquisitionFee: 0,
              detailed: {
                registrationTax: 0,
                plateFee: 0,
                tempPlateFee: 0,
                wheelageTax: 0,
                psVehicleFee: 0,
                transferTax: 0,
                titleTransferFee: 0,
                techSurchargeFee: 0,
                lienFee: 0,
                deputyFilingFee: 0,
                exciseTax: 0,
                licenseAndRegFee: 0,
                processingFee: 0,
                contributionFee: 0
              }
            },
            warrantyPrice: 0,
            gapPrice: 0,
            ecoPrice: 0,
            prePaidMaintenancePrice: 0,
            paintAndFabricationPrice: 0,
            wheelAndTirePrice: 0
          }
        }
      }
    };
};
const setBuyRate = (deal: Deal, oldDeal: Deal): Deal => {
  if (typeof deal.data.info.payment?.markupData?.markup === "number") {
    const buyPercent =
      (deal?.data?.info?.payment?.buyPercent === undefined ||
        deal?.data?.info?.payment?.buyPercent === null) &&
      deal?.data?.info?.payment?.interestRate
        ? deal?.data?.info?.payment?.interestRate
        : deal?.data?.info?.payment?.buyPercent;

    return {
      ...deal,
      data: {
        ...deal.data,
        info: {
          ...deal.data.info,
          payment: {
            ...deal.data.info.payment,
            buyPercent,
            interestRate: new Big(buyPercent || 0)
              .add(new Big(deal.data.info.payment?.markupData?.markup || 0))
              .toNumber()
          }
        }
      }
    };
  } else {
    return deal;
  }
};

const clearMarkupData = (deal: Deal, oldDeal: Deal): Deal => {
  if (deal?.data?.lender?._id !== oldDeal?.data?.lender?._id)
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          payment: {
            ...deal?.data?.info?.payment,
            markupData: undefined
          }
        }
      }
    };
  return deal;
};
const setFundingPaymentMethod = (deal: Deal): Deal => {
  if (!deal.data.info.fundingPaymentMethod) {
    return {
      ...deal,
      data: {
        ...deal?.data,
        info: {
          ...deal?.data?.info,
          fundingPaymentMethod: deal?.data?.dealership?.data?.info?.funding?.paymentMethod || "ACH"
        }
      }
    };
  }
  return deal;
};
const getPowerSportCost = (apr: number, term: number, unitType?: string) => {
  const type = unitType
    ? ["Cruiser / Touring", "Scooter / Moped"].includes(unitType)
      ? "Bike" //Cruiser/Touring & Scooter
      : [
          "ATV",
          "UTV",
          "Snowmobile",
          "Sport Bike",
          "Jet Ski",
          "Lehman Trike",
          "Off-Road",
          "Sport",
          "Can-Am",
          "Dual bikes"
        ].includes(unitType)
      ? "Powersport" // Snowmoblile, ATV, UTV etc..
      : false
    : false;
  if (unitType === "Scooter / Moped") {
    return 50;
  } else if (term >= 1 && term <= 60) {
    if (apr < 14) {
      return type === "Bike" ? 218 : type === "Powersport" ? 293 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 246 : type === "Powersport" ? 345 : 0;
    }
  } else if (term >= 61 && term <= 72) {
    if (apr < 14) {
      return type === "Bike" ? 267 : type === "Powersport" ? 383 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 311 : type === "Powersport" ? 465 : 0;
    }
  } else if (term >= 73 && term <= 84) {
    if (apr < 14) {
      return type === "Bike" ? 327 : type === "Powersport" ? 494 : 0;
    } else if (apr > 13) {
      return type === "Bike" ? 389 : type === "Powersport" ? 609 : 0;
    }
  }
  return 0;
};

const getAutomotiveCost = (amountFinanced: number, term: number, date?: string) => {
  let price = 0;
  const changeDate = new Date("2024-09-03");

  if (!date || new Date(date).getTime() >= changeDate.getTime()) {
    if (term >= 1 && term <= 12) {
      price = 301;
    } else if (term >= 13 && term <= 24) {
      price = 312;
    } else if (term >= 25 && term <= 36) {
      price = 326;
    } else if (term >= 37 && term <= 48) {
      price = 349;
    } else if (term >= 49 && term <= 60) {
      price = 356;
    } else if (term >= 61 && term <= 72) {
      price = 454;
    } else if (term >= 73 && term <= 84) {
      price = 582;
    }
  } else {
    if (term >= 1 && term <= 12) {
      price = 281;
    } else if (term >= 13 && term <= 24) {
      price = 289;
    } else if (term >= 25 && term <= 36) {
      price = 300;
    } else if (term >= 37 && term <= 48) {
      price = 318;
    } else if (term >= 49 && term <= 60) {
      price = 324;
    } else if (term >= 61 && term <= 72) {
      price = 407;
    } else if (term >= 73 && term <= 84) {
      price = 513;
    }
    if (amountFinanced > 100_000 && price > 0) {
      return price + 70;
    }
  }

  return price;
};
const setInsuranceCost = (deal: Deal, insurance: CustomInsurance) => {
  const interestRate = deal?.data?.info?.payment?.interestRate;
  const dealType = deal?.data?.info?.type;
  const unitType = deal?.data?.info?.vehicle?.unitType;
  const amountFinanced = deal?.data?.info?.payment?.dealTotal;
  const insuranceType = insurance.chosenRate.insuranceType;
  const provider = insurance?.provider;

  let cost = 0;

  if (dealType === "Automotive" && provider === "Protective" && insuranceType === "gap") {
    const months =
      insurance?.chosenRate?.customMonthsEnabled &&
      typeof insurance?.chosenRate?.customMonths === "number" &&
      deal?.data?.info?.payment?.numberOfPayments === 87
        ? insurance?.chosenRate?.customMonths
        : deal?.data?.info?.payment?.numberOfPayments;
    cost = getAutomotiveCost(amountFinanced, months, deal?.data?.info?.dealDates?.contractDate);
    return {
      ...insurance,
      chosenRate: {
        ...insurance?.chosenRate,
        dealerCostPrice: cost,
        remitPrice: cost,
        months,
        profit: parseFloat(((insurance.chosenRate.retailPrice ?? 0) - (cost ?? 0)).toFixed(2))
      }
    };
  } else if (dealType === "Power Sport" && provider === "XtraRide") {
    cost = getPowerSportCost(interestRate, deal?.data?.info?.payment?.numberOfPayments, unitType);
    return {
      ...insurance,
      chosenRate: {
        ...insurance?.chosenRate,
        dealerCostPrice: cost,
        remitPrice: cost,
        months: deal.data.info.payment.numberOfPayments,
        profit: parseFloat(((insurance.chosenRate.retailPrice ?? 0) - (cost ?? 0)).toFixed(2))
      }
    };
  }
  return insurance;
};
const calculateInsurancesCost = (deal: Deal): Deal => {
  return {
    ...deal,
    data: {
      ...deal.data,
      info: {
        ...deal.data.info,
        aftermarketOptions: {
          ...(deal?.data?.info?.aftermarketOptions ?? {}),
          insurances: (deal?.data?.info?.aftermarketOptions?.insurances || [])?.map((insurance) =>
            insurance.type === "Custom" ? setInsuranceCost(deal, insurance) : insurance
          )
        }
      }
    }
  };
};
const calculateInsurancesTotals = (deal: Deal): Deal => {
  const insurances = deal?.data?.info?.aftermarketOptions?.insurances;
  const totalInsuranceSellPrice = Array.isArray(insurances)
    ? insurances.reduce(
        function (acc, insuranceData) {
          const type = insuranceData?.chosenRate?.insuranceType;
          switch (type) {
            case "gap":
              acc.totalGAPSellPrice += insuranceData?.chosenRate?.retailPrice;
              acc.totalGAPRemitPrice += insuranceData?.chosenRate?.remitPrice;
              acc.totalGAPDealerCostPrice += insuranceData?.chosenRate?.dealerCostPrice;
              break;
            case "service warranty":
              acc.totalServiceWarrantySellPrice += insuranceData?.chosenRate?.retailPrice;
              acc.totalServiceWarrantyRemitPrice += insuranceData?.chosenRate?.remitPrice;
              acc.totalServiceWarrantyDealerCostPrice += insuranceData?.chosenRate?.dealerCostPrice;
              break;
            case "paint and fabrication":
              acc.totalPaintAndFabricationPrice += insuranceData?.chosenRate?.retailPrice;
              break;
            case "pre-paid maintenance":
              acc.totalPrePaidMaintenancePrice += insuranceData?.chosenRate?.retailPrice;
              break;
            case "eco":
              acc.totalEcoPrice += insuranceData?.chosenRate?.retailPrice;
              break;
            case "wheel and tire":
              acc.totalWheelAndTirePrice += insuranceData?.chosenRate?.retailPrice;
              break;
            case undefined:
            case null:
              break;
            default:
              throw new Error(`Unexpected object ${type}`);
          }

          return acc;
        },
        {
          totalGAPSellPrice: 0,
          totalServiceWarrantySellPrice: 0,
          totalPaintAndFabricationPrice: 0,
          totalPrePaidMaintenancePrice: 0,
          totalEcoPrice: 0,
          totalWheelAndTirePrice: 0,
          totalGAPRemitPrice: 0,
          totalServiceWarrantyRemitPrice: 0,
          totalServiceWarrantyDealerCostPrice: 0,
          totalGAPDealerCostPrice: 0
        }
      )
    : {
        totalGAPSellPrice: 0,
        totalServiceWarrantySellPrice: 0,
        totalPaintAndFabricationPrice: 0,
        totalPrePaidMaintenancePrice: 0,
        totalEcoPrice: 0,
        totalWheelAndTirePrice: 0,
        totalGAPRemitPrice: 0,
        totalServiceWarrantyRemitPrice: 0,
        totalServiceWarrantyDealerCostPrice: 0,
        totalGAPDealerCostPrice: 0
      };

  const aftermarketOptions = deal.data.info.aftermarketOptions ?? {};
  return {
    ...deal,
    data: {
      ...deal.data,
      info: {
        ...deal.data.info,
        aftermarketOptions: {
          ...aftermarketOptions,
          ...totalInsuranceSellPrice
        }
      }
    }
  };
};

const addWasInStatus = (deal: Deal) => {
  deal.data.info.wasInStatus = Object.values(DealStatus).reduce(
    (acc: Record<DealStatus, number>, status) => ({
      ...(acc ?? {}),
      [status]: Number(
        deal?.data?.info?.statusHistory?.some((history) => history?.status === status)
      )
    }),
    (undefined as unknown) as Record<DealStatus, number>
  );
  return deal;
};

const addIsInStatus = (deal: Deal) => {
  deal.data.info.isInStatus = Object.values(DealStatus).reduce(
    (acc: Record<DealStatus, number>, status) => ({
      ...(acc ?? {}),
      [status]: Number(deal.data.info.status === status)
    }),
    (undefined as unknown) as Record<DealStatus, number>
  );
  return deal;
};

const extractCreditScores = (deal: Deal) => {
  const applicantCreditBureau = deal?.data?.creditBureaus?.reverse()?.find((creditBureau) => {
    return ["joint", "applicant"]?.includes(creditBureau?.data?.type);
  });

  const applicantPrequalify = deal?.data?.creditPrequlify?.reverse()?.find((creditPrequlify) => {
    return ["joint", "applicant"]?.includes(creditPrequlify?.data?.type);
  });
  const coApplicantCreditBureau = deal?.data?.creditBureaus?.reverse()?.find((creditBureau) => {
    return ["joint", "coApplicant"]?.includes(creditBureau?.data?.type);
  });

  const coApplicantCreditPrequalify = deal?.data?.creditPrequlify
    ?.reverse()
    ?.find((creditPrequlify) => {
      return ["joint", "coApplicant"]?.includes(creditPrequlify?.data?.type);
    });

  const applicantScore =
    applicantCreditBureau?.data?.["700CreditResponse"]?.Results?.bureau_xml_data?.[0]
      ?.TU_Report?.[0]?.subject_segments?.[0]?.scoring_segments?.[0]?.scoring?.[0]?.score?.[0] ||
    applicantPrequalify?.data?.["700CreditResponse"]?.Results?.XML_Report?.[0]
      ?.Prescreen_Report?.[0]?.Score;

  const coApplicantScore =
    coApplicantCreditBureau?.data?.["700CreditResponse"]?.Results?.bureau_xml_data?.[0]
      ?.TU_Report?.[0]?.subject_segments?.[coApplicantCreditBureau?.data?.type === "joint" ? 1 : 0]
      ?.scoring_segments?.[0]?.scoring?.[0]?.score?.[0] ||
    coApplicantCreditPrequalify?.data?.["700CreditResponse"]?.Results?.XML_Report?.[0]
      ?.Prescreen_Report?.[0]?.Score;
  return {
    ...deal,
    data: {
      ...deal.data,
      info: {
        ...deal.data.info,
        extractedCreditScores: {
          applicant: {
            score:
              typeof applicantScore === "string"
                ? new Big(applicantScore || 0).round(2, 1).toNumber()
                : 0
          },
          coApplicant: {
            score:
              typeof coApplicantScore === "string"
                ? new Big(coApplicantScore || 0).round(2, 1).toNumber()
                : 0
          }
        }
      }
    }
  };
};
const addNamesToDeal = (deal: Deal): Deal => {
  if (deal.data.coApplicant) {
    // @ts-ignore
    deal.applicantNames = `${deal?.data?.applicant?.data?.info?.firstName || ""} ${
      deal?.data?.applicant?.data?.info?.lastName || ""
    } /  ${deal?.data?.coApplicant?.data?.info?.firstName || ""} ${
      deal?.data?.coApplicant?.data?.info?.lastName || ""
    }`;
  } else {
    // @ts-ignore
    deal.applicantNames = `${deal?.data?.applicant?.data?.info?.firstName || ""} ${
      deal?.data?.applicant?.data?.info?.lastName || ""
    }`;
  }

  //@ts-ignore
  deal.userNames = `${deal?.data?.user?.data?.info?.firstName || ""} ${
    deal?.data?.user?.data?.info?.lastName || ""
  }`;
  //@ts-ignore
  deal.repNames = `${deal?.data?.dealership?.data?.representative?.data?.info?.firstName || ""} ${
    deal?.data.dealership?.data?.representative?.data?.info?.lastName || ""
  }`;
  return deal;
};
const addEndDate = (deal: Deal) => {
  try {
    const contractDate = new Date(deal.data.info.dealDates.contractDate ?? "");
    contractDate.setMonth(contractDate.getMonth() + (deal.data.info.payment.numberOfPayments || 0));

    return {
      ...deal,
      data: {
        ...deal.data,
        info: {
          ...deal.data.info,
          potentialEndDate: contractDate.toISOString()
        }
      }
    };
  } catch (error) {
    return deal;
  }
};
const calculate = (deal: Deal, oldDeal: Deal): Deal => {
  if (SYSTEM === "FRONTEND") {
    deal = calculateInsurancesCost(deal);
  }
  deal = calculateInsurancesTotals(deal);
  deal = setFundingPaymentMethod(deal);
  deal = clearMarkupData(deal, oldDeal);
  deal = setDefaultMarkup(deal, oldDeal);
  deal = setBuyRate(deal, oldDeal);
  deal = setDefaultAccounting(deal);
  deal = setSplitMethod(deal, oldDeal);
  deal = setReserveMethodType(deal);
  deal = calcSplitPercentage(deal, oldDeal);
  deal = calcNetTrade(deal);
  deal = calcAmounts(deal);
  deal = calcTotalInsuranceProfit(deal, "gap");
  deal = calcTotalInsuranceProfit(deal, "service warranty");
  deal = calcTotalTrade(deal, oldDeal);
  deal = calcReserveCommission(deal);
  deal = calcDealershipProfit(deal);
  deal = calcWfdProfit(deal);
  deal = calcGrossProfit(deal);
  deal = calcGrossRevenues(deal);
  deal = calcRepresentativeCommission(deal);
  deal = calcManagerCommission(deal);
  deal = calcAccountingGrossProfit(deal);
  deal = calcAccountingManagerCommission(deal);
  deal = extractCreditScores(deal);
  deal = addWasInStatus(deal);
  deal = addIsInStatus(deal);
  deal = addNamesToDeal(deal);
  deal = addEndDate(deal);

  return deal;
};

export {
  actualPrice,
  calcAccountingCommissionableAmount,
  calcAmounts,
  calcCommissionableAmount,
  calcDealTotalWithAmortization,
  calcFinanceCharge,
  calcMonthlyPayment,
  calcReserveCommission,
  calcSplitPercentage,
  calcTaxableAmount,
  calculate,
  calcWfdProfit,
  findCommission,
  findMarkupTableRate,
  findRate,
  getDealershipProgramByDeal,
  getPivotTableData,
  matchConditions,
  resolveTier,
  setDefaultMarkup,
  setReserve,
  setSplit,
  sortReserveByPriority
};
